// USA

const projectname = "UMC";

export const locale = {
  lang: "in",
  data: {
    UPDATE: "सुधारें",
    LIST: "सूची",
    HOME: "होम",
    ADD: "जोड़ें",
    NAME: "नाम",
    ADSH: "Adsh",
    CIK: "CIK",
    SIC: "SIC",
    COUNTRY: "देश ",
    STPRBA: "राज्य",
    CITY: "शहर ",
    ZIP: "ZIP",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "id",
    Usergroup: "यूजर ग्रुप",
    QUESTION_TYPE: "प्रश्न के प्रकार",
    ANSWER_INPUT: "उत्तर",
    ANSWER_TYPE: " उत्तर प्रकार",
    QUESTION_TEXT: "प्रश्न विषय",
    DESCRIPTION: "विवरण",
    FIRSTNAME: "पहला नाम",
    LASTNAME: "उप नाम",
    EMAIL: "ईमेल",
    PHONE: "फ़ोन ",
    COUNTRY_CODE: "देश कोड",
    REGIONS: "क्षेत्र",
    STATES: "राज्य",
    CITIES: "शहर",
    LOCALE: "स्थान",
    COUNTRIES: "देश",
    VALUES: "मान",
    SETTINGTYPES: "सेटिंग टाइप्स ",
    ANSWER_OPTIONS: "उत्तर के विकल्प",
    TRANSLATE: " अनुवाद",
    TITLE: "शीर्षक",
    CONTACT_NO: "संपर्क नंबर",
    USERGROUPS: "यूजर ग्रुप",
    USERS: "उपयोगकर्ता",
    ZONES: "क्षेत्र",
    WARD: "परवरिश",
    CONVENIENCES: "जन सुविधा का नाम",
    TRANSLATOR: {
      SELECT: "भाषा का चयन करें",
    },
    MENU: {
      HOME: "घर",
      MAP: "नक्शा",
      NEW: "नया",
      ACTIONS: "कार्यवाही",
      CREATE_POST: "नई पोस्ट बनाएँ",
      PAGES: "पृष्ठों",
      WARD: "मुहल्ला",
      FEATURES: "विशेषताएं",
      APPS: "Apps",
      DASHBOARD: "डैशबोर्ड",
      ASSIGNMENT: "असाइनमेंट लिस्ट",
      USERS: "उपयोगकर्ता",
      MASTERS: "मास्टर्स",
      MODULES: "मॉड्यूल",
      COMPANY: "कंपनी",
      REGION: "क्षेत्र",
      COUNTRY: "देश",
      STATE: "राज्य",
      CITY: "शहर",
      DISTRICT: "जिला",
      ZONE: "क्षेत्र",
      TAG: "टैग",
      LANGUAGE: "भाषा",
      USERMANAGEMENT: "यूजर मैनेजमेंट",
      USERGROUP: "यूजर ग्रुप",
      PACKAGE: "पैकेज",
      SEC: "SEC",
      GLOBALSUCCESS: "वैश्विक सफलता",
      PDF: "PDF",
      FIELDGROUPMAPPING: "फ़ील्ड समूह मैपिंग",
      STATEMENTTYPENAME: "कथन प्रकार",
      FIELDMAPPING: "फील्ड मैपिंग",
      QUESTIONNAIRETOOL: "प्रश्नावली उपकरण",
      GLOBALSETTING: "वैश्विक सेटिंग",
      SETTING: "सेटिंग",
      SETTINGTYPE: "सेटिंग प्रकार",
      ACCESSLOGS: "प्रवेश लॉग",
      SURVEYMANAGEMENT: "सर्वेक्षण प्रबंधन",
      PUBLICCONVENIENCE: "सार्वजनिक सुविधा",
      EMAILTEMPLATE: "ईमेल टेम्पलेट",
      QUESTIONS: "प्रशन",
      CATEGORY: "वर्ग",
      MENU: "मेन्यू",
    },
    AUTH: {
      GENERAL: {
        OR: "अथवा",
        SUBMIT_BUTTON: "सबमिट",
        NO_ACCOUNT: "क्या आपके पास अकाउंट नहीं है?",
        SIGNUP_BUTTON: "साइन अप करें",
        FORGOT_BUTTON: "पासवर्ड भूल गए",
        BACK_BUTTON: "पीछे",
        PRIVACY: "गोपनीयता",
        LEGAL: "कानूनी",
        CONTACT: "संपर्क करें",
      },
      LOGIN: {
        TITLE: "लॉग इन करें",
        BUTTON: "साइन इन करें",
        SUCCESSMESSAGE: "सफलतापूर्वक लॉगिन",
      },
      FORGOT: {
        TITLE: "पासवर्ड भूल गए",
        DESC: "पासवर्ड बदलने के लिए अपना ईमेल डाले",
        SUCCESS:
          "लिंक आपकी रजिस्टर्ड ईमेल पर भेजा गया है जिसके इस्तेमाल से आप नया पासवर्ड बना सकते हैं",
      },
      SETPASSWORD: {
        TITLE: "पासवर्ड सेट करें",
        BUTTON: "रीसेट",
        SUCCESS: "पासवर्ड सफलतापूर्वक बदला गया",
      },
      REGISTER: {
        TITLE: "साइन अप करें",
        DESC: "अपना खाता बनाने के लिए विवरण डालें",
        SUCCESS: "आपका खाता सफलतापूर्वक रजिस्टर हो गया",
      },
      INPUT: {
        EMAIL: "ईमेल",
        FULLNAME: "पूरा नाम",
        PASSWORD: "पासवर्ड",
        CONFIRM_PASSWORD: "पासवर्ड की पुष्टि करे",
        USERNAME: "उपयोगकर्ता नाम",
        TITLE: "शीर्षक दर्ज करें",
        REMEMBER_ME: "याद रखें",
      },
      VALIDATION: {
        INVALID: "मान्य नहीं है",
        REQUIRED: "अपेक्षित",
        MIN_LENGTH: "न्यूनतम लंबाई",
        AGREEMENT_REQUIRED: "नियम और शर्तें मानना आपेक्षित",
        NOT_FOUND: "नहीं मिला",
        INVALID_LOGIN: "लॉगिन विवरण गलत है",
        REQUIRED_FIELD: "आवश्यक क्षेत्र",
        MIN_LENGTH_FIELD: "न्यूनतम लंबाई",
        MAX_LENGTH_FIELD: "अधिकतम लंबाई",
        INVALID_FIELD: "फ़ील्ड मान्य नहीं है",
        MUST_MATCH: "पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: "नक्शा देखें",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " | भाषा सूची",
        ADDTITLE: projectname + " | भाषा जोड़ें",
        EDITTITLE: projectname + " | भाषा अपडेट",
        LANGUAGELIST: "भाषा सूची",
        LANGUAGENAMEPLURAL: "भाषाएँ",
        LANGUAGENAMESINGULAR: "भाषा",
        NEWLANGUAGE: "नयी भाषा",
        DELETEDSELECTED: "चयनित भाषा हटाएं",
        UPDATESELECTED: "चयनित भाषाओं के लिए अद्यतन स्थिति",
        ADDNEW: "नई भाषा जोड़ें",
        UPDATE: "भाषा अपडेट करें",
        CREATEMESSAGE: "भाषा को सफलतापूर्वक बनाएं",
        UPDATEMESSAGE: "भाषा को सफलतापूर्वक अपडेट करें",
        EDIT: "भाषा संपादित करें",
        ADD: "भाषा जोड़ें",
        LOCALE: "स्थान",
        LOCALE_REQUIRED: "स्थान की आवश्यकता",
        LOCALE_INVALID: "स्थान अमान्य",
      },
      USERS: {
        LISTINGTITLE: projectname + " | उपयोगकर्ता सूची",
        USERSLIST: "उपयोगकर्ता सूची",
        NEWUSER: "नया उपयोगकर्ता",
        USERNAMEPLURAL: "उपयोगकर्ताओं",
        USERNAMESINGULAR: "उपयोगकर्ता",
        DELETEDSELECTED: "चयनित उपयोगकर्ता हटाएं",
        UPDATESELECTED: "चयनित उपयोगकर्ताओं के लिए अद्यतन स्थिति",
        ADDNEW: "नए उपयोगकर्ता को जोड़ना",
        UPDATE: "अपडेट यूजर",
        PASSWORD: "पासवर्ड",
        CONFIRMPASSWORD: "पासवर्ड की पुष्टि कीजिये",
        USERSUCCESS: "उपयोगकर्ता सफलतापूर्वक जोड़ा गया",
        FILTERUSERGROUP: "उपयोगकर्ता समूह फ़िल्टर करें",
        ADDTITLE: projectname + " | उपयोगकर्ता जोड़ें",
        EDITTITLE: projectname + " | उपयोगकर्ता अद्यतन",
        EDIT: "यूजर को संपादित करो",
        ADD: "उपयोगकर्ता जोड़ें",
        GOVT: "सरकार",
        OTHERGOVT: "सरकार के अलावा अन्य",
        GOVTEMP: "सरकारी कर्मचारी",
        NGO: "गैर-सरकारी संगठन (NGO)",
        PVT: "निजी संगठन",
        CONSTENT: "सलाहकार",
        SHG: "स्वयं सहायता समूह (SHG)",
        ACADEMIC: "शिक्षाविद",
        OCCUPATION: "व्यवसाय",
        ORGANIZTION: "संगठन",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " | उपयोगकर्ता समूह सूची",
        USERGROUPLIST: "उपयोगकर्ता समूह सूची",
        USERGROUPNAMEPLURAL: "उपयोगकर्ता समूह",
        USERGROUPNAMESINGULAR: "उपयोगकर्ता समूह",
        NEWUSERGROUP: "नया उपयोगकर्ता समूह",
        DELETEDSELECTED: "चयनित उपयोगकर्ता समूह हटाएं",
        UPDATESELECTED: "चयनित उपयोगकर्ता समूहों के लिए अद्यतन स्थिति",
        ADDNEW: "नया उपयोगकर्ता समूह जोड़ें",
        UPDATE: "अपडेट यूजर ग्रुप",
        ADDTITLE: projectname + " | उपयोगकर्ता समूह जोड़ें",
        EDITTITLE: projectname + " |  अद्यतन उपयोगकर्ता समूह",
      },
      QUESTION: {
        LISTINGTITLE: projectname + "प्रश्न सूची",
        ADDTITLE: projectname + "प्रश्न जोड़ें",
        EDITTITLE: projectname + "प्रश्न अपडेट",
        QUESTIONLIST: "प्रश्न सूची",
        QUESTIONNAMEPLURAL: "प्रश्न",
        QUESTIONNAMESINGULAR: "प्रश्न",
        NEWQUESTION: "नया सवाल",
        DELETEDSELECTED: "चयनित प्रश्न हटाएं",
        UPDATESELECTED: "चयनित प्रश्नों के लिए अद्यतन स्थिति",
        ADDNEW: "नया प्रश्न जोड़ें",
        UPDATE: "अपडेट प्रश्न",
        CREATEMESSAGE: "सफलतापूर्वक प्रश्न बनाएं",
        UPDATEMESSAGE: "प्रश्न सफलतापूर्वक अपडेट करें",
        EDIT: "प्रश्न संपादित करें",
        ADD: "प्रश्न जोड़ें",
        SINGLE_CHOICE: "एकल विकल्प",
        MULTIPLE_CHOICE: "बहुविकल्पी",
        QUESTION_IMAGES: "प्रश्न छवियाँ",
        ANSWER_LIMIT: "उत्तर सीमा",
        CHOICE: "विकल्प",
        NUMERIC: "संख्यात्मक",
        DATE: "दिनांक",
        UNIQUEID: "अद्वितीय आई डी",
        TEXT: "टेक्स्ट",
        IMAGE: "इमेज",
        BOTH: "दोनों",
        MULTIPLE_ANSWER: "बहुविकल्पी",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: "एकाधिक लेबल एकाधिक उत्तर",
        ANSWER_INPUT: "उत्तर इनपुट",
        ATTACH_FILES: "फ़ाइल संलग्न करें",
        QUESTION_TEXT: "प्रश्न पाठ",
        ANSWER_TYPE: "उत्तर टाइप",
        ANSWER_IMAGE: "उत्तर इमेज",
        MIN_RANGE: "न्यूनतम सीमा",
        MAX_RANGE: "अधिकतम सीमा",
        ANSWER: "उत्तर",
        QUESTION: "प्रश्न",
        SEQUENCE: "अनुक्रम",
        ENTER_SEQUENCE: "कृपया अनुक्रम दर्ज करें",
        INVALID_SEQUENCE: "अमान्य अनुक्रम",
        ADD_OPTION: "जोड़ें",
        ADD_MAXIMUM_OF: "आप अधिकतम .... जोड़ सकते हैं",
        ENTER_ANSWER: "कृपया उत्तर दर्ज करें",
        INVALID_ANSWER: "अमान्य उत्तर",
        EXCLUSIVE_RESPONSE: "पारस्परिक रूप से विशेष प्रतिक्रिया",
        ENTER_MAXIMUM_RANGE: "कृपया अधिकतम सीमा दर्ज करें",
        Maximum_number_minimum_number:
          "अधिकतम संख्या न्यूनतम संख्या से कम नहीं है",
        ENTER_MINIMUM_RANGE: "कृपया न्यूनतम सीमा दर्ज करें",
        INVALID_RANGE: "अमान्य श्रेणी (संख्यात्मक मान)",
        PLEASEENTERQUESTION: "कृपया प्रश्न दर्ज करें",
        INVALID_QUESTION: "अमान्य प्रश्न",
        QUESTIONMUSTBEATLEAST:
          "प्रश्न कम से कम 3 वर्ण लंबा होना चाहिए और  रिक्त स्थान की अनुमति नहीं है",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          "अधिकतम अपलोड का आकार 5 एमबी है। अनुमत फ़ाइल प्रकार jpg, png, jpeg हैं। अधिकतम image 228px * 200px का अपलोड कर सकते हैं",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE_ADD:
          "अधिकतम अपलोड का आकार 5 एमबी है। अनुमत फ़ाइल प्रकार jpg, png, jpeg हैं। अधिकतम image 228px * 200px का अपलोड कर सकते हैं",
        REVISION_FOR_THIS_QUESTION:
          "क्या आप इस प्रश्न के लिए संशोधन बनाना चाहते हैं?",
        CREATEMAPPINGMESSAGE: "प्रश्न मानचित्रण सफलतापूर्वक बनाएं",
        UPDATEMAPPINGMESSAGE: "सफलतापूर्वक सवाल मैपिंग अपडेट करें",
        DELETEMAPPINGMESSAGE: "प्रश्न मानचित्रण सफलतापूर्वक हटाएं",
        UPLOAD_ONLY: "आप केवल अपलोड कर सकते हैं",
        CONDITIONISMAPPED:
          "आप आदेश का अनुक्रम बदल नहीं सकते हैं क्योंकि स्थिति मैप की गई है",
      },
      MENU: {
        LISTINGTITLE: projectname + "मेन्यू सूची",
        ADDTITLE: projectname + "मेन्यू जोड़े",
        EDITTITLE: projectname + "मेन्यू अद्यतन",
        MENULIST: "मेन्यू सूची",
        MENUNAMEPLURAL: "मेन्यू",
        MENUNAMESINGULAR: "मेन्यू",
        NEWMENU: "नया मेन्यू",
        DELETEDSELECTED: "चयनित मेन्यू हटाएं",
        UPDATESELECTED: "चयनित मेन्यू के लिए अद्यतन स्थिति",
        ADDNEW: "नया मेन्यू जोड़े",
        UPDATE: "अपडेट मेन्यू ",
        CREATEMESSAGE: "मेनू सफलतापूर्वक बनाएं",
        UPDATEMESSAGE: "अपडेट मेनू सफलतापूर्वक",
        EDIT: "मेनू संपादित करें",
        ADD: "मेनू जोड़ें",
        VALUES: "मान",
        PARENT: "जनक",
        MODULE: "मापांक",
        PAGE: "पृष्ठ",
        ICON: "आइकन",
        ORDERING: "आदेश",
        VALIDATION: {
          SELECTTYPE: "कृपया मेनू प्रकार चुनें",
        },
      },
      SURVEY: {
        LISTINGTITLE: projectname + "सर्वेक्षण सूची",
        SURVEYLIST: "सर्वेक्षण सूची",
        NEWSURVEY: "नया सर्वेक्षण",
        SURVEYNAMEPLURAL: "सर्वेक्षण",
        SURVEYNAMESINGULAR: "सर्वेक्षण",
        DELETEDSELECTED: "चुने गए सर्वेक्षक को हटाएं",
        UPDATESELECTED: "चयनित सर्वेक्षणकर्ता के लिए अद्यतन स्थिति",
        ADDNEW: "नया सर्वेक्षण जोड़ें",
        UPDATE: "अपडेट सर्वे",
        PASSWORD: "पासवर्ड",
        CONFIRMPASSWORD: "पासवर्ड की पुष्टि कीजिये",
        SURVEYSUCCESS: "सर्वेक्षण सफलतापूर्वक जोड़ा गया",
        SURVEYQUESTIONSUBMIT: "सफलतापूर्वक सबमिट करें",
        SURVEYQUESTIONSAVE: "सफलतापूर्वक संचित करना",
        FILTERSURVEYGROUP: "फिल्टर सर्वे ग्रुप",
        ADDTITLE: projectname + "| सर्वेयर जोड़ें ",
        EDITTITLE: projectname + " | सर्वेयर अपडेट ",
        EDIT: "सर्वेक्षण संपादित करें",
        ADD: "नया सर्वेक्षण शुरू करें",
        SRNO: "अनु क्रमांक",
        SEARCH: "खोजें",
        SURVEYORNAME: "सर्वेयर का नाम",
        SURVEYQUESTIONTITLE: "सार्वजनिक उपयुक्तता का सर्वेक्षण",
        SURVEYBY: "टीम द्वारा सर्वेक्षण के लिए प्रपत्र",
        VALIDATIONTEXT: "चिह्नित प्रश्न अनिवार्य हैं।",
        NOTE: "ध्यान दें",
        SURVEYTEXT:
          "भरने के लिए ज़ोन और वार्ड अनिवार्य नहीं है। यदि आप इन सूचनाओं को जानते हैं, तो कृपया एक उपयुक्त विकल्प चुनें। ",
        PUBLICCONVENIENCENOTE:
          "यदि शौचालय का स्थान अलग है तो कृपया ड्रॉपडाउन से उपयुक्त स्थान का चयन करें",
      },
      COMMON: {
        DATE: "तारीख",
        GOTO: "के लिए जाओ",
        UNIQUEID: "यूनीक आई डी",
        COMPLETED: "पूरा हुआ",
        INPROGRESS: "चालू",
        CITYLIST: "शहरों की सूची",
        COUNT: "सर्वेक्षण गणना",
        LOGOUT: "लॉग आउट",
        NEXT: "अगला",
        PREVIOUS: "पिछला",
        HOME: "होम",
        ALL: "प्रत्येक",
        ACTIVE: "सक्रिय",
        INACTIVE: "निष्क्रिय",
        PLEASESELECT: "कृपया चुने",
        BASICINFORMATION: "मूलभूत जानकारी",
        COMPANYMANAGEMENT: "कंपनी प्रबंधन",
        COMPANYREGISTEARS: "कंपनी रजिस्ट्रार",
        CREATEDAT: "रचना तिथि",
        STATUS: "स्थिति",
        ACTION: "कार्यवाही",
        DELETE: "हटाएँ",
        TITLE: "शीर्षक",
        VIEW: "देखें",
        UPDATE: "संपादित करें",
        PARENT: "जनक",
        PIC: "Pic",
        SEARCHBTN: "खोजें",
        FIRSTNAME: "पहला नाम",
        FIRSTNAMEREQ: "कृपया पहला नाम निर्दिष्ट करें",
        FIRSTNAMEERROR: "पहला नाम अमान्य है",
        NAME: "नाम",
        LASTNAME: "उप नाम",
        LASTNAMEREQ: "कृपया अंतिम नाम निर्दिष्ट करें",
        LASTNAMEERROR: "अंतिम नाम अमान्य है",
        EMAIL: "ई मेल",
        EMAILREQ: "कृपया ईमेल निर्दिष्ट करें",
        EMAILERROR: "ईमेल अमान्य है",
        WEBSITE: "वेबसाइट",
        WEBSITEREQ: "कृपया वेबसाइट निर्दिष्ट करें",
        WEBSITEERROR: "वेबसाइट अमान्य है",
        FAX: "फैक्स",
        FAXERROR: "फैक्स अमान्य है",
        ADDRESS: "पता",
        MOBILENUMBER: "मोबाइल नंबर",
        DATEOFBIRTH: "जन्म की तारीख",
        DATEOFBIRTHERROR: "जन्म की तारीख दर्ज करें",
        SAVEBTN: "सेव करें",
        SUBMITBTN: "सबमिट करें",
        SEARCH: "खोजें",
        BACKBTN: "पीछे",
        UPDATEBTN: "अपडेट करें",
        RESETBTN: "रीसेट",
        CANCELBTN: "रद्द करें",
        REPORT: "रिपोर्ट",
        CITY: "शहर",
        COUNTRY: "देश",
        CODE: "कोड",
        ZONE: "क्षेत्र",
        WARD: "वार्ड",
        PUBLICCONVENIENCE: "सार्वजनिक सुविधाएं",
        SELECTCOUNTRY: "देश चुनिए",
        SELECTREGION: "क्षेत्र का चयन करें",
        SELECTSTATE: "राज्य चुनें",
        SELECTCITY: "शहर चुनें",
        SELECTZONE: "ज़ोन का चयन करें",
        SELECTWARD: "वार्ड का चयन करें",
        SELECTUSERGROUP: "यूजरग्रुप का चयन करें",
        STATE: "राज्य",
        PIN: "पिन",
        ZIPCODE: "पिन कोड",
        NORECORDS: "कोई रिकॉर्ड नहीं मिला",
        DELETEALL: "सभी हटा दो",
        UPDATESTATUS: "नवीनतम स्थिति",
        DESCRIPTION: "विवरण",
        DESIGNATION: "पदनाम",
        ENTERDESCRIPTION: "कृपया विवरण दर्ज करें",
        STATUSUPDATED: "स्थिति सफलतापूर्वक अपडेट कर दी गई है",
        SUCCESS: "सफलतापूर्वक",
        SELECTED: "चुना हुआ",
        STATUSSELECTEDUPDATE: "चयनित किये की स्थिति अपडेट हो गई है",
        PERMANTLYDELETE: "क्या आप इसे स्थायी रूप से हटाना सुनिश्चित करते हैं",
        DELETETEXT: "क्या आप इसे स्थायी रूप से हटाना सुनिश्चित करते हैं? ",
        ISDELETING: "हट रहा है",
        DELETESELECTED: "चयनित मिटाएं",
        UPDATESELECTED: "चयनित के लिए अद्यतन स्थिति",
        AMOUNT: "रकम",
        TIME: "समय",
        VALUE: "मानक",
        USERGROUP: "यूजर ग्रुप",
        SELECT: "चुने",
        VERSION: "संस्करण",
        TYPE_THREE_LETTERS: "तीन अक्षर टाइप करें और कुछ समय प्रतीक्षा करें",
        PERMISSION: "अनुमतियाँ",
        LABELSEARCH: "लेबल यहाँ लिखें",
        SELECTANYONE: "कोई एक चुने",
        IMAGEERROR: "कृपया छवि अपलोड करें",
        TEXTERROR: "कृपया विषय निर्दिष्ट करें",
        NUMBERERROR: "कृपया संख्या निर्दिष्ट करें",
        INVALIDFILE: "आपने अवैध फ़ाइल टाईप अपलोड की है",
        // tslint:disable-next-line: max-line-length
        PASSWORDHINT:
          " पासवर्ड में 8-20 अक्षर, कम से कम एक संख्या, कम से कम एक ऊपरी वर्ण, कम से कम एक कम वर्ण, कम से कम एक विशेष वर्ण हो।",
        OOPS: "ओह।",
        ERROR: "गलत",
        HELLO: "हैलो",
        WELCOMEBACK: "पुन: आने पर स्वागत है",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          "ऐसा लगता है कि आप पिछले सर्वेक्षण को पूरा नहीं कर सके। क्या आपने जहाँ छोड़ा था आप जारी रखना चाहेंगे या आप एक नया सर्वेक्षण प्रश्न शुरू करना चाहते हैं? ",
        CONTINUEWITH: "जारी रखें",
        OR: "या",
        PREVIOUSQUESTION: "पिछला प्रश्न",
        FRESHQUESTION: "ताजा प्रश्न",
        CONFIRM: "पुष्टि करें",
        AREYOUSURE: "क्या आप यकीन से पहले से शुरू करेंगें ?",
        CONFIRMIMAGE: "क्या आप इस छवि को हटाना सुनिश्चित करते हैं?",
        YES: "हां",
        NO: "ना",
        TEXTINVALIDE: "विषय में अमान्य वर्ण हैं",
        CONFIRMCONVENIENCE:
          "क्या आप सुनिश्चित करते हैं कि सार्वजनिक सुविधा नाम बदला जाए?",
        TERMSOFUSE: "उपयोग की शर्तें",
        PRIVACYPOLICY: "गोपनीयता नीति",
        FORHELPCONTACT: "मदद के लिए संपर्क करें",
        CLICKFORANEWSURVEY: "नया सर्वे शुरू करने के लिए क्लिक करें",
        VALIDATION: {
          INVALID_IMAGE:
            "आपने एक अमान्य फ़ाइल प्रकार या अधिकतम अपलोड फ़ाइल आकार (5 MB )अपलोड किया है:!",
          NAME_INVALID: "नाम में अमान्य वर्ण हैं",
          NAME_REQUIRED: "कृपया नाम निर्दिष्ट करें",
          USERGROUPREQUIRED: "कृपया उपयोगकर्ता समूह निर्दिष्ट करें",
          NAME_MIN_LENGTH: "नाम कम से कम 2 वर्ण लंबा होना चाहिए।",
          NAME_MAX_LENGTH: "50 से अधिक वर्ण नहीं हो सकते।",
          ZIP_REQUIRED: "कृपया जिपकोड निर्दिष्ट करें",
          ZIP_INVALID: "ज़िपकोड अमान्य है",
          CITY_REQUIRED: "कृपया शहर निर्दिष्ट करें",
          STATE_REQUIRED: "कृपया राज्य निर्दिष्ट करें",
          REGION_REQUIRED: "कृपया क्षेत्र निर्दिष्ट करें",
          COUNTRY_REQUIRED: "कृपया देश निर्दिष्ट करें",
          VALUE_REQUIRED: "कृपया मूल्य निर्दिष्ट करें",
          TITLE_INVALID: "शीर्षक में अमान्य वर्ण हैं",
          TITLE_REQUIRED: "कृपया शीर्षक निर्दिष्ट करें",
          TITLE_MIN_LENGTH: "नाम कम से कम 2 वर्ण लंबा होना चाहिए।",
          TITLE_MAX_LENGTH: "50 से अधिक वर्ण नहीं हो सकते।",
          PIN_REQUIRED: "कृपया पिनकोड निर्दिष्ट करें",
          PIN_REQUIRED_MIN_LENGTH: "पिन कम से कम 5 वर्ण लंबा होना चाहिए।",
          PIN_REQUIRED_MAX_LENGTH: "6 से अधिक वर्ण नहीं हो सकते।",
        },
        PATTERN: {
          // tslint:disable-next-line: quotemark
          // NAME : "[a-zA-Z '.&:_()-]*'"
          // tslint:disable-next-line: quotemark
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
          // tslint:disable-next-line: quotemark
          EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
          // tslint:disable-next-line: quotemark
          ADSH: "[0-9-]{20}",
          // tslint:disable-next-line: quotemark
          SIC: "[0-9]{2,50}",
          // tslint:disable-next-line: quotemark
          NUMBER_ONLY: "[0-9]+",
          // tslint:disable-next-line: quotemark
          FNAME_LNAME: "^[a-zA-Z]+\\s?[a-zA-Z]+$",
          // tslint:disable-next-line: quotemark
          CONTACT_NO: "[0-9]{10}",
          // tslint:disable-next-line: quotemark
          TAG: "[a-zA-Z']*",
          // tslint:disable-next-line: quotemark
          AMOUNT: "[0-9.]+",
          // tslint:disable-next-line: quotemark
          TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
        },
      },
      PROFILE: {
        NAME: "मेरी प्रोफाइल",
        LISTINGTITLE: projectname + "मेरी प्रोफाइल",
        PROFILESUCCESS: "प्रोफाइल को सफलतापूर्वक अपडेट किया गया",
        INVALID_IMAGE:
          "आपने एक अमान्य फ़ाइल प्रकार या अधिकतम अपलोड फ़ाइल आकार (5 MB )अपलोड किया है:!",
        PROFILE_IMAGE: "प्रोफ़ाइल छवि",
        BASIC_INFO: "बुनियादी जानकारी",
        PHONE: "संपर्क नंबर।",
        PHONEREQ: "संपर्क नंबर आवश्यक है",
        PHONEERROR: "कृपया एक वैध नंबर डालें",
        ADDRESS: "पता लिखिए",
        ADDRESSTAB: "पता",
        CHANGEPASSWORD: "पासवर्ड बदलें",
        PASSWORDNOTMATCH: "ओह ! पासवर्ड मेल नहीं खाता। ",
        PASSWORDREQUIRED: "पासवर्ड आवश्यक है",
        OLDPASSWORDREQUIRED: "पुराना पासवर्ड आवश्यक है",
        ENTEROLDPASSWORD: "पुराना पासवर्ड",
        NEWPASSWORDREQUIRED: "नया पासवर्ड आवश्यक है",
        ENTERNEWPASSWORD: "नया पासवर्ड",
        INVALIDNEWPASSWORD: "नया पासवर्ड मान्य नहीं है",
        INVALIDPASSWORD: "पासवर्ड मान्य नहीं है",
        NEWPASSWORDSAME: "नया पासवर्ड पुराने पासवर्ड के समान नहीं होना चाहिए",
        CONFIRMPASSWORD: "पासवर्ड की पुष्टि कीजिये",
        CONFIRMPASSWORDREQUIRED: "पासवर्ड की पुष्टि आवश्यक है",
        CONFIRMPASSWORDSAME: "नया पासवर्ड और पुष्टि पासवर्ड मान मेल नहीं खाते।",
        INVALIDOLDPASSWORD: "पुराना पासवर्ड मान्य नहीं है",
        CREATEUSERGROUP: "उपयोगकर्ता समूह सफलतापूर्वक बनाएं",
        UPDATEUSERGROUP: "उपयोगकर्ता समूह को सफलतापूर्वक अपडेट करें",
      },
      LOGIN: {
        WELCOME: "सेनिटेशन मैपिंग में आपका स्वागत है",
        // tslint:disable-next-line: max-line-length
        LONGTEXT:
          "यह उपकरण आपको स्वच्छता के संदर्भ में सार्वजनिक और सामुदायिक शौचालयों की मौजूदा स्थिति को समझने में मदद करेगा और आप शौचालय की सुविधाओं में क्यूबिकल वार बुनियादी ढांचे और स्वच्छता अंतराल का आकलन कर सकते हैं।",
        EMAIL: "ईमेल पता / फोन नंबर",
        EMAIL_VALIDATION: "कृपया ईमेल या मोबाइल नंबर दर्ज करें।",
        EMAIL_PATTERN:
          "गलत प्रारूप, यह एक वैध ईमेल या 10 अंकों का मोबाइल नंबर होना चाहिए।",
        ACCOUNT: "एक अकाउंट बनाने की आवश्यकता है?",
        REGISTER: "रजिस्टर करें",
        OTP: "OTP भेजें",
        ENTER_OTP: "ओटीपी दर्ज करें",
        ONE_TIME_PASS: "एक बार इस्तेमाल किये जाने वाला पासवर्ड",
        OTP_VALIDATION: "कृपया OTP दर्ज करें ...!",
        RESEND_OTP: "OTP पुन: भेजें",
        OTP_RESEND: "OTP पुन: भेजें",
        NOT_GET_OTP: "OTP प्राप्त नहीं हुआ?",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "स्वच्छता मैपिंग में आपका स्वागत है",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "स्वच्छता मानचित्रण शहरी स्थानीय निकायों के लिए एक व्यापक उपकरण है। यह स्वच्छ भारत मिशन-शहरी के तहत गठित आवास और शहरी मामलों के मंत्रालय (MoHUA) के ODF + प्रोटोकॉल के अनुपालन को सक्षम करने के लिए सार्वजनिक और सामुदायिक शौचालय मरम्मत, नवीनीकरण और रखरखाव को सरल बनाता है। इसमें शौचालय की सुविधाओं में रखरखाव, स्वच्छता और सीट वार बुनियादी ढांचे के अंतराल का आकलन करने के लिए सर्वेक्षण और मानचित्रण उपकरण शामिल हैं.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "यह उपकरण शहरी प्रबंधन केंद्र (UMC) द्वारा विकसित किया गया है, USAID के समर्थन से.",
        TERMSOFUSE: "उपयोग की शर्तें",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "सेवा की शर्तें इस एप्लिकेशन और इसकी सेवाओं के आपके उपयोग का प्रबंधन करती हैं। आवेदन का उपयोग करने से पहले कृपया इन नियमों और शर्तों को ध्यान से पढ़ें। एप्लिकेशन या सेवाओं का उपयोग करके, आप इन नियमों में उल्लिखित नीतियों सहित हमारे नियमों और शर्तों से बंधे होने के लिए सहमत हैं। ये नियम और शर्तें समय-समय पर संशोधित हो सकती हैं।",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "जबकि शहरी प्रबंधन केंद्र (UMC) यह सुनिश्चित करने के लिए हर संभव प्रयास करता है कि आवेदन की सामग्री सटीक और अद्यतित हो, लेकिन इसकी विश्वसनीयता, सटीकता या जानकारी की पूर्णता के लिए किसी भी फोरम में या उसके पास भेजे जाने का वारंट नहीं है। । UMC के पास किसी भी प्रकार के पूर्व सूचना के बिना इस एप्लिकेशन में शामिल जानकारी को पूरा या हटाने या अद्यतन करने का अधिकार और अधिकार है। इस एप्लिकेशन के माध्यम से सामग्री और जानकारी एकत्र की जा रही है",
        TEXT4: "सूचना और शिक्षा का उद्देश्य",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "इस एप्लिकेशन में शामिल सभी सामग्री, तस्वीरें, रिपोर्ट और पाठ सहित जानकारी UMC समूह की संपत्ति हैं और इस प्रकार कॉपीराइट के भारतीय कानूनों के तहत संरक्षित हैं। हमारी सेवाओं का उपयोग आपको हमारी सेवाओं या आपके द्वारा उपयोग की जाने वाली सामग्री में किसी भी बौद्धिक संपदा अधिकार का स्वामित्व नहीं देता है।",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "जब तक अन्यथा निर्दिष्ट या अधिसूचित नहीं किया जाता है कि प्रकाशित विशेष सामग्री / जानकारी इसके प्रतिबंधित उपयोग के लिए है, डाउनलोड करना, प्रतिलिपि बनाना, पुन: प्रस्तुत करना, संशोधित करना और पबियों को वितरित करने या व्यावसायिक रूप से उपयोग करने के लिए इस एप्लिकेशन से सामग्री को पुनर्प्रकाशित करना कॉपीराइट के स्वामित्व और मालिकाना अधिकारों के उल्लंघन में होगा - यूएमसी।",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "स्वच्छता मैपिंग में आपका स्वागत है",
        PRIVACYPOLICY: "गोपनीयता नीति",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "स्वच्छता मानचित्रण शहरी स्थानीय निकायों के लिए एक व्यापक उपकरण है। यह स्वच्छ भारत मिशन-शहरी के तहत गठित आवास और शहरी मामलों के मंत्रालय (MoHUA) के ODF + प्रोटोकॉल के अनुपालन को सक्षम करने के लिए सार्वजनिक और सामुदायिक शौचालय मरम्मत, नवीनीकरण और रखरखाव को सरल बनाता है। इसमें शौचालय की सुविधाओं में रखरखाव, स्वच्छता और सीट वार बुनियादी ढांचे के अंतराल का आकलन करने के लिए सर्वेक्षण और मानचित्रण उपकरण शामिल हैं.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "यह उपकरण शहरी प्रबंधन केंद्र (UMC) द्वारा विकसित किया गया है, USAID के समर्थन से",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "इस एप्लिकेशन का उद्देश्य SBM - MISAAL परियोजना के तहत सार्वजनिक और सामुदायिक शौचालयों के सर्वेक्षण डेटा को कैप्चर करना और ऐसे शौचालयों को चालू करने के लिए बजट की आवश्यकता को पेश करने के लिए इस तरह के डेटा को संसाधित करना है। ऐसा करते समय हम आपकी व्यक्तिगत जानकारी जैसे नाम, पेशा, ईमेल आईडी, संपर्क नंबर, शहर को पहचान सकते हैं या आवेदन के उपयोगकर्ता से संपर्क कर सकते हैं।",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "इस प्रक्रिया में, हम आपको विश्वास दिलाते हैं कि आपकी गोपनीयता की सुरक्षा हमारे लिए महत्वपूर्ण है। आपकी व्यक्तिगत जानकारी केवल आपकी सहमति से एक फॉर्म के माध्यम से हमारे द्वारा एकत्र की जाएगी। जबकि कुछ जानकारी आपसे एकत्र की जाएगी, हम डेटा और सिस्टम सुरक्षा के लिए हमारे सर्वर के सिस्टम लॉग से आईपी एड्रेस, डोमेन नाम, ब्राउज़र, ऑपरेटिंग सिस्टम आदि जैसे अन्य डेटा भी एकत्र कर सकते हैं।",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "UMC आपको विश्वास दिलाता है कि इस एप्लिकेशन के माध्यम से एकत्र की गई जानकारी कड़ाई से गोपनीय होगी और किसी भी तरीके से किसी तीसरे पक्ष को नहीं बताई जाएगी और केवल उसी तरीके से उपयोग की जाएगी जिस तरह से आपने सहमति दी है।",
      },
    },
  },
};
