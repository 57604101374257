<!--BODY START-->
<section class="main-content-area sticky-space">
	<div class="container">
		<form (ngSubmit)="f.form.valid" #f="ngForm">
			<div class="row text-break">
				<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 main-registration-area">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item"><a [routerLink]="['/']">home</a></li>
							<li class="breadcrumb-item active" aria-current="page">edit profile</li>
						</ol>
					</nav>

					<div class="section-title">
						<div class="row mt-5">
							<div class="col-sm-12 col-lg-9 col-md-12 pt-0 ">
								<h2 class="profile-name"
									title="{{ userData.firstname?userData.firstname+', '+userData.lastname:userData.companyname }}"
									[innerHtml]="userData.firstname?nameFormat(userData.firstname, userData.lastname):companyNameFormate(userData.companyname)">
								</h2>
								<p class="black-text mt-4 mb-0 mr-5">{{'PAGES.VIEW_PROFILE.USER_FROM' | translate}} {{ filterMyDate(userData.createdAt) }}
								</p>
								<p class="mr-5" *ngIf="regType == 0">
									{{'PAGES.VIEW_PROFILE.REGISTER' | translate}}  {{localUserData.city_id || ''}} {{userData.idnumber ? " n° " +
									userData.idnumber : ""}}
								</p>
								<p class="mr-5" *ngIf="regType == 1">
									{{
									localUserData.operationalheadquartersregions?userData.operationalheadquartersaddress
									+ " " +
									userData.operationalheadquartersstreetno + ", " +
									userData.operationalheadquarterscity + " " +
									userData.operationalheadquarterspostcode + " "
									+localUserData.operationalheadquartersregions[0].translations[0].name:''
									}}
								</p>
								<h3 class="blue-title mt-4" *ngIf="regType == 0">{{
									localUserData.iam?localUserData.iam[0].title:'' }}
								</h3>
								<h3 class="blue-title mt-4" *ngIf="regType == 1">Overview*</h3>

								<p class="mt-3 mb-0 mr-5" id="description">
									{{profileData.description==""||profileData.description==null? ('PAGES.EDIT_PROFILE.DESCRIPTION_MSG' | translate)
									:profileData.description}}
									<a href="javascript:void(0);" (click)="edit($event,'description')">
										<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
											alt="Modifica" title="Modifica">
									</a> *
								</p>
								<div style="display: none;" id="edit-description">
									<textarea type="text" class="form-control"
										placeholder="{{'PAGES.EDIT_PROFILE.DESCRIPTION_MSG' | translate}}"
										[(ngModel)]="profileData.description" #description="ngModel" name="description"
										id="textbox-edit-description" (blur)="hide($event,'description')" minlength="1"
										maxlength="140" required></textarea>
								</div>
								<div *ngIf="f.submitted && description.errors?.required" class="text-danger">
									{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}

								</div>
							</div>

							<div class="col-sm-12 col-lg-3 col-md-12 avatar">
								<div class="avatar-box mb-2">
									<div *ngIf="userData.pic == null || userData.pic ==''">
										<img *ngIf="localUserData.avatars"
											[src]="avatarBucket + localUserData.avatars.avatar" height="202px"
											width="202px" alt="avatar" title="avatar">
									</div>
									<div *ngIf="userData.pic != null && userData.pic !=''">
										<img [src]="profilePicBucket + userData.pic" height="202px" width="202px"
											alt="profile" title="profile">
									</div>
									<div class="overlay" *ngIf="userData.pic != null && userData.pic !=''">
										<a href="javascript:void(0);" class="icon" title="User Profile"
											(click)="removeProfilePic()">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>
									<div class="up-b">
										<a href="javascript:void(0);"
											(click)="openFileSelector('profilePic')">{{'PAGES.EDIT_PROFILE.UPLOAD' | translate}}</a>
										<input type="file" id="profilePic" style="display:none"
											(change)="uploadProfilePic($event)" #fileInput0
											(click)="fileInput0.value = null" accept="image/png, image/jpeg">
									</div>
								</div>
								<p class="small-text">{{'PAGES.EDIT_PROFILE.IMG_DESC' | translate}}</p>
							</div>
						</div>
					</div>
					<hr>

					<!-- Side Box-->
					<div class="right-listing">
						<!-- available from -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.SEARCH_PROFILE.AVAILABLE_FROM' | translate}}*</legend>
							<div class="col-sm-9">
								<p class="card-subtitle mb-2" id="available-from">
									{{
									profileData.availableFrom==""||profileData.availableFrom==null?('PAGES.EDIT_PROFILE.YOU_CHOOSE' | translate
									):filterMyDate(profileData.availableFrom)
									}}
									<a href="javascript:void(0);" (click)="edit($event,'available-from')">
										<img src="./assets/images/ingeding/calendar.svg" alt="calendar"
											title="calendar">
									</a>
								</p>
								<div style="display: none;" id="edit-available-from">
									<input type="date" class="form-control" [(ngModel)]="profileData.availableFrom"
										#availableFrom="ngModel" name="availableFrom" [min]="today"
										(blur)="hide($event,'available-from')" id="textbox-edit-available-from"
										required>
								</div>
								<div *ngIf="f.submitted && availableFrom.errors?.required" class="text-danger">
									{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
								</div>
							</div>
						</div>
						<hr class="white">

						<!-- Regione Coverd-->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.REGION_COVERED' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="c-box">
									<label class="checkbox-button" *ngFor="let region of RegionArr">
										<input type="checkbox" class="checkbox-button__input" value="{{region._id}}"
											(change)="regionChange($event)"
											[checked]="selectedRegion!=undefined && selectedRegion.includes(region._id)"
											name="region[]">
										<span class="checkbox-button__control"></span> <span
											class="checkbox-button__label">{{region.translations.name}}</span>
									</label>
								</div>
								<div *ngIf="f.submitted && selectedRegion.length == 0" class="text-danger">
									{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
								</div>
							</div>
						</div>
						<hr class="white">

						<!-- Tariff -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.SERVICE_AND_RATE' | translate}}</legend>
							<div class="col-sm-9">
								<div *ngFor="let eachPack of profileData.package; let i = index">
									<div class="row">
										<div class="col-sm-12 col-md-6 black-text">
											<p class="mb-0" id="package-name-{{i}}">
												{{eachPack.name==""? ('PAGES.EDIT_PROFILE.SERVICE' | translate) :eachPack.name}}
												<a href="javascript:void(0);"
													(click)="edit($event,'package-name-'+i, eachPack.name)">
													<img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
														alt="Modifica" title="Modifica">
												</a>
											</p>
											<div style="display: none;" id="edit-package-name-{{i}}">
												<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.SERVICE' | translate}}"
													name="packageName{{i}}" id="textbox-edit-package-name-{{i}}"
													[(ngModel)]="eachPack.name" #name="ngModel" required
													(blur)="hide($event,'package-name-'+i)" maxlength="50">
											</div>
										</div>
										<div class="col-sm-12 col-md-6 right-align">
											<h4 class="mb-0" id="package-rate-{{i}}">{{eachPack.rate==""?"€ 0":"€
												"+checkIfNumber(eachPack.rate)}}
												<a class="pr-1" href="javascript:void(0);"
													(click)="edit($event,'package-rate-'+i, eachPack.rate)">
													<img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
														alt="Modifica" title="Modifica">
												</a>
											</h4>
											<div style="display: none;" id="edit-package-rate-{{i}}">
												<input type="text" class="form-control" placeholder="0"
													name="packageRate{{i}}"
													pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"
													maxlength="7" id="textbox-edit-package-rate-{{i}}"
													[(ngModel)]="eachPack.rate" #rate="ngModel" required
													(blur)="hide($event,'package-rate-'+i)">
											</div>
											<h4 class="mb-0" (click)="deleteThis($event, i, profileData.package)">
												<i class="fa fa-trash-o red ml-1" aria-hidden="true" alt="Delete"
													title="Delete"></i>
											</h4>
										</div>
									</div>
									<p class="small-text" id="package-desc-{{i}}">{{eachPack.desc==""? ('PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate) :eachPack.desc}}
										<a href="javascript:void(0);"
											(click)="edit($event,'package-desc-'+i, eachPack.desc)">
											<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
												alt="Modifica" title="Modifica">
										</a>
									</p>
									<div style="display: none;" id="edit-package-desc-{{i}}">
										<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate}}"
											name="packageDesc{{i}}" id="textbox-edit-package-desc-{{i}}"
											[(ngModel)]="eachPack.desc" #desc="ngModel" required
											(blur)="hide($event,'package-desc-'+i)" maxlength="140">
									</div>
									<div *ngIf="f.submitted && (name.errors?.required || rate.errors?.required || desc.errors?.required)"
										class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && rate.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && rate.errors?.maxlength" class="text-danger">
										{{'PAGES.EDIT_PROFILE.ERROR_MSG' | translate}}
									</div>
									<hr class="white-b">
								</div>

								<!-- Hourly Rate-->
								<div *ngFor="let eachRate of profileData.rate; let i = index">
									<div class="row">
										<div class="col-sm-12 col-md-6 black-text">{{'PAGES.VIEW_PROFILE.HOURLY_RATE' | translate}}</div>
										<div class="col-sm-12 col-md-6 right-align">
											<h4 class="mb-0" id="rate-rate-{{i}}">{{eachRate.rate==""?"€ 0":"€
												"+checkIfNumber(eachRate.rate)}}
												<a class="pr-1" href="javascript:void(0);"
													(click)="edit($event,'rate-rate-'+i, eachRate.rate)">
													<img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
														alt="Modifica" title="Modifica">
												</a>
											</h4>
											<div style="display: none;" id="edit-rate-rate-{{i}}">
												<input type="text" class="form-control" placeholder="0"
													name="rateRate{{i}}"
													pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"
													maxlength="7" id="textbox-edit-rate-rate-{{i}}"
													[(ngModel)]="eachRate.rate" #rate="ngModel" required
													(blur)="hide($event,'rate-rate-'+i)">
											</div>
											<h4 class="mb-0" (click)="deleteThis($event, i, profileData.rate)">
												<i class="fa fa-trash-o red ml-1" aria-hidden="true" alt="Delete"
													title="Delete"></i>
											</h4>
										</div>
									</div>
									<p class="small-text" id="rate-desc-{{i}}">{{eachRate.desc==""? ('PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate) :eachRate.desc}}
										<a href="javascript:void(0);"
											(click)="edit($event,'rate-desc-'+i, eachRate.desc)">
											<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
												alt="Modifica" title="Modifica">
										</a>
									</p>
									<div style="display: none;" id="edit-rate-desc-{{i}}">
										<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate}}"
											name="rateDesc{{i}}" id="textbox-edit-rate-desc-{{i}}"
											[(ngModel)]="eachRate.desc" #desc="ngModel" required
											(blur)="hide($event,'rate-desc-'+i)" maxlength="140">
									</div>
									<div *ngIf="f.submitted && (rate.errors?.required || desc.errors?.required)"
										class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && rate.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && rate.errors?.maxlength" class="text-danger">
										{{'PAGES.EDIT_PROFILE.ERROR_MSG' | translate}}
									</div>
									<hr class="white-b">
								</div>
								<!-- <div *ngIf="f.submitted && profileData.package.length == 0 && profileData.rate.length == 0"
									class="text-danger">
									Campo richiesto
								</div> -->
								<div class="mt-2 add-skill-w">
									<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_FIELD_PROJECT' | translate}}
										<a href="javascript:void(0);" class="pull-right"
											(click)="addMore(profileData.package, 'package')">
											<img src="./assets/images/ingeding/add.svg" alt="add" title="add" width="24"
												height="24">
										</a>
									</p>
								</div>
								<div class="mt-3 add-skill-w">
									<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_HOURLY_RATE' | translate}}
										<a href="javascript:void(0);" class="pull-right"
											(click)="addMore(profileData.rate, 'rate')">
											<img src="./assets/images/ingeding/add.svg" alt="add" title="add" width="24"
												height="24">
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
					<hr>
					<!-- End Side Box-->

					<!-- Category -->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.WORKING_ENVIRONMENT' | translate}}*</legend>
						<div class="col-sm-9">
							<div class="c-box">
								<label class="checkbox-button" *ngFor="let cate of CategoryArr">
									<input type="checkbox" class="checkbox-button__input" value="{{cate._id}}"
										(change)="cateChange($event)"
										[checked]="selectedCategory!=undefined && selectedCategory.includes(cate._id)"
										name="cate[]">
									<span class="checkbox-button__control"></span>
									<span class="checkbox-button__label">{{ cate.translations.title }}</span>
								</label>
								<div *ngIf="f.submitted && selectedCategory.length == 0" class="text-danger">
									{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
								</div>
							</div>
						</div>
					</div>
					<hr>

					<!-- Tasks Done Modifica Update -->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.SKILLS' | translate}}*</legend>
						<div class="col-sm-9">
							<div class="c-box">
								<label class="checkbox-button" *ngFor="let designation of JobDesignationArr">
									<input type="checkbox" class="checkbox-button__input" value="{{designation._id}}"
										(change)="designationChange($event)"
										[checked]="selectedJobDesignation!=undefined && selectedJobDesignation.includes(designation._id)"
										name="designation[]">
									<span class="checkbox-button__control"></span>
									<span class="checkbox-button__label">{{ designation.translations.title }}</span>
								</label>
								<div *ngIf="f.submitted && selectedJobDesignation.length == 0" class="text-danger">
									{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
								</div>
							</div>
						</div>
					</div>
					<hr>

					<!-- Hashtags Done Modifica Update-->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.HASHTAG' | translate}}*</legend>
						<div class="col-sm-9">
							<div class="row">
								<div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
									*ngFor="let tags of TagsArr;let i = index">
									<div class="c-box">
										<label class="checkbox-button">
											<input type="checkbox" class="checkbox-button__input" value="{{tags._id}}"
												(change)="tagsChange($event)"
												[checked]="selectedTags!=undefined && selectedTags.includes(tags._id)"
												name="tags[]">
											<span class="checkbox-button__control"></span>
											<span class="checkbox-button__label">{{ tags.translations.title }}</span>
										</label>
									</div>
								</div>
							</div>
							<div *ngIf="f.submitted && selectedTags.length == 0" class="text-danger">
								{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
							</div>
						</div>
					</div>
					<hr />

					<!-- Photo Gallary -->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.PHOTO_GALLERY' | translate}}</legend>
						<div class="col-sm-9">
							<div id="gallery" class="edit-gallery">
								<div id="image-gallery">
									<div class="row pt-0">
										<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-4 image "
											*ngFor="let photo of photoArr;let i = index">
											<div class="img-wrapper">
												<img src="{{ photo.url?photo.url:photosBucket+photo.name }}"
													alt="Photo-gallery-{{i}}" class="img-responsive" height="100%"
													width="auto">
												<div class="img-overlay">
													<a href="{{ photo.url?photo.url:photosBucket+photo.name }}"></a>
													<a href="javascript:void(0)" class="openGallery"><i
															class="fa fa-eye" aria-hidden="true" alt="View"
															title="View"></i></a>
													<a href="javascript:void(0);" (click)=removePhoto(i)>
														<i class="fa fa-trash-o red" style="color:red"
															aria-hidden="true" alt="Delete" title="Delete"></i></a>
												</div>
											</div>
										</div>
										<!-- photosBucket -->
										<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-4 add-plus">
											<div class="img-wrapper add-image">
												<a href="javascript:void(0)" (click)="openFileSelector('gallery')">
													<i class="fa fa-plus"></i>
												</a>
											</div>
											<input type="file" id="gallery" style="display:none;" multiple="multiple"
												#fileInput1 (click)="fileInput1.value = null"
												(change)="onGalleryAdded($event)" accept="image/png, image/jpeg">
										</div>
									</div>
									<p class="small-text pl-3 pt-3">{{'PAGES.EDIT_PROFILE.UPLOAD_IMG_DESC' | translate}}</p>
									<!-- <div *ngIf="f.submitted && photoArr.length == 0" class="text-danger">
									                    Please upload at least one photo to gallery.
									                  </div> -->
									<!-- End row -->
								</div>
								<div id="overlay" style="display: none;">
									<div id="prevButton"><i class="fa fa-angle-left"></i></div>
									<img alt="images" id="myGalleryImage">
									<div id="nextButton"><i class="fa fa-angle-right"></i></div>
									<div id="exitButton"><i class="fa fa-times"></i></div>
								</div>
							</div>
						</div>
					</div>
					<!-- End Photo gallery -->
					<h3 class="blue-title mt-64" *ngIf="regType == 0">{{'PAGES.VIEW_PROFILE.CURRICULUM' | translate}}</h3>
					<h3 class="blue-title mt-64" *ngIf="regType == 1">{{'PAGES.VIEW_PROFILE.PROFILE' | translate}}</h3>
					<hr />

					<!-- Working Experience -->
					<div class="row" *ngIf="regType == 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.WORK_EXPERIENCES' | translate}}</legend>
						<div class="col-sm-9">
							<div *ngIf="profileData.experience && profileData.experience.length > 0">
								<div class="form-row" *ngFor="let experience of profileData.experience; let i = index">
									<div class="col-md-1">
										<p class="black-text pr-1">{{'PAGES.EDIT_PROFILE.FROM_THE' | translate}}</p>
									</div>
									<div class="col-md-2">
										<p class="mb-0" id="experience-from-{{i}}">{{
											experience.from==""? ('PAGES.EDIT_PROFILE.YEAR' | translate) :experience.from }}
											<a href="javascript:void(0);"
												(click)="edit($event,'experience-from-'+i, experience.from)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-experience-from-{{i}}">
											<select class="minimal form-control" name="experienceFrom{{i}}"
												id="textbox-edit-experience-from-{{i}}" [(ngModel)]="experience.from"
												#from="ngModel" required
												(blur)="hide($event,'experience-from-'+i, i, profileData.experience, 'from')">
												<option value="">{{'PAGES.EDIT_PROFILE.YEAR' | translate}}</option>
												<option *ngFor="let year of counter()">{{ year }}</option>
											</select>
										</div>
										<div *ngIf="f.submitted && f.hasError('required', 'experienceFrom'+i)"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-1">
										<p class="black-text edit-title-to">a</p>
									</div>
									<div class="col-md-2">
										<p class="mb-0" id="experience-to-{{i}}">{{
											experience.to==""? ('PAGES.EDIT_PROFILE.YEAR' | translate) :experience.to }}
											<a href="javascript:void(0);"
												(click)="edit($event,'experience-to-'+i, experience.to)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-experience-to-{{i}}">
											<select class="minimal form-control" name="experienceTo{{i}}"
												id="textbox-edit-experience-to-{{i}}" [(ngModel)]="experience.to"
												#to="ngModel" required
												(blur)="hide($event,'experience-to-'+i, i, profileData.experience, 'to')">
												<option value="">a</option>
												<option value="Oggi">{{'PAGES.EDIT_PROFILE.TODAY' | translate}}</option>
												<option *ngFor="let year of counter(experience.from)">{{ year }}
												</option>
											</select>
										</div>
										<div *ngIf="f.submitted && f.hasError('required', 'experienceTo'+i)"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-6">
										<a href="javascript:void(0);" class="pull-right"
											(click)="deleteThis($event, i, profileData.experience)">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>

									<div class="col-sm-12">
										<p class="mb-0" id="experience-position-{{i}}">
											{{experience.position==""? ('PAGES.EDIT_PROFILE.POSITION' | translate) :experience.position}}
											<a href="javascript:void(0);"
												(click)="edit($event,'experience-position-'+i, experience.position)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-experience-position-{{i}}">
											<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.POSITION' | translate}}"
												name="experiencePosition{{i}}"
												id="textbox-edit-experience-position-{{i}}"
												[(ngModel)]="experience.position" #position="ngModel" required
												(blur)="hide($event,'experience-position-'+i)" maxlength="50">
										</div>
										<div *ngIf="f.submitted && position.errors?.required"
											class="text-danger col-sm-12">
											Campo richiesto
										</div>
										<p class="mb-0" id="experience-company-{{i}}">
											{{experience.company==""? ('PAGES.EDIT_PROFILE.AGENCY' | translate) :experience.company}}
											<a href="javascript:void(0);"
												(click)="edit($event,'experience-company-'+i, experience.company)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-experience-company-{{i}}">
											<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.AGENCY' | translate}}"
												name="experienceCompany{{i}}" id="textbox-edit-experience-company-{{i}}"
												[(ngModel)]="experience.company" #company="ngModel" required
												(blur)="hide($event,'experience-company-'+i)" maxlength="50">
										</div>
										<div *ngIf="f.submitted && company.errors?.required"
											class="text-danger col-sm-12">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<p class="mb-0" id="experience-location-{{i}}">
											{{experience.location==""? ('PAGES.EDIT_PROFILE.PLACE' | translate) :experience.location}}
											<a href="javascript:void(0);"
												(click)="edit($event,'experience-location-'+i, experience.location)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-experience-location-{{i}}">
											<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.PLACE' | translate}}"
												name="experienceLocation{{i}}"
												id="textbox-edit-experience-location-{{i}}"
												[(ngModel)]="experience.location" #location="ngModel" required
												(blur)="hide($event,'experience-location-'+i)" maxlength="50">
										</div>
										<div *ngIf="f.submitted && location.errors?.required"
											class="text-danger col-sm-12">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<hr *ngIf="profileData.experience.length-1 != i">
									</div>
								</div>
							</div>
							<div class="mt-2 add-skill-b">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_WORK_EXPERIENCE' | translate}}
									<a href="javascript:void(0);" class="pull-right"
										(click)="addMore(profileData.experience, 'experience')">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 0" />

					<!-- Formation -->
					<div class="row" *ngIf="regType == 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.TRAINING' | translate}}</legend>
						<div class="col-sm-9">
							<div *ngIf="profileData.formation && profileData.formation.length > 0">
								<div class="form-row" *ngFor="let formation of profileData.formation; let i = index">
									<div class="col-md-1">
										<p class="black-text pr-1">{{'PAGES.EDIT_PROFILE.FROM_THE' | translate}}</p>
									</div>
									<div class="col-md-2">
										<p class="mb-0" id="formation-from-{{i}}"> {{
											formation.from==""? ('PAGES.EDIT_PROFILE.YEAR' | translate) :formation.from }}
											<a href="javascript:void(0);"
												(click)="edit($event,'formation-from-'+i, formation.from)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-formation-from-{{i}}">
											<select class="minimal form-control" name="formationFrom{{i}}"
												id="textbox-edit-formation-from-{{i}}" [(ngModel)]="formation.from"
												#from="ngModel" required (blur)="hide($event,'formation-from-'+i)">
												<option value="">Dal</option>
												<option *ngFor="let year of counter()">{{ year }}</option>
											</select>
										</div>
										<div *ngIf="f.submitted && f.hasError('required', 'formationFrom'+i)"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-1">
										<p class="black-text edit-title-to">a</p>
									</div>
									<div class="col-md-2">
										<p class="mb-0" id="formation-to-{{i}}">{{ formation.to==""?('PAGES.EDIT_PROFILE.YEAR' | translate) :formation.to
											}}
											<a href="javascript:void(0);"
												(click)="edit($event,'formation-to-'+i, formation.to)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-formation-to-{{i}}">
											<select class="minimal form-control" name="formationTo{{i}}"
												id="textbox-edit-formation-to-{{i}}" [(ngModel)]="formation.to"
												#to="ngModel" required (blur)="hide($event,'formation-to-'+i)">
												<option value="">a</option>
												<option value="Oggi">{{'PAGES.EDIT_PROFILE.TODAY' | translate}}</option>
												<option *ngFor="let year of counter(formation.from)">{{ year }}</option>
											</select>
										</div>
										<div *ngIf="f.submitted && f.hasError('required', 'formationTo'+i)"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-6">
										<a href="javascript:void(0);" class="pull-right"
											(click)="deleteThis($event, i, profileData.formation)">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>
									<div class="col-sm-12">
										<p class="mb-0" id="formation-institute-{{i}}">
											{{formation.institute==""? ('PAGES.EDIT_PROFILE.FIRST_NAME' | translate) :formation.institute}}
											<a href="javascript:void(0);"
												(click)="edit($event,'formation-institute-'+i, formation.institute)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-formation-institute-{{i}}">
											<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.FIRST_NAME' | translate}}"
												name="formationInstitute{{i}}"
												id="textbox-edit-formation-institute-{{i}}"
												[(ngModel)]="formation.institute" #institute="ngModel" required
												(blur)="hide($event,'formation-institute-'+i, i, profileData.formation, 'institute')"
												maxlength="50">
										</div>
										<div *ngIf="f.submitted && institute.errors?.required"
											class="text-danger col-sm-12">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<p class="mb-0" id="formation-location-{{i}}">
											{{formation.location==""? ('PAGES.EDIT_PROFILE.PLACE' | translate) :formation.location
											}}
											<a href="javascript:void(0);"
												(click)="edit($event,'formation-location-'+i, formation.location)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-formation-location-{{i}}">
											<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.PLACE' | translate}}"
												name="formationLocation{{i}}" id="textbox-edit-formation-location-{{i}}"
												[(ngModel)]="formation.location" #location="ngModel" required
												(blur)="hide($event,'formation-location-'+i, i, profileData.formation, 'location')"
												maxlength="50">
										</div>
										<div *ngIf="f.submitted && location.errors?.required"
											class="text-danger col-sm-12">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<hr *ngIf="profileData.formation.length-1 != i">
									</div>
								</div>
							</div>
							<!-- <div *ngIf="f.submitted && profileData.formation.length == 0" class="text-danger">
                Formation is required.
              </div> -->
							<div class="mt-2 add-skill-b">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_TRAINING' | translate}}
									<a href="javascript:void(0);" class="pull-right"
										(click)="addMore(profileData.formation, 'formation')">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 0" />

					<!-- About us -->
					<div class="row" *ngIf="regType == 1">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.WHO_WE_ARE' | translate}}*</legend>
						<div class="col-sm-9">
							<div class="form-row">
								<!-- maxlength="800" -->
								<div class="col-sm-12">
									<p class="mb-0" id="aboutus" style="white-space: pre-line;">
										{{profileData.aboutus==""||profileData.aboutus==null? ('PAGES.EDIT_PROFILE.DETAIL_DESCRIPTION' | translate) :profileData.aboutus}}
										<a href="javascript:void(0);" (click)="edit($event,'aboutus')">
											<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
												alt="Modifica" title="Modifica">
										</a>
									</p>
									<div style="display: none;" id="edit-aboutus">
										<textarea class="form-control" rows="10" style="resize: none; height: auto !important;" minlength="4" maxlength="800"
											(blur)="hide($event,'aboutus',null,null,null)" [(ngModel)]="profileData.aboutus" #aboutus="ngModel" name="aboutus"
											id="textbox-edit-aboutus">
										('PAGES.EDIT_PROFILE.DETAIL_DESCRIPTION' | translate)
										 </textarea>
									</div>
									<div *ngIf="f.submitted && (profileData.aboutus == null || profileData.aboutus == '')"
										class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 1" />

					<!-- Skills -->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.SKILLS' | translate}}</legend>
						<div class="col-sm-9">
							<div *ngIf="profileData.skill.length > 0">
								<div class="form-row" *ngFor="let myskill of profileData.skill;let i = index">
									<div class="col-sm-9">
										<p class="mb-0" id="skill-{{i}}">{{ myskill==""? ('PAGES.VIEW_PROFILE.SKILLS' | translate) :myskill }}
											<a href="javascript:void(0);" (click)="edit($event,'skill-'+i, myskill)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-skill-{{i}}">
											<input type="text" class="form-control" placeholder="Competenza"
												name="skill{{i}}" id="textbox-edit-skill-{{i}}"
												(blur)="hide($event,'skill-'+i, i, profileData.skill)" maxlength="50">
										</div>
									</div>
									<div class="col-sm-3">
										<a href="javascript:void(0);" class="pull-right"
											(click)="deleteThis($event, i, profileData.skill)">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>
									<!-- <div *ngIf="f.submitted && mySkills.errors?.required" class="text-danger">
                    Campo richiesto
                  </div> -->
								</div>
							</div>
							<!-- <div *ngIf="f.submitted && profileData.skill.length == 0" class="text-danger">
                Campo richiesto
              </div> -->
							<div class="mt-3 add-skill-b">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_SKILLS' | translate}}
									<a href="javascript:void(0);" class="pull-right" id="addSkills"
										(click)="addMore(profileData.skill)">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
						</div>
					</div>
					<hr />

					<!-- Software-->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.SOFTWARE' | translate}}</legend>
						<div class="col-sm-9">
							<div *ngIf="profileData.software.length > 0">
								<div class="form-row" *ngFor="let soft of profileData.software;let i = index">
									<div class="col-sm-9">
										<p class="mb-0" id="software-{{i}}">{{ soft==""? ('PAGES.VIEW_PROFILE.SOFTWARE' | translate) :soft }}
											<a href="javascript:void(0);" (click)="edit($event,'software-'+i, soft)">
												<img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
													alt="Modifica" title="Modifica">
											</a>
										</p>
										<div style="display: none;" id="edit-software-{{i}}">
											<input type="text" class="form-control" placeholder="Software"
												name="software{{i}}" id="textbox-edit-software-{{i}}"
												(blur)="hide($event,'software-'+i, i, profileData.software)"
												maxlength="50">
										</div>
									</div>
									<div class="col-sm-3">
										<a href="javascript:void(0);" class="pull-right"
											(click)="deleteThis($event, i, profileData.software)">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>
									<!-- <div *ngIf="f.submitted && mySoft.errors?.required" class="text-danger">
                    Campo richiesto
                  </div> -->
								</div>
							</div>
							<!-- <div *ngIf="f.submitted && profileData.software.length == 0" class="text-danger">
                Campo richiesto
              </div> -->
							<div class="mt-3 add-skill-b">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_SOFTWARE' | translate}}
									<a href="javascript:void(0);" class="pull-right" id="addSoftware"
										(click)="addMore(profileData.software)">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
						</div>
					</div>
					<hr />

					<!-- Lingua -->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.LANGUAGES' | translate}}</legend>
						<div class="col-sm-9">
							<div class="form-row mb-2" *ngFor="let lang of profileData.language; let i = index">
								<div class="col-md-5">
									<select class="minimal form-control" id="textbox-edit-language-name-{{i}}"
										name="textbox-edit-language-name-{{i}}"
										(change)="hide($event,'language-name-'+i, i, profileData.language, 'name')"
										required>
										<option value="">{{'PAGES.VIEW_PROFILE.LANGUAGES' | translate}}</option>
										<option *ngFor="let staticLang of LangArray" [value]="staticLang"
											[selected]="lang.name == staticLang">
											{{staticLang}}</option>
									</select>
								</div>
								<div class="col-md-6">
									<select class="minimal form-control" id="textbox-edit-language-proficiency-{{i}}"
										(change)="hide($event,'language-proficiency-'+i, i, profileData.language, 'proficiency')">
										<!-- <option value="">Proficiency</option> -->
										<option *ngFor="let staticProf of PrafArray"
											[selected]="lang.proficiency == staticProf">
											{{staticProf}}
										</option>
									</select>
								</div>
								<div class="col-md-1">
									<a href="javascript:void(0);" class="pull-right"
										(click)="deleteThis($event, i, profileData.language)">
										<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
									</a>
								</div>
							</div>
							<div class="form-row mb-2" *ngIf="otherLangTab">
								<div class="col-md-5">
									<input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.OTHER_LANGUAGE' | translate}}"
										name="otherLanguageName" id="otherLanguageName"
										[(ngModel)]="otherLanguage.name">
								</div>
								<div class="col-md-6">
									<select class="minimal form-control" id="otherLanguageProficiency"
										name="otherLanguageProficiency" [(ngModel)]="otherLanguage.proficiency">
										<option *ngFor="let staticProf of PrafArray"
											[selected]="otherLanguage.proficiency == staticProf">
											{{staticProf}}
										</option>
									</select>
								</div>
								<div class="col-md-1">
									<a href="javascript:void(0);" class="pull-right" (click)="openLangModel()">
										<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
									</a>
								</div>
							</div>
							<!-- <div *ngIf="f.submitted && profileData.language.length == 0" class="text-danger">
                Campo richiesto
              </div> -->
							<div class="mt-3 add-skill-b">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_LANGUAGE' | translate}}
									<a href="javascript:void(0);" class="pull-right"
										(click)="addMore(profileData.language, 'language')">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
							<div class="mt-3 add-skill-b" *ngIf="!otherLangTab">
								<p class="mb-0 blue-text">{{'PAGES.EDIT_PROFILE.ADD_LANGUAGE_NOT_IN_LIST' | translate}}
									<a href="javascript:void(0);" class="pull-right" (click)="otherLangTab = true">
										<img src="./assets/images/ingeding/add.svg" height="24" width="24" alt="add"
											title="add">
									</a>
								</p>
							</div>
						</div>
					</div>
					<hr />

					<!-- Images-->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.DOCUMENTS' | translate}}</legend>
						<div class="col-sm-9">
							<p>{{'PAGES.EDIT_PROFILE.UPLOAD_FILE' | translate}}</p>
							<div class="form-group">
								<div class="dropzone-wrapper mb-2">
									<div class="dropzone-desc">
										<img src="./assets/images/ingeding/upload-icon.svg" alt="upload" title="Upload">
										<h4>Drag and drop</h4>
										<a href="javascript:void(0);">{{'PAGES.EDIT_PROFILE.SELECT_FILE' | translate}}</a>
										<p>{{'PAGES.EDIT_PROFILE.FILE' | translate}}</p>
									</div>
									<input type="file" name="img_logo" id="img_logo" class="dropzone"
										multiple="multiple" #fileInput2 (dragenter)="fileInput2.value = null"
										(click)="fileInput2.value = null" (change)="onDocumentAdded($event)"
										accept="image/png, image/jpeg, .pdf">
								</div>
								<!-- <div *ngIf="f.submitted && documentArr.length==0" class="text-danger">
                  Please upload document.
                </div> -->
							</div>
							<div class="row" *ngFor="let doc of documentArr;let i = index">
								<div class="col-sm-12 col-md-8 file-upload-t">
									<ul>
										<li alt="Done" title="Done">
											<img src="./assets/images/ingeding/done.svg" alt="Done" title="Done">{{
											doc.name }}
											<a *ngIf="doc._id" href="{{documentBucket + doc.name}}" target="_blank"
												download>
												<img src="./assets/images/ingeding/download.svg" alt="Download"
													title="Download">
											</a>
										</li>
									</ul>
								</div>
								<div class="col-sm-12 col-md-4 file-size-t">
									<ul>
										<li>
											{{ Math.floor(doc.size / 1024) > 1000 ? Math.floor(doc.size /
											1048576)+' MB ' : Math.floor(doc.size / 1024)+' KB ' }}
											<a href="javascript:void(0)" (click)=removeDocument(i)>
												<img src="./assets/images/ingeding/trash.svg" alt="Delete"
													title="Delete">
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr />

					<!-- Phone Email Done Modifica Update-->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.CONTACT' | translate}}*</legend>
						<div class="col-sm-9">
							<div class="form-row">
								<div class="col-md-2">
									<select class="minimal form-control" #countrycode="ngModel"
										[(ngModel)]="profileData.countrycode" name="countrycode" required>
										<option value=null>{{'PAGES.EDIT_PROFILE.PREFIX' | translate}}</option>
										<option *ngFor="let country of CountryArr" [value]="country._id">
											+ {{ country.phone_code }}
										</option>
									</select>
									<div *ngIf="f.submitted && f.hasError('required', 'countrycode')"
										class="text-danger">
										{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
									</div>
								</div>
								<div class="col-md-5">
									<input type="tel" class="form-control" placeholder="Telefono"
										[(ngModel)]="profileData.secondarycontact" name="secondarycontact" required
										#secondarycontact="ngModel" />
									<div *ngIf="f.submitted && secondarycontact.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && secondarycontact.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
									<!--
									<div *ngIf="f.submitted && secondarycontact.errors?.maxlength" class="text-danger">
										Contact number cannot exceed more than 12 Digit.
									</div> -->
								</div>
								<div class="col-md-5">
									<input type="text" class="form-control" placeholder="{{'PAGES.VIEW_PROFILE.EMAIL' | translate}}"
										[(ngModel)]="profileData.secondryemail" name="secondryemail"
										#secondryemail="ngModel" required
										pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
									<div *ngIf="f.submitted && secondryemail.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && secondryemail.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />

					<div class="form-group row mb-64 mt-64">
						<div class="col-sm-10 inline-btn">
							<button type="submit" (click)="onUpdate(f)" class="btn-aqua">{{'PAGES.EDIT_PROFILE.SAVE' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</section>

<ng-template #confirmModel let-modal>
	<div class="login-main backdrop">
		<div class="popup">
			<div class="modal-header">
				<span class="ribbon top-left ribbon-primary">
					<!-- <small><b>Conferma</b></small> -->
					<h4>{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</h4>
				</span>
				<!-- <h1>Conferma</h1> -->
			</div>
			<div class="modal-body">
				<p class="mt-2">{{'PAGES.UPGRADE_ACCOUNT.DELETE_PERMANTELY' | translate}}</p>

				<div id="option">
					<div class="col-md-12 text-center">
						<button type="button" class="btn btn-danger" (click)="onNoClick()"
							style="background-color:black;border:none;">{{'PAGES.UPGRADE_ACCOUNT.NO' | translate}}</button>&nbsp;
						<button type="button" class="btn btn-success" style="background-color:#00ad9e;border:none;"
							(click)="onYesClick()">{{'PAGES.UPGRADE_ACCOUNT.YES' | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #langModel let-modal>
	<div class="login-main backdrop">
		<div class="popup">
			<div class="modal-header">
				<span class="ribbon top-left ribbon-primary">
					<!-- <small><b>Conferma</b></small> -->
					<h4>{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</h4>
				</span>
				<!-- <h1>Conferma</h1> -->
			</div>
			<div class="modal-body">
				<p class="mt-2">{{'PAGES.UPGRADE_ACCOUNT.DELETE_PERMANTELY' | translate}}</p>

				<div id="option">
					<div class="col-md-12 text-center">
						<button type="button" class="btn btn-danger" (click)="onNoClick()"
							style="background-color:black;border:none;">{{'PAGES.UPGRADE_ACCOUNT.NO' | translate}}</button>&nbsp;
						<button type="button" class="btn btn-success" style="background-color:#00ad9e;border:none;"
							(click)="otherLang()">{{'PAGES.UPGRADE_ACCOUNT.YES' | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!--BODY END-->