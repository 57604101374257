import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Meta, Title } from "@angular/platform-browser";
import { GridOption } from "../_models/gridoptions.model";
import * as _ from "lodash";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { CategoryService } from "../_services/category.service";
import { RegionService } from "../_services/region.service";
import { TagsService } from "../_services/tags.service";
import { UserProfileService } from "../_services/userprofile.service";
import { AuthenticationService } from "../_services";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-search-profile",
  templateUrl: "./search-profile.component.html",
  styleUrls: ["./search-profile.component.scss"],
})
export class SearchProfileComponent implements OnInit, OnDestroy {
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  MyProfileArr: any = [];
  today = new Date().toISOString().slice(0, 10);
  userType: any = "";
  myTabs = 0;
  isCollapse = 0;
  mySelectedPage = 0;
  id: any = "";
  totalCount: any = 0;
  allid: any = [];
  currentUser: any = "";
  CategoryArr: any = [];
  RegionArr: any = [];
  SelectedTags: any = [];
  allCategoryid: any = [];
  TagsArr: any = [];
  TagsArrs: any = [];
  AvailableFrom: any = "";
  SelectedRegion: any = "";
  isCollapse1 = 0;
  hideshowclass: boolean = true;
  selectedCategory: any = "";
  inactivePostId: any = "";
  deletePostId: any = "";
  model: NgbDateStruct;
  searchtext: any = "";
  gridOptionProfile: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 10,
    total: 0,
    order: "_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 10,
    allrecords: false,
  };
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  constructor(
    private titleService: Title,
    private myProfileService: UserProfileService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
    private regionService: RegionService,
    private tagsService: TagsService,
    private route: ActivatedRoute,
    private meta: Meta,
    private authenticationService: AuthenticationService
  ) {
    this.meta.addTag({ name: "title", content: "Cercasi ingegnere - trova il tuo ignegnere su Ingeding" });
    this.meta.addTag({ name: "keywords", content: "cercasi ingegnere" });
    this.meta.addTag({
      name: "description",
      content:
        "Cerchi un ingegniere? Ingeding connette ingegnieri e professionisti di vari ambiti. Scopri professionisti e aziende ingegnieriche a tua disposizione.",
    });
    route.params.subscribe((val) => {
      window.scrollTo(0, 0);
      this.searchtext =
        this.route.snapshot.params["search"] == undefined ||
          this.route.snapshot.params["search"] == ""
          ? ""
          : this.route.snapshot.params["search"];
      if (this.route.snapshot.params["category"]) {
        this.selectedCategory = this.route.snapshot.params["category"];
        this.selectThisCategory(this.selectedCategory);
        this.hideshowclass = false;
      } else {
        this.selectThisCategory1();
        this.hideshowclass = true;
      }
      this.authenticationService.currentUser$.subscribe((data) => {
        if (data) {
          this.currentUser = data._id;
        }
      });
    });
    this.titleService.setTitle("Ingeding | Search Job Post");
    this.gridOptionProfile.sortField = "availableFrom";
    this.gridOptionProfile.sortOrder = "asc";
  }
  ngOnInit() {
    this.getAllCategory();
    this.getAllRegions();
  }
  ngOnDestroy() {
    this.meta.removeTag('name=title');
    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
  }
  changeCollaps() {
    if (this.isCollapse == 0) {
      this.isCollapse = 1;
    } else {
      this.isCollapse = 0;
    }
  }
  changeCollaps1() {
    if (this.isCollapse1 == 0) {
      this.isCollapse1 = 1;
    } else {
      this.isCollapse1 = 0;
    }
  }
  async getAllCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    await this.categoryService.searchAll(this.gridOption).subscribe(async (res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.CategoryArr.filter((x: any) => { this.allCategoryid.push(x._id) });
    });
  }
  selectThisCategory1() {
    this.hideshowclass = true;
    this.selectedCategory = this.allCategoryid;
    this.SelectedTags = [];
    this.mySelectedPage = 1;
    this.getAllCategoryTages();
  }
  async getAllCategoryTages() {
    await this.categoryService.searchAll(this.gridOption).subscribe(async (res) => {
      let tempid = _.orderBy(res.data, ["translations.title"], ["asc"]);
      tempid.filter((x: any) => { this.allCategoryid.push(x._id) });
      this.gridOption.filter = {};
      this.gridOption.allrecords = true;
      this.gridOption.filter.status = 1;
      await this.tagsService.searchAll(this.gridOption).subscribe((res) => {
        this.TagsArrs.push(res.data)
        this.TagsArrs = _.orderBy(res.data, ["translations.title"], ["asc"]);
        this.cdr.detectChanges();
      });
    });
    this.getAllProfiles1();
  }
  selectThisTags1(event) {
    if (event.target.checked) {
      this.SelectedTags.push(event.target.value);
    } else {
      this.SelectedTags = this.SelectedTags.filter(
        (m) => m != event.target.value
      );
    }
    this.mySelectedPage = 1;
    this.getAllProfiles();
  }
  selectThisCategory(cateId: any) {
    this.hideshowclass = false;
    this.isCollapse1 = 0;
    this.selectedCategory = [cateId];
    this.SelectedTags = [];
    this.mySelectedPage = 1;
    this.getAllCategory();
    this.getCategoryTags(cateId);
  }
  getCategoryTags(cateId: any) {
    this.gridOption.filter = {};
    this.gridOption.allrecords = false;
    this.gridOption.filter.status = 1;
    this.gridOption.filter.category = cateId;
    this.tagsService.searchAll(this.gridOption).subscribe((res) => {
      this.TagsArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.mySelectedPage = 1;
      this.cdr.detectChanges();
      this.getAllProfiles();
    });
  }
  getAllProfiles1() {
    this.gridOptionProfile.filter = {};
    this.gridOptionProfile.filter.status = 1;
    this.gridOptionProfile.allrecords = false;
    if (this.SelectedTags.length > 0) {
      this.gridOptionProfile.filter.tags = this.SelectedTags;
    }
    if (this.userType != "") {
      this.gridOptionProfile.filter.usergroup = this.userType;
    }
    if (this.SelectedRegion != "") {
      this.gridOptionProfile.filter.regions = this.SelectedRegion;
    }
    if (this.AvailableFrom != "") {
      this.gridOptionProfile.filter.availableFrom = this.AvailableFrom;
    }
    if (this.selectedCategory != "") {
      var unique = this.selectedCategory.filter((value, index, array) => array.indexOf(value) === index);
      this.gridOptionProfile.filter.category = unique;
    }
    if (this.currentUser != "") {
      this.gridOptionProfile.filter.currentuser = this.currentUser;
    }
    if (this.searchtext != undefined) {
      this.gridOptionProfile.search = this.searchtext;
    }
    this.myProfileService.searchAll(this.gridOptionProfile).subscribe((res) => {
      this.totalCount = res.totalCount;
      this.MyProfileArr = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
  getAllProfiles() {
    this.gridOptionProfile.skip = (this.mySelectedPage - 1) * 10;
    this.gridOptionProfile.filter = {};
    this.gridOptionProfile.filter.status = 1;
    this.gridOptionProfile.allrecords = false;
    if (this.SelectedTags.length > 0) {
      this.gridOptionProfile.filter.tags = this.SelectedTags;
    }
    if (this.userType != "") {
      this.gridOptionProfile.filter.usergroup = this.userType;
    }
    if (this.SelectedRegion != "") {
      this.gridOptionProfile.filter.regions = this.SelectedRegion;
    }
    if (this.AvailableFrom != "") {
      this.gridOptionProfile.filter.availableFrom = this.AvailableFrom;
    }
    if (this.selectedCategory != "") {
      var unique = this.selectedCategory.filter((value, index, array) => array.indexOf(value) === index);
      this.gridOptionProfile.filter.category = unique;
    }
    if (this.currentUser != "") {
      this.gridOptionProfile.filter.currentuser = this.currentUser;
    }
    if (this.searchtext != undefined) {
      this.gridOptionProfile.search = this.searchtext;
    }
    this.myProfileService.searchAll(this.gridOptionProfile).subscribe((res) => {
      this.totalCount = res.totalCount;
      this.MyProfileArr = res.data;
      this.isCollapse = 0;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
  getAllRegions() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.regionService.searchAll(this.gridOption).subscribe((res) => {
      this.RegionArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
  hide(id: any) {
    if (id == "dateCalendar") {
      $("#dateCalendar").hide();
      $("#availablefrom").show();
      $("#availablefrom").focus();
    } else {
      $("#dateCalendar").show();
      $("#availablefrom").hide();
      this.mySelectedPage = 1;
      this.getAllProfiles();
    }
  }
  selectThisUsertype(event: any) {
    this.userType = event.target.value;
    this.mySelectedPage = 1;
    this.getAllProfiles();
  }
  selectedPage(pageNo: any) {
    this.mySelectedPage = pageNo;
    this.getAllProfiles();
  }
  dateFilter(date: any = null) {
    if (date != null && date != "") {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    } else {
      return "";
    }
  }
  changeOrder(field: any) {
    this.gridOptionProfile.sortField = field;
    if (this.gridOptionProfile.sortOrder == "asc") {
      this.gridOptionProfile.sortOrder = "desc";
    } else {
      this.gridOptionProfile.sortOrder = "asc";
    }
    this.getAllProfiles();
  }
  selectedRegion(event: any) {
    this.SelectedRegion = event.target.value;
    this.mySelectedPage = 1;
    this.getAllProfiles();
  }
  selectThisTags(event) {
    if (event.target.checked) {
      this.SelectedTags.push(event.target.value);
    } else {
      this.SelectedTags = this.SelectedTags.filter(
        (m) => m != event.target.value
      );
    }
    this.mySelectedPage = 1;
    this.getAllProfiles();
  }
  profileDateFilter(date: any = null) {
    if (date != null) {
      var myDate = new Date(date);
      var today = new Date();
      if (myDate > today) {
        const day = myDate.getDate();
        const month = myDate.getMonth() + 1;
        const year = myDate.getFullYear();
        return "Disponibile da " + day + "." + month + "." + year;
      } else {
        return "Disponibile da subito";
      }
    }
  }
  profileAlertLights(date: any = null) {
    if (date != null) {
      var myDate = new Date(date);
      var today = new Date();
      if (myDate > today) {
        return false;
      } else {
        return true;
      }
    }
  }
}
