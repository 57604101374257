// Angular
import { Injectable } from "@angular/core";
// Tranlsation
import { TranslateService } from "@ngx-translate/core";

export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  // Private properties
  private langIds: any = [];

  /**
   * Service Constructor
   *
   * @param translate: TranslateService
   */
  constructor(private translate: TranslateService) {
    // add new langIds to the list
    this.translate.addLangs(["it"]);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("it");
  }

  /**
   * Load Translation
   *
   * @param args: Locale[]
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   *
   * @param lang: any
   */
  setLanguage(lang) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang.locale);
      localStorage.setItem("language", JSON.stringify(lang));
    }
  }

  /**
   * Setup Country
   *
   * @param country: any
   */
  setCountry(country) {
    if (country) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(country);
      localStorage.setItem("country", country);
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    let languageData = localStorage.getItem("language");
    return JSON.parse(languageData) || this.translate.getDefaultLang();
  }

  /**
   * Returns selected language
   */
  getSelectedCountry(): any {
    return localStorage.getItem("country");
  }
}
