import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../_services";
import { CountryService } from "../_services/country.service";
import { JobdesignationService } from "../_services/jobdesignation.service";
import { TagsService } from "../_services/tags.service";
import { GridOption } from "../_models/gridoptions.model";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { JobPost } from "../_models/jobpost.model";
import { JobPostService } from "../_services/jobpost.service";
import * as $ from "jquery";
import * as _ from "lodash";
import { CategoryService } from "../_services/category.service";
import { NgbModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { RegionService } from "../_services/region.service";
import { UserProfileService } from "../_services/userprofile.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-job-post-edit",
  templateUrl: "./job-post-edit.component.html",
  styleUrls: ["./job-post-edit.component.scss"],
})
export class JobPostEditComponent implements OnInit {
  @ViewChild("confirmModel", { static: false }) confirmModel: TemplateRef<any>; // Note: TemplateRef
  @ViewChild("inactivePostModel", { static: false })
  inactivePostModel: TemplateRef<any>; // Note: TemplateRef
  @ViewChild("deletePostModel", { static: false })
  deletePostModel: TemplateRef<any>; // Note: TemplateRef

  removeDocumentIndex: any = -1;
  removePhotoIndex: any = -1;
  deleteIndex: any = -1;
  deleteBindVariable: any;

  model: NgbDateStruct; // the model as NgbDateStruct

  jobPost = new JobPost();
  submitted: any = false;
  photosBucket = environment.imgBucketUrl + "/gallery/";
  documentBucket = environment.imgBucketUrl + "/document/";
  localUserData: any = [];
  JobDesignationArr: any = [];
  TagsArr: any = [];
  CountryArr: any = [];
  CategoryArr: any = [];
  photoImgURL: any = [];
  documentFiles: any = [];
  deletedDocument: any = [];
  documentArr: any = [];
  photoFiles: any = [];
  deletedPhoto: any = [];
  photoArr: any = [];
  RegionArr: any = [];
  today = new Date().toISOString().slice(0, 10);
  selectedTags: any = [];
  selectedJobDesignation: any = [];
  selectedCategory: any = "";
  inactivePostId: any = "";
  deletePostId: any = "";
  regType: any = "";
  checkProfileStatus: boolean;
  PlaceArray: any = [
    "Da remoto",
    "Work from office",
    "Work from client office",
  ];
  latin_month: any = [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ];
  invalid_image: any =
    "Attenzione: tipo di file invalido o troppo grande (dimensione massima: 5 MB)";

  Math: any;

  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };

  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private jobDesignationService: JobdesignationService,
    private tagsService: TagsService,
    private countryService: CountryService,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
    private cdr: ChangeDetectorRef,
    private profileService: UserProfileService,
    private jobPostSetvice: JobPostService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private modalService: NgbModal,
    private meta: Meta,
    private translateService: TranslateService,
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.jobPost._id = this.route.snapshot.params["id"];
    if (this.jobPost._id) {
      this.titleService.setTitle("Ingeding | Edit Job Post");
    } else {
      this.titleService.setTitle("Ingeding | Add Job Post");
    }
    this.Math = Math;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getLoginUserData();
  }

  /** Get ogin user data and profile data */
  getLoginUserData() {
    this.authenticationService.currentUser$.subscribe((data) => {
      if (data) {
        this.localUserData = data;
        this.regType =
          data.usergroup._id == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
        this.jobPost.userid = data._id;
      }
    });
    this.checkUserProfile(this.localUserData._id);
    this.getPostData(this.localUserData._id);
    this.getCategory();
    this.getJobDesignation();
    this.getCountries();
    this.getRegions();
  }

  checkUserProfile(userId: any) {
    this.profileService.checkProfile(userId).subscribe((res) => {
      this.checkProfileStatus = res;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get Post data */
  getPostData(currentUser: any) {
    this.photoArr = [];
    if (this.jobPost._id != "" && this.jobPost._id != undefined) {
      const data = {
        currentuserid: currentUser,
        jobpostid: this.jobPost._id,
      };
      this.jobPostSetvice.getOneJobPost(data).subscribe((res) => {
        if (res.data && res.data.length > 0 && res.data[0]._id) {
          this.photoArr = res.data[0].gallery;
          this.documentArr = res.data[0].document;
          this.jobPost = res.data[0];
          if (!res.data[0].status) {
            this.jobPost.deadline = "";
          }
          this.getHashtag(res.data[0].category);
          this.cdr.detectChanges();
        } else {
          this.jobPost._id = undefined;
          if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
          }
        }
      });
    } else {
      this.authenticationService
        .getfullUserDetails(currentUser)
        .subscribe((data) => {
          this.jobPost.email = data.email;
          this.getHashtag();
        });
    }
  }

  checkIfNumber(x: any) {
    var y = Number(x);
    if (Number.isInteger(y)) {
      return y + ".00";
    } else {
      return y;
    }
  }

  openPopup(id: any, action: any) {
    if (action == 0) {
      this.inactivePostId = id;
      this.modalService.open(this.inactivePostModel);
    } else if (action == 1) {
      this.deletePostId = id;
      this.modalService.open(this.deletePostModel);
    }
  }

  cancel() {
    this.modalService.dismissAll();
  }

  inactive() {
    if (this.inactivePostId != "") {
      this.submitted = true;
      let idsForUpdate = [];
      idsForUpdate.push(this.inactivePostId);
      var jobPostUpdate = {
        ids: idsForUpdate,
        action: "update",
        value: 0,
      };
      this.modalService.dismissAll(this.inactivePostModel);
      this.jobPostSetvice.updateStatusMany(jobPostUpdate).subscribe((res) => {
        this.router.navigateByUrl("/my-posts").then(() => {
          this.toastr.success(
            this.translateService.instant('PAGES.VIEW_JOB_POST.SUCCESFULLY_REMOVED'),
            this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
          );
        });
      });
    }
  }

  delete() {
    if (this.deletePostId != "") {
      this.submitted = true;
      this.jobPostSetvice.delete(this.deletePostId).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.modalService.dismissAll(this.deletePostModel);
          this.deletePostId = "";
          this.router.navigateByUrl("/my-posts").then(() => {
            this.toastr.success(
              this.translateService.instant('PAGES.VIEW_JOB_POST.SUCCESSFULLY_DELETED'),
              this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
            );
          });
        } else {
          this.toastr.error(
            this.translateService.instant('PAGES.LOGIN_PAGE.SOMETHING_WENT_WRONG'),
            this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE')
          );
        }
      });
    }
  }

  getRegions() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.regionService.searchAll(this.gridOption).subscribe((res) => {
      this.RegionArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all category */
  getCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.categoryService.searchAll(this.gridOption).subscribe((res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the tasks */
  getJobDesignation() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.jobDesignationService.searchAll(this.gridOption).subscribe((res) => {
      this.JobDesignationArr = _.orderBy(
        res.data,
        ["translations.title"],
        ["asc"]
      );
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the tags */
  getHashtag(categoryId: any = "") {
    this.gridOption.filter = {};
    this.gridOption.allrecords = false;
    this.gridOption.filter.status = 1;
    if (categoryId != "") {
      this.gridOption.filter.category = categoryId;
    }
    this.tagsService.searchAll(this.gridOption).subscribe((res) => {
      this.TagsArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the country */
  getCountries() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.countryService.searchAll(this.gridOption).subscribe((res) => {
      this.CountryArr = _.orderBy(res.data, ["name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** When tasks changed  */
  designationChange(event: any) {
    if (event.target.checked) {
      this.jobPost.jobdesignations.push(event.target.value);
    } else {
      this.jobPost.jobdesignations = this.jobPost.jobdesignations.filter(
        (m) => m != event.target.value
      );
    }
  }

  /** When category changed  */
  categoryChange(event: any) {
    if (event.target.checked) {
      this.jobPost.category = event.target.value;
      this.jobPost.tags = [];
      this.getHashtag(event.target.value);
    }
  }

  /** When tags changed  */
  tagsChange(event: any) {
    if (event.target.checked) {
      this.jobPost.tags.push(event.target.value);
    } else {
      this.jobPost.tags = this.jobPost.tags.filter(
        (m) => m != event.target.value
      );
    }
  }

  /** open file selector window */
  openFileSelector(field: any) {
    $("input#" + field).click();
  }

  /** Add gallery files */
  onGalleryAdded(event: any) {
    var allowFilesCount = 12 - this.photoArr.length;
    var myFileArray = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      myFileArray.push(element);
    }
    myFileArray = this.removeDuplicatePhotos(myFileArray);
    var actuallCount: any;
    if (myFileArray.length <= allowFilesCount) {
      actuallCount = myFileArray.length;
    } else {
      actuallCount = allowFilesCount;
      this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.REACHED_MAX_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }

    for (let i = 0; i < actuallCount; i++) {
      if (
        myFileArray[i].size < 5242880 &&
        (myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        var result = this.checkAndAddPhotos(myFileArray[i]);
        if (result) {
          this.photoFiles.unshift(myFileArray[i]);
          let reader = new FileReader();
          reader.readAsDataURL(myFileArray[i]);
          reader.onload = (e: any) => {
            this.photoArr.unshift({
              name: myFileArray[i].name,
              size: myFileArray[i].size,
              url: e.target.result,
            });
            if (!(this.cdr as any).destroyed) {
              this.cdr.detectChanges();
            }
          };
        }
      } else {
        this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.INVALID_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  /** Check for duplicate files */
  removeDuplicatePhotos(filesArray) {
    var result = [];
    if (this.photoArr.length > 0) {
      result = filesArray.filter(
        ({ name: name1 }) =>
          !this.photoArr.some(({ name: name2 }) => name1 == name2)
      );
    } else {
      result = filesArray;
    }

    return result;
  }

  /** Check for duplicate photo files */
  checkAndAddPhotos(myfile) {
    var result: Boolean = true;
    for (let i = 0; i < this.photoArr.length; i++) {
      const element = this.photoArr[i];
      if (element.name == myfile.name) {
        result = false;
        break;
      }
    }
    return result;
  }

  /** Add document files */
  onDocumentAdded(event: any) {
    let myFileArray = event.target.files;
    for (let i = 0; i < myFileArray.length; i++) {
      if (
        myFileArray[i].size < 5242880 &&
        (myFileArray[i].type === "application/pdf" ||
          myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        var result = this.checkAndAddDocument(myFileArray[i]);
        if (result) {
          this.documentFiles.unshift(myFileArray[i]);
          this.documentArr.unshift({
            name: myFileArray[i].name,
            size: myFileArray[i].size,
          });
        }
      } else {
        this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.INVALID_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  /** Check for duplicate document files */
  checkAndAddDocument(myfile) {
    var result: Boolean = true;
    for (let i = 0; i < this.documentArr.length; i++) {
      const element = this.documentArr[i];
      if (element.name == myfile.name) {
        result = false;
        break;
      }
    }
    return result;
  }

  /** Edit fields */
  edit(event: any, id: any, value: any = "") {
    $("#" + id).hide();
    $("#edit-" + id).show();
    if (value != "") {
      $("#textbox-edit-" + id).val(value);
    }
    $("#textbox-edit-" + id).focus();
  }

  /** Hide fields */
  hide(
    event: any,
    id: any = "",
    index: any = null,
    bindArray: any = null,
    arrElement: any = null
  ) {
    $("#edit-" + id).hide();
    $("#" + id).show();
    if (bindArray != null) {
      if (arrElement != null) {
        bindArray[index][arrElement] = $("#textbox-edit-" + id).val();
      } else {
        bindArray[index] = $("#textbox-edit-" + id).val();
      }
    }
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** Add more fields */
  addMore(bindArray: any, elements: any = []) {
    if (elements == "flatrate") {
      bindArray.push({
        name: "",
        rate: "",
        desc: "",
      });
    } else if (elements == "hourlyrate") {
      bindArray.push({
        rate: "",
        desc: "",
      });
    } else {
      bindArray.push("");
    }
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** Delete Element */
  deleteThis(event: any, index: any, bindVariable: any = "") {
    this.deleteIndex = index;
    this.deleteBindVariable = bindVariable;
    this.modalService.open(this.confirmModel);
  }

  /** Remove photo files */
  removePhoto(index: any) {
    this.removePhotoIndex = index;
    this.modalService.open(this.confirmModel);
  }

  /** Remove document files */
  removeDocument(index: any) {
    this.removeDocumentIndex = index;
    this.modalService.open(this.confirmModel);
  }

  onNoClick() {
    this.modalService.dismissAll(this.confirmModel);
  }

  onYesClick() {
    if (
      this.deleteIndex != -1 &&
      this.removeDocumentIndex == -1 &&
      this.removePhotoIndex == -1
    ) {
      this.deleteBindVariable.splice(this.deleteIndex, 1);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
      this.deleteIndex = -1;
    }

    if (
      this.deleteIndex == -1 &&
      this.removeDocumentIndex != -1 &&
      this.removePhotoIndex == -1
    ) {
      const deletedocument = this.deleteDocument();
    }

    if (
      this.deleteIndex == -1 &&
      this.removeDocumentIndex == -1 &&
      this.removePhotoIndex != -1
    ) {
      const deletephoto = this.deletePhoto();
    }

    this.modalService.dismissAll(this.confirmModel);
  }

  deleteDocument() {
    const index = this.removeDocumentIndex;
    if (this.documentArr[index]._id) {
      this.deletedDocument.push(this.documentArr[index]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
    this.documentFiles.splice(index, 1);
    this.documentArr.splice(index, 1);
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
    this.removeDocumentIndex = -1;
  }

  deletePhoto() {
    const index = this.removePhotoIndex;
    if (this.photoArr[index]._id) {
      this.deletedPhoto.push(this.photoArr[index]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
    this.photoFiles.splice(index, 1);
    this.photoArr.splice(index, 1);
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
    this.removePhotoIndex = -1;
  }

  filterMyDate(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  checkValidation() {
    var result = true;
    if (
      this.jobPost.flatrate.length == 0 &&
      this.jobPost.hourlyrate.length == 0
    ) {
      result = false;
    } else if (this.jobPost.category == "") {
      result = false;
    } else if (this.jobPost.jobdesignations.length <= 0) {
      result = false;
    } else if (this.jobPost.tags.length <= 0) {
      result = false;
    }
    return result;
  }

  addPost(form: any) {
    let validation = this.checkValidation();
    if (form.form.valid && validation && this.checkProfileStatus) {
      this.submitted = true;
      form.submitted = false;
      const myFormData = new FormData();
      for (let i = 0; i < this.photoFiles.length; i++) {
        const element = this.photoFiles[i];
        myFormData.append("gallery", element);
      }
      for (let i = 0; i < this.documentFiles.length; i++) {
        const element = this.documentFiles[i];
        myFormData.append("document", element);
      }
      this.photoArr.forEach((element) => {
        element["url"] = "";
      });
      this.jobPost.placeofwork =
        this.jobPost.placeofwork == null ? null : this.jobPost.placeofwork;
      this.jobPost.deletedDocument = this.deletedDocument;
      this.jobPost.deletedGallery = this.deletedPhoto;
      myFormData.append("data", JSON.stringify(this.jobPost));
      this.jobPostSetvice.create(myFormData).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.router.navigate(["my-posts"]);
          this.toastr.success(
            this.translateService.instant('PAGES.VIEW_JOB_POST.AD_POSTED_BULLETIN'),
            this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
          );
        } else {
          this.submitted = false;
          this.toastr.error(res.message, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
        }
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    } else {
      this.toastr.error(this.translateService.instant('PAGES.REGISTRATION_PAGE.CORRECT_DATA_TRY_AGAIN'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }
  }

  updatePost(form: any) {
    let validation = this.checkValidation();
    if (form.form.valid && validation) {
      this.submitted = true;
      form.submitted = false;
      const myFormData = new FormData();

      for (let i = 0; i < this.photoFiles.length; i++) {
        const element = this.photoFiles[i];
        myFormData.append("gallery", element);
      }
      for (let i = 0; i < this.documentFiles.length; i++) {
        const element = this.documentFiles[i];
        myFormData.append("document", element);
      }
      this.photoArr.forEach((element) => {
        element["url"] = "";
      });
      this.jobPost.placeofwork =
        this.jobPost.placeofwork == "" ? null : this.jobPost.placeofwork;
      this.jobPost.deletedDocument = this.deletedDocument;
      this.jobPost.deletedGallery = this.deletedPhoto;
      this.jobPost.status = 1;
      myFormData.append("data", JSON.stringify(this.jobPost));
      this.jobPostSetvice.update(myFormData).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.router.navigate(["my-posts"]);
          this.toastr.success(this.translateService.instant('PAGES.EDIT_PROFILE.SUCCESSFULLY_SAVED'), this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS'));
        } else {
          this.submitted = false;
          this.toastr.error(res.message, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
        }
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    } else {
      this.toastr.error(this.translateService.instant('PAGES.REGISTRATION_PAGE.CORRECT_DATA_TRY_AGAIN'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }
  }
}
