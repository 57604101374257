import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef,
} from "@angular/core";
import { NgbModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { Meta, Title } from "@angular/platform-browser";
import { GridOption } from "../_models/gridoptions.model";
import { RegionService } from "../_services/region.service";
import { JobdesignationService } from "../_services/jobdesignation.service";
import { TagsService } from "../_services/tags.service";
import { Profile } from "../_models/profile.model";
import { User } from "../_models";
import { AuthenticationService } from "../_services";
import { CountryService } from "../_services/country.service";
import { UserProfileService } from "../_services/userprofile.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import * as $ from "jquery";
import { Router } from "@angular/router";
import { CategoryService } from "../_services/category.service";
import { TranslateService } from "@ngx-translate/core";
import { LunguageselectionService } from "../_layout/language-selector/language-selector.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-user-profile-edit",
  templateUrl: "./user-profile-edit.component.html",
  styleUrls: ["./user-profile-edit.component.scss"],
})
export class UserProfileEditComponent implements OnInit {
  @ViewChild("confirmModel", { static: false }) confirmModel: TemplateRef<any>; // Note: TemplateRef
  @ViewChild("langModel", { static: false }) langModel: TemplateRef<any>; // Note: TemplateRef
  removeDocumentIndex: any = -1;
  removePhotoIndex: any = -1;
  deleteIndex: any = -1;
  deleteBindVariable: any;
  removeProfilePicIndex: any = -1;
  model: NgbDateStruct; // the model as NgbDateStruct

  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  photosBucket = environment.imgBucketUrl + "/gallery/";
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  documentBucket = environment.imgBucketUrl + "/document/";
  profileData = new Profile();
  userData = new User();
  localUserData: any = [];
  profile: any = "";
  regType: any = "";
  photoImgURL: any = [];
  documentFiles: any = [];
  deletedDocument: any = [];
  documentArr: any = [];
  photoFiles: any = [];
  deletedPhoto: any = [];
  photoArr: any = [];
  today = new Date().toISOString().slice(0, 10);
  JobDesignationArr: any = [];
  selectedJobDesignation = [];
  TagsArr: any = [];
  selectedTags = [];
  RegionArr: any = [];
  selectedRegion = [];
  CountryArr: any = [];
  selectedCategory: any = [];
  skills: any = [];
  CategoryArr: any = [];
  Math: any;
  categoryIdArr: any = [];
  otherLangTab: boolean = false;
  otherLanguage: any = {
    name: "",
    proficiency: "A1 – Beginner",
    otherFlag: true,
  };
  invalid_profile: any =
    "Attenzione: tipo di file invalido o troppo grande (dimensione massima: 500 KB)";
  invalid_image: any =
    "Attenzione: tipo di file invalido o troppo grande (dimensione massima: 5 MB)";
  //LangArray = ["Inglese", "Spagnolo", "Francese", "Tedesco", "Italiano"];
  LangArray = [];
  PrafArray = [
    "A1 – Beginner",
    "A2 – Elementary",
    "B1 – Intermediate",
    "B2 – Upper-Intermediate",
    "C1 – Advanced",
    "C2 – Proficiency",
  ];
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  subscription: Subscription;
  constructor(
    private titleService: Title,
    private jobDesignationService: JobdesignationService,
    private regionService: RegionService,
    private tagsService: TagsService,
    private authenticationService: AuthenticationService,
    private countryService: CountryService,
    private userProfileService: UserProfileService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private meta: Meta,
    private translateService: TranslateService,
    private lunguageselectionService: LunguageselectionService,
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.Math = Math;
    this.titleService.setTitle("Ingeding | Edit Profile");
    this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
      this.ngOnInit();
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getLoginUserData();
    this.translateService.get('PAGES.EDIT_PROFILE.LangArray').subscribe((res: string[]) => {
      this.LangArray = res;
    });
  }

  /** Get ogin user data and profile data */
  getLoginUserData() {

    var id: any = "";
    this.authenticationService.currentUser$.subscribe((data) => {
      if (data) {
        id = data._id ? data._id : "";
      }
    });


    if (id != "") {
      this.documentArr = [];
      this.photoArr = [];
      this.photoFiles = [];
      this.documentFiles = [];
      this.authenticationService.getfullUserDetails(id).subscribe((data) => {
        this.userData = data;
        this.selectedCategory = data.category;
        this.localUserData = data;
        this.getHashtag(this.selectedCategory);
        this.regType = data.usergroup == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
      });
      this.gridOption.filter = {};
      this.gridOption.filter.users = id;
      this.userProfileService.searchAll(this.gridOption).subscribe((res) => {
        this.gridOption.filter = {};
        var lang = res.data[0].language;
        lang.forEach((element) => {
          if (element.otherFlag) {
            this.otherLangTab = true;
            this.otherLanguage = element;
          }
        });
        res.data[0].language = res.data[0].language.filter(
          (m) => m.otherFlag != true
        );
        this.profileData = res.data[0];
        this.profileData.countrycode =
          this.profileData.countrycode == null
            ? this.localUserData.country_id
            : this.profileData.countrycode;
        this.profileData.secondarycontact =
          this.profileData.secondarycontact == null
            ? this.localUserData.contact_no
            : this.profileData.secondarycontact;
        this.profileData.secondryemail =
          this.profileData.secondryemail == null
            ? this.localUserData.email
            : this.profileData.secondryemail;
        this.selectedJobDesignation = res.data[0].jobdesignations;
        this.selectedTags = res.data[0].tags;
        this.selectedRegion = res.data[0].regions;
        this.photoArr = res.data[0].gallery;
        this.documentArr = res.data[0].document;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
      this.getRegion();
      this.getCategory();
      this.getHashtag();
      this.getJobDesignation();
      this.getCountries();
    }
  }

  checkIfNumber(x: any) {
    var y = Number(x);
    if (Number.isInteger(y)) {
      return y + ".00";
    } else {
      return y;
    }
  }

  openLangModel() {
    this.modalService.open(this.langModel);
  }

  otherLang() {
    this.modalService.dismissAll(this.langModel);
    this.otherLangTab = false;
    this.otherLanguage.name = "";
    this.otherLanguage.proficiency = "A1 – Beginner";
  }

  /** Get All the regions */
  getRegion() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.regionService.searchAll(this.gridOption).subscribe((res) => {
      this.RegionArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.categoryService.searchAll(this.gridOption).subscribe((res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the tasks */
  getJobDesignation() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.jobDesignationService.searchAll(this.gridOption).subscribe((res) => {
      this.JobDesignationArr = _.orderBy(
        res.data,
        ["translations.title"],
        ["asc"]
      );
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the tags */
  getHashtag(categoryIdArr: any = []) {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    if (categoryIdArr.length > 0) {
      this.gridOption.filter.category = { $in: categoryIdArr };
    }
    this.tagsService.searchAll(this.gridOption).subscribe((res) => {
      this.TagsArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get all the country */
  getCountries() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.countryService.searchAll(this.gridOption).subscribe((res) => {
      this.CountryArr = _.orderBy(res.data, ["name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  cateChange(event: any) {
    if (event.target.checked) {
      this.selectedCategory.push(event.target.value);
    } else {
      this.selectedCategory = this.selectedCategory.filter(
        (m) => m != event.target.value
      );
    }
    this.selectedTags = [];
    this.getHashtag(this.selectedCategory);
  }

  /** When Regione changed  */
  regionChange(event: any) {
    if (event.target.checked) {
      this.selectedRegion.push(event.target.value);
    } else {
      this.selectedRegion = this.selectedRegion.filter(
        (m) => m != event.target.value
      );
    }
  }

  /** When tasks changed  */
  designationChange(event: any) {
    if (event.target.checked) {
      this.selectedJobDesignation.push(event.target.value);
    } else {
      this.selectedJobDesignation = this.selectedJobDesignation.filter(
        (m) => m != event.target.value
      );
    }
  }

  /** When tags changed  */
  tagsChange(event: any) {
    if (event.target.checked) {
      this.selectedTags.push(event.target.value);
    } else {
      this.selectedTags = this.selectedTags.filter(
        (m) => m != event.target.value
      );
    }
  }

  /** open file selector window */
  openFileSelector(field: any) {
    $("input#" + field).click();
  }

  /** Remove document files */
  removeDocument(index: any) {
    this.removeDocumentIndex = index;
    this.modalService.open(this.confirmModel);
  }

  /** Add document files */
  onDocumentAdded(event: any) {
    let myFileArray = event.target.files;
    for (let i = 0; i < myFileArray.length; i++) {
      if (
        myFileArray[i].size < 5242880 &&
        (myFileArray[i].type === "application/pdf" ||
          myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        var result = this.checkAndAddDocument(myFileArray[i]);
        if (result) {
          this.documentFiles.unshift(myFileArray[i]);
          this.documentArr.unshift({
            name: myFileArray[i].name,
            size: myFileArray[i].size,
          });
        }
      } else {
        this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.INVALID_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  /** Check for duplicate document files */
  checkAndAddDocument(myfile) {
    var result: Boolean = true;
    for (let i = 0; i < this.documentArr.length; i++) {
      const element = this.documentArr[i];
      if (element.name == myfile.name) {
        result = false;
        break;
      }
    }
    return result;
  }

  /** Add gallery files */
  onGalleryAdded(event: any) {
    var allowFilesCount = 12 - this.photoArr.length;
    var myFileArray = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      myFileArray.push(element);
    }
    myFileArray = this.removeDuplicatePhotos(myFileArray);
    var actuallCount: any;
    if (myFileArray.length <= allowFilesCount) {
      actuallCount = myFileArray.length;
    } else {
      actuallCount = allowFilesCount;
      this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.REACHED_MAX_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }

    for (let i = 0; i < actuallCount; i++) {
      if (
        myFileArray[i].size < 5242880 &&
        (myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        var result = this.checkAndAddPhotos(myFileArray[i]);
        if (result) {
          this.photoFiles.unshift(myFileArray[i]);
          let reader = new FileReader();
          reader.readAsDataURL(myFileArray[i]);
          reader.onload = (e: any) => {
            this.photoArr.unshift({
              name: myFileArray[i].name,
              size: myFileArray[i].size,
              url: e.target.result,
            });
            if (!(this.cdr as any).destroyed) {
              this.cdr.detectChanges();
            }
          };
        }
      } else {
        this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.INVALID_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  /** Check for duplicate files */
  removeDuplicatePhotos(filesArray) {
    var result = [];
    if (this.photoArr.length > 0) {
      result = filesArray.filter(
        ({ name: name1 }) =>
          !this.photoArr.some(({ name: name2 }) => name1 == name2)
      );
    } else {
      result = filesArray;
    }

    return result;
  }

  /** Check for duplicate photo files */
  checkAndAddPhotos(myfile) {
    var result: Boolean = true;
    for (let i = 0; i < this.photoArr.length; i++) {
      const element = this.photoArr[i];
      if (element.name == myfile.name) {
        result = false;
        break;
      }
    }
    return result;
  }

  /** Remove photo files */
  removePhoto(index: any) {
    this.removePhotoIndex = index;
    this.modalService.open(this.confirmModel);
  }

  /** Array for years */
  counter(start: any = "") {
    var myArray = [];
    var limit = start == "" ? 1975 : start;
    for (let i = new Date().getFullYear(); i >= limit; i--) myArray.push(i);
    return myArray;
  }

  /** Edit fields */
  edit(event: any, id: any, value: any = "") {
    $("#" + id).hide();
    $("#edit-" + id).show();
    if (value != "") {
      $("#textbox-edit-" + id).val(value);
    }
    $("#textbox-edit-" + id).focus();
  }

  /** Hide fields */
  hide(
    event: any,
    id: any = "",
    index: any = null,
    bindArray: any = null,
    arrElement: any = null
  ) {
    $("#edit-" + id).hide();
    $("#" + id).show();
    if (bindArray != null) {
      if (arrElement != null) {
        bindArray[index][arrElement] = $("#textbox-edit-" + id).val();
      } else {
        bindArray[index] = $("#textbox-edit-" + id).val();
      }
    }
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** Add more fields */
  addMore(bindArray: any, elements: any = []) {
    if (elements == "formation") {
      bindArray.push({
        from: "",
        to: "",
        institute: "",
        location: "",
      });
    } else if (elements == "experience") {
      bindArray.push({
        from: "",
        to: "",
        position: "",
        company: "",
        location: "",
      });
    } else if (elements == "language") {
      if (this.LangArray.length > bindArray.length) {
        bindArray.push({
          name: "",
          proficiency: "A1 – Beginner",
        });
      }
    } else if (elements == "package") {
      bindArray.push({
        name: "",
        rate: "",
        desc: "",
      });
    } else if (elements == "rate") {
      bindArray.push({
        rate: "",
        desc: "",
      });
    } else {
      bindArray.push("");
    }
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  deleteThis(event: any, index: any, bindVariable: any = "") {
    this.deleteIndex = index;
    this.deleteBindVariable = bindVariable;
    this.modalService.open(this.confirmModel);
  }

  filterMyDate(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  myFormValidation() {
    if (
      this.profileData.description == "" ||
      this.profileData.description == null
    ) {
      return false;
    } else if (this.selectedTags.length <= 0) {
      return false;
    } else if (this.selectedJobDesignation.length <= 0) {
      return false;
    } else if (this.selectedCategory.length <= 0) {
      return false;
    }
    else if (
      this.regType == "1" &&
      (this.profileData.aboutus == "" || this.profileData.aboutus == null)
    ) {
      return false;
    }
    else if (this.selectedRegion.length <= 0) {
      return false;
    }
    return true;
  }

  /** On submit form */
  onUpdate(updateForm: any) {
    let isValidat = this.myFormValidation();
    if (updateForm.form.valid && isValidat) {
      updateForm.submitted = false;
      const myProfileData = new FormData();


      for (let i = 0; i < this.photoFiles.length; i++) {
        const element = this.photoFiles[i];
        myProfileData.append("gallery", element);
      }
      for (let i = 0; i < this.documentFiles.length; i++) {
        const element = this.documentFiles[i];
        myProfileData.append("document", element);
      }
      this.photoArr.forEach((element) => {
        element["url"] = "";
      });
      this.profileData.tags = this.selectedTags;
      this.profileData.jobdesignations = this.selectedJobDesignation;
      this.profileData.deletedGallery = this.deletedPhoto;
      this.profileData.deletedDocument = this.deletedDocument;
      this.profileData.regions = this.selectedRegion;
      this.profileData.skill = this.profileData.skill.filter((m) => m != "");
      this.profileData.software = this.profileData.software.filter(
        (m) => m != ""
      );
      this.profileData.experience = this.profileData.experience.filter(
        (m) => m.from != "" && m.to != ""
      );
      this.profileData.experience.sort((a, b) => {
        return b.from - a.from;
      });
      this.profileData.formation = this.profileData.formation.filter(
        (m) => m.from != "" && m.to != ""
      );
      this.profileData.formation.sort((a, b) => {
        return b.from - a.from;
      });
      this.profileData.language = this.profileData.language.filter(
        (m) => m.name != ""
      );
      if (
        this.otherLangTab &&
        this.otherLanguage.name != "" &&
        this.otherLanguage.proficiency != ""
      ) {
        this.profileData.language.push(this.otherLanguage);
      }
      this.profileData.selectedCategory = this.selectedCategory;
      myProfileData.append("data", JSON.stringify(this.profileData));
      this.userProfileService.update(myProfileData).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.toastr.success(this.translateService.instant('PAGES.EDIT_PROFILE.SUCCESSFULLY_SAVED'), this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS'));
          this.router.navigate(["view-profile"]);
        } else {
          this.toastr.error(
            this.translateService.instant('PAGES.LOGIN_PAGE.SOMETHING_WENT_WRONG'),
            this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE')
          );
        }
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    } else {
      this.toastr.error(this.translateService.instant('PAGES.REGISTRATION_PAGE.CORRECT_DATA_TRY_AGAIN'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }
  }

  /** For Remove Profile Picture */
  removeProfilePic() {
    this.removeProfilePicIndex = 1;
    this.modalService.open(this.confirmModel);
  }

  /** For Update Profile Picture */
  uploadProfilePic(event) {
    let myFileArray = event.target.files;
    var myUserData = new FormData();
    this.userData.deletedFiles = [];
    let dataObj = this.userData;
    dataObj["newImage"] = true;
    dataObj["remImage"] = false;
    for (let i = 0; i < myFileArray.length; i++) {
      if (
        myFileArray[i].size < 512000 &&
        (myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        myUserData.append("profile", myFileArray[i]);
        myUserData.append("data", JSON.stringify(dataObj));
        this.authenticationService
          .updateUserDetails(myUserData)
          .subscribe((data) => {
            if (data.status === 1) {
              this.toastr.success(
                this.translateService.instant('PAGES.EDIT_PROFILE.PROFILE_UPDATE_SUCCESSFULLY'),
                this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
              );
              //this.getLoginUserData();
              let id: any;
              this.authenticationService.currentUser$.subscribe((data) => {
                if (data) {
                  id = data._id ? data._id : "";
                }
              });
              this.authenticationService
                .getfullUserDetails(id)
                .subscribe((data) => {
                  this.userData = data;
                  this.localUserData = data;
                  // if (!(this.cdr as any).destroyed) {
                  //   this.cdr.detectChanges();
                  // }
                });
            } else {
              this.toastr.error(data.error, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
            }
          });
      } else {
        this.toastr.error(this.translateService.instant('PAGES.LOGIN_PAGE.INVALID_PROFILE'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  onNoClick() {
    this.modalService.dismissAll(this.confirmModel);
  }

  /** Delete Confimation POP-UP
   *  Sì : Will delete clicked operation
   *  No  : Will Abort opertaion
   */
  onYesClick() {
    if (
      this.deleteIndex != -1 &&
      this.removeDocumentIndex == -1 &&
      this.removePhotoIndex == -1 &&
      this.removeProfilePicIndex == -1
    ) {
      this.deleteBindVariable.splice(this.deleteIndex, 1);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
      this.deleteIndex = -1;
    }
    if (
      this.deleteIndex == -1 &&
      this.removeDocumentIndex != -1 &&
      this.removePhotoIndex == -1 &&
      this.removeProfilePicIndex == -1
    ) {
      const deletedocument = this.deleteDocument();
    }

    if (
      this.deleteIndex == -1 &&
      this.removeDocumentIndex == -1 &&
      this.removePhotoIndex != -1 &&
      this.removeProfilePicIndex == -1
    ) {
      const deletephoto = this.deletePhoto();
    }
    if (
      this.deleteIndex == -1 &&
      this.removeDocumentIndex == -1 &&
      this.removePhotoIndex == -1 &&
      this.removeProfilePicIndex != -1
    ) {
      const removeProfilePic = this.deleteProfilePic();
    }
    this.modalService.dismissAll(this.confirmModel);
  }

  deleteDocument() {
    const index = this.removeDocumentIndex;
    if (this.documentArr[index]._id) {
      this.deletedDocument.push(this.documentArr[index]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
    this.documentFiles.splice(index, 1);
    this.documentArr.splice(index, 1);
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
    this.removeDocumentIndex = -1;
  }
  /** For Delete Photo
   *  Nested Fuunction
   */
  deletePhoto() {
    const index = this.removePhotoIndex;
    if (this.photoArr[index]._id) {
      this.deletedPhoto.push(this.photoArr[index]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
    this.photoFiles.splice(index, 1);
    this.photoArr.splice(index, 1);
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
    this.removePhotoIndex = -1;
  }

  /** For Delete Profile Picture
   *  Nested Fuunction
   */
  deleteProfilePic() {
    var myUserData = new FormData();
    this.userData.pic = "";
    this.userData.deletedFiles = [];
    let dataObj = this.userData;
    dataObj["newImage"] = false;
    dataObj["remImage"] = true;
    myUserData.append("data", JSON.stringify(dataObj));
    this.authenticationService
      .updateUserDetails(myUserData)
      .subscribe((data) => {
        if (data.status === 1) {
          if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
          }
          this.toastr.success(this.translateService.instant('PAGES.EDIT_PROFILE.PROFILE_REMOVE_SUCCESSFULLY'), this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS'));
          this.getLoginUserData();
        } else {
          this.toastr.error(data.error);
        }
      });
    this.removeProfilePicIndex = -1;
  }

  nameFormat(fname: string, lname: string) {
    var firstName = "";
    var lastName = "";
    if (fname != "" && fname != undefined && fname != null && fname.length > 10) {
      firstName += fname.substring(0, 20) + "...";
    } else {
      firstName = fname;
    }
    if (lname != "" && lname != undefined && lname != null && lname.length > 10) {
      lastName += lname.substring(0, 20) + "...";
    } else {
      lastName = lname;
    }
    return firstName + "<sup class='sup-tag'>#iaming</sup><br/>" + lastName;
  }

  companyNameFormate(comapny: string) {
    if (
      comapny != "" &&
      comapny != undefined &&
      comapny != null &&
      comapny.length > 20
    ) {
      return comapny.substring(0, 20) + "...";
    } else {
      return comapny + "<sup class='sup-tag'>#iaming</sup>";
    }
  }
}
