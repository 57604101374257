import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, UserService } from "../_services";
import { SubDetailsService } from "../_services/subdetails.service";
import { GridOption } from "../_models/gridoptions.model";
import { SubPackageService } from "../_services/subpackage.service";
import { FrontHomeService } from "../_services/fronthome.service";

@Component({
  selector: "app-upgrade-account",
  templateUrl: "./upgrade-account.component.html",
  styleUrls: ["./upgrade-account.component.scss"],
})
export class UpgradeAccountComponent implements OnInit {
  @ViewChild("purchaseConfirmationPopup", { static: false })
  purchaseConfirmationPopup: TemplateRef<any>;
  @ViewChild("confirmModel", { static: false }) confirmModel: TemplateRef<any>;
  currentUser: any;
  userData: any = [];
  cardinfo: any = [];
  paymenttype: any = "0";
  planDetail: any = [];
  userType: any;
  scheduledPlan: any = [];
  selectedstripecardid: any = "new";
  scheduled: boolean;
  packInfo: any = [];
  packFlag: any = 0;
  isPurchase: any = false;
  submitted = false;
  Math: any;
  deleted = false;
  stripeCustId: any = "";
  parseFloat: any;
  dataArray: any;
  cardIdForDelete: any = "";
  subscriptionDetails: any = [];
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  constructor(
    private titleService: Title,
    private subDetailService: SubDetailsService,
    private subPackageService: SubPackageService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private frontHomeService: FrontHomeService,
    private toastr: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
    private meta: Meta
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    window.scrollTo(0, 0);
    this.Math = Math;
    this.parseFloat = parseFloat;
    this.titleService.setTitle("Ingeding | Upgrade Account");
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.checkSubscriptionDetails();
    this.getData();
  }

  checkIfNumber(x: any) {
    var y = Number(x);
    if (Number.isInteger(y)) {
      return y + ".00";
    } else {
      return y;
    }
  }

  getData() {
    this.frontHomeService.searchAll(this.gridOption).subscribe((res) => {
      this.dataArray = res.data;
    });
  }

  checkSubscriptionDetails() {
    this.authenticationService.currentUser$.subscribe(async (data) => {
      this.currentUser = data ? data._id : null;
      if (this.currentUser != null) {
        this.authenticationService
          .getfullUserDetails(this.currentUser)
          .subscribe((data) => {
            this.userData = data;
            this.userType =
              data.usergroup == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
            this.checkPurchse(this.currentUser);
            if (this.userType == "0" && this.userData.isCollaborator != true) {
              this.fatchPlan("WithVATUSER");
            } else if (
              this.userType == "0" &&
              this.userData.isCollaborator != false
            ) {
              this.fatchPlan("WithoutVATUSER");
            } else {
              this.fatchPlan(this.userData.noOfCollaborator);
            }
          });
      }
    });
    if (this.currentUser != null) {
      this.subDetailService.getSubDetails(this.currentUser).subscribe((res) => {
        this.subscriptionDetails = res;
        this.fetchCards(this.currentUser);
      });
    }
  }

  checkPurchse(userId: any) {
    this.subDetailService.checkForFuturePlan(userId).subscribe((res) => {
      if (res._id) {
        this.scheduled = true;
        this.scheduledPlan = res;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      } else {
        this.scheduled = false;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      }
    });
  }

  fatchPlan(packagefor: any) {
    const packFilterData = {
      packagefor: packagefor,
    };
    this.subPackageService.getPackages(packFilterData).subscribe((res) => {
      if (res.data.length > 0) {
        this.packInfo = res.data[0];
      }
      this.packFlag = 1;
    });
  }

  fetchCards(userId: any) {
    const customerData = {
      userId: userId,
    };
    this.userService.fetchAllCard(customerData).subscribe((res) => {
      this.cardinfo = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  deleteThisCard(cardId: any, custId: any) {
    this.cardIdForDelete = cardId;
    this.stripeCustId = custId;
    this.modalService.open(this.confirmModel);
  }

  onNoClick() {
    this.modalService.dismissAll(this.confirmModel);
  }

  onYesClick() {
    this.deleted = true;
    this.modalService.dismissAll(this.confirmModel);
    if (this.cardIdForDelete != "") {
      const data = {
        cardId: this.cardIdForDelete,
        custId: this.stripeCustId,
      };
      this.userService.deleteCard(data).subscribe((res) => {
        this.deleted = false;
        if (res.status == 1) {
          this.toastr.success(res.data, "Successo");
        } else {
          this.toastr.error(res.message.field, "Errore");
        }
        this.cardinfo = [];
        this.fetchCards(this.currentUser);
      });
    } else {
      this.deleted = false;
      this.toastr.error("Scegli una carta da eliminare.", "Errore");
    }
  }

  filterMyDate(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  checkNearToExpired(date: any = null) {
    if (date != null) {
      let today = new Date();
      let endDate = new Date(date);

      var Difference_In_Time = endDate.getTime() - today.getTime();

      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (this.Math.floor(Difference_In_Days) <= 30) {
        return true;
      } else {
        return false;
      }
    }
  }

  openPayment() {
    window.scrollTo(0, 0);
    this.isPurchase = true;
  }

  paymentConfirmation(formdata: any) {
    if (this.paymenttype == 0) {
      this.modalService.open(this.purchaseConfirmationPopup);
    } else {
      if (formdata.form.valid) {
        this.modalService.open(this.purchaseConfirmationPopup);
      }
    }
  }

  invoice() {
    this.submitted = true;
    this.modalService.dismissAll(this.purchaseConfirmationPopup);
    var mySubData = {
      userid: this.currentUser,
      stripeproductid: this.packInfo.stripe_product_id,
      stripepriceid: this.packInfo.stripe_price_id,
      packagename: this.packInfo.packagename,
      packageamount: this.packInfo.amount,
      plan_type: 2,
      paymentmode: 2,
    };
    this.subDetailService.createSubscription(mySubData).subscribe((res) => {
      if (res.message === "success" || res.status === 1) {
        //window.scrollTo(0, 0);
        this.toastr.success("Abbonamento acquistato con successo", "Successo");
        this.isPurchase = false;
        this.submitted = false;
        this.checkSubscriptionDetails();
        window.location.reload();
        //this.checkPurchse(this.currentUser);
      } else {
        this.submitted = false;
        this.toastr.error(
          "Qualcosa è andato storto, per favore riprova più tardi",
          "Errore"
        );
      }
    });
  }

  back() {
    window.scrollTo(0, 0);
    this.isPurchase = false;
  }

  newsubscription() {
    this.submitted = true;
    this.modalService.dismissAll(this.purchaseConfirmationPopup);
    var mySubData;
    if (this.selectedstripecardid == "new") {
      mySubData = {
        userid: this.currentUser,
        stripeproductid: this.packInfo.stripe_product_id,
        stripepriceid: this.packInfo.stripe_price_id,
        packagename: this.packInfo.packagename,
        packageamount: this.packInfo.amount,
        cardname: this.cardinfo.cardname,
        number: this.cardinfo.cardnumber,
        exp_month: this.cardinfo.cardmm,
        exp_year: this.cardinfo.cardyy,
        cvc: this.cardinfo.cardcvc,
        plan_type: 2,
        paymentmode: 1,
      };
    } else {
      mySubData = {
        userid: this.currentUser,
        stripeproductid: this.packInfo.stripe_product_id,
        stripepriceid: this.packInfo.stripe_price_id,
        stripecardid: this.selectedstripecardid,
        packagename: this.packInfo.packagename,
        packageamount: this.packInfo.amount,
        plan_type: 2,
        paymentmode: 1,
      };
    }
    this.subDetailService.createSubscription(mySubData).subscribe((res) => {
      if (res.message === "success" || res.status === 1) {
        window.scrollTo(0, 0);
        this.isPurchase = false;
        this.submitted = false;
        this.toastr.success("Abbonamento acquistato con successo", "Successo");
        this.checkSubscriptionDetails();
      } else if (res.status === 0 && res.message && res.message.card) {
        this.submitted = false;
        this.toastr.error(res.message.card, "Errore");
      } else if (res.status === 0 && res.message && res.message.subscription) {
        this.submitted = false;
        this.toastr.error(res.message.subscription, "Errore");
      } else {
        this.submitted = false;
        this.toastr.error(
          "Qualcosa è andato storto, per favore riprova più tardi",
          "Errore"
        );
      }
    });
  }

  ngOnDestroy() {
    this.modalService.dismissAll(this.purchaseConfirmationPopup);
  }

  cancel() {
    window.scrollTo(0, 0);
    this.modalService.dismissAll(this.purchaseConfirmationPopup);
  }
}
