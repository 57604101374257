import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService extends CommonService {
  languageArr: any = [];
  token: any;
  tokenData: any;
  constructor(http: HttpClient) {
    super("language", http);
  }

  getLanguage() {
    this.tokenData = JSON.parse(localStorage.getItem("currentUser"));
    if (this.tokenData) {
      this.languageArr = this.tokenData.language;
    } else {
      //this.getAllLanguage();
      const blank_obj = {};
      this.searchAll(blank_obj).subscribe((res) => {
        this.languageArr = res.data[0];
      })
    }
    return this.languageArr;
  }

  // getAllLanguage() {
  //   const blank_obj = {};
  //   this.searchAll(blank_obj).subscribe((res) => {
  //     this.languageArr = res.data;
  //   })
  //   return this.languageArr;
  // }

  getAllLanguage(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const blank_obj = {};
      this.searchAll(blank_obj).subscribe(
        (res) => {
          this.languageArr = res.data;
          this.languageArr = res.data.filter(item => item.status == 1 && item.on_front_view === 1);
          this.languageArr.sort((a, b) => {
            if (a._id < b._id) return -1;
            if (a._id > b._id) return 1;
            return 0;
          });
          resolve(this.languageArr);
        },
        (error) => {
          console.error('Error fetching languages:', error);
          reject(error);
        }
      );
    });
  }



  // ngOnInit() {
  //   this.getAllLanguage();
  // }
}
