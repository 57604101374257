import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Meta, Title } from "@angular/platform-browser";
import { GridOption } from "../_models/gridoptions.model";
import { AuthenticationService } from "../_services";
import { JobPostService } from "../_services/jobpost.service";
import * as _ from "lodash";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { CategoryService } from "../_services/category.service";
import { RegionService } from "../_services/region.service";
import { TagsService } from "../_services/tags.service";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-search-job-post",
  templateUrl: "./search-job-post.component.html",
  styleUrls: ["./search-job-post.component.scss"],
})
export class SearchJobPostComponent implements OnInit, OnDestroy {
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  MyJobPosts: any = [];
  today = new Date().toISOString().slice(0, 10);
  userType: any = "";
  myTabs = 0;
  isCollapse = 0;
  isCollapse1 = 0;
  hideshowclass: boolean = true;
  currentUser: any = "";
  mySelectedPage: any = 1;
  id: any = "";
  totalCount: any = 0;
  CategoryArr: any = [];
  RegionArr: any = [];
  SelectedTags: any = [];
  TagsArr: any = [];
  TagsArrs: any = [];
  Deadline: any = "";
  SelectedRegion: any = "";
  selectedCategory: any = "";
  inactivePostId: any = "";
  deletePostId: any = "";
  model: NgbDateStruct;
  searchtext: any = "";
  allCategoryid: any = [];
  gridOptionPost: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 10,
    total: 0,
    order: "_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 10,
    allrecords: null,
  };
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  constructor(
    private titleService: Title,
    private myPostService: JobPostService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
    private regionService: RegionService,
    private tagsService: TagsService,
    private route: ActivatedRoute,
    private meta: Meta,
    private authenticationService: AuthenticationService
  ) {
    this.meta.addTag({ name: 'title', content: 'Offerte di lavoro per ingegneri | Ingeding' });
    this.meta.addTag({ name: 'keywords', content: 'offerte lavoro ingegnere' });
    this.meta.addTag({ name: 'description', content: 'Ingeding – la piattaforma per ingegnieri, dove domanda e offerta si incontrano. Scopri tutte le offerte di lavoro e gli incarichi aperti per ingegneri.' });
    route.params.subscribe((val) => {
      window.scrollTo(0, 0);
      this.searchtext =
        this.route.snapshot.params["search"] == undefined ||
          this.route.snapshot.params["search"] == ""
          ? ""
          : this.route.snapshot.params["search"];
      if (this.route.snapshot.params["category"]) {
        this.selectedCategory = this.route.snapshot.params["category"];
        this.selectThisCategory(this.selectedCategory);
        this.hideshowclass = false;
      } else {
        this.selectThisCategory1();
        this.hideshowclass = true;
      }
      this.authenticationService.currentUser$.subscribe((data) => {
        if (data) {
          this.currentUser = data._id;
        }
      });
    });
    this.titleService.setTitle("Ingeding | Search Job Post");
    this.gridOptionPost.sortField = "createdAt";
    this.gridOptionPost.sortOrder = "desc";
  }
  ngOnInit() {
    this.getAllRegions();
    this.getAllCategory();
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.meta.removeTag('name=title');
    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
  }
  async getAllCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    await this.categoryService.searchAll(this.gridOption).subscribe(async (res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.CategoryArr.filter((x: any) => { this.allCategoryid.push(x._id); });
    });
  }
  selectThisCategory(cateId: any) {
    this.hideshowclass = false;
    this.isCollapse1 = 0;
    this.selectedCategory = [cateId];
    this.SelectedTags = [];
    this.gridOptionPost.skip = 0;
    this.mySelectedPage = 1;
    this.gridOptionPost.allrecords = false;
    this.getCategoryTags(cateId);
  }
  selectThisCategory1() {
    this.hideshowclass = true;
    this.selectedCategory = this.allCategoryid;
    this.SelectedTags = [];
    this.mySelectedPage = 1;
    this.getAllCategoryTages();
  }
  async getAllCategoryTages() {
    await this.categoryService.searchAll(this.gridOption).subscribe(async (res) => {
      var tempid = _.orderBy(res.data, ["translations.title"], ["asc"]);
      tempid.filter((x: any) => { this.allCategoryid.push(x._id) });
      this.gridOption.filter = {};
      this.gridOptionPost.allrecords = false;
      this.gridOption.filter.status = 1;
      await this.tagsService.searchAll(this.gridOption).subscribe((res) => {
        this.TagsArrs.push(res.data)
        this.TagsArrs = _.orderBy(res.data, ["translations.title"], ["asc"]);
        this.cdr.detectChanges();
      });
    });
    this.gridOptionPost.allrecords = false;
    this.getAllJobPost();
  }
  getCategoryTags(cateId: any) {
    this.gridOption.filter = {};
    this.gridOption.allrecords = false;
    this.gridOption.filter.status = 1;
    this.gridOption.filter.category = cateId;
    this.tagsService.searchAll(this.gridOption).subscribe((res) => {
      this.TagsArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.mySelectedPage = 1;
      this.getAllJobPost();
    });
  }
  changeCollaps() {
    if (this.isCollapse == 0) {
      this.isCollapse = 1;
    } else {
      this.isCollapse = 0;
    }
  }
  changeCollaps1() {
    if (this.isCollapse1 == 0) {
      this.isCollapse1 = 1;
    } else {
      this.isCollapse1 = 0;
    }
  }
  getAllJobPost() {
    this.gridOptionPost.skip = (this.mySelectedPage - 1) * 10;
    this.gridOptionPost.filter = {};
    this.gridOptionPost.search = "";
    this.gridOptionPost.filter.status = 1;
    if (this.SelectedTags.length > 0) {
      this.gridOptionPost.filter.tags = this.SelectedTags;
    }
    if (this.SelectedRegion != "") {
      this.gridOptionPost.filter.placeofwork = this.SelectedRegion;
    }
    if (this.Deadline != "") {
      this.gridOptionPost.filter.deadline = this.Deadline;
    }
    if (this.selectedCategory != "") {
      var unique = this.selectedCategory.filter((value, index, array) => array.indexOf(value) === index);
      this.gridOptionPost.filter.category = unique;
    }
    if (this.currentUser != "") {
      this.gridOptionPost.filter.currentuser = this.currentUser;
    }
    if (this.searchtext != undefined && this.searchtext != "") {
      this.gridOptionPost.search = this.searchtext;
    }
    this.myPostService.searchAll(this.gridOptionPost).subscribe((res) => {
      this.totalCount = res.totalCount;
      this.MyJobPosts = res.data;
      this.isCollapse = 0;
    });
  }
  getAllRegions() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.regionService.searchAll(this.gridOption).subscribe((res) => {
      this.RegionArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
  hide(id: any) {
    if (id == "dateCalendar") {
      $("#dateCalendar").hide();
      $("#deadline").show();
      $("#deadline").focus();
    } else {
      $("#dateCalendar").show();
      $("#deadline").hide();
      this.gridOptionPost.allrecords = false;
      this.mySelectedPage = 1;
      this.getAllJobPost();
    }
  }
  selectedPage(pageNo: any) {
    this.mySelectedPage = pageNo;
    this.getAllJobPost();
  }
  dateFilter(date: any = null) {
    if (date != null && date != "") {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    } else {
      return "";
    }
  }
  changeOrder(field: any) {
    this.gridOptionPost.sortField = field;
    if (this.gridOptionPost.sortOrder == "asc") {
      this.gridOptionPost.sortOrder = "desc";
    } else {
      this.gridOptionPost.sortOrder = "asc";
    }
    this.getAllJobPost();
  }
  selectedRegion(event: any) {
    this.SelectedRegion = event.target.value;
    this.gridOptionPost.allrecords = false;
    this.mySelectedPage = 1;
    this.getAllJobPost();
  }
  selectThisTags(event) {
    if (event.target.checked) {
      this.SelectedTags.push(event.target.value);
    } else {
      this.SelectedTags = this.SelectedTags.filter(
        (m) => m != event.target.value
      );
    }
    this.mySelectedPage = 1;
    this.gridOptionPost.allrecords = false;
    this.getAllJobPost();
  }
  selectThisTags1(event) {
    if (event.target.checked) {
      this.SelectedTags.push(event.target.value);
    } else {
      this.SelectedTags = this.SelectedTags.filter(
        (m) => m != event.target.value
      );
    }
    this.gridOptionPost.allrecords = false;
    this.mySelectedPage = 1;
    this.getAllJobPost();
  }
}
