import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { User } from "../_models";
import { environment } from "../../environments/environment";
const baseURL = environment.baseURL;

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  token: any;
  localStoageUserData: any;
  currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(
    JSON.parse(localStorage.getItem("currentUser"))
  );
  currentUser$ = this.currentUser.asObservable();
  constructor(private http: HttpClient) {
    this.localStoageUserData = JSON.parse(localStorage.getItem("currentUser"));
    if (this.localStoageUserData) {
      this.token = this.localStoageUserData.accessToken;
    }
  }
  login(data): Observable<any> {
    return this.http.post(baseURL + "user/surveyorLogin", data).pipe(
      map((res: any) => {
        if (res && res.status === 1) {
          localStorage.setItem("authToken", res.data.accessToken);
          localStorage.setItem("currentUser", JSON.stringify(res.data.user));
          this.currentUser.next(res.data.user);
        }
        return res;
      })
    );
  }

  verifyToken(data) {
    let token = { token: data };
    return this.http.post<any>(baseURL + "user/verify/", token);
  }

  socialLogin(data) {
    return this.http.post<any>(baseURL + "users/sociallogin", data).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        if (user && user.status === 1) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(
            environment.authTokenKey,
            user.items.accessToken
          );
          localStorage.setItem("currentUser", JSON.stringify(user.items.user));
          localStorage.setItem("currentUser", user.items.accessToken);
          this.currentUser.next(user.items.user);
        }
        return user;
      })
    );
  }

  forgotPassword(data) {
    return this.http.post<any>(baseURL + "user/forgotPassword", data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  resetPassword(data) {
    return this.http.post<any>(baseURL + "user/resetPassword", data).pipe(
      map((user) => {
        return user;
      })
    );
  }

  getToken() {
    return localStorage.getItem("currentUser");
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  deleteUser(userId: number) {
    return this.http.delete(baseURL + "user/" + userId).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    this.currentUser.next(null);
    localStorage.clear();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("mappinglistId");
    localStorage.removeItem("bodyparttagId");
    localStorage.removeItem("menu");
  }

  getfullUserDetails(id) {
    return this.http
      .get<any>(baseURL + "user/userProfile/" + id, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("currentUser"),
        }),
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return null;
        })
      );
  }

  changePassword(data) {
    return this.http.post<any>(baseURL + "user/changepassword/", data, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authToken"),
      }),
    });
  }

  updateUserDetails(data) {
    return this.http.put<any>(baseURL + "user/", data).pipe(
      map((dataresponse) => {
        if (dataresponse && dataresponse.status === 1) {
          var myObj = JSON.parse(localStorage.getItem("currentUser"));
          myObj.companyname = dataresponse.data.companyname;
          myObj.contact_no = dataresponse.data.contact_no;
          myObj.email = dataresponse.data.email;
          myObj.firstname = dataresponse.data.firstname;
          myObj.lastname = dataresponse.data.lastname;
          myObj.pic = dataresponse.data.pic;
          localStorage.setItem("currentUser", JSON.stringify(myObj));
          this.currentUser.next(myObj);
          return dataresponse;
        }
      })
    );
  }

  getUserNotification() {
    return this.http.get<any>(baseURL + "user/getNotifications").pipe(
      map((dataresponse) => {
        if (dataresponse && dataresponse.status === 1) {
          return dataresponse;
        }
      })
    );
  }
  usernotifications(data) {
    data.read = true;
    return this.http
      .put<any>(baseURL + "user/userupdatenotifications", data)
      .pipe(
        map((dataresponse) => {
          if (dataresponse && dataresponse.status === 1) {
            return dataresponse;
          }
        })
      );
  }
  linkedinLogin(data) {
    return this.http.post<any>(baseURL + "linkedin/", data);
  }
  unsubscribe(data) {
    return this.http.put<any>(baseURL + "user/unsubscribe", data).pipe(
      map((dataresponse) => {
        if (dataresponse && dataresponse.status === 1) {
          return dataresponse;
        }
      })
    );
  }
}
