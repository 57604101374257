<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="footer-box">
                    <div class="logo-box site-logo">
                        <a [routerLink]="['/']">
                            <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 828.47 206.65">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #00ae9e;
                                        }
                                    </style>
                                </defs>
                                <title>2021_05_10_BESENSZONI_Logo_RZ_R</title>
                                <path class="cls-1"
                                    d="M131.35,29.17A22.45,22.45,0,0,0,117.45,34L102.28,45.93l21.28-.19V127.4h30.27V51.66a22.48,22.48,0,0,0-22.48-22.49m-64.9,98.22H96.72V29.17L66.45,53v74.39Z"
                                    transform="translate(-5.92 -3.9)" />
                                <path class="cls-1"
                                    d="M220.75,29.17c7.77,4.13,13.1,24.56,13.1,49.11s-6.45,45-13.1,49.11c19.51,0,41.48-19.37,41.48-49.11s-22-49.11-41.48-49.11m-3.88,98.22c-6.65-4.08-13.1-24.56-13.1-49.11s5.33-45,13.1-49.11c-19.51,0-41.48,19.37-41.48,49.11s22,49.11,41.48,49.11"
                                    transform="translate(-5.92 -3.9)" />
                                <path class="cls-1"
                                    d="M191.76,185.8l0-16.83-9.31,11.83a18.38,18.38,0,0,0,14.44,29.75h23.58a18.35,18.35,0,0,0,17.23-24.74h-46Zm69.38-28.66a19.2,19.2,0,0,0-1.39-3.3,18.37,18.37,0,0,0-16.16-9.62h-55l0-16.83-9.31,11.83A18.38,18.38,0,0,0,193.77,169h55l0,16.83L258,174a18.39,18.39,0,0,0,3.63-8.06s.16-1,.21-1.49l0-.21c.05-.54.07-1.07.07-1.61a18.9,18.9,0,0,0-.37-3.71,17.26,17.26,0,0,0-.45-1.75"
                                    transform="translate(-5.92 -3.9)" />
                                <polygon class="cls-1" points="233.51 0.27 233.51 25.37 263.59 0.27 233.51 0.27" />
                                <polygon class="cls-1" points="0.18 0.27 0.18 25.37 30.27 0.27 0.18 0.27" />
                                <polygon class="cls-1" points="0 123.49 30.27 123.49 30.27 25.27 0 49.1 0 123.49" />
                                <path class="cls-1"
                                    d="M644.47,29.17A22.52,22.52,0,0,0,630.56,34L615.4,45.93l21.28-.19V127.4H667V51.66a22.47,22.47,0,0,0-22.48-22.49m-64.89,98.22h30.27V29.17L579.58,53v74.39Z"
                                    transform="translate(-5.92 -3.9)" />
                                <path
                                    d="M345.69,62.43l-23,17.93h45.72c12.71-32.84-13.13-51.18-34.86-51.18,16,15.49,12.13,33.25,12.13,33.25M312.3,70.15a49.33,49.33,0,0,1,12.86-40.88,49.39,49.39,0,1,0,44.06,82.5c-.31,0-.62.12-.93.16a49.4,49.4,0,0,1-56-41.78"
                                    transform="translate(-5.92 -3.9)" />
                                <path
                                    d="M424.7,61.76A48.81,48.81,0,0,1,454.84,31.9a48.89,48.89,0,1,0,.47,92.59c-.3-.1-.6-.18-.9-.29A48.89,48.89,0,0,1,424.7,61.76m64.08,49.3V3.9H458.51v101a22.4,22.4,0,0,0,30.27,21v-14.9Z"
                                    transform="translate(-5.92 -3.9)" />
                                <polygon class="cls-1" points="513.13 0.27 513.13 25.37 543.22 0.27 513.13 0.27" />
                                <polygon class="cls-1"
                                    points="513.13 123.49 543.4 123.49 543.4 25.27 513.13 49.1 513.13 123.49" />
                                <path
                                    d="M817.08,106.62h2.76a2.48,2.48,0,1,1,0,4.94h-2.76v-4.94Zm0,13.79v-6.48h2.09l3.34,6.48h3.38l-3.64-6.9a4.56,4.56,0,0,0,3.23-4.43,5.07,5.07,0,0,0-5.27-5h-6.14V120.4h3Zm2.18,4.14a12.29,12.29,0,1,1,12.08-12.29,12.08,12.08,0,0,1-12.08,12.29m0,2.84a15.13,15.13,0,1,0-15.13-15.13,15.12,15.12,0,0,0,15.13,15.13"
                                    transform="translate(-5.92 -3.9)" />
                                <path class="cls-1"
                                    d="M733.84,29.17c7.77,4.13,13.1,24.56,13.1,49.11s-6.45,45-13.1,49.11c19.51,0,41.48-19.37,41.48-49.11s-22-49.11-41.48-49.11M730,127.39c-6.65-4.08-13.1-24.56-13.1-49.11s5.33-45,13.1-49.11c-19.51,0-41.48,19.37-41.48,49.11s22,49.11,41.48,49.11"
                                    transform="translate(-5.92 -3.9)" />
                                <path class="cls-1"
                                    d="M704.84,185.8l0-16.83-9.31,11.83A18.38,18.38,0,0,0,710,210.55h23.57a18.35,18.35,0,0,0,17.23-24.74H704.84Zm69.39-28.66a19.2,19.2,0,0,0-1.39-3.3,18.37,18.37,0,0,0-16.16-9.62h-55l0-16.83-9.31,11.83A18.38,18.38,0,0,0,706.86,169h55l0,16.83L771.11,174a18.39,18.39,0,0,0,3.63-8.06s.16-1,.21-1.49l0-.21c0-.54.07-1.07.07-1.61a18.9,18.9,0,0,0-.37-3.71c-.12-.59-.28-1.18-.45-1.75"
                                    transform="translate(-5.92 -3.9)" />
                                <polygon class="cls-1" points="746.59 0.27 746.59 25.37 776.68 0.27 746.59 0.27" />
                                <path
                                    d="M527,147v15.45L516.86,147h-3.05v22h3.33V153.5L527.29,169h3.05V147Zm10.91,0v22h14v-3h-10.7V159.4h9.13v-3h-9.13V150H552v-3Zm18.5,0v3h6v19h3.33V150h6v-3Zm41.2,0-3.82,15.7L589.39,147h-2.68l-4.41,15.7L578.48,147H575l5.74,22h2.9L588,153.57,592.51,169h2.9l5.74-22Zm15.42-.19a7.66,7.66,0,0,0-5.68,2.22c-2.16,2.16-2.22,4.35-2.22,8.94s.06,6.78,2.22,8.94a8.36,8.36,0,0,0,11.35,0c2.16-2.16,2.22-4.35,2.22-8.94s-.06-6.79-2.22-8.94a7.63,7.63,0,0,0-5.67-2.22Zm0,3a4.36,4.36,0,0,1,3.27,1.33c1.11,1.14,1.3,2.5,1.3,6.85s-.19,5.67-1.3,6.82a4.61,4.61,0,0,1-6.54,0c-1.08-1.17-1.29-2.47-1.29-6.82s.22-5.67,1.29-6.85A4.43,4.43,0,0,1,613,149.8ZM627.72,169h3.33V159.7h4.16l4.63,9.25h3.89l-5.06-9.71a5.76,5.76,0,0,0,4.38-5.83c0-3.73-2.65-6.42-6.82-6.42h-8.51v22ZM636,150c2.22,0,3.73,1.29,3.73,3.45s-1.51,3.42-3.73,3.42h-4.93V150H636Zm26.09-3-9,11V147h-3.33v22h3.33v-6.38l3.7-4.44L663,169h3.92L659,155.61l7.12-8.6h-4.08Z"
                                    transform="translate(-5.92 -3.9)" />
                                <path class="cls-1"
                                    d="M448.12,147v22h3.33V147Zm24.12,0v15.45L462.09,147h-3v22h3.33V153.5L472.52,169h3.05V147Zm18-.19a7.63,7.63,0,0,0-5.67,2.22c-2.13,2.16-2.22,4.35-2.22,8.94s.09,6.78,2.22,8.94a7.66,7.66,0,0,0,5.67,2.22,8,8,0,0,0,6-2.5c1.45-1.57,2-3.33,2-6.2v-3.36h-8v2.84h4.69v1.23c0,2.93-1.64,5-4.69,5a4.36,4.36,0,0,1-3.27-1.33c-1.08-1.17-1.3-2.5-1.3-6.85s.22-5.67,1.3-6.85a4.4,4.4,0,0,1,3.27-1.33,4.47,4.47,0,0,1,4.63,3.79h3.33a7.69,7.69,0,0,0-7.95-6.76Z"
                                    transform="translate(-5.92 -3.9)" />
                                <path
                                    d="M283.73,147v22h14v-3h-10.7V159.4h9.13v-3h-9.13V150h10.7v-3Zm33.64,0v15.45L307.22,147h-3.05v22h3.33V153.5L317.65,169h3.05V147Zm18-.19a7.63,7.63,0,0,0-5.67,2.22c-2.13,2.16-2.22,4.35-2.22,8.94s.09,6.78,2.22,8.94a7.66,7.66,0,0,0,5.67,2.22,8,8,0,0,0,6-2.5c1.45-1.57,2-3.33,2-6.2v-3.36h-8v2.84h4.69v1.23c0,2.93-1.63,5-4.69,5a4.36,4.36,0,0,1-3.27-1.33c-1.08-1.17-1.29-2.5-1.29-6.85s.22-5.67,1.29-6.85a4.4,4.4,0,0,1,3.27-1.33,4.47,4.47,0,0,1,4.63,3.79h3.33a7.68,7.68,0,0,0-7.95-6.76Zm14.77.19v22h3.33V147Zm24.12,0v15.45L364.12,147h-3.05v22h3.33V153.5L374.55,169h3.05V147Zm10.91,0v22h14v-3h-10.7V159.4h9.13v-3h-9.13V150h10.7v-3Zm20.45,0v22h14v-3H409V159.4h9.13v-3H409V150h10.7v-3ZM426.07,169h3.33V159.7h4.16l4.63,9.25h3.89L437,159.24a5.76,5.76,0,0,0,4.38-5.83c0-3.73-2.65-6.42-6.82-6.42h-8.51v22Zm8.27-19c2.22,0,3.73,1.29,3.73,3.45s-1.51,3.42-3.73,3.42h-4.93V150h4.93Z"
                                    transform="translate(-5.92 -3.9)" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="footer-box">
                    <div class="footer-header">
                        <div class="f-head">Informazioni</div>
                    </div>
                    <div class="footer-links">
                        <ul>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='su-di-noi'" routerLink="/about-us">Su di noi</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='faq'" routerLink="/faq">FAQ</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='contatti'" routerLink="/contact">Contatti</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='abbonamento-e-pagamenti'"
                                        routerLink="/subscription-payment">Abbonamento e pagamenti</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='best-practice'" routerLink="/best-practice">Best
                                        Practice</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="footer-box">
                    <div class="footer-header">
                        <div class="f-head">Legale</div>
                    </div>
                    <div class="footer-links">
                        <ul>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='colophon'" routerLink="/imprint">Colophon</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='termini--condizioni'"
                                        routerLink="/terms-and-conditions">Termini & condizioni</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='privacy'"
                                        routerLink="/privacy-policy">Privacy</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='cookies'" routerLink="/cookies">Cookies</a>
                                </span>
                            </li>
                            <li>
                                <span *ngFor="let data of dataArray">
                                    <a *ngIf="data.translations.slug=='sitemap'" routerLink="/sitemap">Sitemap</a>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-4 col-sm-12">
                <div class="footer-box">
                    <div class="footer-header">
                        <h6>Iscrizione Newsletter</h6>
                    </div>
                    <div class="subscribe-form">
                        <form class="form-subscribe" action="#">
                            <div>
                                <div class="right-inner-addon input-container">
                                    <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                    <input type="text" class="form-control" placeholder="Inserire email" />
                                </div>
                            </div>
                            <div class="c-box">
                                <label class="checkbox-button">
                                    <input type="checkbox" class="checkbox-button__input" id="choice1-1" name="choice1">
                                    <span class="checkbox-button__control"></span>
                                    <span class="checkbox-button__label">Acconsento a ricevere informazioni dall’impresa
                                        Ingedin su temi selezionati (offerte, novità, eventi, informazioni
                                        generali).</span>
                                </label>
                            </div>
                        </form>
                        <div class="right-align">
                            <ul class="social-icons">
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="./assets/images/ingeding//FB.svg" alt="FB" title="FB">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="./assets/images/ingeding/instagram.svg" alt="Instagram" title="Instagram">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</footer>
<section class="copyright">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <!-- F.lli Besenzoni S.r.l. / P.Iva 00454620170 / REA BS-193804 -->
                INGEDING S.r.l. / P.Iva 02663180228 / REA TN-240908
            </div>
            <div class="col-sm-12 col-md-6 right-align">
                Website by <a href="https://www.tt-consulting.com/de/" class="ml-1">tt-consulting</a></div>
        </div>
    </div>
</section>