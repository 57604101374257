export class Profile {
  _id: number;
  users: any = "";
  description: any = "";
  jobdesignations: any = [];
  tags: any = [];
  gallery: any = [];
  document: any = [];
  experience: any = [];
  formation: any = [];
  skill: any = [];
  software: any = [];
  language: any = [];
  aboutus: any = "";
  availableFrom: any = "";
  regions: any = [];
  package: any = [];
  secondryemail: any = "";
  secondarycontact: any = "";
  countrycode: any = null;
  rate: any = [];
  deletedGallery: any = [];
  deletedDocument: any = [];
  selectedCategory: any = [];
}
