// USA

const projectname = "INGEDING";

export const locale = {
  lang: "en",
  data: {
    UPDATE: "Update",
    LIST: "List",
    HOME: "Home",
    ADD: "Add",
    NAME: "Name",
    ADSH: "Adsh",
    CIK: "CIK",
    SIC: "SIC",
    COUNTRY: "Country",
    STPRBA: "State",
    CITY: "City",
    ZIP: "ZIP",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "id",
    Usergroup: "User Group",
    QUESTION_TYPE: "question type",
    ANSWER_INPUT: "answer input",
    ANSWER_TYPE: "answer type",
    QUESTION_TEXT: "question text",
    DESCRIPTION: "description",
    FIRSTNAME: "First name",
    LASTNAME: "Last name",
    EMAIL: "Email",
    PHONE: "Phone",
    COUNTRY_CODE: "Country code",
    REGIONS: "Regions",
    STATES: "State",
    CITIES: "City",
    LOCALE: "Locale",
    COUNTRIES: "Countries",
    VALUES: "Values",
    SETTINGTYPES: "SETTING TYPES",
    ANSWER_OPTIONS: "ANSWER OPTIONS",
    TRANSLATE: "Translate",
    TITLE: "Title",
    TRANSLATOR: {
      SELECT: "Select Language",
    },
    MENU: {
      HOME: "Home",
      MAP: "Map",
      NEW: "new",
      ACTIONS: "Actions",
      CREATE_POST: "Create New Post",
      PAGES: "Pages",
      WARD: "Ward",
      FEATURES: "Features",
      APPS: "Apps",
      DASHBOARD: "Dashboard",
      ASSIGNMENT: "Assignment List",
      USERS: "Users",
      MASTERS: "Masters",
      MODULES: "Modules",
      COMPANY: "Company",
      REGION: "Region",
      COUNTRY: "Country",
      STATE: "State",
      CITY: "City",
      DISTRICT: "District",
      ZONE: "Zone",
      TAG: "Tag",
      LANGUAGE: "Language",
      USERMANAGEMENT: "User Management",
      USERGROUP: "User Group",
      PACKAGE: "Package",
      SEC: "SEC",
      GLOBALSUCCESS: "Global Successo",
      PDF: "PDF",
      FIELDGROUPMAPPING: "Field Group Mapping",
      STATEMENTTYPENAME: "Statement Type",
      FIELDMAPPING: "Field Mapping",
      QUESTIONNAIRETOOL: "Questionnaire Tool",
      GLOBALSETTING: "Global Setting",
      SETTING: "Setting",
      SETTINGTYPE: "Setting Type",
      // kalu add
      QUESTIONS: "questions",
      MENU: "menu",
    },
    AUTH: {
      GENERAL: {
        OR: "Or",
        SUBMIT_BUTTON: "Submit",
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: "Sign Up",
        FORGOT_BUTTON: "Forgot Password?",
        BACK_BUTTON: "Back",
        PRIVACY: "Privacy",
        LEGAL: "Legal",
        CONTACT: "Contact",
      },
      LOGIN: {
        TITLE: "Login",
        BUTTON: "SIGN IN",
        SUCCESS: "Successfully login.",
      },
      FORGOT: {
        TITLE: "Forgot Password",
        DESC: "Enter your email to reset your password",
        SUCCESS:
          "Link has been sent to your registered email using which you can reset your password.",
      },
      SETPASSWORD: {
        TITLE: "Set Password",
        BUTTON: "Reset",
        SUCCESS: "La password è stata cambiata con successo.",
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Fullname",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password",
        USERNAME: "Username",
        TITLE: "Enter Title",
        REMEMBER_ME: "Remember Me",
      },
      VALIDATION: {
        INVALID: "{{name}} is not valid",
        REQUIRED: "{{name}} is required",
        MIN_LENGTH: "{{name}} minimum length is {{min}}",
        AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
        NOT_FOUND: "The requested {{name}} is not found",
        INVALID_LOGIN: "The login detail is incorrect",
        REQUIRED_FIELD: "Required field",
        MIN_LENGTH_FIELD: "Minimum field length:",
        MAX_LENGTH_FIELD: "Maximum field length:",
        INVALID_FIELD: "Field is not valid",
        MUST_MATCH: "Password and confirm password must match",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: "Map View",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " | Language List",
        ADDTITLE: projectname + " | Language Add",
        EDITTITLE: projectname + " | Language Update",
        LANGUAGELIST: "Languages List",
        LANGUAGENAMEPLURAL: "Languages",
        LANGUAGENAMESINGULAR: "language",
        NEWLANGUAGE: "New Language",
        DELETEDSELECTED: "Delete selected Languages",
        UPDATESELECTED: "Update status for selected Languages",
        ADDNEW: "Add New Language",
        UPDATE: "Update Language",
        CREATEMESSAGE: "Create language successfully",
        UPDATEMESSAGE: "Update language successfully",
        EDIT: "Edit Language",
        ADD: "Add Language",
        LOCALE: "Locale",
        LOCALE_REQUIRED: "Locale Required",
        LOCALE_INVALID: "Locale contains invalid characters",
      },
      USERS: {
        LISTINGTITLE: projectname + " | User List",
        USERSLIST: "Users List",
        NEWUSER: "New User",
        USERNAMEPLURAL: "users",
        USERNAMESINGULAR: "user",
        DELETEDSELECTED: "Delete selected users",
        UPDATESELECTED: "Update status for selected users",
        ADDNEW: "Add New User",
        UPDATE: "Update User",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        USERSUCCESS: "User Added Successfully",
        FILTERUSERGROUP: "Filter User Group",
        ADDTITLE: projectname + " | Users Add",
        EDITTITLE: projectname + " | Users Update",
        EDIT: "Edit User",
        ADD: "Add User",
        GOVT: "Government",
        OTHERGOVT: "Other than Government",
        GOVTEMP: "Government employee",
        NGO: "Non-governmental Organization (NGO)",
        PVT: "Private organization",
        CONSTENT: "Consultant",
        SHG: "Self Help Group (SHG)",
        ACADEMIC: "Academia",
        OCCUPATION: "Occupation",
        ORGANIZTION: "Organization",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " | User Group List",
        USERGROUPLIST: "User Group List",
        USERGROUPNAMEPLURAL: "usergroups",
        USERGROUPNAMESINGULAR: "usergroup",
        NEWUSERGROUP: "New User Group",
        DELETEDSELECTED: "Delete selected user groups",
        UPDATESELECTED: "Update status for selected user groups",
        ADDNEW: "Add New User Group",
        UPDATE: "Update User Group",
        ADDTITLE: projectname + " | Add User Group",
        EDITTITLE: projectname + " | Update User Group",
      },
      QUESTION: {
        LISTINGTITLE: projectname + " | Question List",
        ADDTITLE: projectname + " | Question Add",
        EDITTITLE: projectname + " | Question Update",
        QUESTIONLIST: "Questions List",
        QUESTIONNAMEPLURAL: "Questions",
        QUESTIONNAMESINGULAR: "Question",
        NEWQUESTION: "New Question",
        DELETEDSELECTED: "Delete selected questions",
        UPDATESELECTED: "Update status for selected questions",
        ADDNEW: "Add New Question",
        UPDATE: "Update Question",
        CREATEMESSAGE: "Create question successfully",
        UPDATEMESSAGE: "Update question successfully",
        EDIT: "Edit Question",
        ADD: "Add Question",
        SINGLE_CHOICE: "Single Choice",
        MULTIPLE_CHOICE: "Multiple Choice",
        QUESTION_IMAGES: "Question Images",
        ANSWER_LIMIT: "Answer Limit",
        CHOICE: "Choice",
        NUMERIC: "Numeric",
        DATE: "Date",
        UNIQUEID: "Unique ID",
        TEXT: "text",
        IMAGE: "Image",
        BOTH: "Both",
        MULTIPLE_ANSWER: "Multiple answer",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: "Multiple lable Multiple answer",
        ANSWER_INPUT: "Answer Input",
        ATTACH_FILES: "Attach files",
        QUESTION_TEXT: "question text",
        ANSWER_TYPE: "Answer Type",
        ANSWER_IMAGE: "Answer Image",
        MIN_RANGE: "Min Range",
        MAX_RANGE: "Max Range",
        ANSWER: "Answer",
        QUESTION: "Question",
        SEQUENCE: "Sequence",
        ENTER_SEQUENCE: "Please enter sequence",
        INVALID_SEQUENCE: "Invalid sequence",
        ADD_OPTION: "Add",
        ADD_MAXIMUM_OF: "You can add maximum of",
        ENTER_ANSWER: "Please enter answer",
        INVALID_ANSWER: "Invalid answer",
        EXCLUSIVE_RESPONSE: "Mutually Exclusive Response",
        ENTER_MAXIMUM_RANGE: "Please enter maximum range",
        Maximum_number_minimum_number:
          "Maximum number is not less than to minimum number",
        ENTER_MINIMUM_RANGE: "Please enter minimum range",
        INVALID_RANGE: "Please insert a valid 10 digit phone number",
        PLEASEENTERQUESTION: "Please enter question",
        INVALID_QUESTION: "Invalid question",
        QUESTIONMUSTBEATLEAST:
          "Question must be at least 3 characters long and space is not allowed",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. you can upload maximum  question image size must be 228px * 200px",
        MAX_UPLOAD_SIZE_ADD:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. Image size must be 228px * 200px",
        REVISION_FOR_THIS_QUESTION:
          "Do you want to create revision for this question?",
        CREATEMAPPINGMESSAGE: "Create question mapping successfully",
        UPDATEMAPPINGMESSAGE: "Update question mapping successfully",
        DELETEMAPPINGMESSAGE: "Delete question mapping successfully",
        UPLOAD_ONLY: "You can upload only",
        CONDITIONISMAPPED: "You can not change order as condition is mapped!!",
      },
      MENU: {
        LISTINGTITLE: projectname + " | Menu List",
        ADDTITLE: projectname + " | Menu Add",
        EDITTITLE: projectname + " | Menu Update",
        MENULIST: "Menus List",
        MENUNAMEPLURAL: "Menu",
        MENUNAMESINGULAR: "Menu",
        NEWMENU: "New Menu",
        DELETEDSELECTED: "Delete selected menus",
        UPDATESELECTED: "Update status for selected menus",
        ADDNEW: "Add New Menu",
        UPDATE: "Update Menu",
        CREATEMESSAGE: "Create menu successfully",
        UPDATEMESSAGE: "Update menu successfully",
        EDIT: "Edit Menu",
        ADD: "Add Menu",
        VALUES: "Values",
        PARENT: "Parent",
        MODULE: "Module",
        PAGE: "Page",
        ICON: "Icon",
        ORDERING: "Ordering",
        VALIDATION: {
          SELECTTYPE: "Please Select Menu Type",
        },
      },
      SURVEY: {
        LISTINGTITLE: projectname + " | Survey Listing",
        SURVEYLIST: "List of surveys conducted",
        NEWSURVEY: "New Survey",
        SURVEYNAMEPLURAL: "survey",
        SURVEYNAMESINGULAR: "survey",
        DELETEDSELECTED: "Delete selected surveyor",
        UPDATESELECTED: "Update status for selected surveyor",
        ADDNEW: "Add New Survey",
        UPDATE: "Update Survey",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        SURVEYSUCCESS: "Survey submitted successfully",
        SURVEYQUESTIONSUBMIT: "Submit successfully",
        SURVEYQUESTIONSAVE: "Save successfully",
        FILTERSURVEYGROUP: "Filter Survey Group",
        ADDTITLE: projectname + " | Surveyor Add",
        EDITTITLE: projectname + " | Surveyor Update",
        EDIT: "Edit Survey",
        ADD: "Start New Survey",
        SRNO: "Sr No",
        SEARCH: "Search",
        SURVEYORNAME: "Surveyor Name",
        SURVEYQUESTIONTITLE: "Survey of public conveniences",
        SURVEYBY: "Form to be surveyed by SAATH team ",
        VALIDATIONTEXT: "Marked questions are compulsory.",
        NOTE: "Note",
        SURVEYTEXT:
          "Zone and ward are not complusory to fill. In case you know these information, please select an appropriate option.",
        PUBLICCONVENIENCENOTE:
          "If toilet location is different, please select an appropriate location from the dropdown",
      },
      PROFILE: {
        NAME: "My Profile",
        LISTINGTITLE: projectname + " | My Profile",
        PROFILESUCCESS: "Profile updated successfully!!",
        INVALID_IMAGE:
          "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
        PROFILE_IMAGE: "Profile Image",
        BASIC_INFO: "Basic info",
        PHONE: "Contact No.",
        PHONEREQ: "Contact No. is Required",
        PHONEERROR: "Please enter valid phone number.",
        ADDRESS: "Enter Address",
        ADDRESSTAB: "Address",
        CHANGEPASSWORD: "Change Password",
        RESIDENTIALADDREDSS: "Residential Address",
        PASSWORDNOTMATCH: "Oh snap! password not match.",
        PASSWORDREQUIRED: "Password is required",
        OLDPASSWORDREQUIRED: "Old password is required",
        ENTEROLDPASSWORD: "Old Password",
        NEWPASSWORDREQUIRED: "New password is required",
        ENTERNEWPASSWORD: "New Password",
        INVALIDNEWPASSWORD: "New password is not valid",
        INVALIDPASSWORD: "Password is not valid",
        NEWPASSWORDSAME: "New password should not be same as old password",
        CONFIRMPASSWORD: "Confirm Password",
        CONFIRMPASSWORDREQUIRED: "Confirm password is required",
        CONFIRMPASSWORDSAME:
          "New password and confirm password values do not match.",
        INVALIDOLDPASSWORD: "Old password is not valid",
        CREATEUSERGROUP: "Create user group successfully",
        UPDATEUSERGROUP: "Update user group successfully",
      },
      LOGIN: {
        WELCOME: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        LONGTEXT:
          "This tool will help you to understand the existing condition of public and community toilets in terms of cleanliness and you can assess cubicle-wise infrastructure and cleanliness gaps in the toilet facilities.",
        EMAIL: "Email Address/Phone Number",
        EMAIL_VALIDATION: "Please enter email or mobile number.",
        EMAIL_PATTERN:
          "Wrong format, It should be a valid email or 10 digit mobile number.",
        ACCOUNT: "Need to create an account?",
        REGISTER: "Register",
        OTP: "Send OTP",
        ENTER_OTP: "Enter OTP",
        ONE_TIME_PASS: "One Time Password",
        OTP_VALIDATION: "Please enter OTP...!",
        RESEND_OTP: "Resend OTP in",
        OTP_RESEND: "Resend OTP",
        NOT_GET_OTP: "Don`t get OTP?",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        TERMSOFUSE: "Terms of Use",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The Terms of Service administer your use of this application and its services. Please read these Terms and Conditions carefully before using the application. By using the application or the services, you agree to be bound by our Terms and Conditions including the policies referenced in these terms. These Terms and Conditions may get modified from time to time.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "While Urban Management Centre (UMC) makes every effort to ensure that the content of the application is accurate and up-to-date, but does not warrant to be referred to at or in any forums for its reliability, accuracy or completeness of the information. UMC has authority and rights to delete or update complete or part of the information included in this application without prior intimation of any kinds. The content and the information being collected through this application are solely for",
        TEXT4: "information and education purpose only",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "All materials, information contained in this application, including photographs, reports and text are property of UMC group and thus are protected under Indian laws of copyright. Using our services does not give you ownership of any intellectual property rights in our services or the content you access.",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "Unless otherwise specified or notified that particular materials/information published are for its restricted use, downloading, copying, reproducing, modifying and republishing the content from this application for distributing publically or using commercially will be in violation of copyright and proprietary rights of the owner – UMC.",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        PRIVACYPOLICY: "Privacy Policy",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The purpose of this application is to capture survey data of Public and Community Toilets under SBM - MISAAL project and to process such data for projecting budget requirement to make such toilets operational. While doing so we may collect your personal information like name, profession, email ID, contact number, city to identify or contact the user of the application.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "In this process, we assure you that the protection of your privacy is crucial to us. Your personal information shall be collected by us through a form only with your consent. While some of the information will be collected from you, we may also collect other data like IP address, domain name, browser, operating system etc. from the system logs of our server for data and system security.",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "UMC assures you that the information collected through this application will be strictly confidential and will not be disclosed to a third party in any manner and shall only be used in the way you have consented.",
      },
      COMMON: {
        DATE: "Date",
        GOTO: "Go to",
        UNIQUEID: "Unique ID",
        COMPLETED: "Completed",
        INPROGRESS: "In-Progress",
        CITYLIST: "Cities List",
        COUNT: "Survey Count",
        LOGOUT: "Logout",
        NEXT: "Next",
        PREVIOUS: "Previous",
        HOME: "HOME",
        ALL: "All",
        ACTIVE: "Active",
        INACTIVE: "In Active",
        PLEASESELECT: "Please Select",
        BASICINFORMATION: "Basic Information",
        COMPANYMANAGEMENT: "Company Management",
        COMPANYREGISTEARS: "Company Registrars",
        CREATEDAT: "Created Date",
        STATUS: "Status",
        ACTION: "Action",
        DELETE: "Delete",
        TITLE: "Title",
        VIEW: "View",
        UPDATE: "Edit",
        PARENT: "Parent",
        PIC: "Pic",
        SEARCHBTN: "Search",
        FIRSTNAME: "First Name ",
        FIRSTNAMEREQ: "Please specify first name",
        FIRSTNAMEERROR: "First name is invalid",
        NAME: "Name",
        LASTNAME: "Last Name",
        LASTNAMEREQ: "Please specify last name",
        LASTNAMEERROR: "Last name is invalid",
        EMAIL: "Email",
        EMAILREQ: "Please specify email",
        EMAILERROR: "Email is invalid",
        WEBSITE: "Website",
        WEBSITEREQ: "Please specify website",
        WEBSITEERROR: "Website is invalid",
        FAX: "Fax",
        FAXERROR: "Fax is invalid",
        ADDRESS: "Address",
        MOBILENUMBER: "Mobile Number",
        DATEOFBIRTH: "Date of Birth",
        DATEOFBIRTHERROR: "Please specify date of birth",
        SAVEBTN: "Save",
        SUBMITBTN: "Submit",
        SEARCH: "Search",
        BACKBTN: "Back",
        UPDATEBTN: "Update",
        RESETBTN: "Reset",
        CANCELBTN: "Cancel",
        REPORT: "Report",
        CITY: "City",
        COUNTRY: "Country",
        CODE: " Code",
        ZONE: "Zone",
        WARD: "Ward",
        PUBLICCONVENIENCE: "Public convenience",
        SELECTCOUNTRY: "Select Country",
        SELECTREGION: "Select Region",
        SELECTSTATE: "Select State",
        SELECTCITY: "Select City",
        SELECTZONE: "Select Zone",
        SELECTWARD: "Select Ward",
        SELECTUSERGROUP: "Select UserGroup",
        STATE: "State",
        PIN: "Pin",
        ZIPCODE: "Zip code",
        NORECORDS: "No record(s) found",
        DELETEALL: "Delete All",
        UPDATESTATUS: "Update status",
        DESCRIPTION: "Description",
        DESIGNATION: "Designation",
        ENTERDESCRIPTION: "Please enter description",
        STATUSUPDATED: "Status has been updated successfully",
        SUCCESS: "Successfully",
        SELECTED: "Selected",
        STATUSSELECTEDUPDATE: "Status has been updated for selected",
        PERMANTLYDELETE: "Are you sure to permanently delete this",
        DELETETEXT: "Are you sure you want to delete this record permanently?",
        ISDELETING: "is deleting",
        DELETESELECTED: "Delete Selected",
        UPDATESELECTED: "Update Status for Selected",
        AMOUNT: "Amount",
        TIME: "Time",
        VALUE: "Value",
        USERGROUP: "User Group",
        SELECT: "Select",
        VERSION: "Version",
        TYPE_THREE_LETTERS: "Type three letters and wait some time",
        PERMISSION: "Permissions",
        LABELSEARCH: "Type Label Here",
        SELECTANYONE: "Select any one",
        IMAGEERROR: "Please upload image ",
        TEXTERROR: "Please specify text",
        NUMBERERROR: "Please specify number",
        INVALIDFILE: "You have uploaded an invalid file type",
        // tslint:disable-next-line: max-line-length
        PASSWORDHINT:
          "contains 8-20 characters, at least one number, at least  one upper character, at least one lower character, at least one special character.",
        OOPS: "Oops!",
        ERROR: "Error!",
        HELLO: "Hello!",
        WELCOMEBACK: "Welcome Back",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          "It looks like you couldn`t complete the previous participation. Would you like to continue from where you left or would you like to start a new survey question?",
        CONTINUEWITH: "Continue With",
        OR: "or",
        PREVIOUSQUESTION: "Previous Question",
        FRESHQUESTION: "Fresh Question",
        CONFIRM: "Confirm",
        AREYOUSURE: "Are you sure will start first?",
        CONFIRMIMAGE: "Are you sure delete this image?",
        YES: "Yes",
        NO: "No",
        TEXTINVALIDE: "Text contains invalid characters",
        CONFIRMCONVENIENCE:
          "Are you sure will change public convenience name ?",
        PRIVACYPOLICY: "Privacy Policy",
        TERMSOFUSE: "Terms of Use",
        FORHELPCONTACT: "For help Contact",
        CLICKFORANEWSURVEY: "Click for a new survey",
        VALIDATION: {
          INVALID_IMAGE:
            "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
          NAME_INVALID: "Name contains invalid characters",
          NAME_REQUIRED: "Please specify name",
          USERGROUPREQUIRED: "Please specify user group",
          NAME_MIN_LENGTH: "Name must be at least 2 characters long.",
          NAME_MAX_LENGTH: "cannot exceed more than 50 characters.",
          ZIP_REQUIRED: "Please specify zipcode",
          ZIP_INVALID: "Zipcode is invalid",
          CITY_REQUIRED: "Please specify city",
          STATE_REQUIRED: "Please specify state",
          REGION_REQUIRED: "Please specify region",
          COUNTRY_REQUIRED: "Please specify country",
          VALUE_REQUIRED: "Please specify value",
          TITLE_INVALID: "Title contains invalid characters",
          TITLE_REQUIRED: "Please specify title",
          TITLE_MIN_LENGTH: "Title must be at least 2 characters long.",
          TITLE_MAX_LENGTH: "cannot exceed more than 50 characters.",
          PIN_REQUIRED: "Please specify Pin",
          PIN_REQUIRED_MIN_LENGTH: "Pin must be at least 5 characters long.",
          PIN_REQUIRED_MAX_LENGTH: "cannot exceed more than 6 characters.",
        },
        PATTERN: {
          // tslint:disable-next-line: quotemark
          // NAME : "[a-zA-Z '.&:_()-]*'"
          // tslint:disable-next-line: quotemark
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
          // tslint:disable-next-line: quotemark
          EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
          // tslint:disable-next-line: quotemark
          ADSH: "[0-9-]{20}",
          // tslint:disable-next-line: quotemark
          SIC: "[0-9]{2,50}",
          // tslint:disable-next-line: quotemark
          NUMBER_ONLY: "[0-9]+",
          // tslint:disable-next-line: quotemark
          FNAME_LNAME: "^[a-zA-Z]+\\s?[a-zA-Z]+$",
          // tslint:disable-next-line: quotemark
          CONTACT_NO: "[0-9]{10}",
          // tslint:disable-next-line: quotemark
          TAG: "[a-zA-Z']*",
          // tslint:disable-next-line: quotemark
          AMOUNT: "[0-9.]+",
          // tslint:disable-next-line: quotemark
          TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
        },
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Selected records count: ",
        ALL: "All",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        FILTER: "Filter",
        BY_STATUS: "by Status",
        BY_TYPE: "by Type",
        BUSINESS: "Business",
        INDIVIDUAL: "Individual",
        SEARCH: "Search",
        IN_ALL_FIELDS: "in all fields",
      },
      ECOMMERCE: "eCommerce",
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        CUSTOMERS_LIST: "Customers list",
        NEW_CUSTOMER: "New Customer",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Customer Delete",
          DESCRIPTION: "Are you sure to permanently delete this customer?",
          WAIT_DESCRIPTION: "Customer is deleting...",
          MESSAGE: "Customer has been deleted",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Customers Delete",
          DESCRIPTION: "Are you sure to permanently delete selected customers?",
          WAIT_DESCRIPTION: "Customers are deleting...",
          MESSAGE: "Selected customers have been deleted",
        },
        UPDATE_STATUS: {
          TITLE: "Status has been updated for selected customers",
          MESSAGE: "Selected customers status have successfully been updated",
        },
        EDIT: {
          UPDATE_MESSAGE: "Customer has been updated",
          ADD_MESSAGE: "Customer has been created",
        },
      },
    },
  },
};
