import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { User } from "../_models";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Changepass } from "src/app/_models/changepassword.model";
import { LoaderService } from "../_services/loader.service";
import { CountryService } from "../_services/country.service";
import { JobdesignationService } from "../_services/jobdesignation.service";
import { IamsService } from "../_services/iams.service";
import { GridOption } from "../_models/gridoptions.model";
import { TranslationService } from "../_services/translation.service";
import { LunguageselectionService } from "../_layout/language-selector/language-selector.service";
import { LanguageService } from "../_services/language.service";
import { RegionService } from "../_services/region.service";
import { Title } from "@angular/platform-browser";
import * as _ from "lodash";
import { AvatarService } from "../_services/avatar.service";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../_services";
import { Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsComponent implements OnInit {
  documentBucket = environment.imgBucketUrl + '/id_cards/';
  // documentBucket = "";
  @ViewChild("deleteUserModel", { static: false }) deleteUserModel: TemplateRef<any>; // Note: TemplateRef
  @ViewChild("confirmModel", { static: false }) confirmModel: TemplateRef<any>; // Note: TemplateRef

  userData = new User();
  changePass = new Changepass();
  myId: any = null;
  Math: any;
  deleteThisDoc: any = "";
  regType: any = "0";
  CityArr: any = [];
  RegionArr: any = [];
  CountryArr: any = [];
  CategoryArr: any = [];
  DesignationArr: any = [];
  IamsArr: any = [];
  AvatarArr: any = [];
  files: any = [];
  filesName: any = [];
  imgURL: any = [];
  deletedFiles: any = [];

  // selectedCategory: any = [];
  selectedDesignation: any = [];
  selectedIams: any = "";

  mailnotification: any = 1;
  countOfColo: any = "0";
  invalid_image: any =
    "Attenzione: tipo di file invalido o troppo grande (dimensione massima: 5 MB)";
  phoneduplicate = false;
  emailduplicate = false;

  oldpassword: any;
  password: any;
  confirmpassword: any;

  deletePassword: any = "";
  //language
  languageList: any;
  currentLang: any;
  subscription: any;
  langobj: any;
  languages: any = [];

  displayedAvatar: any = [];
  showMoreBtn: boolean = true;
  showLessBtn: boolean = false;

  avatarBucket = environment.imgBucketUrl + "/avatar/";
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private avatarService: AvatarService,
    private iamsService: IamsService,
    private countryService: CountryService,
    private jobDesignationService: JobdesignationService,
    private modalService: NgbModal,
    private userService: UserService,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    public loaderService: LoaderService,
    private translationService: TranslationService,
    private lunguageselectionService: LunguageselectionService,
    private regionService: RegionService,
    private languageService: LanguageService,
    private meta: Meta,
    private translateService: TranslateService,
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.Math = Math;
    this.titleService.setTitle("Ingeding | User Settings");
    this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
      this.ngOnInit();
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getLoginUserData();
    this.getIams();
    // this.getCategory();
    this.getRegion();
    this.getCountry();
    this.getAvatar();
    this.getLanguage();
  }

  getLoginUserData() {
    this.authenticationService.currentUser$.subscribe(async (data) => {
      this.myId = data ? data._id : null;
      this.getuserData(this.myId);
    });
  }

  getuserData(id: any) {
    if (id != null) {
      this.deletedFiles = [];
      this.files = [];
      this.languages = [];
      // this.selectedCategory = [];
      this.selectedIams = "";
      this.filesName = [];
      this.countOfColo = null;
      this.regType = null;
      this.authenticationService.getfullUserDetails(id).subscribe((data) => {
        this.mailnotification = data.mailnotification == 1 ? 1 : 0;
        this.userData = data;
        this.languages = data.language;
        // this.selectedCategory = data.category;
        this.filesName = data.images;
        this.countOfColo = data.noOfCollaborator;
        this.regType = data.usergroup == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
        if (this.regType == 0) {
          this.iamsService.getOne(data.iams).subscribe(async (res) => {
            if (res.type == 1 && res.status == 1) {
              this.selectedIams = "otherIams";
              this.userData.newIams = res.title;
              if (!(this.cdr as any).destroyed) {
                this.cdr.detectChanges();
              }
            } else {
              this.selectedIams = data.iams;
              if (!(this.cdr as any).destroyed) {
                this.cdr.detectChanges();
              }
            }
          });
        }
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  checkValues() {
    var result = true;
    if (this.regType == 0) {
      // if (this.userData.city_id.trim().length === 0) {
      //   result = false;
      // }
      // if (this.selectedCategory.length <= 0 || this.filesName.length <= 0) {
      //   result = false;
      // }
      if (this.selectedIams == "") {
        result = false;
      }
      if (
        this.selectedIams == "otherIams" &&
        (this.userData.newIams == "" ||
          this.userData.newIams == undefined ||
          this.userData.newIams == null)
      ) {
        result = false;
      }
    } else {
      if (this.userData.companyname.trim().length === 0) {
        result = false;
      }
      // if (this.selectedCategory.length <= 0) {
      //   result = false;
      // }
      if (this.userData.registeredofficecity.trim().length === 0) {
        result = false;
      }
      if (this.userData.operationalheadquarterscity.trim().length === 0) {
        result = false;
      }
    }
    return result;
  }

  onSubmit(updateForm: { form: { valid: any; }; submitted: boolean; }) {
    var rules = this.checkValues();
    if (updateForm.form.valid && rules != false) {
      updateForm.submitted = false;
      const myFormData = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        myFormData.append("files", this.files[i]);
      }

      // this.userData.category = this.selectedCategory;
      if (this.userData.usergroup != "60f679adc3afbf2ab4974e4e") {
        if (this.selectedIams == "otherIams") {
          this.userData.iams = "";
          this.userData.isNewIams = true;
        } else {
          this.userData.iams = this.selectedIams;
          this.userData.isNewIams = false;
        }
        this.userData.deletedFiles = this.deletedFiles;
      } else {
        this.userData.noOfCollaborator = this.countOfColo;
      }

      myFormData.append("data", JSON.stringify(this.userData));
      this.authenticationService
        .updateUserDetails(myFormData)
        .subscribe((data) => {
          if (data.status === 1) {
            if (!(this.cdr as any).destroyed) {
              this.cdr.detectChanges();
            }
            this.toastr.success(
              this.translateService.instant('PAGES.USER_SETTING.YOUR_CHANGES_SAVED'),
              this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
            );
            this.getLoginUserData();
          } else {
            this.toastr.error(data.error, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
          }
        });
    } else {
      this.toastr.error(this.translateService.instant('PAGES.REGISTRATION_PAGE.CORRECT_DATA_TRY_AGAIN'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }
  }

  onChangePassword(changePasswordForm: { form: { valid: any; }; submitted: boolean; }) {
    if (
      changePasswordForm.form.valid &&
      this.changePass.confirmpassword == this.changePass.password &&
      this.changePass.oldpassword != ""
    ) {
      this.authenticationService
        .changePassword(this.changePass)
        .subscribe((res) => {
          if (res.status === 1) {
            this.changePass.clear();
            if (!(this.cdr as any).destroyed) {
              this.cdr.detectChanges();
            }
            changePasswordForm.submitted = false;
            this.toastr.success(this.translateService.instant('PAGES.EDIT_PROFILE.SUCCESSFULLY_SAVED'), this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS'));
          } else {
            this.toastr.error(res.message.password);
          }
        });
    } else {
      this.toastr.error(this.translateService.instant('PAGES.REGISTRATION_PAGE.CORRECT_DATA_TRY_AGAIN'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
    }
  }

  onClickCheckbox(event: any) {
    if (event.target.checked) {
      if (event.target.name == "isCollaborator") {
        this.userData.isCollaborator = true;
      }
    } else {
      if (event.target.name == "isCollaborator") {
        this.userData.isCollaborator = false;
      }
    }
  }

  getAvatar() {
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.avatarService.searchAll(this.gridOption).subscribe((res) => {
      this.AvatarArr = res.data;
      this.showLess();
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  chooseThisAvatar(avatarId: any) {
    this.userData.avatar = avatarId;
  }

  getIams() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.gridOption.filter.type = 0;
    this.iamsService.searchAll(this.gridOption).subscribe((res) => {
      this.IamsArr = _.orderBy(res.data, ["title"], ["asc"]);
      // Translate titles
      this.translateTitles().then(() => {
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    });
  }

  getCountry() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.countryService.searchAll(this.gridOption).subscribe((res) => {
      this.CountryArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getDesignation() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.jobDesignationService.searchAll(this.gridOption).subscribe((res) => {
      this.DesignationArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getRegion() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.regionService.searchAll(this.gridOption).subscribe((res) => {
      this.RegionArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getLanguage() {
    this.languageList = this.languageService.getLanguage();
    this.subscription =
      this.lunguageselectionService.languageVisibilityChange.subscribe(
        (value) => {
          this.langobj = value;
          this.currentLang = JSON.parse(localStorage.getItem("language"));
          this.langobj = value;
          if (this.currentLang.locale !== this.langobj.locale) {
            this.translationService.setLanguage(this.langobj);
            this.getLoginUserData();
          } else {
            this.translationService.setLanguage(this.currentLang);
          }
          this.titleService.setTitle("My Profile");
        }
      );
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  iamsChange(event: any) {
    if (event.target.checked) {
      this.selectedIams = event.target.value;
    }
  }


  sameAddress() {
    this.userData.registeredofficeaddress =
      this.userData.operationalheadquartersaddress;
    this.userData.registeredofficestreetno =
      this.userData.operationalheadquartersstreetno;
    this.userData.registeredofficeregion =
      this.userData.operationalheadquartersregion;
    this.userData.registeredofficecity =
      this.userData.operationalheadquarterscity;
    this.userData.registeredofficepostcode =
      this.userData.operationalheadquarterspostcode;
    this.cdr.detectChanges();
  }

  checkAndAddFiles(myfile) {
    var result: Boolean = true;
    for (let i = 0; i < this.filesName.length; i++) {
      const element = this.filesName[i];
      if (element.name == myfile.name) {
        result = false;
        break;
      }
    }
    return result;
  }

  onFilesAdded(event: any) {
    let myFileArray = event.target.files;
    for (let i = 0; i < myFileArray.length; i++) {
      if (
        myFileArray[i].size < 5242880 &&
        (myFileArray[i].type === "application/pdf" ||
          myFileArray[i].type === "image/png" ||
          myFileArray[i].type === "image/jpg" ||
          myFileArray[i].type === "image/jpeg")
      ) {
        var result = this.checkAndAddFiles(myFileArray[i]);
        if (result) {
          this.files.unshift(myFileArray[i]);
          let reader = new FileReader();
          reader.readAsDataURL(myFileArray[i]);
          reader.onload = (e: any) => {
            this.filesName.unshift({
              name: myFileArray[i].name,
              size: myFileArray[i].size,
            });
            if (!(this.cdr as any).destroyed) {
              this.cdr.detectChanges();
            }
          };
        }
      } else {
        this.toastr.error(this.translateService.instant('PAGES.EDIT_PROFILE.INVALID_IMG'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
      }
    }
  }

  removeFile() {
    var index = this.deleteThisDoc;
    if (this.filesName[index]._id) {
      this.deletedFiles.push(this.filesName[index]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
    this.files.splice(index, 1);
    // this.imgURL.splice(index, 1);
    this.filesName.splice(index, 1);
    if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
    }
  }

  showMore() {
    this.showMoreBtn = false;
    this.showLessBtn = true;
    this.displayedAvatar = [];
    for (var i = 0; i < this.AvatarArr.length; i++) {
      this.displayedAvatar.push(this.AvatarArr[i]);
    }
  }

  showLess() {
    this.showMoreBtn = true;
    this.showLessBtn = false;
    this.displayedAvatar = [];
    if (this.AvatarArr.length >= 6) {
      for (var i = 0; i < 6; i++) {
        this.displayedAvatar.push(this.AvatarArr[i]);
      }
    } else {
      for (var i = 0; i < this.AvatarArr.length; i++) {
        this.displayedAvatar.push(this.AvatarArr[i]);
      }
    }
  }

  openPopup(type: any, index: any = "") {
    if (type == "userdelete") {
      this.modalService.open(this.deleteUserModel);
    } else {
      this.deleteThisDoc = index;
      this.modalService.open(this.confirmModel);
    }
  }

  cancel() {
    this.deleteThisDoc = "";
    this.modalService.dismissAll();
  }

  delete(type: any = "") {
    if (type == "docs") {
      this.removeFile();
      this.deleteThisDoc = "";
      this.modalService.dismissAll(this.confirmModel);
    }
  }

  deleteUser(deleteForm: any) {
    if (deleteForm.form.valid) {
      deleteForm.submitted = false;
      var data = {
        userid: this.userData._id,
        password: this.deletePassword,
      };
      this.userService.deleteCurrentUser(data).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.modalService.dismissAll(this.deleteUserModel);
          this.authenticationService.logout();
          this.router.navigateByUrl("/auth/login").then(() => {
            this.toastr.success(
              this.translateService.instant('PAGES.USER_SETTING.ACCOUNT_REMOVE_SUCCESSFULLY'),
              this.translateService.instant('PAGES.USER_SETTING.CONFIRMED')
            );
          });
        } else {
          this.deletePassword = "";
          this.toastr.error(res.message.password, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
        }
      });
    }
  }

  onMailNotificationChange(event: any) {
    const updateMailNotification = {
      user_id: this.myId,
      mailnotification: event.target.checked,
    };
    this.userService
      .setMailNotification(updateMailNotification)
      .subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.toastr.success(this.translateService.instant('PAGES.EDIT_PROFILE.SUCCESSFULLY_SAVED'), this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS'));
        } else {
          this.toastr.error(res.message, this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
        }
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
  }

  private async translateTitles(): Promise<void> {
    // Iterate over each item and translate the title
    await Promise.all(this.IamsArr.map(async (item) => {
      item.title = await this.translateService.instant('PAGES.USER_SETTING.' + item.title);
    }));
  }
}
