import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../_services";
import { User } from "src/app/_models";
import { CategoryService } from "src/app/_services/category.service";
import { GridOption } from "../../_models/gridoptions.model";
import * as _ from "lodash";
import { UserProfileService } from "src/app/_services/userprofile.service";
import { ToastrService } from "ngx-toastr";
import { SubDetailsService } from "src/app/_services/subdetails.service";
import { TranslationService } from "src/app/_services/translation.service";
import { LunguageselectionService } from "src/app/_layout/language-selector/language-selector.service";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userData = new User();
  searchBar: any = false;
  searchtext: any = "";
  authButton = true;
  CategoryArr: any = [];
  getScreenWidth: any;
  selectedCategory: any = "";

  subscription: Subscription;
  searchRadio: any = "1";
  checkProfileStatus: boolean;
  hideshowclass: boolean = false;
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  currentLang: any;
  langobj: any;
  name;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private profileService: UserProfileService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private categoryService: CategoryService,
    private cdr: ChangeDetectorRef,
    private subdetailService: SubDetailsService,
    private translationService: TranslationService,
    private lunguageselectionService: LunguageselectionService,
  ) {
    this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
      // this.setLanguage()
      // this.currentLang = JSON.parse(localStorage.getItem('language'));
      this.langobj = value;
      // if (this.currentLang.locale !== this.langobj.locale) {
      this.translationService.setLanguage(this.langobj);
      //this.translateService.use(this.langobj.locale);
      // }
      // this.translateService.use(this.langobj.locale).subscribe((data) => {
      //   this.cdr.detectChanges(); // Trigger change detection
      // });
      this.getCategory()

    });
    this.router.events.subscribe((event) => {
      this.authenticationService.currentUser$.subscribe((res) => {
        this.userData = res;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
      this.getScreenWidth = window.innerWidth;
      if (event instanceof NavigationStart) {
        var myArray = event.url.split("/");
        if (this.userData && this.userData._id) {
          this.subdetailService
            .checkSubStatus(this.userData._id)
            .subscribe((res) => {
              if (res == true && myArray.length > 0) {
                if (
                  myArray[1] == "search-job-posts" ||
                  myArray[1] == "search-profiles"
                ) {
                  this.authButton = true;
                  if (this.selectedCategory.length == 0) {
                    this.hideshowclass = true;
                  }
                  else {
                    this.hideshowclass = false;
                  }
                  this.searchtext = decodeURI(myArray[3]) == "undefined" ? "" : decodeURI(myArray[3]);
                  if (myArray[1] == "search-job-posts") {
                    this.searchRadio = "1";

                  } else if (myArray[1] == "search-profiles") {
                    this.searchRadio = "2";
                  }
                } else if (myArray[1] == "add-job-post") {
                  this.authButton = true;
                  this.clickedOnCreateJob();
                } else {
                  this.authButton = true;
                  this.searchtext = "";
                }
              } else if (
                res == false &&
                myArray.length > 0 &&
                myArray[1] == "user-settings"
              ) {
                this.authButton = true;
                this.searchtext = "";
              } else {
                this.searchtext = "";
                this.router.navigate(["/upgrade-account"]);
              }
            });
        } else {
          if (
            myArray[1] == "search-job-posts" ||
            myArray[1] == "search-profiles"
          ) {
            this.authButton = true;
            if (this.selectedCategory.length == 0) {
              this.hideshowclass = true;
            }
            else {
              this.hideshowclass = false;
            }
            this.searchtext = decodeURI(myArray[3]) == "undefined" ? "" : decodeURI(myArray[3]);
            if (myArray[1] == "search-job-posts") {
              this.searchRadio = "1";
            } else if (myArray[1] == "search-profiles") {
              this.searchRadio = "2";
            }
          } else if (myArray[1] == "auth" && myArray[2] == "login") {
            if (this.getScreenWidth < 1024) {
              this.authButton = false;
            } else {
              this.authButton = true;
            }
            this.searchtext = "";
          } else if (myArray[1] == "auth" && myArray[2] == "register") {
            if (this.getScreenWidth < 1024) {
              this.authButton = false;
            } else {
              this.authButton = true;
            }
            this.searchtext = "";
          } else {
            this.authButton = true;
            this.searchtext = "";
          }
        }
      }
    });
  }

  ngOnInit() {
    if (this.selectedCategory.length == 0) {
      this.hideshowclass = true;
    }
    else {
      this.hideshowclass = false;
    }
    this.getCategory();
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    var myArray = this.router.url.split("/");
    if (myArray[1] == "auth" && myArray[2] == "register") {
      if (this.getScreenWidth < 1024) {
        this.authButton = false;
      } else {
        this.authButton = true;
      }
    } else if (myArray[1] == "auth" && myArray[2] == "login") {
      if (this.getScreenWidth < 1024) {
        this.authButton = false;
      } else {
        this.authButton = true;
      }
    } else {
      this.authButton = true;
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/auth/login"]);
  }

  showSearchBar() {
    this.searchBar = true;
  }

  hideSearchBar() {
    var searchText = this.searchtext != "" ? this.searchtext.trim() : "";
    if (this.searchRadio == 1) {
      this.changeSearchbarState();
      if (this.selectedCategory.length != 0) {
        this.selectedCategory = this.selectedCategory;
        this.router.navigate([
          "/search-job-posts",
          this.selectedCategory,
          searchText,
        ]);
      } else {
        this.router.navigate([
          "/search-job-posts",
          this.selectedCategory,
          searchText,
        ]);
      }

    } else if (this.searchRadio == 2) {
      this.changeSearchbarState();
      if (this.selectedCategory.length != 0) {
        this.selectedCategory = this.selectedCategory;
        this.router.navigate([
          "/search-profiles",
          this.selectedCategory,
          searchText,
        ]);
      } else {
        this.router.navigate([
          "/search-profiles",
          this.selectedCategory,
          searchText,
        ]);
      }

    }
  }

  clickedOnCreateJob() {
    if (this.userData && this.userData._id) {
      this.subdetailService
        .checkSubStatus(this.userData._id)
        .subscribe((res) => {
          if (res == false) {
            this.router.navigate(["/upgrade-account"]);
          } else {
            this.profileService
              .checkProfile(this.userData._id)
              .subscribe((res) => {
                this.checkProfileStatus = res;
                if (res) {
                  this.router.navigate(["/add-job-post"]);
                } else {
                  this.router.navigate(["/edit-profile"]);
                  this.toastr.error(
                    this.translateService.instant('HEADER.CREATD_AD_COMPELETE_PROFILE'),
                    this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'),
                  );
                }
              });
          }
        });
    }
  }

  changeSearchbarState() {
    this.searchBar = false;
  }

  resetFilter() {
    this.searchRadio = "1";
    this.selectedCategory = "";
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  clearSearchbar() {
    this.searchtext = "";
  }

  /** Get all category */
  getCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.categoryService.searchAll(this.gridOption).subscribe((res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      this.CategoryArr.filter((item: any) => { Array.isArray(item.translations) ? item.translations = item.translations[0] : item.translations = item.translations; })
      // this.selectedCategory = this.CategoryArr[0]._id;
      // if (!(this.cdr as any).destroyed) {
      this.cdr.detectChanges();
      // }
    });
  }

  selectThisCate(id) {
    this.hideshowclass = false;
    this.selectedCategory = id;
  }
  // selectThisCate1() {

  selectThisCate1() {
    this.hideshowclass = true;
    this.selectedCategory = '';
  }
  // }
}
