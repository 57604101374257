import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";

export class CommonService {
  constructor(private urlModule: string, public http: HttpClient) { }

  baseUrl = environment.baseURL + this.urlModule;

  getAll() {
    return this.http.post(this.baseUrl, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  get(data) {
    return this.http.get(this.baseUrl + data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  getOne(id) {
    return this.http.get(this.baseUrl + "/" + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getAllAreaInfo(latlong) {
    return this.http.post(this.baseUrl + "/getAreaInfo", latlong).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  searchAll(searchObj) {
    return this.http.post(this.baseUrl + "/list", searchObj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  cityUpdate(resource) {
    return this.http.put(this.baseUrl + "/cityUpdate", resource).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  create(resource): Observable<any> {
    return this.http.post(this.baseUrl, resource).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  update(resource) {
    return this.http.put(this.baseUrl, resource).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  delete(id) {
    return this.http.delete(this.baseUrl + "/" + id).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  getCount() {
    return this.http.get(this.baseUrl + "/count").pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  deleteSelected(idsForDeletion) {
    return this.http.post(this.baseUrl + "/updateMany", idsForDeletion).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  updateStatusMany(obj) {
    return this.http.post(this.baseUrl + "/updateMany", obj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  deleteImage(obj) {
    return this.http.post(this.baseUrl + "/removeImage", obj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }

  uploadImage(obj) {
    return this.http.post(this.baseUrl + "/upload", obj).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getFormEditLimits(data) {
    return this.http.post(this.baseUrl + "/getFormEditLimits", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
