<!--BODY START-->
<section class="main-content-area sticky-space">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">home</a></li>
                <li class="breadcrumb-item active" aria-current="page">ricerca professionisti</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-sm-12">
                <div class="job-section-title">
                    <!-- <h1>Scopri il meglio dei nostri utenti</h1> -->
                    <h1>{{'PAGES.SEARCH_PROFILE.JOB_SECTION_TITLE' | translate}}</h1>
                </div>
            </div>
        </div>
        <div class="row about-desc">
            <div class="col-sm-12 col-md-6">
                <p>{{'PAGES.SEARCH_PROFILE.JOB_SECTION_DESC' | translate}}</p>
            </div>
        </div>
    </div>
</section>
<section class="card-main mt-64">
    <div class="container pro-research">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-5 main-registration-area">
                <div class="topradiobuttons">
                    <div class="toprdio toprdio-primary radio-inline">
                        <input type="radio" name="usertype" value="" (change)="selectThisUsertype($event)" id="radio3"
                            [(ngModel)]="userType">
                        <label for="radio3">{{'PAGES.SEARCH_PROFILE.SHOW_ALL' | translate}}</label>
                    </div>
                    <div class="toprdio toprdio-primary radio-inline">
                        <input type="radio" name="usertype" value="60f6843ac3afbf2ab4974e4f"
                            (change)="selectThisUsertype($event)" id="radio4" [(ngModel)]="userType">
                        <label for="radio4">{{'PAGES.SEARCH_PROFILE.PROFESSIONALS' | translate}}</label>
                    </div>
                    <div class="toprdio toprdio-primary radio-inline">
                        <input type="radio" name="usertype" value="60f679adc3afbf2ab4974e4e"
                            (change)="selectThisUsertype($event)" id="radio5" [(ngModel)]="userType">
                        <label for="radio5">{{'PAGES.SEARCH_PROFILE.COMPANIES' | translate}}</label>
                    </div>
                </div>
                <hr class="mt-5 mb-2">
                <div>
                    <div [ngClass]="{'blue-text':hideshowclass == true,'inactive':hideshowclass != true}">
                        <a id="all" href="javascript:void(0)" (click)="selectThisCategory1()">Tutte le aree</a>
                        <span class="float-right" *ngIf="hideshowclass == true && isCollapse1 == 1"><a
                                href="javascript:void(0);" (click)="changeCollaps1()"><i class="fa fa-angle-up"
                                    aria-hidden="true"></i></a></span>
                        <span class="float-right" *ngIf="hideshowclass == true && isCollapse1 == 0"><a
                                href="javascript:void(0);" (click)="changeCollaps1()"><i class="fa fa-angle-down"
                                    aria-hidden="true"></i></a></span>
                    </div>
                    <div *ngIf="hideshowclass == true && isCollapse1 == 1">
                        <div class="c-box" *ngFor="let tags1 of TagsArrs;let i1 = index">
                            <label class="checkbox-button">
                                <input type="checkbox" class="checkbox-button__input" value="{{tags1._id}}"
                                    name="tags1[]" (change)="selectThisTags1($event)"
                                    [checked]="SelectedTags!=undefined && SelectedTags.includes(tags1._id)">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">{{ tags1.translations.title }}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <hr class="mt-2 mb-2">
                <div *ngFor="let cat of CategoryArr;let i = index">
                    <div [ngClass]="{'blue-text':selectedCategory == cat._id ,'inactive':selectedCategory != cat._id}">
                        <a href="javascript:void(0)"
                            (click)="selectThisCategory(cat._id)">{{cat.translations.title}}</a>
                        <span class="float-right" *ngIf="selectedCategory == cat._id && isCollapse == 1"><a
                                href="javascript:void(0);" (click)="changeCollaps()"><i class="fa fa-angle-up"
                                    aria-hidden="true"></i></a></span>
                        <span class="float-right" *ngIf="selectedCategory == cat._id && isCollapse == 0"><a
                                href="javascript:void(0);" (click)="changeCollaps()"><i class="fa fa-angle-down"
                                    aria-hidden="true"></i></a></span>
                    </div>
                    <div *ngIf="selectedCategory == cat._id && isCollapse == 1">
                        <div class="c-box" *ngFor="let tags of TagsArr;let i = index">
                            <label class="checkbox-button">
                                <input type="checkbox" class="checkbox-button__input" value="{{tags._id}}"
                                    (change)="selectThisTags($event)" name="tags[]"
                                    [checked]="SelectedTags!=undefined && SelectedTags.includes(tags._id)">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">{{ tags.translations.title }}</span>
                            </label>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2">
                </div>
                <select class="minimal form-control mt-4 mb-4" name="placeofwork" (change)="selectedRegion($event)"
                    required>
                    <option value="">{{'PAGES.SEARCH_JOB_POST.ALL_REGION' | translate}}</option>
                    <!-- <option value="000000a0aa00a000000aa000">Da remoto</option> -->
                    <option *ngFor="let place of RegionArr" value="{{place._id}}">
                        {{ place.translations.name }}
                    </option>
                </select>
                <span class="pt-2">{{'PAGES.SEARCH_PROFILE.AVAILABLE_FROM' | translate}}
                    <a href="javascript:void(0)" id="dateCalendar" (click)="hide('dateCalendar')">
                        <img src="./assets/images/ingeding/blue-calendar.svg" alt="calendar" title="calendar">
                        {{ dateFilter(AvailableFrom) }}
                    </a>
                    <input type="date" name="availablefrom" id="availablefrom" (blur)="hide('availablefrom')"
                        style="display: none;" [(ngModel)]="AvailableFrom" [min]="today">
                </span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-9">
                <div class="row" *ngIf="MyProfileArr.length > 0">
                    <div class="col-sm-12 right-align filter mb-5">
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('availableFrom')">
                            {{'PAGES.SEARCH_PROFILE.AVAILABILITY' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOptionProfile.sortOrder == 'asc' && gridOptionProfile.sortField == 'availableFrom'"
                                alt="Arrow" title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOptionProfile.sortOrder == 'desc' && gridOptionProfile.sortField == 'availableFrom'"
                                alt="Arrow" title="Arrow">
                        </a>
                    </div>
                    <div class="col-sm-12 col-md-6" *ngFor="let profile of MyProfileArr">
                        <div class="card h-100">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-left media-middle" *ngIf="!currentUser">
                                        <img [src]="avatarBucket + profile.avatars[0].avatar" class="media-object"
                                            alt="Avatar" title="Avatar">
                                    </div>
                                    <div class="media-left media-middle"
                                        *ngIf="profile.user[0] && profile.user[0].pic!=null && profile.user[0].pic!='' && currentUser">
                                        <img [src]="profilePicBucket + profile.user[0].pic" class="media-object"
                                            alt="Profile picture" title="Profile picture">
                                    </div>
                                    <div class="media-left media-middle"
                                        *ngIf="profile.user[0] && (profile.user[0].pic==null || profile.user[0].pic=='') && currentUser">
                                        <img [src]="avatarBucket + profile.avatars[0].avatar" class="media-object"
                                            alt="Avatar" title="Avatar">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="name-tag"
                                            *ngIf="profile.user && profile.user[0].usergroup == '60f679adc3afbf2ab4974e4e' && currentUser">
                                            {{profile.user[0].companyname}}</h5>
                                        <h5 class="name-tag"
                                            *ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f' && currentUser">
                                            {{profile.user[0].firstname + ' ' + profile.user[0].lastname }}
                                        </h5>
                                        <span class="label label-primary">{{profile.tag[0] ?
                                            profile.tag[0].translations[0].title : ""}}</span>
                                        <span class="label label-primary"
                                            *ngIf="profile.tag.length > 1">+{{profile.tag.length
                                            - 1}}</span>
                                    </div>
                                </div>
                                <div class="card-height-fix">
                                    <h4 class="card-title"
                                        *ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f'">
                                        {{profile.iam[0].title}}</h4>
                                    <p class="card-text">{{profile.description}}</p>
                                </div>
                                <div class="card-bt-info">
                                    <p class="card-subtitle">
                                        <span
                                            [ngClass]="{'green-dot':profileAlertLights(profile.availableFrom), 'yellow-dot':!profileAlertLights(profile.availableFrom) }"></span>
                                        {{profileDateFilter(profile.availableFrom)}}
                                    </p>
                                    <a [routerLink]="['/view-profile/'+profile.user[0]._id]" class="card-link"
                                        target="_blank">
                                        <img src="./assets/images/ingeding/link-icon.svg" alt="Arrow" title="Arrow">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pag-b mt-64 mb-64" *ngIf="MyProfileArr.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="totalCount"
                        [(page)]="mySelectedPage" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                        (pageChange)="selectedPage(mySelectedPage)">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                </div>
                <div class="row" *ngIf="MyProfileArr.length == 0">
                    <div class="col-sm-12 right-align filter mb-5">
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('availableFrom')">
                            {{'PAGES.SEARCH_PROFILE.AVAILABILITY' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOptionProfile.sortOrder == 'asc' && gridOptionProfile.sortField == 'availableFrom'"
                                alt="Arrow" title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOptionProfile.sortOrder == 'desc' && gridOptionProfile.sortField == 'availableFrom'"
                                alt="Arrow" title="Arrow">
                        </a>
                    </div>
                    <div class="col-sm-12 mb-5">
                        <div class="card">
                            <div class="card-body text-center">
                                <span>{{'PAGES.SEARCH_JOB_POST.NO_RESULT_FOUND' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>