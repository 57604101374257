const projectname = "UMC";

export const locale = {
  lang: "or",
  data: {
    UPDATE: "ସୁଧାରନ୍ତୁ",
    LIST: " ତାଲିକା",
    HOME: " ଘର",
    ADD: "ଯୋଡନ୍ତୁ",
    NAME: " ନାମ",
    ADSH: "Adsh",
    CIK: "CIK",
    SIC: "SIC",
    COUNTRY: " ଦେଶ",
    STPRBA: " ରାଜ୍ୟ",
    CITY: " ସହର",
    ZIP: "ZIP",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "id",
    Usergroup: "ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ",
    QUESTION_TYPE: " ପ୍ରଶ୍ନ ପ୍ରକାର",
    ANSWER_INPUT: " ଉତ୍ତର",
    ANSWER_TYPE: " ଉତ୍ତର ପ୍ରକାର",
    QUESTION_TEXT: " ପ୍ରଶ୍ନ ବିଷୟ",
    DESCRIPTION: "ବର୍ଣ୍ଣନା ",
    FIRSTNAME: " ପ୍ରଥମ ନାମ",
    LASTNAME: " ଶେଷ ନାମ",
    EMAIL: "ଇ ମେଲ",
    PHONE: "ଟେଲିଫୋନ",
    COUNTRY_CODE: "ଦେଶ କୋଡ୍ ",
    REGIONS: "କ୍ଷେତ୍ର",
    STATES: "ରାଜ୍ୟ",
    CITIES: "ସହର",
    LOCALE: "ସ୍ଥାନ",
    COUNTRIES: "ଦେଶ",
    VALUES: "ମୂଲ୍ୟ",
    SETTINGTYPES: "ସେଟିଂ ପ୍ରକାର",
    ANSWER_OPTIONS: "ଉତ୍ତର ବିକଳ୍ପ",
    TRANSLATE: " ଅନୁବାଦ",
    TITLE: " ଶୀର୍ଷକ",
    CONTACT_NO: " ଯୋଗାଯୋଗ ନମ୍ବର",
    USERGROUPS: "ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ",
    USERS: "ବ୍ୟବହାରକାରୀ",
    ZONES: " କ୍ଷେତ୍ର",
    WARD: " ୱାର୍ଡ ",
    CONVENIENCES: " ଜନ ସେବା କେନ୍ଦ୍ର ର ନାମ",
    TRANSLATOR: {
      SELECT: " ଭାଷା କୁ ଚୟନ କର",
    },
    MENU: {
      HOME: "ଘର ",
      MAP: "ନକ୍ସା ",
      NEW: "ନୂଆ ",
      ACTIONS: "କାର୍ଯ୍ୟ",
      CREATE_POST: "ନୂଆ ପୋଷ୍ଟ ବନାନ୍ତୁ",
      PAGES: "ପୃଷ୍ଠା ଗୁଡିକ",
      WARD: " ୱାର୍ଡ",
      FEATURES: " ବିଶେଷତା ",
      APPS: "Apps",
      DASHBOARD: " ଡ୍ୟାସବୋର୍ଡ",
      ASSIGNMENT: " ଆସାଇନମେଣ୍ଟ",
      USERS: " ବ୍ୟାବହାରକାରୀ",
      MASTERS: " ମାଷ୍ଟର୍ସ ",
      MODULES: " ମଡ୍ୟୁଲ୍ ",
      COMPANY: " କମ୍ପାନୀ ",
      REGION: " କ୍ଷେତ୍ର",
      COUNTRY: "ଦେଶ",
      STATE: "ରାଜ୍ୟ",
      CITY: "ସହର",
      DISTRICT: "ଜିଲ୍ଲା",
      ZONE: " କ୍ଷେତ୍ର",
      TAG: "ଟ୍ୟାଗ୍",
      LANGUAGE: "ଭାଷା",
      USERMANAGEMENT: " ଉପଯୋଗକର୍ତ୍ତା ମେନେଜମେଣ୍ଟ  ",
      USERGROUP: "ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ",
      PACKAGE: " ପ୍ୟାକେଜ୍",
      SEC: "SEC",
      GLOBALSUCCESS: " ଗ୍ଲୋବାଲ୍ ସଫଳତା",
      PDF: "PDF",
      FIELDGROUPMAPPING: " ଫିଲ୍ଡ ସମୂହ ମ୍ୟାପିଂ",
      STATEMENTTYPENAME: "ବିବୃତ୍ତି ପ୍ରକାର ନାମ",
      FIELDMAPPING: " ଫିଲ୍ଡ ମ୍ୟାପିଂ ",
      QUESTIONNAIRETOOL: " ପ୍ରଶ୍ନପତ୍ର ଉପକରଣ",
      GLOBALSETTING: " ଗ୍ଲୋବାଲ୍ ସେଟିଂ",
      SETTING: " ସେଟିଂ",
      SETTINGTYPE: " ସେଟିଂ ପ୍ରକାର ",
      ACCESSLOGS: " ଆକ୍ସେସ୍ ଲଗ୍ସ୍ ",
      SURVEYMANAGEMENT: " ସର୍ଭେ ପରିଚାଳନା",
      PUBLICCONVENIENCE: " ଜନସାଧାରଣ ସୁବିଧା ",
      EMAILTEMPLATE: " ଇମେଲ୍ ଟେମ୍ପଲେଟ୍ ",
      QUESTIONS: " ପ୍ରଶ୍ନଗୁଡିକ",
      CATEGORY: " ବର୍ଗ ",
      MENU: " ମେଁନୁ",
    },
    AUTH: {
      GENERAL: {
        OR: " କିମ୍ବା ",
        SUBMIT_BUTTON: "ଦାଖଲ କରନ୍ତୁ",
        NO_ACCOUNT: " କଣ ଆପଣଙ୍କ ପାଖରେ ଆକାଉଣ୍ଟ୍ ନାହିଁ?",
        SIGNUP_BUTTON: " ସାଇନ୍ ଅପ୍ କରନ୍ତୁ ",
        FORGOT_BUTTON: " କଣ ଆପଣ ପାସୱାର୍ଡ଼ ଭୁଲିଗଲେ?",
        BACK_BUTTON: " ପଛକୁ",
        PRIVACY: " ଗୋପନୀୟ",
        LEGAL: " ଆଇନଗତ",
        CONTACT: " ଯୋଗାଯୋଗ କରନ୍ତୁ",
      },
      LOGIN: {
        TITLE: "ଲଗ ଇନ କରନ୍ତୁ",
        BUTTON: " ସାଇନ ଇନ କରନ୍ତୁ ",
        SUCCESSMESSAGE: " ସଫଳତା ପୂର୍ବକ ଲଗ ଇନ",
      },
      FORGOT: {
        TITLE: "ପାସ୍ୱାର୍ଡ ଭୁଲି ଯାଇଛନ୍ତି",
        DESC: "ପାସ୍ୱାର୍ଡ ବଦଳାଇବା ପାଇଁ ଆପଣଙ୍କ ଇ ମେଲ ଦିୟନ୍ତୁ",
        SUCCESS:
          " ଲୀଙ୍କ ଆପଣଙ୍କ ପଞ୍ଜିକୃତ ଇ ମେଲ ରେ ପଠାଯାଇଛି ଯାହାକୁ  ବ୍ୟବହାର କରି ନୂଆ ପାସୱର୍ଡ ତିଆରି  କରି ପାରିବେ",
      },
      SETPASSWORD: {
        TITLE: " ପାସୱର୍ଡ ସେଟ କରନ୍ତୁ ",
        BUTTON: "ରିସେଟs",
        SUCCESS: "ପାସୱର୍ଡ ସଫଳତା ପୂର୍ବକ ବଦଳି ଗଲା ",
      },
      REGISTER: {
        TITLE: " ସାଇନ ଅପ କରନ୍ତୁ ",
        DESC: " ନିଜ ଖାତା ବନାଇବା ପାଇଁ ନିଜ ବିବରଣ ଦିୟନ୍ତୁ",
        SUCCESS: " ଆପଣଙ୍କ ଖାତା ସଫଳତା ପୂର୍ବକ ରେଜିଷ୍ଟ୍ରର ହୋଇଯାଇଛି ",
      },
      INPUT: {
        EMAIL: " ଇମେଲ୍",
        FULLNAME: " ପୁରା ନାମ ",
        PASSWORD: " ପାସୱାର୍ଡ ",
        CONFIRM_PASSWORD: " ପାସୱାର୍ଡ ନିଶ୍ଚିତ କରନ୍ତୁ ",
        USERNAME: " ଉପଯୋଗକର୍ତ୍ତା ନାମ ",
        TITLE: " ଶୀର୍ଷକ ପ୍ରବେଶ କରନ୍ତୁ ",
        REMEMBER_ME: "ମୋତେ ସ୍ମରଣ କରନ୍ତୁ",
      },
      VALIDATION: {
        INVALID: " ଅମାନ୍ୟ",
        REQUIRED: " ଆବଶ୍ୟକ",
        MIN_LENGTH: " ସର୍ବନିମ୍ନ ଲମ୍ବ ",
        AGREEMENT_REQUIRED: " ନିଅମ ଏବଂ ସର୍ତ୍ତ ମାନିବ ଆବଶ୍ୟକ",
        NOT_FOUND: " ମିଳିଲା ନାହି",
        INVALID_LOGIN: "ଲଗ ଇନ ବିବରଣ ଭୁଲ ଅଛି    ",
        REQUIRED_FIELD: " ଆବଶ୍ୟକ କ୍ଷେତ୍ର",
        MIN_LENGTH_FIELD: " ସର୍ବନିମ୍ନ ଲମ୍ବ କ୍ଷେତ୍ର ",
        MAX_LENGTH_FIELD: " ସର୍ବାଧିକ ଲମ୍ବ କ୍ଷେତ୍ର",
        INVALID_FIELD: " ଅମାନ୍ୟ କ୍ଷେତ୍ର",
        MUST_MATCH:
          " ପାସୱର୍ଡ ଏବଂ କନଫର୍ମ  ପାସୱର୍ଡ  ନିଶ୍ଚିତ ଭାବରେ ମେଳ ହେବା ଦରକାର",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: " ନକ୍ସା ଦେଖନ୍ତୁ",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " | ଭାଷା ଶୁଚି",
        ADDTITLE: projectname + " | ଭାଷା ଯୋଡନ୍ତୁ",
        EDITTITLE: projectname + " | ଭାଷା ଅପଡେଟ",
        LANGUAGELIST: " ଭାଷା ଶୁଚି",
        LANGUAGENAMEPLURAL: " ଭାଷା ନାମ ବହୁଳ",
        LANGUAGENAMESINGULAR: " ଭାଷା",
        NEWLANGUAGE: " ନୂତନ ଭାଷା",
        DELETEDSELECTED: " ଚୟନିତ  ଭାଷା ହଟାନ୍ତୁ",
        UPDATESELECTED: " ଚୟନିତ  ଭାଷା ହେତୁ ଅଦ୍ୟତନ ସ୍ତିତି",
        ADDNEW: " ନୂତନ ଭାଷା ଯୋଡନ୍ତୁ",
        UPDATE: " ଭାଷା ଅପଡେଟ କରନ୍ତୁ",
        CREATEMESSAGE: " ଭାଷା କୁ ସଫଳତା ପୂର୍ବକ ବନାନ୍ତୁ",
        UPDATEMESSAGE: " ଭାଷା କୁ ସଫଳତା ପୂର୍ବକ ଅପଡେଟ କରନ୍ତୁ",
        EDIT: " ଭାଷା ସମ୍ପାଦନ କରନ୍ତୁ",
        ADD: " ଭାଷା ଯୋଡନ୍ତୁ",
        LOCALE: " ସ୍ଥାନ",
        LOCALE_REQUIRED: " ସ୍ଥାନ ଆବଶ୍ୟକ ",
        LOCALE_INVALID: " ସ୍ଥାନ ଅମାନ୍ୟ",
      },
      USERS: {
        LISTINGTITLE: projectname + " | ଉପଯୋଗକର୍ତ୍ତା ଶୁଚି",
        USERSLIST: " ଉପଯୋଗକର୍ତ୍ତା ଶୁଚି",
        NEWUSER: " ନୂତନ ଉପଯୋଗକର୍ତ୍ତା ",
        USERNAMEPLURAL: " ଉପଯୋଗକର୍ତ୍ତାମାନ",
        USERNAMESINGULAR: " ଉପଯୋଗକର୍ତ୍ତା",
        DELETEDSELECTED: " ବାଛିଥିବା ଉପୟୋଗକର୍ତ୍ତା ବିଲୋପିତ କରନ୍ତୁ ",
        UPDATESELECTED: " ବାଛିଥିବା ଉପୟୋଗକର୍ତ୍ତା ଙ୍କ ପାଇଁ ଅଦ୍ୟତନ ସ୍ଥିତି ",
        ADDNEW: " ନୂତନ ଉପୟୋଗକର୍ତ୍ତା ଙ୍କୁ ଯୋଡନ୍ତୁ",
        UPDATE: " ଅପଡେଟ କରନ୍ତୁ",
        PASSWORD: " ପାସୱାର୍ଡ ",
        CONFIRMPASSWORD: " ପାସ୍ବାଡ଼ ନିଶ୍ଚିତ କରନ୍ତୁ",
        USERSUCCESS: " ଉପୟୋଗକର୍ତ୍ତା  ସଫଳତା ପୂର୍ବକ ଯୋଡି ହେଲା",
        FILTERUSERGROUP: " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ଫିଲ୍ଟର୍ କରନ୍ତୁ ",
        ADDTITLE: projectname + " | ଉପଯୋଗକର୍ତ୍ତା ଯୋଡନ୍ତୁ",
        EDITTITLE: projectname + " | ଉପଯୋଗକର୍ତ୍ତା ଅଦ୍ୟତନ",
        EDIT: " ଉପଯୋଗକର୍ତ୍ତା ସମ୍ପାଦନ କରନ୍ତୁ ",
        ADD: " ଉପଯୋଗକର୍ତ୍ତା ଯୋଡନ୍ତୁ ",
        GOVT: " ସରକାର ",
        OTHERGOVT: "ସରକାର ଛଡା ଅନ୍ୟ ",
        GOVTEMP: " ସରକାରୀ କର୍ମଚାରୀ",
        NGO: " ବେସରକାରୀ ସଂସ୍ଥା(NGO)",
        PVT: " ବ୍ୟକ୍ତିଗତ ସଂସ୍ଥା ",
        CONSTENT: " ପରାମର୍ଶଦାତା",
        SHG: " ସ୍ୱୟଂ ସହାୟକ ଗୋଷ୍ଟି(SHG)",
        ACADEMIC: " ଶିକ୍ଷାବିତ ",
        OCCUPATION: " ବୃତ୍ତି ",
        ORGANIZTION: " ସଂଗଠନ ",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " | ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ତାଲିକା ",
        USERGROUPLIST: " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ତାଲିକା  ",
        USERGROUPNAMEPLURAL: " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଟି ",
        USERGROUPNAMESINGULAR: " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଟି",
        NEWUSERGROUP: " ନୂତନ ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ",
        DELETEDSELECTED: " ଚୟନିତ ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ହଟାନ୍ତୁ",
        UPDATESELECTED: " ଚୟନିତ  ଉପଯୋଗକର୍ତ୍ତା ହେତୁ ଅଦ୍ୟତନ ସ୍ତିତି",
        ADDNEW: " ନୂତନ ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଠୀ ଯୋଡନ୍ତୁ ",
        UPDATE: " ଅପଡେଟ  କରନ୍ତୁ  ",
        ADDTITLE: projectname + " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଟି ଯୋଡନ୍ତୁ",
        EDITTITLE: projectname + " ଉପଯୋଗକର୍ତ୍ତା ଗୋଷ୍ଟି ଅପଡେଟ  କରନ୍ତୁ",
      },
      QUESTION: {
        LISTINGTITLE: projectname + " ପ୍ରଶ୍ନ ଶୁଚି ",
        ADDTITLE: projectname + "ପ୍ରଶ୍ନ ଯୋଡନ୍ତୁ ",
        EDITTITLE: projectname + "ପ୍ରଶ୍ନ ଅପଡେଟ କରନ୍ତୁ",
        QUESTIONLIST: " ପ୍ରଶ୍ନ ଶୁଚି ",
        QUESTIONNAMEPLURAL: " ପ୍ରଶ୍ନ ",
        QUESTIONNAMESINGULAR: " ପ୍ରଶ୍ନ",
        NEWQUESTION: " ନୂତନ ପ୍ରଶ୍ନ ",
        DELETEDSELECTED: " ଚୟନିତ ପ୍ରଶ୍ନ ହଟାନ୍ତୁ ",
        UPDATESELECTED: " ଚୟନିତ ପ୍ରଶ୍ନ ଅପଡେଟ୍ କରନ୍ତୁ",
        ADDNEW: " ନୂତନ ଯୋଡନ୍ତୁ ",
        UPDATE: " ଅପଡେଟ୍ କରନ୍ତୁ ",
        CREATEMESSAGE: " ସଫଳତା ପୂର୍ବକ ପ୍ରଶ୍ନ ତିଆରି କରନ୍ତୁ",
        UPDATEMESSAGE: " ସଫଳତା ପୂର୍ବକ ପ୍ରଶ୍ନ ଅପଡେଟ୍ କରନ୍ତ",
        EDIT: " ପ୍ରଶ୍ନ ସମ୍ପାଦନ କରନ୍ତୁ",
        ADD: " ପ୍ରଶ୍ନ ଯୋଡନ୍ତୁ |",
        SINGLE_CHOICE: "ଏକକ ବିକଳ୍ପ",
        MULTIPLE_CHOICE: "ବହୁ ବିକଳ୍ପ",
        QUESTION_IMAGES: "ପ୍ରଶ୍ନ ଚିତ୍ରଗୁଡ଼ିକ ",
        ANSWER_LIMIT: "ଉତ୍ତର ସୀମା",
        CHOICE: " ବିକଳ୍ପ",
        NUMERIC: "ସଂଖ୍ୟା",
        DATE: "ତାରିଖ",
        UNIQUEID: " ଅନନ୍ୟ ଆଇ ଡି     ",
        TEXT: " ଟେକ୍ସଟ",
        IMAGE: " ଛବି",
        BOTH: " ଉଭୟ",
        MULTIPLE_ANSWER: "ବହୁ ଉତ୍ତର",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: " ଏକାଧିକ ଲେବଲ ଏକାଧିକ ଉତ୍ତର",
        ANSWER_INPUT: " ଉତ୍ତର ଇନ୍ପୁଟ",
        ATTACH_FILES: " ଫାଇଲେ ସଂଲଙ୍ଘନ କରନ୍ତୁ",
        QUESTION_TEXT: " ପ୍ରଶ୍ନ ଟେକ୍ସଟ",
        ANSWER_TYPE: " ଉତ୍ତର ପ୍ରକାର",
        ANSWER_IMAGE: " ଉତ୍ତର ଛବି",
        MIN_RANGE: " ସର୍ବନିମ୍ନ ସୀମା",
        MAX_RANGE: " ସର୍ବାଧିକ ସୀମା",
        ANSWER: " ଉତ୍ତର:",
        QUESTION: " ପ୍ରଶ୍ନ",
        SEQUENCE: " କ୍ରମ",
        ENTER_SEQUENCE: " ପ୍ରବେଶ କ୍ରମ |",
        INVALID_SEQUENCE: " ଅମାନ୍ୟ କ୍ରମ ",
        ADD_OPTION: "ଯୋଡନ୍ତୁ |",
        ADD_MAXIMUM_OF: " MAXIMUM OF ଆପଣ ସର୍ବାଧିକ …..ଯୋଡି ପାରିବେ ",
        ENTER_ANSWER: " ଉତ୍ତର ପ୍ରବେଶ କରନ୍ତୁ ",
        INVALID_ANSWER: " ଅମାନ୍ୟ ଉତ୍ତର",
        EXCLUSIVE_RESPONSE: " ଏକ୍ସକ୍ଲୁସିଭ୍ ଉତ୍ତର ",
        ENTER_MAXIMUM_RANGE: " MAXIMUM ଦୟାକରି ସର୍ବାଧିକ ସୀମା ପ୍ରବେଶ କରନ୍ତୁ ",
        Maximum_number_minimum_number:
          " ସର୍ବାଧିକ ସଂଖ୍ୟା ସର୍ବନିମ୍ନ ସଂଖ୍ୟା ଠାରୁ କାମ ନୁହେ ",
        ENTER_MINIMUM_RANGE: " ଦୟାକରି ମିନିମମ୍ ସୀମା ଦାଖଲ କରନ୍ତୁ ",
        INVALID_RANGE: " ଅମାନ୍ୟ ଶ୍ରେଣୀ (ସଂଖ୍ୟା)",
        PLEASEENTERQUESTION: " ଦୟାକରି ପ୍ରଶ୍ନ ଦିଅନ୍ତୁ",
        INVALID_QUESTION: " ଅମାନ୍ୟ ପ୍ରଶ୍ନ ",
        QUESTIONMUSTBEATLEAST:
          " ପ୍ରଶ୍ନ କାମ ସେ କାମ ୩ ବର୍ଣ ଲମ୍ବା ହେବାର ଅଛି ଏବଂ ଖାଲି ସ୍ଥାନ ର ଅନୁମତି ନାହିଁ",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          " ଅଧିକତ୍ବ ଅପଲୋଡ଼ ର ଆକାର ୫ ଏମବି ଅଟେ ଅନୁମତ ଫାଇଲ ପ୍ରକାର jpg,png ,jpeg ଅଟେ ଅଧିକତମ ଫୋଟୋ 228p *200px ର ଅପଲୋଡ଼ କରିପାରିବେ ",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE_ADD:
          " ଅଧିକତ୍ବ ଅପଲୋଡ଼ ର ଆକାର ୫ ଏମ ବି ଅଟେ ଅନୁମତ ଫାଇଲ ପ୍ରକାର jpg,png ,jpeg ଅଟେ ଅଧିକତମ ଫୋଟୋ 228p *200px ର ଅପଲୋଡ଼ କରିପାରିବେ",
        REVISION_FOR_THIS_QUESTION:
          " ଆପଣ ଏହି ପ୍ରଶ୍ନ ର ସଂଶୋଧନ କରିବାକୁ ଚାହୁଁଛନ୍ତି? ",
        CREATEMAPPINGMESSAGE: "ପ୍ରଶ୍ନ ମ୍ୟାପିଂ ସଫଳତା ପୂର୍ବକ ସୃଷ୍ଟି କରନ୍ତୁ ",
        UPDATEMAPPINGMESSAGE: "ପ୍ରଶ୍ନ ମ୍ୟାପିଂ ସଫଳତା ପୂର୍ବକ ଅପଡେଟ୍ କରନ୍ତୁ ",
        DELETEMAPPINGMESSAGE: "ପ୍ରଶ୍ନ ମ୍ୟାପିଂ ସଫଳତା ପୂର୍ବକ ହଟାନ୍ତୁ",
        UPLOAD_ONLY: " ଆପଣ କେବଳ ଅପଲୋଡ୍ କରି ପାରିବେ",
        CONDITIONISMAPPED:
          " ଆପଣ ଆଦେଶ ର ଅନୁକ୍ରମ ବଦଳାଇ ପାରିବେ ନାହିଁ କାହିଁକି ନା ସ୍ତିଥି ମେପ କରା ଯାଇଛି  ",
      },
      MENU: {
        LISTINGTITLE: projectname + "ମେଁନୁ ଶୁଚି",
        ADDTITLE: projectname + " ମେଁନୁ ଯୋଡନ୍ତୁ",
        EDITTITLE: projectname + " ମେଁନୁ ଅପଡେଟ୍ କରନ୍ତୁ",
        MENULIST: "ମେଁନୁ ଶୁଚି",
        MENUNAMEPLURAL: " ମେଁନୁ",
        MENUNAMESINGULAR: " ମେଁନୁ",
        NEWMENU: " ନୂଆ ମେଁନୁ",
        DELETEDSELECTED: " ଚୟନିତ ମେଁନୁ ହଟାନ୍ତୁ",
        UPDATESELECTED: " ଚୟନିତ ମେଁନୁ ହେତୁ ଅପଡେଟ",
        ADDNEW: " ନୂତନ ଯୋଡନ୍ତୁ ",
        UPDATE: " ଅପଡେଟ କରନ୍ତୁ",
        CREATEMESSAGE: "ମେନୁ ସଫଳତା ପୂର୍ବକ ବନାନ୍ତୁ",
        UPDATEMESSAGE: "ଅପଡେଟ ମେନୁ ସଫଳତା ପୂର୍ବକ",
        EDIT: "ମେନୁ ସମ୍ପାଦିତ କରନ୍ତୁ",
        ADD: "ମେନୁ ଯୋଡନ୍ତୁ ",
        VALUES: "ମୂଲ୍ୟ ",
        PARENT: "ଜନକ",
        MODULE: "ମୋଡ଼ୁଲ",
        PAGE: "ପୃଷ୍ଠା",
        ICON: "ଆଇକନ",
        ORDERING: "ଆଦେଶ",
        VALIDATION: {
          SELECTTYPE: "ଦୟା କରି ମେନୁ ଚୟନ କରନ୍ତୁ ",
        },
      },
      SURVEY: {
        LISTINGTITLE: projectname + "ସର୍ବେକ୍ଷଣ ଶୁଚି",
        SURVEYLIST: "ସର୍ବେ ଶୁଚି ",
        NEWSURVEY: "ନୂତନ ସର୍ଭେ ",
        SURVEYNAMEPLURAL: "ସର୍ବେକ୍ଷଣ ",
        SURVEYNAMESINGULAR: "ସର୍ବେକ୍ଷଣ",
        DELETEDSELECTED: " ଚୟନ କରିଥିବା ସର୍ଭେ କ୍ଷଣ କୁ ହଟାନ୍ତୁ",
        UPDATESELECTED: " ଚୟନିତ ସର୍ବେକ୍ଷଣ କାରୀଙ୍କ ପାଇଁ ଅଧ୍ୟତାନ ସ୍ତିତି",
        ADDNEW: "ନୂତନ ସର୍ଭେକ୍ଷଣ ଯୋଡନ୍ତୁ",
        UPDATE: "ଅପଡେଟ ସର୍ଭେ",
        PASSWORD: "ପାସୱାର୍ଡ଼",
        CONFIRMPASSWORD: "ପାସୱାର୍ଡ଼ ର ପୃଷ୍ଟି କରନ୍ତୁ",
        SURVEYSUCCESS: "ସର୍ଭେକ୍ଷଣ ସଫଳତା ସହିତ ଯୋଡିଲା",
        SURVEYQUESTIONSUBMIT: "ସଫଳତା ସହିତ ଦାଖଲ କରନ୍ତୁ",
        SURVEYQUESTIONSAVE: "ସଫଳତା ସହିତ ସେଭ କରନ୍ତୁ",
        FILTERSURVEYGROUP: "ସର୍ଭେ ଗୋଷ୍ଟି କୁ ଫିଲ୍ଟର କରନ୍ତୁ",
        ADDTITLE: projectname + "| ସର୍ଭେଖ୍ୟକ ଯୋଗ କରନ୍ତୁ ",
        EDITTITLE: projectname + " | ସର୍ଭେଖ୍ୟକ ଅପଡେଟ  କରନ୍ତୁ ",
        EDIT: "ସର୍ଭେକ୍ଷଣ ସମ୍ପାଦିତ କରନ୍ତୁ",
        ADD: "ନୂତନ ସର୍ଭେକ୍ଷଣ ଆରମ୍ଭ କରନ୍ତୁ",
        SRNO: "ଅନୁ କ୍ରମାଙ୍କ",
        SEARCH: "ସନ୍ଧାନ",
        SURVEYORNAME: "ସର୍ଭେକ୍ଷକ କ ନାମ",
        SURVEYQUESTIONTITLE: "ସର୍ବସାଧାରଣ ସୁବିଧାର ସର୍ଭେ",
        SURVEYBY: "ଟିମ ଦ୍ଵାରା ସର୍ଭେକ୍ଷଣ ପାଇଁ ଫର୍ମ",
        VALIDATIONTEXT: "ଚିହିଁତ ପ୍ରଶ୍ନ ବାଧ୍ୟତା ମୂଳକ ଅଟେ",
        NOTE: "ଧ୍ୟାନ ଦିଅନ୍ତୁ",
        SURVEYTEXT:
          "ଜୋନ ଏବଂ  ୱାର୍ଡ  ପୂରଣ କରିବା ବାଧ୍ୟତା ମୂଳକ ନୁହେ ଯଦି ଆପଣ ଏହି ସୂଚନା ବିଷୟରେ ଜାଣିଛନ୍ତି ତେବେ ଏକ ଉପଯୁକ୍ତ ବିକଳ୍ପ ଚୟନ କରନ୍ତୁ। ",
        PUBLICCONVENIENCENOTE:
          "ଯଦି ଅପଣଂକ ଶୌଚାଳୟ ଅଲଗା ସ୍ଥାନ ରେ ଅଛି ତେବେ ଦୟା କରି ବିକଳ୍ପ ଗୁଡିକ ମଧ୍ୟରୁ ଉପଯୁସକ୍ତ ସ୍ଥାନ ଚୟନ କରନ୍ତୁ",
      },
      COMMON: {
        DATE: "ତାରିଖ",
        GOTO: "ପାଇଁ ଯାଆନ୍ତୁ",
        UNIQUEID: "ୟୁନିକ ଆଇଡି",
        COMPLETED: "ପୂରଣ ହେଲା",
        INPROGRESS: "ଚାଲୁଅଛି",
        CITYLIST: "ସହରର ଶୁଚି",
        COUNT: "ସର୍ଭେକ୍ଷଣ ଗଣନା",
        LOGOUT: "ଲଗ ଆଉଟ",
        NEXT: "ପରବର୍ତୀ",
        PREVIOUS: "ପୂର୍ବବର୍ତ୍ତୀ",
        HOME: "ଘର",
        ALL: "ସମସ୍ତ",
        ACTIVE: "ସକ୍ରିୟ",
        INACTIVE: "ନିଷ୍କ୍ରିୟ",
        PLEASESELECT: "ଦୟାକରି ଚୟନ କରନ୍ତୁ",
        BASICINFORMATION: "ମୌଳିକ ସୂଚନା",
        COMPANYMANAGEMENT: "କମ୍ପାନୀ ପରିଚଳନା",
        COMPANYREGISTEARS: "କମ୍ପାନୀ ରେଜିଷ୍ଟ୍ର",
        CREATEDAT: "ତାରିଖ ଦିୟନ୍ତୁ",
        STATUS: "ସ୍ତିତି",
        ACTION: "କାର୍ଯ୍ୟବାହୀ",
        DELETE: "ବିଲୋପିତ",
        TITLE: "ଶୀର୍ଷକ",
        VIEW: "ଦେଖନ୍ତୁ",
        UPDATE: "ଅପଡେଟ କରନ୍ତୁ",
        PARENT: "ଜନକ",
        PIC: "Pic",
        SEARCHBTN: "ସନ୍ଧାନ କରନ୍ତୁ",
        FIRSTNAME: " ପ୍ରଥମ  ନାମ ",
        FIRSTNAMEREQ: " ଦୟାକରି ପ୍ରଥମ  ନାମ ନିର୍ଦ୍ଧିଷ୍ଟ କରନ୍ତୁ",
        FIRSTNAMEERROR: " ପ୍ରଥମ ନାମ ଭୁଲ ଅଛି",
        NAME: " ନାମ",
        LASTNAME: " ଶେଷ ନାମ",
        LASTNAMEREQ: " ଦୟାକରି ଶେଷ ନାମ ନିର୍ଦ୍ଧିଷ୍ଟ କରନ୍ତୁ",
        LASTNAMEERROR: " ଶେଷ ନାମ ଭୁଲ ଅଛି",
        EMAIL: " ‘ଇମେଲ",
        EMAILREQ: " ଦୟାକରି ଇମେଲ ନିର୍ଦ୍ଧିଷ୍ଟ କରନ୍ତୁ",
        EMAILERROR: " ଇମେଲ ଭୁଲ ଅଛି",
        WEBSITE: " ୱେବ ସାଇଟ",
        WEBSITEREQ: " ଦୟାକରି ୱେବ ସାଇଟ ନିର୍ଦ୍ଧିଷ୍ଟ କରନ୍ତୁ",
        WEBSITEERROR: " ୱେବ ସାଇଟ ଭୁଲ ଅଛି",
        FAX: " ଫେକ୍ସ",
        FAXERROR: " ଫେକ୍ସ ଭୁଲ ଅଛି",
        ADDRESS: " ଠିକଣା",
        MOBILENUMBER: " ମୋବାଇଲ ନମ୍ବର",
        DATEOFBIRTH: " ଜନ୍ମ ତାରିଖ",
        DATEOFBIRTHERROR: " ଜନ୍ମ ତାରିଖ ପ୍ରବେଶ କରନ୍ତୁ",
        SAVEBTN: " ସେଭ କରନ୍ତୁ",
        SUBMITBTN: " ଦାଖଲ କରନ୍ତୁ",
        SEARCH: " ସନ୍ଧାନ",
        BACKBTN: " ପଛକୁ",
        UPDATEBTN: " ଅପଡେଟ କରନ୍ତୁ",
        RESETBTN: " ରିସେଟ",
        CANCELBTN: " କେନସଲ କରନ୍ତୁ",
        REPORT: " ରିପୋର୍ଟ",
        CITY: " ସହର",
        COUNTRY: " ଦେଶ",
        CODE: " କୋଡ଼",
        ZONE: " ଜୋନ",
        WARD: " ୱାର୍ଡ",
        PUBLICCONVENIENCE: " ସାର୍ବଜନିକ ସୁଭିଧା",
        SELECTCOUNTRY: " ଦେଶ ଚୟନ କରନ୍ତୁ ",
        SELECTREGION: " କ୍ଷେତ୍ର ଚୟନ କରନ୍ତୁ",
        SELECTSTATE: " ରାଜ୍ୟ ଚୟନ କରନ୍ତୁ",
        SELECTCITY: " ସହର ଚୟନ କରନ୍ତୁ",
        SELECTZONE: " ଜୋନ ଚୟନ କରନ୍ତୁ",
        SELECTWARD: " ୱାର୍ଡ ଚୟନ କରନ୍ତୁ",
        SELECTUSERGROUP: " ବ୍ୟବହାରିକ ଗୋଷ୍ଟି ଚୟନ କରନ୍ତୁ",
        STATE: " ରାଜ୍ୟ",
        PIN: " ପିନ",
        ZIPCODE: " ପିନ କୋଡ଼",
        NORECORDS: " ରେକର୍ଡ ନାହିଁ",
        DELETEALL: " ସମସ୍ତ ଡିଲିଟ କରନ୍ତୁ",
        UPDATESTATUS: " ଅପଡେଟ ସ୍ତିତି",
        DESCRIPTION: " ବିବରଣୀ",
        DESIGNATION: " ପଦବୀ",
        ENTERDESCRIPTION: " ଦୟାକରି ବିବରଣୀ ପ୍ରବେଶ କରନ୍ତୁ",
        STATUSUPDATED: " ସ୍ତିତି ସଫଳତା ପୂର୍ବକ ଅପଡେଟ ହୋଇଯାଇଛି",
        SUCCESS: " ସଫଳତାପୂର୍ବକ",
        SELECTED: " ଚୟନ କରାଯାଇଛି",
        STATUSSELECTEDUPDATE: " ଆପଣ ଚୟନ କରିଥିବା ସ୍ତିତି ଟି ଅପଡେଟ ହୋଇ ଯାଇଛି",
        PERMANTLYDELETE:
          " ଆପଣ କଣ ଏହି ସ୍ତିତି କୁ ସମ୍ପୂର୍ଣ ଭାବେ ଡିଲିଟ କରିବାକୁ ଚାହାନ୍ତି",
        DELETETEXT:
          " ଆପଣ କଣ ଏହି ସ୍ତିତି କୁ ସମ୍ପୂର୍ଣ ଭାବେ ଡିଲିଟ କରିବାକୁ ଚାହାନ୍ତି  କି? ",
        ISDELETING: " ଡିଲିଟ ହେଉଛି",
        DELETESELECTED: " ଚୟନ ଡିଲିଟ କରନ୍ତୁ",
        UPDATESELECTED: " ସ୍ତିତି ଅପଡେଟ ଚୟନ କରନ୍ତୁ",
        AMOUNT: " ରାଶି",
        TIME: " ସମୟ",
        VALUE: " ମାନକ",
        USERGROUP: " ବ୍ୟବହାରିକ ଗୋଷ୍ଟି",
        SELECT: "‘ଚୟନ କରନ୍ତୁ",
        VERSION: " ସଂସ୍କରଣ",
        TYPE_THREE_LETTERS:
          " ତିନୋଟି ଲେର୍ଟର ଲେଖନ୍ତୁ ଏବଂ କିଛି ସମୟ ଅପେକ୍ଷା କରନ୍ତୁ",
        PERMISSION: " ଅନୁମତି",
        LABELSEARCH: " ଏଠାରେ ଲେବେଲ ଲେଖନ୍ତୁ",
        SELECTANYONE: " କୌଣସି ଗୋଟିଏ ଚୟନ କରନ୍ତୁ",
        IMAGEERROR: " ଦୟାକରି ଛବି କୁ ଅପଲୋଡ଼ କରନ୍ତୁ",
        TEXTERROR: " ଦୟାକରି ବିଷୟଟି ଚୟନ କରନ୍ତୁ",
        NUMBERERROR: " ଦୟାକରି  ସଂଖ୍ୟା ଟି ନିର୍ଦ୍ଧିଷ୍ଟ କରନ୍ତୁ",
        INVALIDFILE: " ଆପଣ ଅପଲୋଡ଼ କରି ଥିବା ଫାଇଲ ଟି ଅମାନ୍ୟ ଆଟେ",
        // tslint:disable-next-line: max-line-length
        PASSWORDHINT:
          " ପାସୱାର୍ଡ଼ ରେ ୮ ରୁ ୨୦ ଅକ୍ଷର, ଅତି କାମରେ ଗୋଟିଏ ସଂଖ୍ୟା , ଗୋଟିଏ ଉପର ବର୍ଗ , ଗୋଟିଏ ନିମ୍ନ ବର୍ଗ ଏବଂ ଗୋଟିଏ ବିଶେଷ ବର୍ଗ ରହିବ ଆବଶ୍ୟକ।",
        OOPS: " ଆହା",
        ERROR: " ଭୁଲ",
        HELLO: " ହେଲୋ",
        WELCOMEBACK: " ପୁନର୍ବାର ଆସିଥିବାରୁ ଆପଣଂକୁ ସ୍ୱାଗତ",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          " ଏମିତି ଲାଗୁଛି କି ଆପଣ ପୂର୍ବ ସର୍ବେକ୍ଷଣ ପୁରା କରିପାରି ନାହାନ୍ତି କଣ ଆପଣ ଜଉଠୁ ଛାଡି ଥିଲେ ସେଠାରୁ ଚାଲୁ ରଖିବାକୁ ଚାହୁଁଛନ୍ତି କିମ୍ଭା ଆପଣ ଏକ ନୂଆ ସର୍ବେ କ୍ଷଣ ପ୍ରଶ୍ନ ଆରମ୍ଭ କରିବାକୁ ଚାହୁଁଛନ୍ତି ? ",
        CONTINUEWITH: " ଜାରି ରଖନ୍ତୁ",
        OR: " କିମ୍ଭା",
        PREVIOUSQUESTION: " ପୂର୍ବବର୍ତ୍ତୀ ପ୍ରଶ୍ନ",
        FRESHQUESTION: " ଫ୍ରେଶ ପ୍ରଶ୍ନ",
        CONFIRM: " ନିଶ୍ଚିତ କରନ୍ତୁ",
        AREYOUSURE: " କଣ ଆପଣ ପ୍ରଥମ ରୁ ଆରମ୍ଭ କରିବା ପାଇଁ ଚାହାନ୍ତି କି?",
        CONFIRMIMAGE: " କଣ ଆପଣ ଛବି ଟି ହଟାଇବା ପାଇଁ ନିଶ୍ଚିତ କି?",
        YES: "ହଁ",
        NO: " ‘ନାହିଁ ",
        TEXTINVALIDE: " ବିଷୟଟି ଅମାନ୍ୟ ଆଟେ",
        CONFIRMCONVENIENCE:
          " ସାର୍ବଜନୀନ ସୁବିଧା ର ନାମ ବଦଳାଇବା ପାଇଁ କଣ ଆପଣ ନିଶ୍ଚୟ କରୁଛନ୍ତି କି?",
        TERMSOFUSE: " ବ୍ୟବହାରିକ ସର୍ତ",
        PRIVACYPOLICY: " ଗୋପନୀୟ ନୀତି",
        FORHELPCONTACT: " ସାହାଯ୍ୟ କରିବା ପାଇଁ ସମ୍ପର୍ଙ୍ଗ କରନ୍ତୁ",
        CLICKFORANEWSURVEY: "ନୂତନ ସର୍ଭେ ଆରମ୍ଭ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
        VALIDATION: {
          INVALID_IMAGE:
            "ଆପଣ ଏକ ଅମାନ୍ୟ ଫାଇଲ୍ ପ୍ରକାର କିମ୍ବା ସର୍ବାଧିକ ଅପଲୋଡ୍ ଆକାର ସୀମା ଉପର ଫାଇଲ୍ (୫ ଏମ ବି) ଅପଲୋଡ୍ କରିଛନ୍ତି |:!",
          NAME_INVALID: "ନାମରେ ଅମାନ୍ୟ ବର୍ଣ୍ଣ ଅଛି ",
          NAME_REQUIRED: "ଦୟାକରି ଏକ ନାମ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          USERGROUPREQUIRED: "ଦୟାକରି ଏକ ଉପଭୋକ୍ତା ଗୋଷ୍ଠୀ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          NAME_MIN_LENGTH: "ନାମ ଅତିକମରେ ୨ ଅକ୍ଷର ଲମ୍ବା ହେବା ଜରୁରୀ |",
          NAME_MAX_LENGTH: "୫୦ ରୁ ଅଧିକ ଅକ୍ଷର ରହିପାରିବ ନାହିଁ ।",
          ZIP_REQUIRED: "ଦୟାକରି ଜିପକୋଡ୍ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ |",
          ZIP_INVALID: "ଜିପକୋଡ୍ ଅମାନ୍ୟ ଅଟେ ",
          CITY_REQUIRED: "ଦୟାକରି ଏକ ସହର ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          STATE_REQUIRED: "ଦୟାକରି ରାଜ୍ୟ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          REGION_REQUIRED: "ଦୟାକରି ଏକ ଅଞ୍ଚଳ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          COUNTRY_REQUIRED: "ଦୟାକରି ଦେଶ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ ",
          VALUE_REQUIRED: "ଦୟାକରି ମୂଲ୍ୟ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ",
          TITLE_INVALID: "ଶୀର୍ଷକରେ ଅମାନ୍ୟ ବର୍ଣ୍ଣ ଅଛି ",
          TITLE_REQUIRED: "ଦୟାକରି ଶୀର୍ଷକ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ",
          TITLE_MIN_LENGTH: "ନାମ ଅତିକମରେ ୨ ଅକ୍ଷର ଲମ୍ବା ହେବା ଜରୁରୀ ।",
          TITLE_MAX_LENGTH: "୫୦ ରୁ ଅଧିକ ଅକ୍ଷର ରହିପାରିବ ନାହିଁ।",
          PIN_REQUIRED: "ଦୟାକରି ଏକ ପିନକୋଡ୍ ନିର୍ଦ୍ଦିଷ୍ଟ କରନ୍ତୁ",
          PIN_REQUIRED_MIN_LENGTH: "ପିନ ଅତିକମରେ 5 ଅକ୍ଷର ଲମ୍ବା ହେବା ଜରୁରୀ ।",
          PIN_REQUIRED_MAX_LENGTH: "୬ ରୁ ଅଧିକ ଅକ୍ଷର ରହିପାରିବ ନାହିଁ।",
        },
        PATTERN: {
          // tslint:disable-next-line: quotemark
          // NAME : "[a-zA-Z '.&:_()-]*'"
          // tslint:disable-next-line: quotemark
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
          // tslint:disable-next-line: quotemark
          EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
          // tslint:disable-next-line: quotemark
          ADSH: "[0-9-]{20}",
          // tslint:disable-next-line: quotemark
          SIC: "[0-9]{2,50}",
          // tslint:disable-next-line: quotemark
          NUMBER_ONLY: "[0-9]+",
          // tslint:disable-next-line: quotemark
          FNAME_LNAME: "^[a-zA-Z]+\\s?[a-zA-Z]+$",
          // tslint:disable-next-line: quotemark
          CONTACT_NO: "[0-9]{10}",
          // tslint:disable-next-line: quotemark
          TAG: "[a-zA-Z']*",
          // tslint:disable-next-line: quotemark
          AMOUNT: "[0-9.]+",
          // tslint:disable-next-line: quotemark
          TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
        },
      },
      PROFILE: {
        NAME: "ମୋ ପ୍ରୋଫାଇଲ୍",
        LISTINGTITLE: projectname + "ମୋ ପ୍ରୋଫାଇଲ୍",
        PROFILESUCCESS: "ପ୍ରୋଫାଇଲ୍ ସଫଳତାର ସହିତ ଅପଡେଟ ହେଲା",
        INVALID_IMAGE:
          "ଆପଣ ଏକ ଅମାନ୍ୟ ଫାଇଲ୍ ପ୍ରକାର କିମ୍ବା ସର୍ବାଧିକ ଅପଲୋଡ୍ ଆକାର ସୀମା ଉପର ଫାଇଲ୍ (୫ ଏମ ବି) ଅପଲୋଡ୍ କରିଛନ୍ତି!",
        PROFILE_IMAGE: "ପ୍ରୋଫାଇଲ୍ ଛବି",
        BASIC_INFO: "ମୌଳିକ ସୂଚନା ",
        PHONE: "ଯୋଗାଯୋଗ ନମ୍ବର",
        PHONEREQ: "ଯୋଗାଯୋଗ ନମ୍ବର ଆବଶ୍ୟକ ଅଟେ ",
        PHONEERROR: "ଦୟାକରି ବୈଧ ଫୋନ୍ ନମ୍ବର ଦିୟନ୍ତୁ",
        ADDRESS: "ଠିକଣା ଲେଖନ୍ତୁ ",
        ADDRESSTAB: "ଠିକଣା",
        CHANGEPASSWORD: "ପାସୱାର୍ଡ ପରିବର୍ତ୍ତନ କରନ୍ତୁ ",
        PASSWORDNOTMATCH: "ଓହ ! ପାସୱାର୍ଡ ମେଳ ଖାଉ ନାହିଁ | ",
        PASSWORDREQUIRED: "ପାସୱାର୍ଡ ଆବଶ୍ୟକ ଅଟେ ",
        OLDPASSWORDREQUIRED: "ପୁରୁଣା ପାସୱାର୍ଡ ଆବଶ୍ୟକ ଅଟେ  ",
        ENTEROLDPASSWORD: "ପୁରୁଣା ପାସୱାର୍ଡ",
        NEWPASSWORDREQUIRED: "ନୂତନ ପାସୱାର୍ଡ ଆବଶ୍ୟକ ଅଟେ ",
        ENTERNEWPASSWORD: "ନୂତନ ପାସୱାର୍ଡ",
        INVALIDNEWPASSWORD: "ନୂତନ ପାସୱାର୍ଡ ମାନ୍ୟ ନୁହେଁ ",
        INVALIDPASSWORD: "ପାସୱାର୍ଡ ମାନ୍ୟ ନୁହେଁ",
        NEWPASSWORDSAME: "ନୂତନ ପାସୱାର୍ଡ ପୁରୁଣା ପାସୱାର୍ଡ ସହିତ ସମାନ ହୋଇନଥିବ",
        CONFIRMPASSWORD: "ପାସୱାର୍ଡ ନିଶ୍ଚିତ କରନ୍ତୁ ",
        CONFIRMPASSWORDREQUIRED: "ପାସୱାର୍ଡର ସୁନିଶ୍ଚିତ କରିବା ଆବଶ୍ୟକ",
        CONFIRMPASSWORDSAME:
          "ନୂତନ ପାସୱାର୍ଡ ଏବଂ ସୁନିଶ୍ଚିତ କରିଥିବା ପାସୱାର୍ଡର ମୂଲ୍ୟ ମେଳ ଖାଉ ନାହିଁ।",
        INVALIDOLDPASSWORD: "ପୁରୁଣା ପାସୱାର୍ଡ ଅମାନ୍ୟ ଅଟେ ",
        CREATEUSERGROUP: "ସଫଳତାର ସହିତ ଉପଭୋକ୍ତା ଗୋଷ୍ଠୀ ସୃଷ୍ଟି କରନ୍ତୁ",
        UPDATEUSERGROUP: " ଉପଭୋକ୍ତା ଗୋଷ୍ଠୀକୁ ସଫଳତାର ସହିତ ଅପଡେଟ କରନ୍ତୁ",
      },
      LOGIN: {
        WELCOME: "ପରିମଳ ମ୍ୟାପିଂକୁ ସ୍ୱାଗତ |",
        // tslint:disable-next-line: max-line-length
        LONGTEXT:
          "ଏହି ଉପକରଣ ଆପଣଙ୍କୁ ପରିମଳ ପରିପ୍ରେକ୍ଷୀରେ ସର୍ବସାଧାରଣ ଏବଂ ଗୋଷ୍ଠୀ ଶୌଚାଳୟର ସ୍ଥିତିକୁ  ବୁଝିବାରେ  ସାହାଯ୍ୟ କରିବ ଏବଂ ଆପଣ ଶୌଚାଳୟ ସୁବିଧାଗୁଡ଼ିକରେ କ୍ୟୁବିକଲ୍ ଭିତ୍ତିଭୂମି ଏବଂ ପରିମଳ ବ୍ୟବଧାନକୁ ଆକଳନ କରିପାରିବେ।",
        EMAIL: "ଇମେଲ୍ ଠିକଣା / ଫୋନ୍ ନମ୍ବର |",
        EMAIL_VALIDATION: "ଦୟାକରି ଇମେଲ୍ କିମ୍ବା ମୋବାଇଲ୍ ନମ୍ବର ଦିଅନ୍ତୁ ।",
        EMAIL_PATTERN:
          "ଭୁଲ ଫର୍ମାଟ୍, ଏହା ଏକ ମାନ୍ୟ ଇମେଲ୍ କିମ୍ବା ୧୦ ଅଙ୍କ ବିଶିଷ୍ଟ ମୋବାଇଲ୍ ନମ୍ବର ହେବା ଜରୁରୀ।",
        ACCOUNT: "ଏକ ଆକାଉଣ୍ଟ ସୃଷ୍ଟି କରିବା ଆବଶ୍ୟକ ?",
        REGISTER: "ପଞ୍ଜିକରଣ କରନ୍ତୁ ",
        OTP: "OTP ପଠାନ୍ତୁ ",
        ENTER_OTP: "ପ୍ରବେଶ କରନ୍ତୁ",
        ONE_TIME_PASS: "ଗୋଟିଏ ଥର ବ୍ୟବହାର କରାଯାଉଥିବା ପାସୱାର୍ଡ",
        OTP_VALIDATION: "ଦୟାକରି OTP ପ୍ରବେଶ କରନ୍ତୁ ...!",
        RESEND_OTP: "OTP ପୁନଃ ପଠାନ୍ତୁ ",
        OTP_RESEND: "OTP ପୁନଃ ପଠାନ୍ତୁ ",
        NOT_GET_OTP: "OTP ଗ୍ରହଣ ହେଉ ନାହିଁ?",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "ପରିମଳ ମ୍ୟାପିଂକୁ ସ୍ୱାଗତ |",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "ସହରୀ ସ୍ଥାନୀୟ ସଂସ୍ଥାଗୁଡ଼ିକ ପାଇଁ ପରିମଳ ମ୍ୟାପିଂ ହେଉଛି ଏକ ବିସ୍ତୃତ ଉପକରଣ | ସ୍ଵଛ ଭାରତ ମିଶନ-ସହରୀ ଅଧୀନରେ ଗଠିତ ଗୃହ ଓ ନଗର କାର୍ଯ୍ୟ ମନ୍ତ୍ରାଳୟ (MoHUA)ର ODF+ ପ୍ରୋଟୋକଲକୁ ପାଳନ କରିବା ପାଇଁ ଏହା ସର୍ବସାଧାରଣ ତଥା ଗୋଷ୍ଠୀ ଶୌଚାଳୟ ମରାମତି, ନବୀକରଣ ଏବଂ ରକ୍ଷଣାବେକ୍ଷଣକୁ ସରଳ କରିଥାଏ | ଶୌଚାଳୟ ସୁବିଧାଗୁଡ଼ିକରେ ରକ୍ଷଣାବେକ୍ଷଣ, ସ୍ୱଚ୍ଛତା ଏବଂ  ଭିତ୍ତିଭୂମି ଫାଟକୁ ଆକଳନ କରିବା ପାଇଁ ଏଥିରେ ସର୍ଭେ ଏବଂ ମ୍ୟାପିଂ ଉପକରଣ ଅନ୍ତର୍ଭୁକ୍ତ.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "USAID ସହଯୋଗରେ ଏହି ଉପକରଣକୁ ସହରୀ ପରିଚାଳନା କେନ୍ଦ୍ର (UMC) ଦ୍ୱାରା ବିକଶିତ କରାଯାଇଛି.",
        TERMSOFUSE: "ବ୍ୟବହାର ସର୍ତ୍ତାବଳୀ",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "ସେବା ସର୍ତ୍ତାବଳୀ ଏହି ଅନୁପ୍ରୟୋଗ ଏବଂ ଏହାର ସେବାଗୁଡିକର ବ୍ୟବହାରକୁ ପରିଚାଳନା କରେ | ଅନୁପ୍ରୟୋଗ ବ୍ୟବହାର କରିବା ପୂର୍ବରୁ ଦୟାକରି ଏହି ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ ଭଲଭାବରେ ପଢ଼ନ୍ତୁ | ଅନୁପ୍ରୟୋଗ କିମ୍ବା ସେବାଗୁଡିକ ବ୍ୟବହାର କରି, ଆପଣ ଏହି ନିୟମଗୁଡିକରେ ବର୍ଣ୍ଣିତ ନୀତିଗୁଡିକ ସହିତ ଆମର ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ ଦ୍ୱାରା ବନ୍ଧା ହେବାକୁ ରାଜି ହୁଅନ୍ତି | ଏହି ସର୍ତ୍ତାବଳୀ ଏବଂ ସର୍ତ୍ତଗୁଡିକ ସମୟ ସମୟରେ ସଂଶୋଧିତ ହୋଇପାରେ",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "ସହରୀ ପରିଚାଳନା କେନ୍ଦ୍ର (UMC) ପ୍ରୟୋଗର ବିଷୟବସ୍ତୁ ସଠିକ୍ ଏବଂ ଅତ୍ୟାଧୁନିକ ବୋଲି ନିଶ୍ଚିତ କରିବାକୁ ସମସ୍ତ ପ୍ରୟାସ କରୁଥିବାବେଳେ ଏହାର ନିର୍ଭରଯୋଗ୍ୟତା, ସଠିକତା କିମ୍ବା ସୂଚନାର ସମ୍ପୂର୍ଣ୍ଣତା ପାଇଁ କୌଣସି ଫୋରମ୍କୁ ପଠାଯିବା ୱାରେଣ୍ଟେଡ୍ ନୁହେଁ | କୌଣସି ପୂର୍ବ ସୂଚନା ବିନା ଏହି ଅନୁପ୍ରୟୋଗରେ ଅନ୍ତର୍ଭୂକ୍ତ ହୋଇଥିବା ସୂଚନାକୁ ସଂପୂର୍ଣ୍ଣ କିମ୍ବା ବିଲୋପ କିମ୍ବା ଅପଡେଟ୍ କରିବାକୁ UMC ର ଅଧିକାର ଅଛି | ଏହି ଅନୁପ୍ରୟୋଗ ମାଧ୍ୟମରେ ବିଷୟବସ୍ତୁ ଏବଂ ସୂଚନା ସଂଗ୍ରହ କରାଯାଉଛି |",
        TEXT4: "ସୂଚନା ଏବଂ ଶିକ୍ଷା ଉଦ୍ଦେଶ୍ୟ ",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "ଏହି ପ୍ରୟୋଗରେ ଅନ୍ତର୍ଭୁକ୍ତ ସମସ୍ତ ସାମଗ୍ରୀ, ଫଟୋ, ରିପୋର୍ଟ ଏବଂ ପାଠ୍ୟ ହେଉଛି UMC ଗ୍ରୁପ୍ ର ସମ୍ପତ୍ତି ଏବଂ ତେଣୁ ଭାରତୀୟ କପିରାଇଟ୍ ନିୟମ ଅନୁଯାୟୀ ସୁରକ୍ଷିତ | ଆମର ସେବାଗୁଡିକର ବ୍ୟବହାର ଆପଣଙ୍କୁ ଆମର ସେବାରେ କିମ୍ବା ଆପଣ ବ୍ୟବହାର କରୁଥିବା ବିଷୟବସ୍ତୁରେ କୌଣସି ବୌଦ୍ଧିକ ସମ୍ପତ୍ତି ଅଧିକାରର ମାଲିକାନା ପ୍ରଦାନ କରେ ନାହିଁ ।",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "ଅନ୍ୟଥା ନିର୍ଦ୍ଦିଷ୍ଟ ନହେବା କିମ୍ବା ପ୍ରକାଶିତ ନହେବା ପର୍ଯ୍ୟନ୍ତ ପ୍ରକାଶିତ ନିର୍ଦ୍ଦିଷ୍ଟ ବିଷୟବସ୍ତୁ / ସୂଚନା ଏହାର ସୀମିତ ବ୍ୟବହାର, ଡାଉନଲୋଡ୍, କପି, ପୁନଃ ପ୍ରକାଶନ, ସଂଶୋଧନ ଏବଂ ବଣ୍ଟନ କିମ୍ବା ବ୍ୟବସାୟିକ ଭାବରେ ଏହାକୁ ବ୍ୟବହାର କରିବା ପାଇଁ ଅଟେ | ଅନୁପ୍ରୟୋଗରୁ ବିଷୟବସ୍ତୁ ପ୍ରକାଶନ କରିବା କପିରାଇଟ୍ ମାଲିକାନା ଅଧିକାର ର ଉଲ୍ଲଂଘନ ହେବ  - ୟୁ ଏମ ସି ",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "ପରିମଳ ମ୍ୟାପିଂକୁ ସ୍ୱାଗତ",
        PRIVACYPOLICY: "ଗୋପନୀୟତା ନୀତି",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "ସହରୀ ସ୍ଥାନୀୟ ସଂସ୍ଥାଗୁଡ଼ିକ ପାଇଁ ପରିମଳ ମ୍ୟାପିଂ ହେଉଛି ଏକ ବିସ୍ତୃତ ଉପକରଣ | ସ୍ଵଛ ଭାରତ ମିଶନ-ସହରୀ ଅଧୀନରେ ଗଠିତ ଗୃହ ଓ ନଗର କାର୍ଯ୍ୟ ମନ୍ତ୍ରାଳୟ (MoHUA)ର ODF+ ପ୍ରୋଟୋକଲକୁ ପାଳନ କରିବା ପାଇଁ ଏହା ସର୍ବସାଧାରଣ ତଥା ଗୋଷ୍ଠୀ ଶୌଚାଳୟ ମରାମତି, ନବୀକରଣ ଏବଂ ରକ୍ଷଣାବେକ୍ଷଣକୁ ସରଳ କରିଥାଏ | ଶୌଚାଳୟ ସୁବିଧାଗୁଡ଼ିକରେ ରକ୍ଷଣାବେକ୍ଷଣ, ସ୍ୱଚ୍ଛତା ଏବଂ  ଭିତ୍ତିଭୂମି ଫାଟକୁ ଆକଳନ କରିବା ପାଇଁ ଏଥିରେ ସର୍ଭେ ଏବଂ ମ୍ୟାପିଂ ଉପକରଣ ଅନ୍ତର୍ଭୁକ୍ତ.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "USAID ସହଯୋଗରେ ଏହି ଉପକରଣକୁ ସହରୀ ପରିଚାଳନା କେନ୍ଦ୍ର (UMC) ଦ୍ୱାରା ବିକଶିତ କରାଯାଇଛି",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "ଏହି ଏପ୍ପଲିକେସନର  ଉଦ୍ଦେଶ୍ୟ ହେଉଛି SBM - MISAAL ପ୍ରକଳ୍ପ ଅଧୀନରେ ସର୍ବସାଧାରଣ ଏବଂ ଗୋଷ୍ଠୀ ଶୌଚାଳୟର ସର୍ଭେ ତଥ୍ୟ କ୍ୟାପଚର କରିବା ଏବଂ ଏହିପରି ଶୌଚାଳୟର କାର୍ଯ୍ୟ ପାଇଁ ବଜେଟ୍ ଆବଶ୍ୟକତା ଉପସ୍ଥାପନ କରିବା ପାଇଁ ଏହିପରି ତଥ୍ୟ ପ୍ରକ୍ରିୟାକରଣ କରିବା | ଏହା କରିବାବେଳେ ଆମେ ଆପଣଙ୍କର ବ୍ୟକ୍ତିଗତ ସୂଚନାକୁ ନାମ, ବୃତ୍ତି, ଇମେଲ୍, ଯୋଗାଯୋଗ ନମ୍ବର, ସହର କିମ୍ବା ଅନୁପ୍ରୟୋଗର ଉପଭୋକ୍ତା ସହିତ ଯୋଗାଯୋଗ କରିପାରିବୁ ।",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "ଏହି ପ୍ରକ୍ରିୟାରେ, ଆମେ ଆପଣଙ୍କୁ ନିଶ୍ଚିତ କରୁଛୁ ଯେ ଆପଣଙ୍କ ଗୋପନୀୟତା ରକ୍ଷା କରିବା ଆମ ପାଇଁ ଗୁରୁତ୍ୱପୂର୍ଣ୍ଣ | ଆପଣଙ୍କ ସମ୍ମତି ସହିତ କେବଳ ଏକ ଫର୍ମ ମାଧ୍ୟମରେ ଆପଣଙ୍କ ବ୍ୟକ୍ତିଗତ ସୂଚନା ଆମ ଦ୍ୱାରା ସଂଗ୍ରହ ହେବ | ଯେତେବେଳେ ଆପଣଙ୍କଠାରୁ କିଛି ସୂଚନା ସଂଗ୍ରହ ହେବ, ଆମେ ତଥ୍ୟ ଏବଂ ସିଷ୍ଟମ୍ ସୁରକ୍ଷା ପାଇଁ ଆମର ସର୍ଭରର ସିଷ୍ଟମ୍ ଲଗ୍ ରୁ ଅନ୍ୟ ତଥ୍ୟ ଯେପରିକି IP ଠିକଣା, ଡୋମେନ୍ ନାମ, ବ୍ରାଉଜର୍, ଅପରେଟିଂ ସିଷ୍ଟମ୍ ଇତ୍ୟାଦି ସଂଗ୍ରହ କରିପାରିବା ।",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "UMC ଆପଣଙ୍କୁ ନିଶ୍ଚିନ୍ତ କରେ ଯେ ଏହି ଅନୁପ୍ରୟୋଗ ମାଧ୍ୟମରେ ସଂଗୃହିତ ସୂଚନା କଠୋର ଭାବରେ ଗୋପନୀୟ ରହିବ ଏବଂ କୌଣସି  ପ୍ରକାରେ ତୃତୀୟ ପକ୍ଷକୁ ପ୍ରକାଶ କରାଯିବ ନାହିଁ ଏବଂ କେବଳ ଆପଣ ସହମତ ହୋଇଥିବା ଉପାୟରେ ବ୍ୟବହାର କରାଯିବ ।",
      },
    },
  },
};
