import { ChangeDetectorRef, Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import { FrontHomeService } from "../_services/fronthome.service";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { JobPostService } from "../_services/jobpost.service";
import { UserProfileService } from "../_services/userprofile.service";
import { GridOption } from "../_models/gridoptions.model";
import { CategoryService } from "src/app/_services/category.service";
import * as _ from "lodash";
import { Meta } from '@angular/platform-browser';
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../_services";
import { Subscription } from "rxjs";

@Component({
  selector: "app-fronthome",
  templateUrl: "./fronthome.component.html",
  styleUrls: ["./fronthome.component.scss"],
})
export class FronthomeComponent implements OnInit, OnDestroy {
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  dataArray: any;
  myId: any = "";
  searchBar: any = false;
  searchtext: any = "";
  selectedCategory: any = "";
  searchRadio: any = "1";
  hideshowclass: boolean = false;
  isLogin: boolean = false;
  jobPostArr: any = [];
  profileArr: any = [];
  CategoryArr: any = [];
  cmsImageUrl: any = environment.imgBucketUrl + "/cms/";
  // image = "gears.png";
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  currentUser: Subscription;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private jobPostService: JobPostService,
    private frontHomeService: FrontHomeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private categoryService: CategoryService,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private userProfileService: UserProfileService
  ) {
    this.meta.addTag({ name: 'title', content: 'Il portale dell’ingegneria: Ingeding' });
    this.meta.addTag({ name: 'keywords', content: 'portale ingegneria' });
    this.meta.addTag({ name: 'description', content: 'Scopri il primo portale in Italia dedicato a fare rete nel mondo dell’ingegneria. Ingeding - la piattaforma per dare e trovare lavoro nell’ingegneria.' });
    if (localStorage.getItem("currentUser")) {
      this.isLogin = true;
    }
    this.currentUser = this.authService.currentUser$.subscribe(async (data) => {
      this.myId = data ? data._id : "";
      this.getJobPost(this.myId);
      this.getProfiles(this.myId);
    });
    this.titleService.setTitle("Ingeding | Home");
  }

  ngOnInit() {
    if (this.selectedCategory.length == 0) {
      this.hideshowclass = true;
    }
    else {
      this.hideshowclass = false;
    }
    window.scrollTo(0, 0);
    this.getCategory();
    this.getData();
  }

  ngOnDestroy() {
    this.meta.removeTag('name=title');
    this.meta.removeTag('name=keywords');
    this.meta.removeTag('name=description');
    this.currentUser.unsubscribe();
  }

  redirectToJobpost(id: any) {
    if (this.isLogin) {
      window.open("view-job-post/" + id);
      //this.router.navigate(["/view-job-post/"+id]);
    } else {
      this.toastr.error(
        "Solo gli utenti registrati possono vedere annunci e profili. Effettua il login o registrati ora!",
        "Errore"
      );
    }
  }

  redirectToProfile(id: any) {
    if (this.isLogin) {
      window.open("view-profile/" + id);
      //this.router.navigate(["/view-profile/"+id]);
    } else {
      this.toastr.error(
        "Solo gli utenti registrati possono vedere annunci e profili. Effettua il login o registrati ora!",
        "Errore"
      );
    }
  }

  showSearchBar() {
    this.searchBar = true;
  }

  hideSearchBar() {
    var searchText = this.searchtext != "" ? this.searchtext.trim() : "";
    if (this.searchRadio == 1) {
      this.changeSearchbarState();
      if (this.selectedCategory.length != 0) {
        this.selectedCategory = this.selectedCategory;
        this.router.navigate([
          "/search-job-posts",
          this.selectedCategory,
          searchText,
        ]);
      } else {
        this.router.navigate([
          "/search-job-posts",
          this.selectedCategory,
          searchText,
        ]);
      }
    } else if (this.searchRadio == 2) {
      this.changeSearchbarState();
      if (this.selectedCategory.length != 0) {
        this.selectedCategory = this.selectedCategory;
        this.router.navigate([
          "/search-profiles",
          this.selectedCategory,
          searchText,
        ]);
      } else {
        this.router.navigate([
          "/search-profiles",
          this.selectedCategory,
          searchText,
        ]);
      }

    }
  }

  changeSearchbarState() {
    this.searchBar = false;
  }

  resetFilter() {
    this.searchRadio = "1";
    this.selectedCategory = this.CategoryArr[0]._id;
  }

  clearSearchbar() {
    this.searchtext = "";
  }

  getData() {
    this.frontHomeService.searchAll(this.gridOption).subscribe((res) => {
      this.dataArray = res.data;

      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getJobPost(currentUser: any) {
    const data = {
      currentUserId: currentUser
    }
    this.jobPostService.randomView(data).subscribe((res) => {
      this.jobPostArr = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getProfiles(currentUser: any) {
    const data = {
      currentUserId: currentUser
    }
    this.userProfileService.randomView(data).subscribe((res) => {
      this.profileArr = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  dateFilter(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  profileDateFilter(date: any = null) {
    if (date != null) {
      var myDate = new Date(date);
      var today = new Date();
      if (myDate > today) {
        const day = myDate.getDate();
        const month = myDate.getMonth() + 1;
        const year = myDate.getFullYear();
        return "Disponibile da " + day + "." + month + "." + year;
      } else {
        return "Disponibile da subito";
      }
    }
  }

  profileAlertLights(date: any = null) {
    if (date != null) {
      var myDate = new Date(date);
      var today = new Date();
      if (myDate > today) {
        return false;
      } else {
        return true;
      }
    }
  }

  getCategory() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.categoryService.searchAll(this.gridOption).subscribe((res) => {
      this.CategoryArr = _.orderBy(res.data, ["translations.title"], ["asc"]);
      // this.selectedCategory = this.CategoryArr[0]._id;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  selectThisCate(id) {
    this.hideshowclass = false;
    this.selectedCategory = id;
  }
  selectThisCate1() {
    this.hideshowclass = true;
    this.selectedCategory = '';
  }
}
