import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SubDetailsService extends CommonService {
  constructor(public http: HttpClient) {
    super("subscriptiondetail", http);
  }
  getSubDetails(data: any) {
    return this.http
      .get(this.baseUrl + "/fetchUserWiseSubscription/" + data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return null;
        })
      );
  }
  createSubscription(data: any) {
    return this.http.post(this.baseUrl + "/createnewSubscription", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  checkForFuturePlan(data: any) {
    return this.http
      .get(this.baseUrl + "/checkFutureSubscription/" + data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          return null;
        })
      );
  }
  checkSubStatus(data: any) {
    return this.http.get(this.baseUrl + "/checkSubscription/" + data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
