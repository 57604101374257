// USA

const projectname = "UMC";

export const locale = {
  lang: "gs",
  data: {
    UPDATE: "અપડેટ",
    LIST: "યાદી",
    HOME: "ઘર",
    ADD: "ઉમેરો",
    NAME: "નામ",
    ADSH: "Adsh",
    CIK: "સીઆઇકે",
    SIC: "એસઆઇસી",
    COUNTRY: "દેશ",
    STPRBA: "રાજ્ય",
    CITY: "શહેર",
    ZIP: "પિન કોડ",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "આઈડી",
    Usergroup: "વપરાશકર્તા જૂથ",
    QUESTION_TYPE: "પ્રશ્ન પ્રકાર",
    ANSWER_INPUT: "જવાબ ઇનપુટ",
    ANSWER_TYPE: "જવાબ પ્રકાર",
    ANSWER_OPTIONS: "જવાબ વિકલ્પો",
    QUESTION_TEXT: "પ્રશ્ન ટેક્સ્ટ",
    DESCRIPTION: "વર્ણન",
    FIRSTNAME: "પ્રથમ નામ",
    LASTNAME: "છેલ્લા નામ",
    EMAIL: "ઇમેઇલ",
    PHONE: "ફોન",
    COUNTRY_CODE: "દેશનો કોડ",
    REGIONS: "પ્રદેશો",
    STATES: "રાજ્ય",
    CITIES: "શહેર",
    LOCALE: "સ્થાનિક",
    COUNTRIES: "દેશો",
    VALUES: "મૂલ્યો",
    SETTINGTYPES: "સુયોજિત પ્રકારો",
    TRANSLATOR: {
      SELECT: "ભાષા પસંદ કરો",
    },
    MENU: {
      HOME: "ઘર",
      MAP: "નકશો",
      NEW: "નવું",
      ACTIONS: "ક્રિયાઓ",
      CREATE_POST: "નવી પોસ્ટ બનાવો",
      PAGES: "પાના",
      FEATURES: "વિશેષતા",
      APPS: "એપ્લિકેશન્સ",
      DASHBOARD: "ડેશબોર્ડ",
      ASSIGNMENT: "સોંપણી સૂચિ",
      USERS: "વપરાશકર્તાઓ",
      MASTERS: "માસ્ટર્સ",
      MODULES: "મોડ્યુલો",
      COMPANY: "કંપની",
      REGION: "પ્રદેશ",
      COUNTRY: "દેશ",
      STATE: "રાજ્ય",
      CITY: "શહેર",
      DISTRICT: "જીલ્લો",
      ZONE: "ઝોન",
      TAG: "ટેગ",
      LANGUAGE: "ભાષા",
      USERMANAGEMENT: "વપરાશકર્તા વ્યવસ્થાપન",
      USERGROUP: "વપરાશકર્તા જૂથ",
      PACKAGE: "પેકેજ",
      SEC: "એસ.ઈ.સી.",
      UMCSUCCESS: "યુએમસી સફળતા",
      PDF: "પી.ડી.એફ.",
      FIELDGROUPMAPPING: "ક્ષેત્ર જૂથ મેપિંગ",
      STATEMENTTYPENAME: "વિધાન પ્રકાર",
      FIELDMAPPING: "ફીલ્ડ મેપિંગ",
      QUESTIONNAIRETOOL: "પ્રશ્નાવલી સાધન",
      UMCSETTING: "વૈશ્વિક સેટિંગ",
      GLOBALSETTING: "વૈશ્વિક સેટિંગ",
      SETTING: "સેટિંગ",
      SETTINGTYPE: "સેટિંગ પ્રકાર",
      // kalu add
      QUESTIONS: "પ્રશ્નો",
    },
    AUTH: {
      GENERAL: {
        OR: "અથવા",
        SUBMIT_BUTTON: "સબમિટ કરો",
        NO_ACCOUNT: "તમારું ખાતું નથી?",
        SIGNUP_BUTTON: "સાઇન અપ કરો",
        FORGOT_BUTTON: "પાસવર્ડ ભૂલી ગયા છો?",
        BACK_BUTTON: "પાછળ",
        PRIVACY: "ગોપનીયતા",
        LEGAL: "કાયદેસર",
        CONTACT: "સંપર્ક કરો",
      },
      LOGIN: {
        TITLE: "પ્રવેશ કરો",
        BUTTON: "સાઇન ઇન",
        SUCCESSMESSAGE: "સફળતાપૂર્વક પ્રવેશ કરો",
      },
      FORGOT: {
        TITLE: "ભૂલી ગયા પાસવર્ડ",
        DESC: "તમારો પાસવર્ડ ફરીથી સેટ કરવા માટે તમારું ઇમેઇલ દાખલ કરો",
        SUCCESS:
          "લિંક તમારા રજિસ્ટર્ડ ઇમેઇલ પર મોકલવામાં આવી છે જેનો ઉપયોગ કરીને તમે તમારો પાસવર્ડ ફરીથી સેટ કરી શકો છો.",
      },
      SETPASSWORD: {
        TITLE: "પાસવર્ડ સેટ કરો",
        BUTTON: "ફરીથી સેટ કરો",
        SUCCESS: "પાસવર્ડ સફળતાપૂર્વક બદલાઈ ગયો છે",
      },
      REGISTER: {
        TITLE: "સાઇન અપ કરો",
        DESC: "તમારું એકાઉન્ટ બનાવવા માટે તમારી વિગતો દાખલ કરો",
        SUCCESS: "તમારું એકાઉન્ટ સફળતાપૂર્વક નોંધાયેલું છે.",
      },
      INPUT: {
        EMAIL: "ઇમેઇલ",
        FULLNAME: "પૂરું નામ",
        PASSWORD: "પાસવર્ડ",
        CONFIRM_PASSWORD: "પાસવર્ડની પુષ્ટિ કરો",
        USERNAME: "વપરાશકર્તા નામ",
        TITLE: "શીર્ષક દાખલ કરો",
        REMEMBER_ME: "મને યાદ રાખો",
      },
      VALIDATION: {
        INVALID: "{{name}} માન્ય નથી",
        REQUIRED: "{{name}} જરૂરી છે",
        MIN_LENGTH: "{{name}} લઘુત્તમ લંબાઈ છે {{min}}",
        AGREEMENT_REQUIRED: "નિયમો અને શરતો સ્વીકારવી જરૂરી છે",
        NOT_FOUND: "વિનંતી {{name}} મળ્યું નથી",
        INVALID_LOGIN: "આ પ્રવેશ વિગતવાર ખોટું છે",
        REQUIRED_FIELD: "આવશ્યક ક્ષેત્ર",
        MIN_LENGTH_FIELD: "ન્યૂનતમ ક્ષેત્ર લંબાઈ:",
        MAX_LENGTH_FIELD: "મહત્તમ ક્ષેત્રની લંબાઈ:",
        INVALID_FIELD: "ક્ષેત્ર માન્ય નથી",
        MUST_MATCH: "પાસવર્ડ અને પુષ્ટિ પાસવર્ડ મેચ કરવો આવશ્યક છે",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: "નકશો દૃશ્ય",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " |ભાષા સૂચિ ",
        ADDTITLE: projectname + " |ભાષા ઉમેરો ",
        EDITTITLE: projectname + " |ભાષા અપડેટ ",
        LANGUAGELIST: "ભાષાઓની સૂચિ",
        LANGUAGENAMEPLURAL: "ભાષાઓ",
        LANGUAGENAMESINGULAR: "ભાષા",
        NEWLANGUAGE: "નવી ભાષા",
        DELETEDSELECTED: "પસંદ કરેલી ભાષાઓ કાઢી નાખો",
        UPDATESELECTED: "પસંદ કરેલી ભાષાઓ માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવી ભાષા ઉમેરો",
        UPDATE: "અપડેટ લેંગ્વેજ",
        CREATEMESSAGE: "ભાષા સફળતાપૂર્વક બનાવો",
        UPDATEMESSAGE: "ભાષા સફળતાપૂર્વક અપડેટ કરો",
        EDIT: "ભાષા સંપાદિત કરો",
        ADD: "ભાષા ઉમેરો",
        LOCALE: "સ્થાનિક",
        LOCALE_REQUIRED: "લોકેલ આવશ્યક છે",
        LOCALE_INVALID: "લોકેલમાં અમાન્ય અક્ષરો છે",
      },
      USERS: {
        LISTINGTITLE: projectname + " |વપરાશકર્તા સૂચિ ",
        USERSLIST: "વપરાશકર્તાઓની સૂચિ",
        NEWUSER: "નવા વપરાશકર્તા",
        USERNAMEPLURAL: "વપરાશકર્તાઓ",
        USERNAMESINGULAR: "વપરાશકર્તા",
        DELETEDSELECTED: "પસંદ કરેલા વપરાશકર્તાઓને કાઢી નાખો",
        UPDATESELECTED: "પસંદ કરેલા વપરાશકર્તાઓ માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવો વપરાશકર્તા ઉમેરો",
        UPDATE: "અપડેટ યુઝર",
        PASSWORD: "પાસવર્ડ",
        CONFIRMPASSWORD: "પાસવર્ડની પુષ્ટિ કરો",
        USERSUCCESS: "વપરાશકર્તા સફળતાપૂર્વક ઉમેર્યા",
        FILTERUSERGROUP: "ફિલ્ટર વપરાશકર્તા જૂથ",
        ADDTITLE: projectname + " |વપરાશકર્તાઓ ઉમેરો ",
        EDITTITLE: projectname + " |વપરાશકર્તાઓ અપડેટ કરો ",
        EDIT: "એડિટ યુઝર",
        ADD: "વપરાશકર્તા ઉમેરો",
        GOVT: "સરકાર",
        OTHERGOVT: "સરકાર સિવાય",
        GOVTEMP: "સરકારી નોકર",
        NGO: "બિન-સરકારી સંગઠન (એનજીઓ)",
        PVT: "ખાનગી સંસ્થા",
        CONSTENT: "સલાહકાર",
        SHG: "સેલ્ફ હેલ્પ ગ્રુપ (એસએચજી)",
        ACADEMIC: "શિક્ષણવિદો",
        OCCUPATION: "વ્યવસાય",
        ORGANIZTION: "સંસ્થા",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " |વપરાશકર્તા જૂથ સૂચિ ",
        USERGROUPLIST: "યુઝર ગ્રુપ લિસ્ટ",
        USERGROUPNAMEPLURAL: "વપરાશકર્તા જૂથો",
        USERGROUPNAMESINGULAR: "વપરાશકર્તા જૂથ",
        NEWUSERGROUP: "નવું યુઝર ગ્રુપ",
        DELETEDSELECTED: "પસંદ કરેલા વપરાશકર્તા જૂથો કાઢી નાખો",
        UPDATESELECTED: "પસંદ કરેલા વપરાશકર્તા જૂથો માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવું વપરાશકર્તા જૂથ ઉમેરો",
        UPDATE: "અપડેટ યુઝર ગ્રુપ",
        ADDTITLE: projectname + " |વપરાશકર્તા જૂથ ઉમેરો ",
        EDITTITLE: projectname + " |વપરાશકર્તા જૂથ અપડેટ કરો ",
      },
      QUESTION: {
        LISTINGTITLE: projectname + " | પ્રશ્ન સૂચિ",
        ADDTITLE: projectname + " | પ્રશ્ન ઉમેરો",
        EDITTITLE: projectname + " | પ્રશ્ન સુધારો",
        QUESTIONLIST: "પ્રશ્નોની સૂચિ",
        QUESTIONNAMEPLURAL: "પ્રશ્નો",
        QUESTIONNAMESINGULAR: "પ્રશ્ન",
        NEWQUESTION: "નવો પ્રશ્ન",
        DELETEDSELECTED: "પસંદ કરેલા પ્રશ્નો કાઢી નાખો",
        UPDATESELECTED: "પસંદ કરેલા પ્રશ્નો માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવો પ્રશ્ન ઉમેરો",
        UPDATE: "અપડેટ પ્રશ્ન",
        CREATEMESSAGE: "સફળતાપૂર્વક પ્રશ્ન બનાવો",
        UPDATEMESSAGE: "પ્રશ્ન સફળતાપૂર્વક અપડેટ કરો",
        EDIT: "પ્રશ્ન સંપાદિત કરો",
        ADD: "પ્રશ્ન ઉમેરો",
        SINGLE_CHOICE: "સિંગલ ચોઇસ",
        MULTIPLE_CHOICE: "બહુવૈીકલ્પિક",
        QUESTION_IMAGES: "પ્રશ્ન છબીઓ",
        ANSWER_LIMIT: "જવાબ મર્યાદા",
        CHOICE: "ચોઇસ",
        NUMERIC: "આંકડાકીય",
        DATE: "તારીખ",
        UNIQUEID: "અનન્ય આઈડી",
        TEXT: "ટેક્સ્ટ",
        IMAGE: "છબી",
        BOTH: "બંને",
        MULTIPLE_ANSWER: "બહુવિધ જવાબ",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: "મલ્ટીપલ લેબલ મલ્ટીપલ જવાબ",
        ANSWER_INPUT: "જવાબ ઇનપુટ",
        ATTACH_FILES: "ફાઇલો જોડો",
        QUESTION_TEXT: "પ્રશ્ન ટેક્સ્ટ",
        ANSWER_TYPE: "જવાબ પ્રકાર",
        ANSWER_IMAGE: "જવાબ છબી",
        MIN_RANGE: "નાની રેન્જ",
        MAX_RANGE: "મોટામોમટી રેન્જ",
        ANSWER: "જવાબ",
        QUESTION: "પ્રશ્ન",
        SEQUENCE: "સિક્વન્સ",
        ENTER_SEQUENCE: "કૃપા કરી ક્રમ દાખલ કરો",
        INVALID_SEQUENCE: "અમાન્ય ક્રમ",
        ADD_OPTION: "ઉમેરો",
        ADD_MAXIMUM_OF: "તમે વધુમાં વધુ ઉમેરી શકો",
        ENTER_ANSWER: "મહેરબાની કરીને જવાબ દાખલ કરો",
        INVALID_ANSWER: "અમાન્ય જવાબ",
        EXCLUSIVE_RESPONSE: "પરસ્પર એક્સક્લુઝિવ રિસ્પોન્સ",
        ENTER_MAXIMUM_RANGE: "કૃપા કરીને મહત્તમ શ્રેણી દાખલ કરો",
        Maximum_number_minimum_number:
          "મહત્તમ સંખ્યા લઘુત્તમ સંખ્યા કરતા ઓછી નથી",
        ENTER_MINIMUM_RANGE: "કૃપા કરી ન્યૂનતમ શ્રેણી દાખલ કરો",
        INVALID_RANGE: "અમાન્ય શ્રેણી (આંકડાકીય મૂલ્ય)",
        PLEASEENTERQUESTION: "કૃપા કરી પ્રશ્ન દાખલ કરો",
        INVALID_QUESTION: "અમાન્ય પ્રશ્ન",
        QUESTIONMUSTBEATLEAST:
          "પ્રશ્ન ઓછામાં ઓછો 3 અક્ષરો લાંબુ હોવો જોઈએ અને જગ્યાની મંજૂરી નથી",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          "મહત્તમ અપલોડ કદ 5MB છે. માન્ય ફાઇલ પ્રકારો છે jpg, png, jpeg. તમે મહત્તમ પ્રશ્ન છબી અપલોડ કરી શકો છો કદ 228px * 200px હોવું આવશ્યક છે",
        MAX_UPLOAD_SIZE_ADD:
          "મહત્તમ અપલોડ કદ 5MB છે. માન્ય ફાઇલ પ્રકારો છે jpg, png, jpeg. છબીનું કદ 228px * 200px હોવું જોઈએ",
        REVISION_FOR_THIS_QUESTION:
          "શું તમે આ પ્રશ્ન માટે સંશોધન બનાવવા માંગો છો?",
        CREATEMAPPINGMESSAGE: "સવાલ મેપિંગ સફળતાપૂર્વક બનાવો",
        UPDATEMAPPINGMESSAGE: "પ્રશ્ન મેપિંગને સફળતાપૂર્વક અપડેટ કરો",
        DELETEMAPPINGMESSAGE: "પ્રશ્ન મેપિંગને સફળતાપૂર્વક કા Deleteી નાખો",
        UPLOAD_ONLY: "તમે ફક્ત અપલોડ કરી શકો છો",
        CONDITIONISMAPPED: "શરત મેપ થયેલ હોવાથી તમે ઓર્ડર બદલી શકતા નથી!!",
      },
      MENU: {
        ADDNEWMENU: "નવું મેનુ ઉમેરો",
        LISTINGTITLE: projectname + " | મેનુ સૂચિ",
        ADDTITLE: projectname + " | મેનુ ઉમેરો",
        EDITTITLE: projectname + " | મેનુ અપડેટ",
        MENULIST: "મેનૂ સૂચિ",
        MENUNAMEPLURAL: "મેનુ",
        MENUNAMESINGULAR: "મેનુ",
        NEWMENU: "નવું મેનૂ",
        DELETEDSELECTED: "પસંદ કરેલા મેનૂઝ કા Deleteી નાખો",
        UPDATESELECTED: "પસંદ કરેલા મેનૂઝ માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવું મેનૂ ઉમેરો",
        UPDATE: "અપડેટ મેનૂ",
        CREATEMESSAGE: "મેનુ સફળતાપૂર્વક બનાવો",
        UPDATEMESSAGE: "મેનુ સફળતાપૂર્વક અપડેટ કરો",
        EDIT: "મેનુ સંપાદિત કરો",
        ADD: "મેનુ ઉમેરો",
        VALUES: "મૂલ્યો",
        PARENT: "પેરેંટ",
        MODULE: "મોડ્યુલ",
        PAGE: "પૃષ્ઠ",
        ICON: "ચિહ્ન",
        ORDERING: "ઓર્ડરિંગ",
        VALIDATION: {
          SELECTTYPE: "કૃપા કરી મેનુ પ્રકાર પસંદ કરો",
        },
      },
      COMMON: {
        DATE: "તારીખ",
        GOTO: "પર જાઓ",
        UNIQUEID: "અનન્ય આઈડી",
        COMPLETED: "પૂર્ણ",
        INPROGRESS: "પ્રગતિમા",
        CITYLIST: "શહેરોની સૂચિ",
        COUNT: "સર્વે ગણતરી",
        LOGOUT: "લૉગ આઉટ",
        HOME: "ઘર",
        ZONE: "ઝોન",
        WARD: "વોર્ડ",
        PUBLICCONVENIENCE: "જાહેર સગવડ ",
        ALL: "બધા",
        ACTIVE: "સક્રિય",
        INACTIVE: "નિષ્ક્રિય",
        PLEASESELECT: "કૃપા કરીને પસંદ કરો",
        BASICINFORMATION: "મૂળભૂત માહિતી",
        COMPANYMANAGEMENT: "કંપની મેનેજમેન્ટ",
        COMPANYREGISTEARS: "કંપની રજિસ્ટ્રાર્સ",
        CREATEDAT: "બનાવેલી તારીખ",
        STATUS: "સ્થિતિ",
        ACTION: "એક્શન",
        DELETE: "કાઢી નાંખો",
        TITLE: "શીર્ષક",
        VIEW: "જુઓ",
        UPDATE: "સંપાદિત કરો",
        PARENT: "પેરેંટ",
        PIC: "પીક",
        SEARCHBTN: "શોધ",
        FIRSTNAME: "પ્રથમ નામ",
        FIRSTNAMEREQ: "પ્રથમ નામ આવશ્યક છે",
        FIRSTNAMEERROR: "પ્રથમ નામ અમાન્ય છે",
        NAME: "નામ",
        LASTNAME: "છેલ્લું નામ",
        LASTNAMEREQ: "છેલ્લું નામ આવશ્યક છે",
        LASTNAMEERROR: "છેલ્લું નામ અમાન્ય છે",
        EMAIL: "ઇમેઇલ",
        EMAILREQ: "ઇમેઇલ આવશ્યક છે",
        EMAILERROR: "ઇમેઇલ અમાન્ય છે",
        WEBSITE: "વેબસાઇટ",
        WEBSITEREQ: "વેબસાઇટ આવશ્યક છે",
        WEBSITEERROR: "વેબસાઇટ અમાન્ય છે",
        FAX: "ફેક્સ",
        FAXERROR: "ફેક્સ અમાન્ય છે",
        ADDRESS: "સરનામું",
        MOBILENUMBER: "મોબાઇલ નંબર",
        DATEOFBIRTH: "જન્મ તારીખ",
        DATEOFBIRTHERROR: "જન્મ તારીખ આવશ્યક છે.",
        SAVEBTN: "સેવ",
        SUBMITBTN: "સબમિટ કરો ",
        SEARCH: "શોધ",
        BACKBTN: "પાછળ",
        UPDATEBTN: "અપડેટ",
        RESETBTN: "રીસેટ",
        CANCELBTN: "રદ કરો",
        REPORT: "અહેવાલ",
        CITY: "સિટી",
        COUNTRY: "દેશ",
        CODE: "કોડ",
        SELECTCOUNTRY: "દેશ પસંદ કરો",
        SELECTREGION: "પસંદ કરો પ્રદેશ",
        SELECTSTATE: "રાજ્ય પસંદ કરો",
        SELECTCITY: "શહેર પસંદ કરો",
        SELECTZONE: "ઝોન પસંદ કરો",
        SELECTWARD: "વોર્ડ પસંદ કરો",
        STATE: "રાજ્ય",
        PIN: "પિન",
        ZIPCODE: "પિન કોડ",
        NORECORDS: "કોઈ રેકોર્ડ (ઓ) મળ્યો નથી",
        DELETEALL: "બધું કાઢી નાંખો",
        UPDATESTATUS: "અપડેટ સ્ટેટસ",
        DESCRIPTION: "વર્ણન",
        DESIGNATION: "હોદ્દો",
        ENTERDESCRIPTION: "મહેરબાની કરીને વર્ણન દાખલ કરો",
        STATUSUPDATED: "સ્થિતિ સફળતાપૂર્વક અપડેટ કરવામાં આવી છે",
        SUCCESS: "સફળતાપૂર્વક",
        SELECTED: "પસંદ કરેલું",
        STATUSSELECTEDUPDATE: "સ્થિતિ પસંદ કરવા માટે અપડેટ કરવામાં આવી છે",
        PERMANTLYDELETE: "શું તમને ખાતરી છે કે આ કાયમીરૂપે કાઢી નાખશે",
        DELETETEXT:
          "શું તમે ખરેખર આ રેકોર્ડને કાયમી ધોરણે કાઢી નાખવા માંગો છો?",
        ISDELETING: "ડિલીટ કરી રહ્યું છે",
        DELETESELECTED: "પસંદ કરેલા કાઢી નાંખો",
        UPDATESELECTED: "અપડેટ સ્ટેટસ ફોર સિલેક્ટેડ",
        AMOUNT: "રકમ",
        TIME: "સમય",
        NEXT: "આગળ",
        PREVIOUS: "અગાઉના",
        VALUE: "કિંમત",
        USERGROUP: "યુઝર ગ્રુપ",
        SELECT: "પસંદ કરો",
        VERSION: "સંસ્કરણ",
        TYPE_THREE_LETTERS: "ત્રણ અક્ષરો લખો અને થોડો સમય રાહ જુઓ",
        USERGROUPREQUIRED: "વપરાશકર્તા જૂથ આવશ્યક છે",
        PERMISSION: "પરવાનગી",
        LABELSEARCH: "અહીં ટાઇપ લેબલ",
        SELECTANYONE: "કોઈપણ પસંદ કરો",
        IMAGEERROR: "મહેરબાની કરીને છબી અપલોડ કરો ",
        TEXTERROR: "મહેરબાની કરીને ટેક્સ્ટનો ઉલ્લેખ કરો",
        TEXTINVALIDE: "ટેક્સ્ટમાં અમાન્ય અક્ષરો છે",
        NUMBERERROR: "મહેરબાની કરીને અક્ષરોનો ઉલ્લેખ કરો",
        PASSWORDHINT:
          "8-10 અક્ષરો, ઓછામાં ઓછું એક સંખ્યા, ઓછામાં ઓછું એક અપર કેરેક્ટર, ઓછામાં ઓછું એક લોઅર કેરેક્ટર, ઓછામાં ઓછું એક સ્પેશ્યલ કેરેક્ટર સમાવે છે.",
        OOPS: "અરેરે!",
        ERROR: "ભૂલ!",
        HELLO: "હાય!",
        WELCOMEBACK: "પાછા સ્વાગત છે",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          "એવું લાગે છે કે તમે અગાઉની ભાગીદારી પૂર્ણ કરી શક્યા નથી. તમે જ્યાંથી ગયા ત્યાંથી ચાલુ રાખવા માંગો છો અથવા તમે નવો સર્વેક્ષણ પ્રશ્ન શરૂ કરવા માંગો છો?",
        CONTINUEWITH: "સાથે ચાલુ રાખો",
        OR: "અથવા",
        PREVIOUSQUESTION: "પાછલો પ્રશ્ન",
        FRESHQUESTION: "નવો પ્રશ્ન",
        CONFIRM: "પુષ્ટિ કરો",
        AREYOUSURE: "શું તમને ખાતરી છે કે પહેલા શરૂઆત થશે?",
        CONFIRMIMAGE: "શું તમે ખરેખર આ છબી કાઢી નાખવા માંગો છો?",
        YES: "હા",
        NO: "ના",
        CONFIRMCONVENIENCE: "શું તમને ખાતરી છે કે જાહેર સગવડ નામ બદલાશે?",
        TERMSOFUSE: "વાપરવાના નિયમો",
        PRIVACYPOLICY: "ગોપનીયતા નીતિ",
        FORHELPCONTACT: "મદદ માટે સંપર્ક કરો",
        CLICKFORANEWSURVEY: "નવો સર્વે શરૂ કરવા ક્લિક કરો",
        VALIDATION: {
          INVALID_IMAGE:
            "તમે અમાન્ય ફાઇલ પ્રકાર અપલોડ કર્યો છે અથવા મહત્તમ અપલોડ ફાઇલ કદ: 5 એમબી!",
          NAME_INVALID: "નામમાં અમાન્ય અક્ષરો છે",
          NAME_REQUIRED: "કૃપા કરી નામ સ્પષ્ટ કરો",
          NAME_MIN_LENGTH: "નામ ઓછામાં ઓછું 2 અક્ષરો લાંબું હોવું જોઈએ.",
          NAME_MAX_LENGTH: "characters૦ અક્ષરોથી વધુ ન હોઈ શકે.",
          ZIP_REQUIRED: "ઝિપકોડ આવશ્યક છે",
          ZIP_INVALID: "ઝિપકોડ અમાન્ય છે",
          CITY_REQUIRED: "શહેર જરૂરી છે",
          STATE_REQUIRED: "રાજ્ય જરૂરી છે",
          REGION_REQUIRED: "પ્રદેશ જરૂરી છે",
          COUNTRY_REQUIRED: "દેશ જરૂરી છે",
          VALUE_REQUIRED: "કૃપા કરી કિંમત સ્પષ્ટ કરો",
          TITLE_INVALID: "શીર્ષકમાં અમાન્ય અક્ષરો છે",
          TITLE_REQUIRED: "મહેરબાની કરીને શીર્ષક સ્પષ્ટ કરો",
          TITLE_MIN_LENGTH: "શીર્ષક ઓછામાં ઓછું 2 અક્ષરો લાંબું હોવું જોઈએ.",
          TITLE_MAX_LENGTH: "characters૦ અક્ષરોથી વધુ ન હોઈ શકે.",
          PIN_REQUIRED: "કૃપા કરીને પિન સ્પષ્ટ કરો",
          PIN_REQUIRED_MIN_LENGTH:
            "પિન ઓછામાં ઓછું 5 અક્ષરો લાંબું હોવું જોઈએ.",
          PIN_REQUIRED_MAX_LENGTH: "6 અક્ષરોથી વધુ ન હોઈ શકે.",
        },
        PATTERN: {
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
        },
      },
      PROFILE: {
        NAME: "મારી પ્રોફાઈલ",
        LISTINGTITLE: projectname + " |મારી પ્રોફાઈલ",
        PROFILESUCCESS: "પ્રોફાઇલ સફળતાપૂર્વક અપડેટ થઈ !!",
        INVALID_IMAGE:
          "તમે અમાન્ય ફાઇલ પ્રકાર અપલોડ કર્યો છે અથવા મહત્તમ અપલોડ ફાઇલ કદ: 5 એમબી!",
        PROFILE_IMAGE: "પ્રોફાઇલ છબી",
        BASIC_INFO: "મૂળભૂત માહિતી",
        PHONE: "સંપર્ક નં.",
        PHONEREQ: "સંપર્ક નંબર આવશ્યક છે",
        PHONEERROR: "સંપર્ક નંબર અમાન્ય છે",
        ADDRESS: "સરનામું દાખલ કરો",
        ADDRESSTAB: "સરનામું",
        CHANGEPASSWORD: "પાસવર્ડ બદલો",
        RESIDENTIALADDREDSS: "રહેણાંક સરનામું",
        PASSWORDNOTMATCH: "ઓહ ત્વરિત! પાસવર્ડ મેળ ખાતો નથી. ",
        PASSWORDREQUIRED: "પાસવર્ડ અનિવાર્ય છે",
        OLDPASSWORDREQUIRED: "જૂનો પાસવર્ડ આવશ્યક છે",
        ENTEROLDPASSWORD: "જુનો પાસવર્ડ",
        NEWPASSWORDREQUIRED: "નવો પાસવર્ડ આવશ્યક છે",
        ENTERNEWPASSWORD: "નવો પાસવર્ડ",
        INVALIDNEWPASSWORD: "નવો પાસવર્ડ માન્ય નથી",
        INVALIDPASSWORD: "પાસવર્ડ માન્ય નથી",
        NEWPASSWORDSAME: "નવો પાસવર્ડ ઓલ્ડ પાસવર્ડ જેવો ન હોવો જોઈએ",
        CONFIRMPASSWORD: "પાસવર્ડની પુષ્ટિ કરો",
        CONFIRMPASSWORDREQUIRED: "પુષ્ટિ પાસવર્ડ આવશ્યક છે",
        CONFIRMPASSWORDSAME:
          "નવો પાસવર્ડ અને પુષ્ટિ પાસવર્ડ મૂલ્યો મેળ ખાતા નથી.",
        INVALIDOLDPASSWORD: "જૂનો પાસવર્ડ માન્ય નથી",
        CREATEUSERGROUP: "સફળતાપૂર્વક વપરાશકર્તા જૂથ બનાવો",
        UPDATEUSERGROUP: "સફળતાપૂર્વક વપરાશકર્તા જૂથને અપડેટ કરો",
      },
      LOGIN: {
        WELCOME: "સેનિટેશન મેપિંગ પર આપનું સ્વાગત છે",
        LONGTEXT:
          "આ સાધન તમને સ્વચ્છતાના સંદર્ભમાં જાહેર અને સમુદાયના શૌચાલયોની હાલની સ્થિતિને સમજવામાં મદદ કરશે અને તમે શૌચાલય સુવિધાઓમાં ક્યુબિકલ-ઇન્ફ્રાસ્ટ્રક્ચર અને સ્વચ્છતાના અંતરનું મૂલ્યાંકન કરી શકો છો.",
        EMAIL: "ઇમેઇલ સરનામું / ફોન નંબર",
        EMAIL_VALIDATION: "કૃપા કરીને ઇમેઇલ અથવા મોબાઇલ નંબર દાખલ કરો.",
        EMAIL_PATTERN:
          "ખોટું ફોર્મેટ, તે માન્ય ઇમેઇલ અથવા 10 અંકનો મોબાઇલ નંબર હોવો જોઈએ.",
        ACCOUNT: "એક એકાઉન્ટ બનાવવાની જરૂર છે?",
        REGISTER: "નોંધણી કરો",
        OTP: "ઓટીપી મોકલો",
        ENTER_OTP: "ઓટીપી દાખલ કરો",
        ONE_TIME_PASS: "વન ટાઇમ પાસવર્ડ",
        OTP_VALIDATION: "મહેરબાની કરીને ઓટીપી દાખલ કરો ...!",
        RESEND_OTP: "ઓટીપી ને ફરીથી મોકલો",
        OTP_RESEND: "ઓટીપી ફરીથી મોકલો",
        NOT_GET_OTP: "ઓટીપી નથી મળતું?",
      },
      SURVEY: {
        LISTINGTITLE: projectname + " | સર્વે સૂચિઓ ",
        SURVEYLIST: "સર્વે સૂચિઓ ",
        NEWSURVEY: "ન્યુ સર્વે",
        SURVEYORNAME: "સર્વેયરનું નામ",
        SURVEYNAMEPLURAL: "સર્વે",
        SURVEYNAMESINGULAR: "સર્વે",
        DELETEDSELECTED: "પસંદ કરેલા સર્વેયરને દૂર કરો",
        UPDATESELECTED: "પસંદ કરેલા સર્વેયર માટે સ્થિતિ અપડેટ કરો",
        ADDNEW: "નવો સર્વે ઉમેરો",
        UPDATE: "અપડેટ સર્વે",
        SURVEYSUCCESS: "સર્વે સફળતાપૂર્વક ઉમેર્યો",
        SURVEYQUESTIONSAVE: "સફળતાપૂર્વક ઉમેર્યો",
        SURVEYQUESTIONSUBMIT: "સફળતાપૂર્વક સબમિટ",
        FILTERSURVEYGROUP: "ફિલ્ટર સર્વે ગ્રુપ",
        ADDTITLE: projectname + " | સર્વે ઉમેરો",
        EDITTITLE: projectname + " | સર્વે અપડેટ",
        EDIT: "સર્વે સંપાદિત કરો",
        ADD: "નવો સર્વે શરૂ કરો",
        SRNO: "શ્રી ન",
        SURVEYQUESTIONTITLE: "જાહેર સવલતોનો સર્વે",
        SURVEYBY: "સાથની ટીમ દ્વારા સર્વેક્ષણ માટેનું ફોર્મ ",
        VALIDATIONTEXT: "ચિન્હિત પ્રશ્નો ફરજિયાત છે.",
        NOTE: "નૉૅધ",
        SURVEYTEXT:
          "ઝોન અને વોર્ડ ભરવા માટે ફરજિયાત નથી. જો તમને આ માહિતી ખબર હોય તો, કૃપા કરીને કોઈ યોગ્ય વિકલ્પ પસંદ કરો.",
        PUBLICCONVENIENCENOTE:
          "જો શૌચાલયનું સ્થાન અલગ હોય તો ડ્રોપડાઉનમાંથી યોગ્ય સ્થાન પસંદ કરો",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "સેનિટેશન મેપિંગ પર આપનું સ્વાગત છે",
        TERMSOFUSE: "વાપરવાના નિયમો",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "સેનિટેશન મેપિંગ એ અર્બન લોકલ બોડીઝ માટે એક વ્યાપક સાધન છે. તે સ્વચ્છ ભારત મિશન-અર્બન હેઠળ રચાયેલ ગૃહ અને શહેરી બાબતોના મંત્રાલયના ઓડીએફ + પ્રોટોકોલોના પાલનને સક્ષમ બનાવવા માટે જાહેર અને સમુદાયના શૌચાલયની સમારકામ, નવીનીકરણ અને જાળવણીને સરળ બનાવે છે. તેમાં શૌચાલય સુવિધાઓમાં જાળવણી, સ્વચ્છતા અને સીટ વાઇઝ ઇન્ફ્રાસ્ટ્રક્ચર ગાબડાઓને આકારણી માટે સર્વેક્ષણો અને મેપિંગ ટૂલ્સ છે.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "આ ટૂલ યુએસએઆઇડીના સમર્થનથી અર્બન મેનેજમેન્ટ સેન્ટર (યુએમસી) દ્વારા વિકસાવવામાં આવ્યું છે.",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "સેવાની શરતો આ એપ્લિકેશન અને તેની સેવાઓનો તમારો ઉપયોગ સંચાલિત કરે છે. કૃપા કરીને એપ્લિકેશનનો ઉપયોગ કરતા પહેલા આ નિયમો અને શરતો કાળજીપૂર્વક વાંચો. એપ્લિકેશન અથવા સેવાઓનો ઉપયોગ કરીને, તમે આ નિયમોમાં ઉલ્લેખિત નીતિઓ સહિત અમારી શરતો અને શરતો દ્વારા બંધાયેલા છો તે માટે તમે સંમત થાઓ છો. આ નિયમો અને શરતોમાં સમય-સમય પર ફેરફાર થઈ શકે છે.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "જ્યારે અર્બન મેનેજમેન્ટ સેન્ટર (UMC) એપ્લિકેશનની સામગ્રી સચોટ અને અદ્યતન છે તેની ખાતરી કરવા માટે તમામ પ્રયત્નો કરે છે, પરંતુ તેની વિશ્વસનીયતા, ચોકસાઈ અથવા માહિતીની સંપૂર્ણતા માટે કોઈપણ ફોરમમાં અથવા તેના સંદર્ભમાં ઉલ્લેખ કરવાની ખાતરી આપતી નથી. UMC પાસે આ એપ્લિકેશનમાં સમાવિષ્ટ માહિતીના કોઈપણ ભાગની પૂર્વાહિત માહિતીને કાઢી નાખવા અથવા અપડેટ કરવાનો અધિકાર છે અને કોઈપણ પ્રકારની પૂર્વ સૂચના વિના તેનો અધિકાર છે. આ એપ્લિકેશન દ્વારા એકત્રિત કરવામાં આવતી સામગ્રી અને માહિતી ફક્ત તેના માટે છે",
        TEXT4: "માત્ર માહિતી અને શિક્ષણ હેતુ",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "આ એપ્લિકેશનમાં સમાવિષ્ટ બધી સામગ્રી, માહિતી, જેમાં ફોટોગ્રાફ્સ, અહેવાલો અને ટેક્સ્ટનો સમાવેશ થાય છે તે UMC જૂથની મિલકત છે અને તેથી ભારતીય કૉપિરાઇટના કાયદા હેઠળ સુરક્ષિત છે. અમારી સેવાઓનો ઉપયોગ તમને અમારી સેવાઓ અથવા તમે ઍક્સેસ કરી શકો છો તે સામગ્રીના કોઈપણ બૌદ્ધિક સંપત્તિ હકોની માલિકી આપતું નથી.",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "જાહેર કરેલી વિશિષ્ટ વિતરણ માટે અથવા વ્યાવસાયિક રૂપે વિતરણ કરવા માટે આ એપ્લિકેશનમાંથી સામગ્રીને ડાઉનલોડ કરવા, કોપી કરવા, પુનrodઉત્પાદન, સુધારણા અને ફરીથી પ્રકાશિત કરવા માટે, પ્રકાશિત થયેલ વિશિષ્ટ સામગ્રી / માહિતી માટે અન્યથા સ્પષ્ટ કરેલી અથવા સૂચિત સિવાય કૉપિરાઇટના અને માલિકના માલિકીના અધિકારનું ઉલ્લંઘન થશે - UMC.",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "સેનિટેશન મેપિંગ પર આપનું સ્વાગત છે",
        PRIVACYPOLICY: "ગોપનીયતા નીતિ",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "સેનિટેશન મેપિંગ એ અર્બન લોકલ બોડીઝ માટે એક વ્યાપક સાધન છે. તે સ્વચ્છ ભારત મિશન-અર્બન હેઠળ રચાયેલ ગૃહ અને શહેરી બાબતોના મંત્રાલયના ઓડીએફ + પ્રોટોકોલોના પાલનને સક્ષમ બનાવવા માટે જાહેર અને સમુદાયના શૌચાલયની સમારકામ, નવીનીકરણ અને જાળવણીને સરળ બનાવે છે. તેમાં શૌચાલય સુવિધાઓમાં જાળવણી, સ્વચ્છતા અને સીટ વાઇઝ ઇન્ફ્રાસ્ટ્રક્ચર ગાબડાઓને આકારણી માટે સર્વેક્ષણો અને મેપિંગ ટૂલ્સ છે.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "આ ટૂલ યુએસએઆઇડીના સમર્થનથી અર્બન મેનેજમેન્ટ સેન્ટર (યુએમસી) દ્વારા વિકસાવવામાં આવ્યું છે.",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "આ એપ્લિકેશનનો ઉદ્દેશ એસબીએમ - મિસાલ પ્રોજેક્ટ હેઠળ જાહેર અને સમુદાય શૌચાલયોના સર્વે ડેટાને ક કેપ્ચર કરવાનો છે અને આવા શૌચાલયોને કાર્યરત બનાવવા માટે બજેટ આવશ્યકતા રજૂ કરવા માટે આવા ડેટાની પ્રક્રિયા કરવી છે. આમ કરતી વખતે, અમે એપ્લિકેશનની વપરાશકર્તાને ઓળખવા અથવા સંપર્ક કરવા માટે નામ, વ્યવસાય, ઇમેઇલ આઈડી, સંપર્ક નંબર, શહેર જેવી તમારી વ્યક્તિગત માહિતી એકત્રિત કરી શકીએ છીએ.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "આ પ્રક્રિયામાં, અમે તમને ખાતરી આપીશું કે તમારી ગુપ્તતાનું સંરક્ષણ અમારા માટે નિર્ણાયક છે. તમારી વ્યક્તિગત માહિતી ફક્ત તમારી સંમતિથી કોઈ ફોર્મ દ્વારા અમારા દ્વારા એકત્રિત કરવામાં આવશે. જ્યારે કેટલીક માહિતી તમારી પાસેથી એકત્રિત કરવામાં આવશે, ત્યારે અમે ડેટા અને સિસ્ટમ સુરક્ષા માટે અમારા સર્વરના સિસ્ટમ નોંધણી આઇપી સરનામું, ડોમેન નામ, બ્રાઉઝર, ઓપરેટિંગ સિસ્ટમ વગેરે જેવા અન્ય ડેટા પણ એકત્રિત કરી શકીએ છીએ.",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "યુએમસી તમને ખાતરી આપે છે કે આ એપ્લિકેશન દ્વારા એકત્રિત કરવામાં આવેલી માહિતી સખત રીતે ગોપનીય રહેશે અને કોઈ પણ રીતે તૃતીય પક્ષને જાહેર કરવામાં આવશે નહીં અને તમે જે રીતે સંમત થયા છો તે રીતે જ તેનો ઉપયોગ કરવામાં આવશે.",
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "પસંદ કરેલા રેકોર્ડની ગણતરી:",
        ALL: "બધા",
        SUSPENDED: "સસ્પેન્ડ",
        ACTIVE: "એક્ટિવ",
        FILTER: "ફિલ્ટર",
        BY_STATUS: "સ્ટેટસ બાય",
        BY_TYPE: "ટાઈપ બાય",
        BUSINESS: "બિઝનેસ",
        INDIVIDUAL: "વ્યક્તિગત",
        SEARCH: "શોધ",
        IN_ALL_FIELDS: "બધા ક્ષેત્રમાં",
      },
      ECOMMERCE: "ઈકોમર્સ",
      CUSTOMERS: {
        CUSTOMERS: "ગ્રાહકો",
        CUSTOMERS_LIST: "ગ્રાહકોની સૂચિ",
        NEW_CUSTOMER: "નવો ગ્રાહક",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "ગ્રાહક કાઢી નાંખો",
          DESCRIPTION:
            "શું તમે ખરેખર આ ગ્રાહકને કાયમી ધોરણે કાઢી નાખવા માંગો છો?",
          WAIT_DESCRIPTION: "ગ્રાહક કાtingી રહ્યું છે ...",
          MESSAGE: "ગ્રાહક કાઢી નાખવામાં આવ્યો છે",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "ગ્રાહકો કાઢી નાંખો",
          DESCRIPTION:
            "શું તમે ખરેખર પસંદ કરેલા ગ્રાહકોને કાયમી ધોરણે કાઢી નાખવા માંગો છો?",
          WAIT_DESCRIPTION: "ગ્રાહકો કાtingી રહ્યાં છે ...",
          MESSAGE: "પસંદ કરેલા ગ્રાહકોને કાઢી નાખવામાં આવ્યા છે",
        },
        UPDATE_STATUS: {
          TITLE: "પસંદ કરેલા ગ્રાહકો માટે સ્થિતિ અપડેટ કરવામાં આવી છે",
          MESSAGE:
            "પસંદ કરેલા ગ્રાહકોની સ્થિતિ સફળતાપૂર્વક અપડેટ કરવામાં આવી છે",
        },
        EDIT: {
          UPDATE_MESSAGE: "ગ્રાહક અપડેટ કરવામાં આવ્યો છે",
          ADD_MESSAGE: "ગ્રાહક બનાવવામાં આવ્યો છે",
        },
      },
    },
  },
};
