import { Subject } from "rxjs";
// Angular
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
// Service
import { TranslationService } from "./_services/translation.service";
// language list
import { locale as enLang } from "./_config/i18n/en";
import { locale as chLang } from "./_config/i18n/ch";
import { locale as esLang } from "./_config/i18n/es";
import { locale as jpLang } from "./_config/i18n/jp";
import { locale as deLang } from "./_config/i18n/de";
import { locale as frLang } from "./_config/i18n/fr";
import { locale as gsLang } from "./_config/i18n/gs";
import { locale as inLang } from "./_config/i18n/in";
import { locale as orLang } from "./_config/i18n/or";
import { LoaderService } from "./_services/loader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = "INGEDING";
  loading: Subject<boolean> = this.loaderService.loading;
  constructor(
    private translationService: TranslationService,
    public loaderService: LoaderService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang,
      gsLang,
      inLang,
      orLang
    );
  }
  ngOnInit() { }
}
