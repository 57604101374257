<section class="main-content-area sticky-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12" *ngFor="let data of dataArray">
                <div class="mb-5 mt-5" *ngIf="data.translations.slug=='abbonamento-e-pagamenti'"
                    [innerHtml]='data.translations.shortdescription| safe'>
                </div>
            </div>
        </div>
    </div>
</section>