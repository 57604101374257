<!--BODY START-->
<section class="main-content-area sticky-space">
	<div class="alert alert-danger text-center p-3" role="alert"
		*ngIf="checkProfileStatus != undefined && checkProfileStatus == false && profileData && profileData.flag != '0' && flag!=0">
		<strong>**Per contattare un utente si prega di completare il proprio profilo.</strong>
	</div>
	<div class="container">
		<div class="row text-break">
			<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 main-registration-area" *ngIf="flag!=0">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a [routerLink]="['']">home</a></li>
						<li class="breadcrumb-item active" aria-current="page">ricerca professionisti</li>
					</ol>
				</nav>
				<div *ngIf="profileData && profileData.user && flag!=2">
					<div class="section-title">
						<div class="row mt-5">
							<div class="col-sm-12 col-md-9 pt-0 ">
								<h2 class="profile-name" title="{{ profileData.user[0].firstname?profileData.user[0].firstname + ', ' +
                  profileData.user[0].lastname:profileData.user[0].companyname }}"
									[innerHtml]="profileData.user[0].firstname?nameFormat(profileData.user[0].firstname, profileData.user[0].lastname):companyNameFormate(profileData.user[0].companyname)">
								</h2>
								<div class="black-text mt-4">Utente da {{ filterMyDate(profileData.user[0].createdAt) }}
								</div>
								<p class="mr-5" *ngIf="regType == 0">
									Iscritto all’albo di {{profileData.user[0].city_id || ''}}
									{{profileData.user[0].idnumber ? " n° " + profileData.user[0].idnumber : ""}}
								</p>
								<p class="mr-5" *ngIf="regType == 1">
									{{
									profileData.operationalheadquartersregions?profileData.user[0].operationalheadquartersaddress
									+ ", " +
									profileData.user[0].operationalheadquartersstreetno + ", " +
									profileData.user[0].operationalheadquarterscity + " " +
									profileData.user[0].operationalheadquarterspostcode + " "
									+profileData.operationalheadquartersregions[0].translations[0].name:''
									}}
								</p>
								<h3 class="blue-title mt-4" *ngIf="regType == 0">
									{{ profileData.iam?profileData.iam[0].title:'' }}
								</h3>
								<h3 class="blue-title mt-4" *ngIf="regType == 1">
									Descrizione
								</h3>

								<p class="mt-3 mb-5 mr-5"
									*ngIf="profileData.description!='' && profileData.description!=null && profileData.description!=undefined">
									{{profileData.description}}
								</p>
							</div>
							<div class="col-sm-12 col-md-3"
								*ngIf="profileData.user[0].pic == null || profileData.user[0].pic ==''">
								<img *ngIf="profileData.avatars" [src]="avatarBucket + profileData.avatars[0].avatar"
									alt="avatar" title="avatar" height="202px" width="202px">
							</div>
							<div class="col-sm-12 col-md-3"
								*ngIf="profileData.user[0].pic != null && profileData.user[0].pic !=''">
								<img [src]="profilePicBucket + profileData.user[0].pic" height="202px" width="202px"
									alt="profile" title="profile">
							</div>
						</div>
					</div>
					<hr>
					<!-- Side Box Done-->
					<div class="right-listing">
						<div class="row" *ngIf="ActiveJobPost.length > 0 && userId != '' && userId != undefined">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0"> Lavori aperti </legend>
							<div class="col-sm-9">
								<div *ngFor="let post of ActiveJobPost">
									<a [routerLink]="['/view-job-post/'+post._id]">{{post.jobtitle}} <i
											class="fa fa-arrow-right" aria-hidden="true"></i></a>
								</div>
							</div>
						</div>
						<hr class="white" *ngIf="ActiveJobPost.length > 0 && userId != '' && userId != undefined">
						<!-- Available from -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">Disponibilità</legend>
							<div class="col-sm-9">
								<p class="card-subtitle mb-2">
									<span
										[ngClass]="{'green-dot':profileAlertLights(profileData.availableFrom), 'yellow-dot':!profileAlertLights(profileData.availableFrom) }"></span>
									{{ profileData.availableFrom==""||profileData.availableFrom==null?"Disponibile
									da":"Disponibile da " +
									filterMyDate(profileData.availableFrom) }}
								</p>
							</div>
						</div>
						<hr class="white">

						<!-- Regione Coverd -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">Regioni coperte</legend>
							<div class="col-sm-9">
								<ul>
									<li *ngFor="let region of RegionArr">{{region.translations[0].name}}</li>
									<a *ngIf="RegionArr.length != region.length" href="javascript:void(0)"
										class="size12" (click)="moreThreeRegion()">mostra di più</a>
								</ul>
							</div>
						</div>
						<hr class="white" *ngIf="profileData.rate.length > 0 || profileData.package.length > 0">

						<!-- Done -->
						<div class="row" *ngIf="profileData.rate.length > 0 || profileData.package.length > 0">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">Servizi e tariffe</legend>
							<div class="col-sm-9">
								<div *ngIf="profileData.rate.length > 0">
									<div *ngFor="let eachRate of profileData.rate; let i = index">
										<div class="row">
											<div class="col-sm-12 col-md-6 black-text">Tariffa oraria</div>
											<div class="col-sm-12 col-md-6 right-align">
												<h4 class="mb-0">€ {{ checkIfNumber(eachRate.rate) }} / ora</h4>
											</div>
										</div>
										<p class="small-text">{{eachRate.desc}}
										</p>
										<hr class="white-b"
											*ngIf="profileData.rate.length > 1 && profileData.rate.length !== i+1">
									</div>
								</div>
								<div *ngIf="profileData.package.length > 0">
									<div *ngFor="let eachPack of profileData.package; let i = index">
										<div class="row">
											<div class="col-sm-12 col-md-6 black-text">{{eachPack.name}}</div>
											<div class="col-sm-12 col-md-6 right-align">
												<h4 class="mb-0">€ {{ checkIfNumber(eachPack.rate) }}</h4>
											</div>
										</div>
										<p class="small-text">{{eachPack.desc}}
										</p>
										<hr class="white-b"
											*ngIf="profileData.package.length > 1 && profileData.package.length !== i+1">
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<!-- End Side Box-->

					<!-- Hashtags Done-->
					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Hashtag</legend>
						<div class="col-sm-9">
							<ul>
								<li *ngFor="let tag of tags"><a
										href="javascript:void(0)">#{{tag.translations[0].title}}</a></li>
							</ul>
						</div>
					</div>
					<hr *ngIf="photoArr.length>0">

					<!-- Fotogalleria Done-->
					<div class="row" *ngIf="photoArr.length>0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Galleria fotografica</legend>
						<div class="col-sm-9">
							<div id="gallery">
								<div id="image-gallery">
									<div class="row pt-0">
										<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-4 image "
											*ngFor="let photo of photoArr;let i = index">
											<div class="img-wrapper">
												<img src="{{ photo.url?photo.url:photosBucket+photo.name }}"
													alt="Photo-gallery-{{i}}" class="img-responsive">
												<div class="img-overlay">
													<a href="{{ photo.url?photo.url:photosBucket+photo.name }}"></a>
													<a href="javascript:void(0)" class="openGallery"><i
															class="fa fa-eye" aria-hidden="true"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="overlay" style="display: none;">
									<div id="prevButton"><i class="fa fa-angle-left"></i></div>
									<img alt="images" id="myGalleryImage">
									<div id="nextButton"><i class="fa fa-angle-right"></i></div>
									<div id="exitButton"><i class="fa fa-times"></i></div>
								</div>
							</div>
						</div>
					</div>

					<h3 class="blue-title mt-64"
						*ngIf="regType == 0 && (documentArr.length > 0 || profileData.software.length > 0 || profileData.language.length > 0 || profileData.skill.length > 0 || profileData.experience.length > 0 || profileData.formation.length > 0)">
						Curriculum</h3>
					<h3 class="blue-title mt-64" *ngIf="regType == 1 || profileData.aboutus">Profilo</h3>
					<hr />

					<!-- Su di noi Done-->
					<div class="row" *ngIf="regType == 1 && profileData.aboutus">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Chi siamo</legend>
						<div class="col-sm-9">
							<p style="white-space: pre-line;">{{profileData.aboutus}}</p>
						</div>
					</div>
					<hr *ngIf="regType == 1  && profileData.aboutus" />

					<!-- Numero collaboratori Done-->
					<div class="row" *ngIf="regType == 1">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Numero collaboratori</legend>
						<div class="col-sm-9">
							<div *ngIf="profileData.user[0].noOfCollaborator == 0">
								<p>1 - 10</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 1">
								<p>11 - 50</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 2">
								<p>51 - 250</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 3">
								<p>251 - 1000</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 4">
								<p>1001 - 5000</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 5">
								<p>5001 - 10000</p>
							</div>
							<div *ngIf="profileData.user[0].noOfCollaborator == 6">
								<p>10000 +</p>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 1" />

					<!-- Esperienza lavorativa Done -->
					<div class="row" *ngIf="regType == 0 && profileData.experience.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Esperienza lavorativa</legend>
						<div class="col-sm-9">
							<div class="edu-yr" *ngFor="let experience of profileData.experience">
								<span class="black-text">{{ experience.from +' - '+ experience.to}}</span>
								<p> {{experience.position}} - {{experience.company}}<br />
									{{experience.location}}</p>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 0 && profileData.experience.length > 0" />

					<!-- Formazione Done -->
					<div class="row" *ngIf="regType == 0 && profileData.formation.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Formazione</legend>
						<div class="col-sm-9">
							<div class="edu-yr" *ngFor="let formation of profileData.formation">
								<span class="black-text">{{formation.from + ' - ' + formation.to}}</span>
								<p> {{formation.institute}} - {{formation.location}}</p>
							</div>
						</div>
					</div>
					<hr *ngIf="regType == 0 && profileData.formation.length > 0" />

					<!-- Competenze Done -->
					<div class="row" *ngIf="profileData.skill.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Competenze</legend>
						<div class="col-sm-9">
							<ul>
								<li *ngFor="let myskill of profileData.skill"><img
										src="./assets/images/ingeding/done.svg" alt="Done" title="Done"> {{ myskill }}
								</li>
							</ul>
						</div>
					</div>
					<hr *ngIf="profileData.skill.length > 0" />

					<!-- Software Done -->
					<div class="row" *ngIf="profileData.software.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Software</legend>
						<div class="col-sm-9">
							<ul>
								<li *ngFor="let soft of profileData.software"><img
										src="./assets/images/ingeding/done.svg" alt="Done" title="Done"> {{ soft }}</li>
							</ul>
						</div>
					</div>
					<hr *ngIf="profileData.software.length > 0" />

					<!-- Lingue Done -->
					<div class="row" *ngIf="profileData.language.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Lingue</legend>
						<div class="col-sm-9">
							<ul>
								<li *ngFor="let lang of profileData.language"><img
										src="./assets/images/ingeding/done.svg" alt="Done" title="Done"> {{lang.name + '
									- ' + lang.proficiency}}</li>
							</ul>
						</div>
					</div>
					<hr *ngIf="profileData.language.length > 0" />

					<!-- Documenti Done -->
					<div class="row" *ngIf="documentArr.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">Documenti</legend>
						<div class="col-sm-9">
							<div class="row" *ngFor="let doc of documentArr;let i = index">
								<div class="col-sm-12 col-md-8 file-upload-t">
									<ul>
										<li>
											{{doc.name}}
											<a href="{{documentBucket + doc.name}}" target="_blank" download>
												<img src="./assets/images/ingeding/download.svg" alt="Download"
													title="Download"></a>
										</li>
									</ul>
								</div>
								<div class="col-sm-12 col-md-4 file-size-t">
									<ul>
										<li>
											{{ Math.floor(doc.size / 1024) > 1000 ? Math.floor(doc.size /
											1048576)+' MB ' : Math.floor(doc.size / 1024)+' KB ' }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr *ngIf="documentArr.length > 0" />

					<div class="form-group row mb-64" *ngIf="!showContactForm">
						<div class="col-sm-12 inline-btn">
							<button type="button" class="btn-aqua mr-2" (click)="showPopup()"
								*ngIf="profileData.flag == '1'"
								[disabled]="checkProfileStatus!=undefined && !checkProfileStatus">{{regType == 0
								?"Contattami":"Contattaci"}}</button>
							<button type="button" class="btn-aqua mr-2" [routerLink]="['/edit-profile']"
								*ngIf="profileData.flag == '0'">Modifica</button>
							<span *ngIf="profileData.flag == '2'" class="text-danger">Hai già inviato la tua richiesta
								di contatto a quest'utente.</span>
							<span
								*ngIf="profileData.flag == '1' && checkProfileStatus && profileData.user[0].companyname">
								Domande, dubbi, richieste particolari? Siamo a tua disposizione!</span>
							<span
								*ngIf="profileData.flag == '1' && checkProfileStatus && profileData.user[0].firstname">Domande,
								dubbi, richieste particolari? Sono a tua disposizione!</span>
							<span class="text-danger" *ngIf="!checkProfileStatus"><strong>**Per contattare un utente si
									prega di completare il proprio profilo.</strong></span>
						</div>
					</div>
				</div>
				<div *ngIf="!profileData._id && flag!=2">
					<div class="col-sm-12 mt-3 mb-3">
						<div class="card">
							<div class="card-body text-danger text-center">
								<span>Can not find this User profile!<br />Please contact admin/author or check
									URL.</span>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="profileData._id && flag==2">
					<div class="col-sm-12 mt-3 mb-3">
						<div class="card">
							<div class="card-body text-danger text-center">
								<span>Your profile is not complete!<br />Per favore,<a [routerLink]="['/edit-profile']">
										clicca qui</a> to per inserire i dettagli del tuo profilo.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="main-content-area sticky-space mt-64" *ngIf="showContactForm">
	<div class="container">
		<form (ngSubmit)="f.form.valid && contactProfile()" #f="ngForm">
			<div class="row about-desc">
				<div class="col-sm-12 col-md-12 section-title">
					<h2 class="mt-0">{{regType == 0
						?"Contattami":"Contattaci"}}</h2>
				</div>
				<div class="col-sm-12 col-md-12 mt-3">
					<p class="small-text">{{regType == 0
						?"Domande, dubbi, richieste particolari? Sono a tua disposizione!":"Domande, dubbi, richieste
						particolari? Siamo a tua disposizione!"}}</p>
				</div>
				<div class="col-sm-12 col-md-12 mt-3">
					<textarea class="form-control" rows="5" style="height: auto !important;" [(ngModel)]="personalmsg"
						minlength="4" maxlength="500" name="personalmsg" id="personalmsg"
						placeholder="Lascia qui il tuo messaggio." required></textarea>
					<div *ngIf="f.submitted && f.hasError('required', 'personalmsg')" class="text-danger">
						Campo richiesto
					</div>
				</div>
				<div class="col-sm-12 col-md-6 mt-3">
					<input type="email" class="form-control" placeholder="Email" name="email" id="email"
						[(ngModel)]="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required>
					<div *ngIf="f.submitted && f.hasError('required', 'email')" class="text-danger">
						Campo richiesto
					</div>
					<div *ngIf="f.submitted && f.hasError('pattern', 'email')" class="text-danger">
						Campo invalido
					</div>
				</div>

				<div class="col-sm-12 col-md-2 mt-3">
					<select class="minimal form-control" id="phone_code" name="phone_code" [(ngModel)]="phone_code"
						required>
						<option value="">Country Prefisso</option>
						<option *ngFor="let country of CountryArr;let i = index" [value]="country._id">
							+ {{ country.phone_code }}
						</option>
					</select>
					<div *ngIf="f.submitted && f.hasError('required', 'phone_code')" class="text-danger">
						Si prega di selezionare
					</div>
				</div>
				<div class="col-sm-12 col-md-4 mt-3">
					<input type="text" class="form-control" placeholder="Numero di telefono" name="phone_no"
						id="phone_no" [(ngModel)]="phone_no" pattern="^[0-9]+$" required>
					<div *ngIf="f.submitted && f.hasError('required', 'phone_no')" class="text-danger">
						Campo richiesto
					</div>
					<div *ngIf="f.submitted && f.hasError('pattern', 'phone_no')" class="text-danger">
						Campo invalido
					</div>
					<!-- <div *ngIf="f.submitted && f.hasError('maxlength', 'phone_no')" class="text-danger">
						Campo non può superare 12 caratteri
					</div> -->
				</div>
				<div class="col-sm-12 col-md-12 mt-3 mb-64">
					<div class="row">
						<div class="col-6 col-sm-6 col-lg-2 col-md-3">
							<button type="button" class="btn btn-aqua btn-block"
								style="background-color:#D70000;border:none;" *ngIf="profileData.status == 1"
								(click)="showContactForm=false">Cancella</button>
						</div>
						<div class="col-6 col-sm-6 col-lg-2 col-md-3">
							<button type="submit" class="btn btn-aqua btn-block" [disabled]="isContacted">Invia</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</section>

<section class="main-content-area sticky-space mt-64" *ngIf="relevantProfilesArr.length > 0">
	<div class="container">
		<div class="row about-desc">
			<div class="col-sm-12 col-md-8 section-title">
				<h2 class="mt-0">Vedi altri professionisti e aziende del settore</h2>
			</div>
			<div class="col-sm-12 col-md-4 right-align">
				<a [routerLink]="['/search-profiles']">Mostra tutti <img src="./assets/images/ingeding/arrow.svg"
						alt="Arrow" title="Arrow"></a>
			</div>
		</div>
	</div>
</section>

<section class="card-main mt-64 mb-64" *ngIf="relevantProfilesArr.length > 0">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-4" *ngFor="let profile of relevantProfilesArr;let i = index">
				<div class="card h-100">
					<div class="card-body">
						<div class="media">
							<div class="media-left media-middle"
								*ngIf="profile.user[0] && profile.user[0].pic!=null && profile.user[0].pic!=''">
								<img [src]="profilePicBucket + profile.user[0].pic" class="media-object" alt="Avatar"
									title="Avatar">
							</div>
							<div class="media-left media-middle"
								*ngIf="profile.user[0] && (profile.user[0].pic==null || profile.user[0].pic=='')">
								<img [src]="avatarBucket + profile.avatars[0].avatar" class="media-object" alt="Avatar"
									title="Avatar">
							</div>
							<div class="media-body">
								<a [routerLink]="['/view-profile/'+profile.user[0]._id]"
									*ngIf="profile.user && profile.user[0].usergroup == '60f679adc3afbf2ab4974e4e'">
									<h5 class="name-tag">
										{{profile.user[0].companyname}}
									</h5>
								</a>
								<a [routerLink]="['/view-profile/'+profile.user[0]._id]"
									*ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f'">
									<h5 class="name-tag">
										{{profile.user[0].firstname + ' ' + profile.user[0].lastname }}
									</h5>
								</a>
								<span class="label label-primary">{{profile.tag[0].translations[0].title}}</span>
								<span class="label label-primary" *ngIf="profile.tag.length > 1">+{{profile.tag.length -
									1}}</span>
							</div>
						</div>
						<div class="card-height-fix">
							<a [routerLink]="['/view-profile/'+profile.user[0]._id]"
								*ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f'">
								<h4 class="card-title">
									{{profile.iam[0].title}}</h4>
							</a>
							<p class="card-text">{{profile.description}}</p>
						</div>

						<div class="card-bt-info">
							<p class="card-subtitle">
								<span
									[ngClass]="{'green-dot':profileAlertLights(profile.availableFrom), 'yellow-dot':!profileAlertLights(profile.availableFrom) }"></span>
								{{filterMyDate(profile.availableFrom)}}
							</p>
							<a [routerLink]="['/view-profile/'+profile.user[0]._id]" class="card-link">
								<img src="./assets/images/ingeding/link-icon.svg" alt="Arrow" title="Arrow">
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="bottom-banner mt-192">
	<div class="container banner-bg">
		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-6">
				<div class="banner-b-caption">
					<h2> Ottieni accesso a tutti i contenuti in modo semplice e veloce.</h2>
					<p>I primi 3 mesi sono gratuiti, nessuna carta di credito richiesta.</p>
					<div class="btn-group"> <a href="javascript:void(0)" class="btn-white">Iscriviti ora</a> <a
							href="javascript:void(0)" class="btn-outline white">FAQ</a> </div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-6 main-left-text-box">
				<div class="phone-img"> <img src="./assets/images/ingeding/Free_iPhone_11_Pro_Mockup_3 1.png"
						alt="iphone" title="iphone"> </div>
			</div>
		</div>
	</div>
</section> -->
<!--BODY END-->