<!--BODY START-->
<section class="main-content-area sticky-space">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">home</a></li>
                <li class="breadcrumb-item active" aria-current="page">ricerca lavori</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-sm-12">
                <div class="job-section-title">
                    <!-- <h1>Ad ogni lavoro il proprio professionista</h1> -->
                    <h1>{{'PAGES.SEARCH_JOB_POST.JOB_SECTION_TITLE' | translate}}</h1>
                </div>
            </div>
        </div>
        <div class="row about-desc">
            <div class="col-sm-12 col-md-6">
                <p>{{'PAGES.SEARCH_JOB_POST.JOB_SECTION_DESC' | translate}}
                </p>
            </div>
        </div>
    </div>
</section>
<section class="card-main mt-64">
    <div class="container pro-research">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-5 main-registration-area">
                <hr class="mt-0 mb-2">
                <div>
                    <div [ngClass]="{'blue-text':hideshowclass == true,'inactive':hideshowclass != true}">
                        <a id="all" href="javascript:void(0)" class="all" (click)="selectThisCategory1()">{{'HEADER.SEARCH.ALL_CATEGORY' | translate}}</a>
                        <span class="float-right" *ngIf="hideshowclass == true && isCollapse1 == 1"><a
                                href="javascript:void(0);" (click)="changeCollaps1()"><i class="fa fa-angle-up"
                                    aria-hidden="true"></i></a></span>
                        <span class="float-right" *ngIf="hideshowclass == true && isCollapse1 == 0"><a
                                href="javascript:void(0);" (click)="changeCollaps1()"><i class="fa fa-angle-down"
                                    aria-hidden="true"></i></a></span>
                    </div>
                    <div *ngIf="hideshowclass == true && isCollapse1 == 1">
                        <div class="c-box" *ngFor="let tags1 of TagsArrs;let i1 = index">
                            <label class="checkbox-button">
                                <input type="checkbox" class="checkbox-button__input" value="{{tags1._id}}"
                                    name="tags1[]" (change)="selectThisTags1($event)"
                                    [checked]="SelectedTags!=undefined && SelectedTags.includes(tags1._id)">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">{{ tags1.translations.title }}</span>
                            </label>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2">
                </div>
                <div *ngFor="let cat of CategoryArr;let i = index">
                    <div [ngClass]="{'blue-text':selectedCategory == cat._id ,'inactive':selectedCategory != cat._id}">
                        <a href="javascript:void(0)"
                            (click)="selectThisCategory(cat._id)">{{cat.translations.title}}</a>
                        <span class="float-right" *ngIf="selectedCategory == cat._id && isCollapse == 1"><a
                                href="javascript:void(0);" (click)="changeCollaps()"><i class="fa fa-angle-up"
                                    aria-hidden="true"></i></a></span>
                        <span class="float-right" *ngIf="selectedCategory == cat._id && isCollapse == 0"><a
                                href="javascript:void(0);" (click)="changeCollaps()"><i class="fa fa-angle-down"
                                    aria-hidden="true"></i></a></span>
                    </div>
                    <div *ngIf="selectedCategory == cat._id && isCollapse == 1">
                        <div class="c-box" *ngFor="let tags of TagsArr;let i = index">
                            <label class="checkbox-button">
                                <input type="checkbox" class="checkbox-button__input" value="{{tags._id}}"
                                    (change)="selectThisTags($event)" name="tags[]"
                                    [checked]="SelectedTags!=undefined && SelectedTags.includes(tags._id)">
                                <span class="checkbox-button__control"></span>
                                <span class="checkbox-button__label">{{ tags.translations.title }}</span>
                            </label>
                        </div>
                    </div>
                    <hr class="mt-2 mb-2">
                </div>
                <select class="minimal form-control mt-4 mb-4" name="placeofwork" (change)="selectedRegion($event)"
                    required>
                    <option value="">{{'PAGES.SEARCH_JOB_POST.ALL_REGION' | translate}}</option>
                    <option value="000000a0aa00a000000aa000">{{'PAGES.SEARCH_JOB_POST.REMOTELY' | translate}}</option>
                    <option *ngFor="let place of RegionArr" value="{{place._id}}">
                        {{ place.translations.name }}
                    </option>
                </select>
                <span class="pt-2">{{'PAGES.SEARCH_JOB_POST.DEADLINE_UP_TO' | translate}}
                    <a href="javascript:void(0)" id="dateCalendar" (click)="hide('dateCalendar')">
                        <img src="./assets/images/ingeding/blue-calendar.svg" alt="calendar" title="calendar">
                        {{ dateFilter(Deadline) }}
                    </a>
                    <input type="date" name="deadline" id="deadline" (blur)="hide('deadline')" style="display: none;"
                        [(ngModel)]="Deadline" [min]="today">
                </span>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-9">
                <div class="row" *ngIf="MyJobPosts.length > 0">
                    <div class="col-sm-12 right-align filter mb-5">
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('createdAt')">
                           {{'PAGES.SEARCH_JOB_POST.PUBLICATION_DATE' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOptionPost.sortOrder == 'asc' && gridOptionPost.sortField == 'createdAt'"
                                alt="Arrow" title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOptionPost.sortOrder == 'desc' && gridOptionPost.sortField == 'createdAt'"
                                alt="Arrow" title="Arrow">
                        </a>
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('deadline')">
                            {{'PAGES.SEARCH_JOB_POST.DEADLINE' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOptionPost.sortOrder == 'asc' && gridOptionPost.sortField == 'deadline'"
                                alt="Arrow" title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOptionPost.sortOrder == 'desc' && gridOptionPost.sortField == 'deadline'"
                                alt="Arrow" title="Arrow">
                        </a>
                    </div>
                    <div class="col-sm-12 col-md-6" *ngFor="let post of MyJobPosts">
                        <div class="card h-100">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-left media-middle" *ngIf="!currentUser">
                                        <img [src]="avatarBucket + post.avatars[0].avatar" class="media-object"
                                            alt="Avatar" title="Avatar">
                                    </div>
                                    <div class="media-left media-middle"
                                        *ngIf="post.user[0] && post.user[0].pic!=null && post.user[0].pic!='' && currentUser">
                                        <img [src]="profilePicBucket + post.user[0].pic" class="media-object"
                                            alt="Profile picture" title="Profile picture">
                                    </div>
                                    <div class="media-left media-middle"
                                        *ngIf="post.user[0] && (post.user[0].pic==null || post.user[0].pic=='') && currentUser">
                                        <img [src]="avatarBucket + post.avatars[0].avatar" class="media-object"
                                            alt="Avatar" title="Avatar">
                                    </div>
                                    <div class="media-body">
                                        <h5 class="name-tag"
                                            *ngIf="post.user && post.user[0].usergroup == '60f679adc3afbf2ab4974e4e' && currentUser">
                                            {{post.user[0].companyname}}</h5>
                                        <h5 class="name-tag"
                                            *ngIf="post.user && post.user[0].usergroup == '60f6843ac3afbf2ab4974e4f' && currentUser">
                                            {{post.user[0].firstname + ' ' + post.user[0].lastname }}
                                        </h5>
                                        <span class="label label-primary">{{post.tag[0].translations[0].title}}</span>
                                        <span class="label label-primary" *ngIf="post.tag.length > 1">+{{post.tag.length
                                            - 1}}</span>
                                    </div>
                                </div>
                                <div class="card-height-fix">
                                    <a href="javascript:void(0)" [routerLink]="['/view-job-post/'+post._id]"
                                        target="_blank">
                                        <h4 class="card-title">{{post.jobtitle}}</h4>
                                    </a>
                                    <p class="card-text">{{post.overview}}</p>
                                </div>
                                <div class="card-bt-info">
                                    <p class="card-subtitle">{{'PAGES.MY_POST.PUBLISHED_ON' | translate}} {{ dateFilter(post.createdAt)}}</p>
                                    <div>
                                        <a [routerLink]="['/view-job-post/'+post._id]" class="card-link"
                                            target="_blank">
                                            <img src="./assets/images/ingeding/link-icon.svg" alt="Modifica"
                                                title="Modifica">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pag-b mt-64 mb-64" *ngIf="MyJobPosts.length > 0">
                    <ngb-pagination class="d-flex justify-content-end" [collectionSize]="totalCount"
                        [(page)]="mySelectedPage" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                        (pageChange)="selectedPage(mySelectedPage)">
                        <ng-template ngbPaginationFirst>First</ng-template>
                        <ng-template ngbPaginationLast>Last</ng-template>
                        <ng-template ngbPaginationPrevious>Prev</ng-template>
                        <ng-template ngbPaginationNext>Next</ng-template>
                        <ng-template ngbPaginationEllipsis>...</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                </div>
                <div class="row" *ngIf="MyJobPosts.length == 0">
                    <div class="col-sm-12 right-align filter mb-5">
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('createdAt')">
                            {{'PAGES.SEARCH_JOB_POST.PUBLICATION_DATE' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOption.sortOrder == 'asc' && gridOption.sortField == 'createdAt'" alt="Arrow"
                                title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOption.sortOrder == 'desc' && gridOption.sortField == 'createdAt'"
                                alt="Arrow" title="Arrow">
                        </a>
                        <a href="javascript:void(0);" class="active" (click)="changeOrder('deadline')">
                            {{'PAGES.SEARCH_JOB_POST.DEADLINE' | translate}}
                            <img src="./assets/images/ingeding/filter-acending.svg"
                                *ngIf="gridOption.sortOrder == 'asc' && gridOption.sortField == 'deadline'" alt="Arrow"
                                title="Arrow">
                            <img src="./assets/images/ingeding/filter-descending.svg"
                                *ngIf="gridOption.sortOrder == 'desc' && gridOption.sortField == 'deadline'" alt="Arrow"
                                title="Arrow">
                        </a>
                    </div>
                    <div class="col-sm-12 mb-5">
                        <div class="card">
                            <div class="card-body text-center">
                                <span>{{'PAGES.SEARCH_JOB_POST.NO_RESULT_FOUND' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>