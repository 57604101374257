<section class="main-content-area sticky-space">
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <div class="section-title">
               <span *ngFor="let data of dataArray">
                  <label *ngIf="data.translations.slug == 'sitemap'" style="margin-top: 25px"
                     [innerHtml]='data.translations.shortdescription| safe'>
                  </label>
               </span>
               <span *ngFor="let data of dataArray">
                  <img *ngIf="data.translations.slug == 'sitemap'"
                     [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                     alt="" title="Gears">
               </span>
            </div>
         </div>
      </div>
   </div>
</section>