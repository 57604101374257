<!--BODY START-->
<section class="main-content-area sticky-space">
	<div class="alert alert-danger text-center p-3" role="alert"
		*ngIf="checkProfileStatus!=undefined && !checkProfileStatus">
		<strong>{{'PAGES.VIEW_PROFILE.COMPLETE_YOUR_PROFILE' | translate}}</strong>
	</div>
	<div class="container">
		<div class="row text-break">
			<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 main-registration-area" *ngIf="flag!=0">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a [routerLink]="['']">home</a></li>
						<li class="breadcrumb-item active" aria-current="page">ricerca appalti</li>
					</ol>
				</nav>
				<div *ngIf="viewJobData._id">
					<div class="section-title">
						<div class="row mt-5">
							<div class="col-sm-12 col-md-9 pt-0 ">
								<h2 class="profile-name">{{ viewJobData.jobtitle }}</h2>

								<div class="black-text mt-4">{{'PAGES.MY_POST.PUBLISHED_ON' | translate}} {{ filterMyDate(viewJobData.createdAt) }}
								</div>
								<p class="mr-5" *ngIf="regType && regType==1">Da
									<a [routerLink]="['/view-profile/'+viewJobData.user[0]._id]">
										<span class="blue-text">
											{{viewJobData.user[0].companyname?viewJobData.user[0].companyname:''}}
										</span>
									</a>
								</p>
								<p class="mr-5" *ngIf="regType && regType==0">Da
									<a [routerLink]="['/view-profile/'+viewJobData.user[0]._id]">
										<span class="blue-text">
											{{viewJobData.user[0].firstname?viewJobData.user[0].firstname + ' ' +
											viewJobData.user[0].lastname:''}}
										</span>
									</a>
								</p>

								<p class="card-subtitle mb-2" *ngIf="viewJobData.status == 0">
									<span class="red-dot"></span> {{'PAGES.VIEW_JOB_POST.ANNOUNCEMENT_CLOSED' | translate}}
								</p>
								<p class="card-subtitle mb-2" *ngIf="viewJobData.status == 1">
									<span class="green-dot"></span> {{'PAGES.VIEW_JOB_POST.ADVERTISMENT_OPEN' | translate}}
								</p>
								<h3 class="blue-title mt-4">{{'PAGES.VIEW_PROFILE.DESCRIPTION' | translate}}</h3>

								<p class="mt-3 mb-5 mr-5">{{viewJobData.overview==""||viewJobData.overview==null? ('PAGES.VIEW_JOB_POST.ANNOUNCEMENT_CLOSED' | translate) :viewJobData.overview}}</p>
							</div>
							<div class="col-sm-12 col-md-3"
								*ngIf="viewJobData.user && (viewJobData.user[0].pic==null || viewJobData.user[0].pic=='')">
								<img *ngIf="viewJobData.avatars" [src]="avatarBucket + viewJobData.avatars[0].avatar"
									alt="profile" title="profile" height="202px" width="202px">
							</div>
							<div class="col-sm-12 col-md-3"
								*ngIf="viewJobData.user && viewJobData.user[0].pic!=null && viewJobData.user[0].pic!=''">
								<img [src]="profilePicBucket + viewJobData.user[0].pic" height="202px" width="202px"
									alt="profile" title="profile">
							</div>
						</div>
					</div>
					<hr>

					<!-- Side Box-->
					<div class="right-listing">

						<!-- Deadline -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.SEARCH_JOB_POST.DEADLINE' | translate}}</legend>
							<div class="col-sm-9">
								<p class="card-subtitle mb-2">
									{{
									viewJobData.deadline==""||viewJobData.deadline==null?"":filterMyDate(viewJobData.deadline)
									}}
								</p>
							</div>
						</div>
						<hr class="white">

						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.NUMBER_OF_HOUR_EXPECTED' | translate}}</legend>
							<div class="col-sm-9">
								<p class="card-subtitle mb-2">
									{{ viewJobData.noofhours==""||viewJobData.noofhours==null?"":viewJobData.noofhours
									}} ore
								</p>
							</div>
						</div>
						<hr class="white">

						<!-- Done -->
						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.COMPENSATION' | translate}}</legend>
							<div class="col-sm-9">
								<div *ngIf="viewJobData.flatrate && viewJobData.flatrate.length > 0">
									<div *ngFor="let eachRate of viewJobData.flatrate; let i = index">
										<div class="row">
											<div class="col-sm-12 col-md-6 black-text">{{eachRate.name}}</div>
											<div class="col-sm-12 col-md-6 right-align">
												<h4 class="mb-0">€ {{ checkIfNumber(eachRate.rate)}}</h4>
											</div>
										</div>
										<p class="small-text">{{eachRate.desc}}
										</p>
										<hr class="white-b"
											*ngIf="viewJobData.flatrate.length > 1 && viewJobData.flatrate.length !== i+1">
									</div>
								</div>
								<div *ngIf="viewJobData.hourlyrate && viewJobData.hourlyrate.length > 0">
									<div *ngFor="let eachPack of viewJobData.hourlyrate;let i = index">
										<div class="row">
											<div class="col-sm-12 col-md-6 black-text">{{'PAGES.VIEW_JOB_POST.HOURLY_WAGE' | translate}}</div>
											<div class="col-sm-12 col-md-6 right-align">
												<h4 class="mb-0">€ {{checkIfNumber(eachPack.rate)}} / ora</h4>
											</div>
										</div>
										<p class="small-text">{{eachPack.desc}}
										</p>
										<hr class="white-b"
											*ngIf="viewJobData.hourlyrate.length > 1 && viewJobData.hourlyrate.length !== i+1">
									</div>
								</div>
							</div>
						</div>
						<hr class="white">

						<div class="row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.WORKPLACE' | translate}}</legend>
							<div class="col-sm-9">
								<p class="card-subtitle mb-2"
									*ngIf="viewJobData.placeofworks && viewJobData.placeofworks.length > 0">
									{{ viewJobData.placeofworks[0].translations[0].name }}
								</p>
								<p class="card-subtitle mb-2"
									*ngIf="viewJobData.placeofwork=='000000a0aa00a000000aa000'">
									{{'PAGES.SEARCH_JOB_POST.ALL_REGION' | translate}}
								</p>
							</div>
						</div>
					</div>
					<hr />
					<!-- End Side Box-->

					<!-- Hashtags Done-->
					<div class="row" *ngIf="categoryName!=''">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{categoryName}}</legend>
						<div class="col-sm-9">
							<ul>
								<li *ngFor="let tag of viewJobData.tag"><a
										href="javascript:void(0)">#{{tag.translations[0].title}}</a>
								</li>
							</ul>
						</div>
					</div>
					<hr *ngIf="viewJobData.gallery.length > 0" />

					<!-- Fotogalleria Done-->
					<div class="row" *ngIf="viewJobData.gallery.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.PHOTO_GALLERY' | translate}}</legend>
						<div class="col-sm-9">
							<div id="gallery">
								<div id="image-gallery">
									<div class="row pt-0">
										<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 image "
											*ngFor="let photo of viewJobData.gallery;let i = index">
											<div class="img-wrapper">
												<img src="{{ photo.url?photo.url:photosBucket+photo.name }}"
													alt="Photo-gallery-{{i}}" class="img-responsive">
												<div class="img-overlay">
													<a href="{{ photo.url?photo.url:photosBucket+photo.name }}"></a>
													<a href="javascript:void(0)" class="openGallery"><i
															class="fa fa-eye" aria-hidden="true"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="overlay" style="display: none;">
									<div id="prevButton"><i class="fa fa-angle-left"></i></div>
									<img alt="images" id="myGalleryImage">
									<div id="nextButton"><i class="fa fa-angle-right"></i></div>
									<div id="exitButton"><i class="fa fa-times"></i></div>
								</div>
							</div>
						</div>
					</div>

					<h3 class="blue-title mt-64">{{'PAGES.VIEW_JOB_POST.DETAILS' | translate}}</h3>
					<hr />

					<div class="row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.DESCRIPTION' | translate}}</legend>
						<div class="col-sm-9">
							<p class="mt-3 mr-5" style="white-space: pre-line;">
								{{viewJobData.description==""||viewJobData.description==null? ('PAGES.VIEW_JOB_POST.BRIEF_DESC_ABOUT_YOURSELF' | translate) :viewJobData.description}}</p>
						</div>
					</div>
					<hr />

					<!-- Documenti Done -->
					<div class="row" *ngIf="viewJobData.document.length > 0">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.DOCUMENTS' | translate}}</legend>
						<div class="col-sm-9">
							<div class="row" *ngFor="let doc of viewJobData.document;let i = index">
								<div class="col-sm-12 col-md-8 file-upload-t">
									<ul>
										<li>
											{{doc.name}}
											<a href="{{documentBucket + doc.name}}" target="_blank" download>
												<img src="./assets/images/ingeding/download.svg" alt="Download"
													title="Download"></a>
										</li>
									</ul>
								</div>
								<div class="col-sm-12 col-md-4 file-size-t">
									<ul>
										<li>
											{{ Math.floor(doc.size / 1024) > 1000 ? Math.floor(doc.size /
											1048576)+' MB ' : Math.floor(doc.size / 1024)+' KB ' }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr *ngIf="viewJobData.document.length > 0" />

					<div class="form-group row mb-64" *ngIf="!showApplyForm">
						<div class="col-sm-12 inline-btn" *ngIf="viewJobData.status == 1">
							<button type="button" class="btn-aqua mr-2" (click)="showPopup()"
								*ngIf="viewJobData.flag == '1'"
								[disabled]="checkProfileStatus!=undefined && !checkProfileStatus">{{'PAGES.VIEW_JOB_POST.CONTACT' | translate}}</button>
							<button type="button" class="btn-aqua mr-2"
								[routerLink]="['/edit-job-post/'+viewJobData._id]"
								*ngIf="viewJobData.flag == '0'">{{'PAGES.VIEW_PROFILE.EDIT' | translate}}</button>
							<span *ngIf="viewJobData.flag == '2'" class="text-danger">{{'PAGES.VIEW_JOB_POST.ALREADY_APPILED_FOR_ANNOUNCEMENT' | translate}}</span>
							<span *ngIf="viewJobData.flag == '1' && checkProfileStatus">{{'PAGES.VIEW_JOB_POST.CONTACT_WHO_POST_THE_AD' | translate}}</span>
							<span class="text-danger ml-2" *ngIf="!checkProfileStatus"><strong>{{'PAGES.VIEW_PROFILE.COMPLETE_YOUR_PROFILE' | translate}}</strong></span>
						</div>
					</div>
				</div>
				<div *ngIf="!viewJobData._id">
					<div class="col-sm-12 mt-3 mb-3">
						<div class="card">
							<div class="card-body text-danger text-center">
								<span>{{'PAGES.VIEW_JOB_POST.ANNOUNCEMENT_CLOSED' | translate}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="main-content-area sticky-space mt-64" *ngIf="showApplyForm">
	<div class="container">
		<div class="row about-desc">
			<div class="col-sm-12 col-md-12 section-title">
				<h2 class="mt-0">{{'PAGES.VIEW_JOB_POST.MESSAGE' | translate}}</h2>
			</div>
			<div class="col-sm-12 col-md-12 mt-3">
				<p class="small-text">{{'PAGES.VIEW_JOB_POST.WHY_CONTACT_WHO_POST_ADV' | translate}}
				</p>
			</div>
			<div class="col-sm-12 col-md-12 mt-3">
				<textarea class="form-control" rows="5" style="height: auto !important;" [(ngModel)]="applicationMsg"
					minlength="4" maxlength="3000" name="applicationMsg" id="applicationMsg"
					placeholder="Lascia qui II tuo messaggio."></textarea>
			</div>
			<div class="col-sm-12 col-md-12 mt-3 mb-64">
				<div class="row">
					<div class="col-6 col-sm-6 col-lg-2 col-md-3">
						<button type="button" class="btn btn-aqua btn-block"
							style="background-color:#D70000;border:none;" *ngIf="viewJobData.status == 1"
							(click)="showApplyForm=false">{{'PAGES.UPGRADE_ACCOUNT.CANCEL' | translate}}
						</button>
					</div>
					<div class="col-6 col-sm-6 col-lg-2 col-md-3">
						<button type="button" class="btn btn-aqua btn-block" (click)="applyForJob()"
							[disabled]="isApply">{{'PAGES.VIEW_JOB_POST.SEND_THE_MSG' | translate}}</button>
					</div>
				</div>
			</div>
			<!-- <div class="col-sm-12 col-md-12 mt-4">
				<a [href]="'mailto:'+viewJobData.email" class="mr-5"><img src="./assets/images/ingeding/mail.svg"
						alt="Mail" title="Mail"> {{viewJobData.email}}</a>
				<a [href]="'tel:+'+viewJobData.country.phone_code+viewJobData.contact_no"><img
						src="./assets/images/ingeding/phone.svg" alt="Cell" title="Cell">{{ '
					+'+viewJobData.country.phone_code +'
					'+ viewJobData.contact_no}}</a>
			</div> -->
		</div>
	</div>
</section>

<section class="main-content-area sticky-space mt-64" *ngIf="relevantJobPOstArr.length > 0">
	<div class="container">

		<div class="row about-desc">
			<div class="col-sm-12 col-md-8 section-title">
				<h2 class="mt-0">Vedi altri lavori del settore</h2>
			</div>
			<div class="col-sm-12 col-md-4 right-align"> <a [routerLink]="['/search-job-posts']">Mostra tutti <img
						src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a> </div>
		</div>
	</div>
</section>

<section class="card-main mt-64 mb-64" *ngIf="relevantJobPOstArr.length > 0">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-4" *ngFor="let jobPost of relevantJobPOstArr;let i = index">
				<div class="card h-100">
					<div class="card-body">
						<div class="media">
							<div class="media-left media-middle"
								*ngIf="jobPost.user[0] && jobPost.user[0].pic!=null && jobPost.user[0].pic!=''">
								<img [src]="profilePicBucket + jobPost.user[0].pic" class="media-object" alt="Avatar"
									title="Avatar">
							</div>
							<div class="media-left media-middle"
								*ngIf="jobPost.user[0] && (jobPost.user[0].pic==null || jobPost.user[0].pic=='')">
								<img [src]="avatarBucket + jobPost.avatars[0].avatar" class="media-object" alt="Avatar"
									title="Avatar">
							</div>
							<div class="media-body">
								<a [routerLink]="['/view-profile/'+jobPost.user[0]._id]" *ngIf=" jobPost.user &&
                  jobPost.user[0].usergroup=='60f679adc3afbf2ab4974e4e'">
									<h5 class=" name-tag">
										{{jobPost.user[0].companyname}}
									</h5>
								</a>
								<a [routerLink]="['/view-profile/'+jobPost.user[0]._id]" *ngIf=" jobPost.user &&
                  jobPost.user[0].usergroup=='60f6843ac3afbf2ab4974e4f'">
									<h5 class=" name-tag">
										{{jobPost.user[0].firstname + ' ' + jobPost.user[0].lastname }}
									</h5>
								</a>
								<span class="label label-primary">{{jobPost.tag[0].translations[0].title}}</span>
								<span class="label label-primary" *ngIf="jobPost.tag.length > 1">+{{jobPost.tag.length -
									1}}</span>
							</div>
						</div>
						<div class="card-height-fix">
							<a [routerLink]="['/view-job-post/'+jobPost._id]">
								<h4 class="card-title">{{jobPost.jobtitle}}</h4>
							</a>
							<p class="card-text">{{jobPost.overview}}</p>
						</div>
						<div class="card-bt-info">
							<p class="card-subtitle">Pubblicato il {{ filterMyDate(jobPost.createdAt)}}</p>
							<a [routerLink]="['/view-job-post/'+jobPost._id]" class="card-link">
								<img src="./assets/images/ingeding/link-icon.svg" alt="Arrow" title="Arrow">
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="bottom-banner mt-192">
	<div class="container banner-bg">
		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-6">
				<div class="banner-b-caption">
					<h2> Ottieni accesso a tutti i contenuti in modo semplice e veloce.</h2>
					<p>I primi 3 mesi sono gratuiti, nessuna carta di credito richiesta.</p>
					<div class="btn-group"> <a href="javascript:void(0)" class="btn-white">Iscriviti ora</a> <a
							href="javascript:void(0)" class="btn-outline white">FAQ</a> </div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-6 main-left-text-box">
				<div class="phone-img"> <img src="./assets/images/ingeding/Free_iPhone_11_Pro_Mockup_3 1.png"
						alt="iphone" title="iphone"> </div>
			</div>
		</div>
	</div>
</section> -->
<!--BODY END-->