import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UserService extends CommonService {
  constructor(http: HttpClient) {
    super("user", http);
  }
  create(resource): Observable<any> {
    return this.http.post(this.baseUrl, resource);
  }
  setMailNotification(data: any) {
    return this.http.patch(this.baseUrl + "/mailnotification", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  fetchAllCard(data: any) {
    return this.http.post(this.baseUrl + "/getcustomecardDetail", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  deleteCard(data: any) {
    return this.http.post(this.baseUrl + "/deleteCard", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  deleteCurrentUser(data: any) {
    return this.http.post(this.baseUrl + "/deleteduser", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
