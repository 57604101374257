// Angular
import { Component, HostBinding, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../_services/translation.service';
import { LunguageselectionService } from './language-selector.service';
import { LanguageService } from '../../_services/language.service';
import { GridOption } from '../../_models/gridoptions.model';

@Component({
	selector: 'app-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LanguageSelectorComponent implements OnInit {
	// Public properties
	@Input() iconType: '' | 'brand';
	country: any;
	language: any;
	selectLanguage: any;
	languages: any;
	gridOption: GridOption = {
		pageTitle: 'select Page',
		page: 1,
		pagesize: 5,
		total: 0,
		order: '-_id',
		search: '',
		filter: {},
		sortField: '',
		sortOrder: '',
		skip: 0,
		limit: 0,
		allrecords: false
	};
	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 */
	// tslint:disable-next-line: max-line-length
	constructor(private translationService: TranslationService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private lunguageselectionService: LunguageselectionService,
		private languageService: LanguageService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// tslint:disable-next-line: triple-equals
		// this.countryselectionService.countryVisibilityChange.subscribe((value) => {
		// 	this.country = value;
		// });
		this.getLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});
	}



	initializeLanguageSelection() {
		const storedLanguage = localStorage.getItem('language_changes');
		if (storedLanguage) {
			const langObject = this.translationService.getSelectedLanguage();
			this.selectLanguage = this.languages.find(lang => lang._id === langObject._id);
		} else {
			this.selectLanguage = this.languages[0];
			this.translationService.setLanguage(this.selectLanguage);
		}
		this.cdr.markForCheck(); // Ensure change detection runs after setting the language
	}

	async getLanguage() {

		this.languages = await this.languageService.getAllLanguage();
		this.initializeLanguageSelection()
		this.cdr.markForCheck();
	}
	/**
	 * Set language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		this.lunguageselectionService.changeLanguage(lang)
	}

	/**
	 * Set selected language
	 */
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}
}
