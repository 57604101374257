// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


// Production Env  of client
export const environment = {
	production: true,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	frontUrl: 'https://ingeding.it/', // local
	frontendUrl: 'https://ingeding.it/', // local
	baseURL: 'https://ingeding.it:3301/api/v1/',
	PDFURL: 'https://ingeding.it/',
	imgAzureUrl: 'https://ingedingstorage.blob.core.windows.net',
	imgBucketUrl: 'https://ingeding.it:3301/ingeding',
	accesskeyId: 'AKIAIGR2SBDQJRDDFELA',
	countrydropdown : true,
	languagedropdown: true,
};

//Dev Env  of client
// export const environment = {
// 	production: false,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
// 	frontUrl: 'https://ingeding.it/', // local
// 	frontendUrl: 'https://ingeding.it/', // local
// 	baseURL: 'https://ingeding.it:3302/api/v1/', //
// 	PDFURL: 'https://ingeding.it/', //
// 	imgAzureUrl: 'https://ingedingstorage.blob.core.windows.net',
// 	imgBucketUrl: 'https://ingeding.it:3302/ingeding',
// 	accesskeyId: 'AKIAIGR2SBDQJRDDFELA',
// 	countrydropdown: true,
// 	languagedropdown: true,
// };

// export const environment = {
// 	production: false,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
// 	frontUrl: 'http://localhost:4200/', // local
// 	frontendUrl: 'http://localhost:4200/', // local
// 	baseURL: 'http://localhost:3301/api/v1/', //
// 	PDFURL: 'http://localhost:4200/', //
// 	imgAzureUrl: 'https://ingedingstorage.blob.core.windows.net',
// 	imgBucketUrl: 'https://ingeding.it:3301/ingeding',
// 	accesskeyId: 'AKIAIGR2SBDQJRDDFELA',
// 	countrydropdown : true,
// 	languagedropdown: true,
// };
