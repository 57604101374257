import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { GridOption } from "../../_models/gridoptions.model";
import { FrontHomeService } from "../../_services/fronthome.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  dataArray: any;
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  cmsImageUrl: any = environment.imgBucketUrl + "/cms/";
  constructor(
    private frontHomeService: FrontHomeService,
    private cdr: ChangeDetectorRef
  ) {
    this.getData();
  }

  ngOnInit() { }

  getData() {
    this.frontHomeService.searchAll(this.gridOption).subscribe((res) => {
      this.dataArray = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
}
