import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { GridOption } from "../../_models/gridoptions.model";
import { FrontHomeService } from "../../_services/fronthome.service";
import { LunguageselectionService } from "src/app/_layout/language-selector/language-selector.service";
import { Subscription } from "rxjs";
import { TranslationService } from "src/app/_services/translation.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  dataArray: any;
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  cmsImageUrl: any = environment.imgBucketUrl + "/cms/";
  subscription: Subscription;
  currentLang: any;
  langobj: any;
  constructor(
    private frontHomeService: FrontHomeService,
    private cdr: ChangeDetectorRef,
    private lunguageselectionService: LunguageselectionService,
    private translationService: TranslationService,
  ) {
    //this.getData();
    this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
      // // this.setLanguage()
      // this.currentLang = JSON.parse(localStorage.getItem('language'));
      this.langobj = value;
      // // if (this.currentLang.locale !== this.langobj.locale) {
      this.translationService.setLanguage(this.langobj);

      this.getData();
    });
  }

  ngOnInit() { }

  getData() {
    this.frontHomeService.searchAll(this.gridOption).subscribe((res) => {
      this.dataArray = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
}
