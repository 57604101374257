import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AlertComponent } from "./_components";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InterceptService } from "./_services/intercept.service";
import { TranslateModule } from "@ngx-translate/core";
import { PrivacyComponent } from "./privacy/privacy.component";
import { ProfileComponent } from "./profile/profile.component";
import { TermsComponent } from "./terms/terms.component";
import { FronthomeComponent } from "./fronthome/fronthome.component";
import { HeaderComponent } from "../app/auth/header/header.component";
import { FooterComponent } from "../app/auth/footer/footer.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserProfileEditComponent } from "./user-profile-edit/user-profile-edit.component";
import { UserProfileViewComponent } from "./user-profile-view/user-profile-view.component";
import { MyPostsComponent } from "./my-posts/my-posts.component";
import { CookieOptions } from "angular2-cookie/core";
import { JobPostEditComponent } from "./job-post-edit/job-post-edit.component";
import { JobPostViewComponent } from "./job-post-view/job-post-view.component";
import { SearchJobPostComponent } from "./search-job-post/search-job-post.component";
import { SearchProfileComponent } from "./search-profile/search-profile.component";
import { FaqComponent } from "./faq/faq.component";
import { SafePipe } from "./pipes/safe.pipe";
import { ContactComponent } from "./contact/contact.component";
import { CookiesComponent } from "./cookies/cookies.component";
import { ImprintComponent } from "./imprint/imprint.component";
import { BestPracticeComponent } from "./best-practice/best-practice.component";
import { SubscriptionPaymentComponent } from "./subscription-payment/subscription-payment.component";
import { SitemapComponent } from "./sitemap/sitemap.component";
import { UpgradeAccountComponent } from "./upgrade-account/upgrade-account.component";
import { AboutUsComponent } from './about-us/about-us.component';
import { LanguageSelectorComponent } from "./_layout/language-selector/language-selector.component";
import { SharedModule } from "./shared/shared.module";

@NgModule({
  imports: [
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    NgbModule,
    TranslateModule.forRoot(),
    SharedModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    TermsComponent,
    PrivacyComponent,
    HeaderComponent,
    FooterComponent,
    FronthomeComponent,
    ProfileComponent,
    UserSettingsComponent,
    UserProfileEditComponent,
    UserProfileViewComponent,
    MyPostsComponent,
    JobPostEditComponent,
    JobPostViewComponent,
    SearchJobPostComponent,
    SearchProfileComponent,
    FaqComponent,
    SafePipe,
    ContactComponent,
    SitemapComponent,
    CookiesComponent,
    ImprintComponent,
    BestPracticeComponent,
    SubscriptionPaymentComponent,
    UpgradeAccountComponent,
    AboutUsComponent,
    // LanguageSelectorComponent,

  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
    { provide: CookieOptions, useValue: {} },
  ],
})
export class AppModule { }
