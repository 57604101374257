<!--BODY START-->
<section class="main-content-area sticky-space">
	<div class="container">
		<div class="row text-break">
			<div class="col-sm-12 col-md-12 col-lg-8 main-registration-area">
				<nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a [routerLink]="['']">home</a></li>
						<li class="breadcrumb-item active" aria-current="page">user settings</li>
					</ol>
				</nav>
				<div class="section-title">
					<h2>{{'HEADER.DROPDOWN.SETTINGS' | translate}}</h2>
				</div>
				<h3 class="blue-title mt-64">{{'PAGES.USER_SETTING.PROFILE_SETTING' | translate}}</h3>
				<hr>
				<form class="register-form" (ngSubmit)="f.form.valid" #f="ngForm">
					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.FIRST_NAME' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="form-row">
									<div class="col-md-2">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.TITLE' | translate}}" name="nameprefix"
											#nameprefix="ngModel" [(ngModel)]="userData.nameprefix">
									</div>
									<div class="col-md-5">
										<input type="text" class="form-control" name="firstname" placeholder="{{'PAGES.EDIT_PROFILE.FIRST_NAME' | translate}}"
											#firstname="ngModel" [(ngModel)]="userData.firstname" minlength="2"
											maxlength="50" required>
										<div *ngIf="f.submitted && firstname.errors?.required" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && firstname.errors?.minlength" class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && firstname.errors?.maxlength" class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && firstname.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-5">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.SURNAME' | translate}}" name="lastname"
											#lastname="ngModel" [(ngModel)]="userData.lastname" minlength="2"
											maxlength="50" required>
										<div *ngIf="f.submitted && lastname.errors?.required" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && lastname.errors?.minlength" class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && lastname.errors?.maxlength" class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && lastname.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
					</div>
					<div *ngIf="regType==1">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.FIRST_NAME' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="c-box">
									<input type="text" class="form-control" name="companyname" placeholder="{{'PAGES.EDIT_PROFILE.FIRST_NAME' | translate}}"
										#companyname="ngModel" [(ngModel)]="userData.companyname" minlength="2"
										maxlength="50" required>
									<div *ngIf="f.submitted && (companyname.errors?.required || userData.companyname.trim().length === 0)"
										class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && companyname.errors?.minlength" class="text-danger">
										{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
									</div>
									<div *ngIf="f.submitted && companyname.errors?.maxlength" class="text-danger">
										{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
									</div>
									<div *ngIf="f.submitted && companyname.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
					</div>

					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.I_AM_I' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="topradiobuttons">
									<div class="toprdio toprdio-primary radio-inline"
										*ngFor="let iams of IamsArr;let i = index">
										<input type="radio" id="iams-radio{{i}}" value="{{iams._id}}" name="iams"
											(change)="iamsChange($event)" [checked]="selectedIams==iams._id?true:false">
										<label for="iams-radio{{i}}">{{iams.title}}</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" id="isNewIams" value="otherIams" name="iams"
											(click)="iamsChange($event)"
											[checked]="selectedIams=='otherIams'?true:false">
										<label for="isNewIams">{{'PAGES.USER_SETTING.OTHER' | translate}}</label>
									</div>
								</div>
								<div *ngIf="selectedIams && selectedIams=='otherIams'">
									<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.JOB_TITLE' | translate}}" name="newIams"
										#newIams="ngModel" [(ngModel)]="userData.newIams" id="newIams" />
									<div *ngIf="f.submitted && newIams.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
								</div>
								<div *ngIf="f.submitted && !userData.newIams && selectedIams=='otherIams'"
									class="text-danger">
									{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
								</div>
								<div *ngIf="f.submitted && selectedIams == ''" class="text-danger">
									{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
								</div>
							</div>
						</fieldset>
						<hr>
					</div>
					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.MATCH_IVA' | translate}}	{{!userData.isCollaborator?'*':'' }}</legend>
							<div class="col-sm-9">
								<div class="c-box">
									<input type="text" class="form-control" placeholder="IT01234567890" name="vatnumber"
										id="vatnumber" #vatnumber="ngModel" [(ngModel)]="userData.vatnumber"
										[required]="!userData.isCollaborator" />
									<div *ngIf="f.submitted && vatnumber.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<!-- <div *ngIf="f.submitted && vatnumber.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div> -->
									<label class="checkbox-button pt-3 pb-2">
										<input type="checkbox" class="checkbox-button__input" value="true"
											name="isCollaborator" [checked]="userData.isCollaborator"
											(click)="onClickCheckbox($event)">
										<span class="checkbox-button__control"></span>
										<span class="checkbox-button__label">{{'PAGES.USER_SETTING.OCCASIONAl_COLLABORATOR' | translate}}</span> </label>
									<p class="small-text">{{'PAGES.USER_SETTING.VAT_MSG' | translate}}</p>
								</div>
							</div>
						</fieldset>
					</div>
					<div *ngIf="regType==1">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.MATCH_IVA' | translate}}</legend>
							<div class="col-sm-9">
								<div class="c-box">
									<input type="text" class="form-control" placeholder="IT01234567890" name="vatnumber"
										id="vatnumber" #vatnumber="ngModel" [(ngModel)]="userData.vatnumber"
										required />
									<div *ngIf="f.submitted && vatnumber.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<!-- <div *ngIf="f.submitted && vatnumber.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div> -->
								</div>
							</div>
						</fieldset>
					</div>
					<hr>
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.TAX_ID_CODE' | translate}}</legend>
						<div class="col-sm-9">
							<div class="c-box">
								<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.TAX_ID_CODE' | translate}}"
									name="codicefiscale" id="codicefiscale" #codicefiscale="ngModel"
									[(ngModel)]="userData.codicefiscale" />
								<div *ngIf="f.submitted &&  codicefiscale.errors?.pattern" class="text-danger">
									{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
								</div>
							</div>
						</div>
					</fieldset>
					<hr>
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.RECIPIENT_CODE' | translate}}</legend>
						<div class="col-sm-9">
							<div class="c-box">
								<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.RECIPIENT_CODE' | translate}}"
									name="codicedestinatario" id="codicedestinatario" #codicedestinatario="ngModel"
									[(ngModel)]="userData.codicedestinatario" pattern="^[A-Za-z0-9]{7}$" />
								<div *ngIf="f.submitted && codicedestinatario.errors?.pattern" class="text-danger">
									{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
								</div>
							</div>
						</div>
					</fieldset>
					<hr>
					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.BILLING_ADDRESS' | translate}}
							</legend>
							<div class="col-sm-9">
								<div class="form-row mb-3">
									<div class="col-md-9">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.ADDRESS' | translate}}" name="paaddress"
											id="paaddress" #paaddress="ngModel" [(ngModel)]="userData.paaddress"
											minlength="2" maxlength="50">
										<div *ngIf="f.submitted && paaddress.errors?.minlength" class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && paaddress.errors?.maxlength" class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && paaddress.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.STREET_NUMBER' | translate}}" name="pastreetno"
											id="pastreetno" #pastreetno="ngModel" [(ngModel)]="userData.pastreetno">
										<div *ngIf="f.submitted && pastreetno.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="col-md-5">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.CITY' | translate}}" name="pacity"
											id="pacity" #pacity="ngModel" minlength="2" maxlength="50"
											[(ngModel)]="userData.pacity">
										<div *ngIf="f.submitted && pacity.errors?.minlength" class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && pacity.errors?.maxlength" class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && pacity.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-4">
										<select class="minimal form-control" name="paregion" id="paregion"
											#paregion="ngModel" [(ngModel)]="userData.paregion">
											<option value="">{{'PAGES.USER_SETTING.REGION' | translate}}</option>
											<option *ngFor="let region of RegionArr;let i = index" [value]="region._id">
												{{ region.translations.name }}
											</option>
										</select>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.POSTAL_CODE' | translate}}" name="papostcode"
											id="papostcode" #papostcode="ngModel" [(ngModel)]="userData.papostcode"
											pattern="^[0-9]+$">
										<div *ngIf="f.submitted && papostcode.errors?.pattern" class="text-danger">
											{{'PAGES.USER_SETTING.INVALIDATION_FIELD' | translate}}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
					</div>
					<div *ngIf="regType==1">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.WEBSITE' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="c-box">
									<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.WEBSITE' | translate}}" name="website"
										id="website" #website="ngModel" [(ngModel)]="userData.website" required />
									<div *ngIf="f.submitted && website.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && website.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.EMPLOYEE_AND_COLLABORATORS' | translate}}
							</legend>
							<div class="col-sm-9">
								<div class="topradiobuttons">
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="0" id="radio11"
											[(ngModel)]="countOfColo">
										<label for="radio11">1 - 10</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="1" id="radio12"
											[(ngModel)]="countOfColo">
										<label for="radio12">11 - 50</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="2" id="radio13"
											[(ngModel)]="countOfColo">
										<label for="radio13">51 - 250</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="3" id="radio14"
											[(ngModel)]="countOfColo">
										<label for="radio14">251 - 1000</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="4" id="radio15"
											[(ngModel)]="countOfColo">
										<label for="radio15">1001 - 5000</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="5" id="radio16"
											[(ngModel)]="countOfColo">
										<label for="radio16">5001 - 10000</label>
									</div>
									<div class="toprdio toprdio-primary radio-inline">
										<input type="radio" name="countOfColo" value="6" id="radio17"
											[(ngModel)]="countOfColo">
										<label for="radio17">10000 +</label>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.OPERATIONAl_HEADQUARTERS' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="form-row mb-3">
									<div class="col-md-9">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.ADDRESS' | translate}}"
											name="operationalheadquartersaddress" id="operationalheadquartersaddress"
											#operationalheadquartersaddress="ngModel"
											[(ngModel)]="userData.operationalheadquartersaddress" minlength="2"
											maxlength="50" required>
										<div *ngIf="f.submitted && operationalheadquartersaddress.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquartersaddress.errors?.minlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquartersaddress.errors?.maxlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquartersaddress.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.STREET_NUMBER' | translate}}"
											name="operationalheadquartersstreetno" id="operationalheadquartersstreetno"
											#operationalheadquartersstreetno="ngModel"
											[(ngModel)]="userData.operationalheadquartersstreetno" required>
										<div *ngIf="f.submitted && operationalheadquartersstreetno.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquartersstreetno.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="col-md-5">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.CITY' | translate}}"
											name="operationalheadquarterscity" id="operationalheadquarterscity"
											#operationalheadquarterscity="ngModel"
											[(ngModel)]="userData.operationalheadquarterscity" required>
										<div *ngIf="f.submitted && (operationalheadquarterscity.errors?.required || userData.operationalheadquarterscity.trim().length === 0)"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquarterscity.errors?.minlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquarterscity.errors?.maxlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquarterscity.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-4">
										<select class="minimal form-control" name="operationalheadquartersregion"
											id="operationalheadquartersregion" #operationalheadquartersregion="ngModel"
											[(ngModel)]="userData.operationalheadquartersregion" required>
											<option value="">{{'PAGES.USER_SETTING.REGION' | translate}}</option>
											<option *ngFor="let region of RegionArr;let i = index" [value]="region._id">
												{{ region.translations.name }}
											</option>
										</select>
										<div *ngIf="f.submitted && f.hasError('required', 'operationalheadquartersregion')"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="CAP"
											name="operationalheadquarterspostcode" id="operationalheadquarterspostcode"
											#operationalheadquarterspostcode="ngModel"
											[(ngModel)]="userData.operationalheadquarterspostcode" pattern="^[0-9]+$"
											required>
										<div *ngIf="f.submitted && operationalheadquarterspostcode.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && operationalheadquarterspostcode.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.REGISTERED_OFFICE' | translate}}*</legend>
							<div class="col-sm-9">
								<div class="form-row">
									<div class="col-md-12">
										<label class="checkbox-button ">
											<input type="checkbox" class="checkbox-button__input" name="sameaddress"
												(click)="sameAddress()">
											<span class="checkbox-button__control"></span>
											<span class="checkbox-button__label">{{'PAGES.USER_SETTING.REG_OFC_AS_SAME' | translate}}</span> </label>
									</div>
								</div>
								<div class="form-row mb-3">
									<div class="col-md-9">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.ADDRESS' | translate}}"
											name="registeredofficeaddress" id="registeredofficeaddress"
											#registeredofficeaddress="ngModel"
											[(ngModel)]="userData.registeredofficeaddress" minlength="2" maxlength="50"
											required>
										<div *ngIf="f.submitted && registeredofficeaddress.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficeaddress.errors?.minlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficeaddress.errors?.maxlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficeaddress.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.STREET_NUMBER' | translate}}"
											name="registeredofficestreetno" id="registeredofficestreetno"
											#registeredofficestreetno="ngModel"
											[(ngModel)]="userData.registeredofficestreetno" required>
										<div *ngIf="f.submitted && registeredofficestreetno.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficestreetno.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
								<div class="form-row">
									<div class="col-md-5">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.CITY' | translate}}"
											name="registeredofficecity" id="registeredofficecity"
											#registeredofficecity="ngModel" [(ngModel)]="userData.registeredofficecity"
											required>
										<div *ngIf="f.submitted && (registeredofficecity.errors?.required || userData.registeredofficecity.trim().length === 0)"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficecity.errors?.minlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficecity.errors?.maxlength"
											class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficecity.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-4">
										<select class="minimal form-control" name="registeredofficeregion"
											id="registeredofficeregion" #registeredofficeregion="ngModel"
											[(ngModel)]="userData.registeredofficeregion" required>
											<option value="">{{'PAGES.USER_SETTING.REGION' | translate}}</option>
											<option *ngFor="let region of RegionArr;let i = index" [value]="region._id">
												{{ region.translations.name }}
											</option>
										</select>
										<div *ngIf="f.submitted && f.hasError('required', 'registeredofficeregion')"
											class="text-danger">
											{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
										</div>
									</div>
									<div class="col-md-3">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.POSTAL_CODE' | translate}}"
											name="registeredofficepostcode" id="registeredofficepostcode"
											#registeredofficepostcode="ngModel"
											[(ngModel)]="userData.registeredofficepostcode" pattern="^[0-9]+$" required>
										<div *ngIf="f.submitted && registeredofficepostcode.errors?.required"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
										</div>
										<div *ngIf="f.submitted && registeredofficepostcode.errors?.pattern"
											class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
					</div>
					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.REGISTERED_IN_THE_REGISTER' | translate}}</legend>
							<div class="col-sm-9">
								<div class="form-row">
									<div class="col-md-6">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.CITY' | translate}}" #city_id="ngModel"
											[(ngModel)]="userData.city_id" id="city_id" name="city_id" minlength="2"
											maxlength="50">
										<div *ngIf="f.submitted && city_id.errors?.minlength" class="text-danger">
											{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && city_id.errors?.maxlength" class="text-danger">
											{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
										</div>
										<div *ngIf="f.submitted && city_id.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
									<div class="col-md-6">
										<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.IDENTIFICATION_NUMBER' | translate}}"
											name="idnumber" id="idnumber" #idnumber="ngModel"
											[(ngModel)]="userData.idnumber" pattern="^[0-9]+$">
										<div *ngIf="f.submitted && idnumber.errors?.pattern" class="text-danger">
											{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
						<hr>
					</div>
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.ACCOUNT_ADMINISTRATION' | translate}}*
						</legend>
						<div class="col-sm-9">
							<div class="form-row">
								<div class="col-md-2">
									<select class="minimal form-control" #country_id="ngModel"
										[(ngModel)]="userData.country_id" name="country_id" required>
										<option value="">{{'PAGES.EDIT_PROFILE.PREFIX' | translate}}</option>
										<option *ngFor="let country of CountryArr;let i = index" [value]="country._id">
											+ {{ country.phone_code }}
										</option>
									</select>
									<div *ngIf="f.submitted && f.hasError('required', 'country_id')"
										class="text-danger">
										{{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
									</div>
								</div>
								<div class="col-md-5">
									<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.TELEPHONE' | translate}}"
										[(ngModel)]="userData.contact_no" name="contact_no" required
										#contact_no="ngModel" pattern="^[0-9]+$" />
									<div *ngIf="f.submitted && contact_no.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && contact_no.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && contact_no.errors?.minlength" class="text-danger">
										{{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
									</div>
									<div *ngIf="f.submitted && contact_no.errors?.maxlength" class="text-danger">
										{{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
									</div>
									<div *ngIf="phoneduplicate" class="text-danger">
										{{phoneduplicate}}
									</div>
								</div>
								<div class="col-md-5">
									<input type="text" class="form-control" placeholder="{{'PAGES.VIEW_PROFILE.EMAIL' | translate}}"
										[(ngModel)]="userData.email" name="email" #email="ngModel" required
										pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
									<div *ngIf="f.submitted && email.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="f.submitted && email.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
									<div *ngIf="emailduplicate" class="text-danger">
										{{emailduplicate}}
									</div>
								</div>
							</div>
							<div class="form-row mt-3" *ngIf="regType==1">
								<div class="col-md-7">
									<input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.PEC' | translate}}" [(ngModel)]="userData.pec"
										name="pec" #pec="ngModel" required>
									<div *ngIf="f.submitted && pec.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-12">
									<div class="c-box">
										<p class="small-text mt-3">{{'PAGES.USER_SETTING.CONTACT_MSG' | translate}}</p>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<hr>
					<div *ngIf="regType==0">
						<fieldset class="form-group row">
							<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.IDENTITY_CARD' | translate}}</legend>
							<div class="col-sm-9">
								<div class="form-row">
									<div class="col-md-12">
										<p>{{'PAGES.USER_SETTING.UPLOAD_MSG' | translate}}</p>
										<div class="form-group">
											<div class="dropzone-wrapper mb-2">
												<div class="dropzone-desc">
													<img src="./assets/images/ingeding/upload-icon.svg" alt="upload"
														title="Upload">
													<h4>{{'PAGES.USER_SETTING.DRAG_AND_DROP' | translate}}</h4>
													<a href="#">{{'PAGES.EDIT_PROFILE.SELECT_FILE' | translate}}</a>
													<p>{{'PAGES.EDIT_PROFILE.FILE' | translate}}</p>
												</div>
												<input type="file" name="img_logo" id="img_logo" class="dropzone"
													(dragenter)="fileInput.value = null" #fileInput
													(click)="fileInput.value = null" multiple="multiple"
													(change)="onFilesAdded($event)" accept="image/png, image/jpeg, .pdf"
													required>
											</div>
										</div>
										<div class="row" *ngFor="let file of filesName;let i = index">
											<div class="col-sm-12 col-md-8 file-upload-t">
												<ul>
													<li alt="Done" title="Done">
														<img src="./assets/images/ingeding/done.svg" alt="Done"
															title="Done">{{ file.name }}
														<a *ngIf="file._id" href="{{documentBucket + file.name}}"
															target="_blank" download>
															<img src="./assets/images/ingeding/download.svg"
																alt="Download" title="Download">
														</a>
													</li>
												</ul>
											</div>
											<div class="col-sm-12 col-md-4 file-size-t">
												<ul>
													<li>
														{{ Math.floor(file.size / 1024) > 1000 ? Math.floor(file.size /
														1048576)+' MB ' : Math.floor(file.size / 1024)+' KB ' }}
														<i class="fa fa-trash-o red" aria-hidden="true"
															(click)="openPopup('deleteThis',i)"></i>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<hr *ngIf="regType==0">
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.CHOOSE_YOUR_AVATAR' | translate}}</legend>
						<div class="col-sm-9">
							<div id="gallery">
								<div id="image-gallery" *ngIf="displayedAvatar.length > 0">
									<div class="row">
										<div class="col-lg-2 col-md-3 col-sm-3 col-xs-3 col-4 avatar"
											*ngFor="let myAvatar of displayedAvatar;let i = index">
											<div class="avt-wrapper" (click)="chooseThisAvatar(myAvatar._id)">
												<img src="{{ avatarBucket + myAvatar.avatar }}" height="100%"
													width="100%" alt="Photo-gallery-{{i}}"
													[ngClass]="{'img-responsive': true, 'my-box-border': myAvatar._id == userData.avatar}">
											</div>
										</div>
										<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
											<span class="float-right">
												<a *ngIf="showMoreBtn" href="javascript:void(0)"
													(click)="showMore()">{{'HOMEPAGE.SHOW_MORE' | translate}} <i
														class="fa fa-arrow-down"></i></a>
												<a *ngIf="showLessBtn" href="javascript:void(0)"
													(click)="showLess()">{{'PAGES.USER_SETTING.SHOW_LESS' | translate}} <i class="fa fa-arrow-up"></i></a>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<hr>
					<div class="form-group row mb-64">
						<div class="col-sm-10 inline-btn">
							<button type="submit" (click)="onSubmit(f)" class="btn-aqua">{{'PAGES.EDIT_PROFILE.SAVE' | translate}}</button>
							<a href="javascript:void(0)" (click)="openPopup('userdelete')"
								class="text-danger ml-4">{{'PAGES.USER_SETTING.DELETE_ACCOUNT' | translate}}</a>
						</div>
					</div>
				</form>
				<form class="register-form" (ngSubmit)="l.form.valid" #l="ngForm">
					<h3 class="blue-title mt-64">{{'PAGES.USER_SETTING.CHANGE_PASSWORD' | translate}}</h3>
					<hr>
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.CURRENT_PASSWORD' | translate}}</legend>
						<div class="col-sm-9">
							<div class="form-row">
								<div class="col-md-6">
									<input type="password" class="form-control" id="oldpassword" #oldpassword="ngModel"
										[(ngModel)]="changePass.oldpassword" placeholder="{{'PAGES.USER_SETTING.CURRENT_PASSWORD' | translate}}"
										autocomplete="off" name="oldpassword" required
										pattern="^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-~`!' '|@#ß$%^:+=_&*\/;<>,.?'\[\]{}()\\\\]).*$">
									<div *ngIf="l.submitted && oldpassword.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="l.submitted && oldpassword.errors?.pattern" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<hr>
					<fieldset class="form-group row">
						<legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.USER_SETTING.NEW_PASSWORD' | translate}}</legend>
						<div class="col-sm-9">
							<div class="form-row">
								<div class="col-md-6">
									<input type="password" class="form-control" id="password" #password="ngModel"
										[(ngModel)]="changePass.password" placeholder="{{'PAGES.USER_SETTING.NEW_PASSWORD' | translate}}"
										autocomplete="off" name="password" required
										pattern="^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-~`!' '|@#ß$%^:+=_&*\/;<>,.?'\[\]{}()\\\\]).*$">
									<div *ngIf="l.submitted && password.errors?.required" class="text-danger">
										{{'PAGES.USER_SETTING.PASSWORD_IS_REQUIRED' | translate}}
									</div>
									<div *ngIf="l.submitted && password.errors?.pattern" class="text-danger">
										{{'PAGES.USER_SETTING.PASSWORD_IS_INVALID' | translate}}
									</div>
								</div>
								<div class="col-md-6">
									<input type="password" class="form-control" id="confirmpassword" autocomplete="off"
										placeholder="{{'PAGES.USER_SETTING.REPEAT_YOUR_PASSWORD' | translate}}" name="confirmpassword" required
										#confirmpassword="ngModel" [(ngModel)]="changePass.confirmpassword">
									<div *ngIf="l.submitted && confirmpassword.errors?.required" class="text-danger">
										{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
									</div>
									<div *ngIf="l.submitted && changePass.confirmpassword != changePass.password"
										class="text-danger">
										{{'PAGES.USER_SETTING.PASSWORD_ERROR_MSG' | translate}}
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="col-md-12">
									<div class="c-box">
										<p class="small-text mt-3">{{'PAGES.USER_SETTING.PASSWORD_VALIDATION_MSG' | translate}}</p>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<div class="form-group row mb-64">
						<div class="col-sm-10 inline-btn">
							<button type="submit" (click)="onChangePassword(l)" class="btn-aqua">{{'PAGES.EDIT_PROFILE.SAVE' | translate}}</button>
						</div>
					</div>
				</form>
				<h3 class="blue-title mt-64">{{'PAGES.USER_SETTING.NOTIFICATION_SETTING' | translate}}</h3>
				<hr>
				<div class="form-group row">
					<legend class="col-form-label col-3 col-sm-3 float-sm-left pt-0">
						<label class="checkbox-button pl-3 pt-3 pb-2">
							<input type="checkbox" class="checkbox-button__input" [checked]="mailnotification == 1"
								(change)="onMailNotificationChange($event)">
							<span class="checkbox-button__control"></span>
							<span class="checkbox-button__label"></span>
						</label>
					</legend>
					<div class="col-8 col-sm-9">
						{{'PAGES.USER_SETTING.NOTIFY_MSG' | translate}}
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-4">
				<div class="right-listing">
					<h4>{{'PAGES.USER_SETTING.EDIT_YOUR_PUBLIC_PROFILE' | translate}}</h4>
					<p>{{'PAGES.USER_SETTING.EDIT_PROFILE_MSG' | translate}}
					</p>
					<button class="btn-aqua" [routerLink]="['/edit-profile']">{{'HEADER.DROPDOWN.EDIT_PROFILE' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</section>
<ng-template #deleteUserModel let-modal>
	<div class="login-main backdrop ">
		<div class="popup">
			<div class="modal-body">
				<div class="col-sm-12 col-md-12 main-registration-area">
					<form class="logform p-0" (ngSubmit)="du.form.valid && deleteUser(du)" #du="ngForm">
						<button type="button" class="close" (click)="cancel()">&times;</button>
						<h2 class="mb-5 card-title">{{'PAGES.USER_SETTING.DELETE_ACCOUNT' | translate}}</h2>
						<div class="mb-1">
							<p class="small-text">{{'PAGES.USER_SETTING.ACCOUNT_DELETE_PERMANENTLY' | translate}}</p>
							<p class="small-text">{{'PAGES.USER_SETTING.ARE_YOU_SURE' | translate}}</p>
						</div>
						<div class="form-row mb-5">
							<input type="password" class="form-control" id="deletePassword" [(ngModel)]="deletePassword"
								placeholder="{{'PAGES.USER_SETTING.PASSWORD' | translate}}" autocomplete="off" name="deletePassword" required>
							<div *ngIf="du.submitted && du.hasError('required', 'deletePassword')" class="text-danger">
								{{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
							</div>
						</div>
						<button type="submit" class="btn-aqua btn-block loginbtn"
							style="background-color:#D70000;border:none;">{{'PAGES.USER_SETTING.DELETE' | translate}}</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #confirmModel let-modal>
	<div class="login-main backdrop">
		<div class="popup">
			<div class="modal-header">
				<span class="ribbon top-left ribbon-primary">
					<h4>{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</h4>
				</span>
			</div>
			<div class="modal-body">
				<p class="mt-2">{{'PAGES.UPGRADE_ACCOUNT.DELETE_PERMANTELY' | translate}}</p>
				<div id="option">
					<div class="col-md-12 text-center">
						<button type="button" class="btn btn-danger" (click)="cancel()"
							style="background-color:black;border:none;">{{'PAGES.UPGRADE_ACCOUNT.NO' | translate}}</button>&nbsp;
						<button type="button" class="btn btn-success" style="background-color:#00ad9e;border:none;"
							(click)="delete('docs')">{{'PAGES.UPGRADE_ACCOUNT.YES' | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>