import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { GridOption } from "../_models/gridoptions.model";
import { FrontHomeService } from "../_services/fronthome.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  dataArray: any;
  cmsImageUrl: any = environment.imgBucketUrl + "/cms/";
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };
  constructor(private frontHomeService: FrontHomeService, private meta: Meta) {
    this.meta.addTag({ name: "robots", content: "noindex" });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getData();
  }

  getData() {
    this.frontHomeService.searchAll(this.gridOption).subscribe((res) => {
      this.dataArray = res.data;
    });
  }
}
