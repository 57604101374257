import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { CommonService } from "./data.service";

@Injectable({ providedIn: "root" })
export class UserProfileService extends CommonService {
  constructor(public http: HttpClient) {
    super("userProfile", http);
  }
  randomView(data: any) {
    return this.http.post(this.baseUrl + "/randomView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  relevantUserProfile(data: any) {
    return this.http.post(this.baseUrl + "/releventuserprofile", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getOneUserProfile(data: any) {
    return this.http.post(this.baseUrl + "/onerecordView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  checkProfile(data: any) {
    return this.http.get(this.baseUrl + "/checkProfile/" + data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
