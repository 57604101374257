<!--BODY START-->
<section class="main-content-area sticky-space">
    <div class="alert alert-danger text-center p-3" role="alert"
        *ngIf="checkProfileStatus!=undefined && !checkProfileStatus">
        <strong>{{'PAGES.VIEW_PROFILE.COMPLETE_YOUR_PROFILE' | translate}}</strong>
    </div>
    <div class="container">
        <form (ngSubmit)="f.form.valid" #f="ngForm">
            <div class="row text-break">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 main-registration-area">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">home</a></li>
                            <li class="breadcrumb-item active" aria-current="page" *ngIf="jobPost._id==undefined">create
                                job post</li>
                            <li class="breadcrumb-item active" aria-current="page" *ngIf="jobPost._id!=undefined">update
                                job post</li>
                        </ol>
                    </nav>
                    <div class="section-title">
                        <div class="row mt-5">
                            <div class="col-sm-12 col-lg-12 col-md-12 pt-0 ">
                                <h2 class="profile-name" id="jobtitle">{{ jobPost.jobtitle!='' &&
                                    jobPost.jobtitle!=null?jobPost.jobtitle: ('PAGES.USER_SETTING.TITLE' | translate) + '*' }}<a href="javascript:void(0);"
                                        (click)="edit($event,'jobtitle')">
                                        <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                            alt="Modifica" title="Modifica">
                                    </a>
                                </h2>
                                <div style="display: none;" id="edit-jobtitle">
                                    <input type="text" class="form-control" placeholder="{{'PAGES.USER_SETTING.TITLE' | translate}}"
                                        [(ngModel)]="jobPost.jobtitle" #jobtitle="ngModel" name="jobtitle"
                                        id="textbox-edit-jobtitle" (blur)="hide($event,'jobtitle',null,null,null)"
                                        minlength="2" maxlength="140" required>
                                    <!-- pattern="^[a-zA-Z0-9\s\\@#\,.\/\-_]+$" -->
                                </div>
                                <div *ngIf="f.submitted && jobtitle.errors?.required" class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                </div>
                                <div *ngIf="f.submitted && jobtitle.errors?.minlength" class=" text-danger">
                                    {{'PAGES.USER_SETTING.MIN_CHARACTER' | translate}}
                                </div>
                                <div *ngIf="f.submitted && jobtitle.errors?.maxlength" class=" text-danger">
                                    {{'PAGES.VIEW_JOB_POST.DESC_MAX_140_CHARACTER' | translate}}
                                </div>
                                <!-- <div *ngIf="f.submitted && jobtitle.errors?.pattern" class=" text-danger">
                                    Campo invalido
                                </div> -->
                                <p class="black-text mt-4 mb-0 mr-5" *ngIf="jobPost._id!=undefined">{{'PAGES.MY_POST.PUBLISHED_ON' | translate}}
                                    {{filterMyDate(jobPost.createdAt)}}</p>

                                <p *ngIf="regType == 1">{{'PAGES.VIEW_JOB_POST.FROM' | translate}} <span
                                        class="blue-text">{{localUserData.companyname?localUserData.companyname:''}}</span>
                                </p>
                                <p *ngIf="regType == 0">{{'PAGES.VIEW_JOB_POST.FROM' | translate}}  <span
                                        class="blue-text">{{localUserData.firstname?localUserData.firstname + ' ' +
                                        localUserData.lastname :''}}</span></p>

                                <h3 class="blue-title mt-4">{{'PAGES.VIEW_PROFILE.DESCRIPTION' | translate}}</h3>

                                <p class="mt-3 mb-0 mr-5" id="overview">
                                    {{jobPost.overview==""||jobPost.overview==null? ('PAGES.VIEW_JOB_POST.BRIEF_DESC' | translate ) + '*':jobPost.overview}}
                                    <a href="javascript:void(0);" (click)="edit($event,'overview')">
                                        <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                            alt="Modifica" title="Modifica">
                                    </a>
                                </p>
                                <div style="display: none;" id="edit-overview">
                                    <textarea type="text" class="form-control"
                                        placeholder="{{'PAGES.VIEW_JOB_POST.BRIEF_DESC' | translate}}*"
                                        [(ngModel)]="jobPost.overview" #overview="ngModel" name="overview"
                                        id="textbox-edit-overview" (blur)="hide($event,'overview',null,null,null)"
                                        minlength="4" maxlength="140" required></textarea>
                                </div>
                                <div *ngIf="f.submitted && overview.errors?.required" class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Side Box-->
                    <div class="right-listing">
                        <!-- Deadline -->
                        <div class="row">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.SEARCH_JOB_POST.DEADLINE' | translate}}*</legend>
                            <div class="col-sm-9">
                                <p class="card-subtitle mb-2" id="deadline">
                                    {{
                                    jobPost.deadline==""||jobPost.deadline==null? ('PAGES.EDIT_PROFILE.YOU_CHOOSE' | translate) :filterMyDate(jobPost.deadline)
                                    }}
                                    <a href="javascript:void(0);" (click)="edit($event,'deadline')">
                                        <img src="./assets/images/ingeding/calendar.svg" alt="calendar"
                                            title="calendar">
                                    </a>
                                </p>
                                <div style="display: none;" id="edit-deadline">
                                    <input type="date" class="form-control" [(ngModel)]="jobPost.deadline"
                                        #deadline="ngModel" name="deadline"
                                        (blur)="hide($event,'deadline',null,null,null)" [min]="today"
                                        id="textbox-edit-deadline" required>
                                </div>
                                <div *ngIf="f.submitted && deadline.errors?.required" class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                </div>
                            </div>
                        </div>
                        <hr class="white">

                        <div class="row">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.NUMBER_OF_HOUR_EXPECTED' | translate}}*</legend>
                            <div class="col-sm-9">
                                <p class="card-subtitle mb-2" id="noofhours">
                                    {{ jobPost.noofhours==""||jobPost.noofhours==null?"0":jobPost.noofhours }}
                                    <a href="javascript:void(0);" (click)="edit($event,'noofhours')">
                                        <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                            alt="Modifica" title="Modifica">
                                    </a>
                                </p>
                                <div style="display: none;" id="edit-noofhours">
                                    <input type="text" class="form-control" [(ngModel)]="jobPost.noofhours"
                                        #noofhours="ngModel" name="noofhours"
                                        (blur)="hide($event,'noofhours',null,null,null)" id="textbox-edit-noofhours"
                                        required>
                                    <!-- pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"> -->
                                </div>
                                <div *ngIf="f.submitted && noofhours.errors?.required" class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                </div>
                                <div *ngIf="f.submitted && noofhours.errors?.pattern" class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
                                </div>
                            </div>
                        </div>
                        <hr class="white">

                        <!-- Tariff -->
                        <div class="row">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.COMPENSATION' | translate}}*</legend>
                            <div class="col-sm-9">
                                <div *ngFor="let eachPack of jobPost.flatrate; let i = index">
                                    <div class="row">
                                        <!-- <div class="col-sm-12 col-md-6 black-text">
                                            <p class="mb-0" id="flatrate-name-{{i}}">Tariffa a progetto {{i+1}} </p>
                                        </div> -->
                                        <div class="col-sm-12 col-md-6 black-text">
                                            <p class="mb-0" id="flatrate-name-{{i}}">
                                                {{eachPack.name==""? ('PAGES.VIEW_JOB_POST.PROJECT_RATE' | translate) :eachPack.name}}
                                                <a href="javascript:void(0);"
                                                    (click)="edit($event,'flatrate-name-'+i, eachPack.name)">
                                                    <img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
                                                        alt="Modifica" title="Modifica">
                                                </a>
                                            </p>
                                            <div style="display: none;" id="edit-flatrate-name-{{i}}">
                                                <input type="text" class="form-control" placeholder="{{'PAGES.VIEW_JOB_POST.PROJECT_RATE' | translate}}"
                                                    name="packageName{{i}}" id="textbox-edit-flatrate-name-{{i}}"
                                                    [(ngModel)]="eachPack.name" #name="ngModel" required
                                                    (blur)="hide($event,'flatrate-name-'+i)" maxlength="50">
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 right-align">
                                            <h4 class="mb-0" id="flatrate-rate-{{i}}">
                                                {{eachPack.rate==""?"€ 0":"€ " + checkIfNumber(eachPack.rate)}}
                                                <a class="pr-1" href="javascript:void(0);"
                                                    (click)="edit($event,'flatrate-rate-'+i, eachPack.rate)">
                                                    <img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
                                                        alt="Modifica" title="Modifica">
                                                </a>
                                            </h4>
                                            <div style="display: none;" id="edit-flatrate-rate-{{i}}">
                                                <input type="text" class="form-control" placeholder="0"
                                                    name="flatrateRate{{i}}" id="textbox-edit-flatrate-rate-{{i}}"
                                                    (blur)="hide($event,'flatrate-rate-'+i)" [(ngModel)]="eachPack.rate"
                                                    #rate="ngModel" required
                                                    pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"
                                                    maxlength="7">
                                            </div>
                                            <h4 class="mb-0" (click)="deleteThis($event, i, jobPost.flatrate)">
                                                <i class="fa fa-trash-o red ml-1" aria-hidden="true" title="Delete"></i>
                                            </h4>
                                        </div>
                                    </div>
                                    <p class="small-text" id="flatrate-desc-{{i}}">{{eachPack.desc==""? ('PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate) :eachPack.desc}}
                                        <a href="javascript:void(0);"
                                            (click)="edit($event,'flatrate-desc-'+i, eachPack.desc)">
                                            <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                                alt="Modifica" title="Modifica">
                                        </a>
                                    </p>
                                    <div style="display: none;" id="edit-flatrate-desc-{{i}}">
                                        <input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate}}"
                                            name="flatrateDesc{{i}}" id="textbox-edit-flatrate-desc-{{i}}"
                                            [(ngModel)]="eachPack.desc" #desc="ngModel" required
                                            (blur)="hide($event,'flatrate-desc-'+i)" maxlength="140">
                                    </div>
                                    <div *ngIf="f.submitted && (name.errors?.required || desc.errors?.required || rate.errors?.required)"
                                        class="text-danger">
                                        {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && name.errors?.maxlength" class=" text-danger">
                                        {{'PAGES.USER_SETTING.MAX_CHARACTER' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && desc.errors?.maxlength" class=" text-danger">
                                        {{'PAGES.VIEW_JOB_POST.DESC_MAX_140_CHARACTER' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && rate.errors?.pattern" class=" text-danger">
                                        {{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && rate.errors?.maxlength" class=" text-danger">
                                        {{'PAGES.VIEW_JOB_POST.RATE_MAX_7_CHARACTER' | translate}}
                                    </div>
                                    <hr class="white-b">
                                </div>

                                <!-- Hourly Rate-->
                                <div *ngFor="let eachRate of jobPost.hourlyrate; let i = index">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 black-text">{{'PAGES.VIEW_PROFILE.HOURLY_RATE' | translate}}</div>
                                        <div class="col-sm-12 col-md-6 right-align">
                                            <h4 class="mb-0" id="hourlyrate-rate-{{i}}">{{eachRate.rate==""?"€ 0":"€
                                                "+checkIfNumber(eachRate.rate)}}
                                                <a class="pr-1" href="javascript:void(0);"
                                                    (click)="edit($event,'hourlyrate-rate-'+i, eachRate.rate)">
                                                    <img class="gray-edit-p" src="./assets/images/ingeding/pencil.svg"
                                                        alt="Modifica" title="Modifica">
                                                </a>
                                            </h4>
                                            <div style="display: none;" id="edit-hourlyrate-rate-{{i}}">
                                                <input type="text" class="form-control" placeholder="0"
                                                    name="rateRate{{i}}" id="textbox-edit-hourlyrate-rate-{{i}}"
                                                    (blur)="hide($event,'hourlyrate-rate-'+i)"
                                                    [(ngModel)]="eachRate.rate" #rate="ngModel" required
                                                    pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"
                                                    maxlength="7">
                                            </div>
                                            <h4 class="mb-0" (click)="deleteThis($event, i, jobPost.hourlyrate)">
                                                <i class="fa fa-trash-o red ml-1" aria-hidden="true" title="Delete"></i>
                                            </h4>
                                        </div>
                                    </div>
                                    <p class="small-text" id="rate-desc-{{i}}">{{eachRate.desc==""? ('PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate) :eachRate.desc}}
                                        <a href="javascript:void(0);"
                                            (click)="edit($event,'rate-desc-'+i, eachRate.desc)">
                                            <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                                alt="Modifica" title="Modifica">
                                        </a>
                                    </p>
                                    <div style="display: none;" id="edit-rate-desc-{{i}}">
                                        <input type="text" class="form-control" placeholder="{{'PAGES.EDIT_PROFILE.BRIEF_DESCRIPTION' | translate}}"
                                            name="rateDesc{{i}}" id="textbox-edit-rate-desc-{{i}}"
                                            [(ngModel)]="eachRate.desc" #desc="ngModel" required
                                            (blur)="hide($event,'rate-desc-'+i)" maxlength="140">
                                    </div>
                                    <div *ngIf="f.submitted && (desc.errors?.required || rate.errors?.required)"
                                        class=" text-danger">
                                        {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && rate.errors?.pattern" class=" text-danger">
                                        {{'PAGES.UPGRADE_ACCOUNT.INVALID_FIELD' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && rate.errors?.maxlength" class=" text-danger">
                                        {{'PAGES.VIEW_JOB_POST.RATE_MAX_7_CHARACTER' | translate}}
                                    </div>
                                    <hr class="white-b">
                                </div>
                                <div *ngIf="f.submitted && jobPost.flatrate.length == 0 && jobPost.hourlyrate.length == 0"
                                    class=" text-danger">
                                    {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                </div>
                                <div class="mt-2 add-skill-w" *ngIf="jobPost.hourlyrate.length == 0">
                                    <p class="mb-0 blue-text">{{'PAGES.VIEW_JOB_POST.ADD_FEE_TO_PROJECT' | translate}}
                                        <a href="javascript:void(0);" class="pull-right"
                                            (click)="addMore(jobPost.flatrate, 'flatrate')">
                                            <img src="./assets/images/ingeding/add.svg" alt="add" title="add" width="24"
                                                height="24">
                                        </a>
                                    </p>
                                </div>
                                <div class="mt-3 add-skill-w" *ngIf="jobPost.flatrate.length == 0">
                                    <p class="mb-0 blue-text"> {{'PAGES.EDIT_PROFILE.ADD_HOURLY_RATE' | translate}}
                                        <a href="javascript:void(0);" class="pull-right"
                                            (click)="addMore(jobPost.hourlyrate, 'hourlyrate')">
                                            <img src="./assets/images/ingeding/add.svg" alt="add" title="add" width="24"
                                                height="24">
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr class="white">

                        <div class="row">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_JOB_POST.WORKPLACE' | translate}}*</legend>
                            <div class="col-sm-9">
                                <select class="minimal form-control" #placeofwork="ngModel"
                                    [(ngModel)]="jobPost.placeofwork" name="placeofwork" required>
                                    <option value="">{{'PAGES.VIEW_JOB_POST.WORKPLACE' | translate}}</option>
                                    <option value="000000a0aa00a000000aa000">{{'PAGES.SEARCH_JOB_POST.REMOTELY' | translate}}</option>
                                    <option *ngFor="let place of RegionArr" value="{{place._id}}">
                                        {{ place.translations.name }}
                                    </option>
                                </select>
                                <div *ngIf="f.submitted && f.hasError('required', 'placeofwork')" class=" text-danger">
                                    {{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- End Side Box-->

                    <!-- Ambito lavorativo Done-->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.WORKING_ENVIRONMENT' | translate}}*</legend>
                        <div class="col-sm-9">
                            <div class="topradiobuttons">
                                <div class="toprdio toprdio-primary radio-inline"
                                    *ngFor="let category of CategoryArr;let i = index">
                                    <input type="radio" id="category-radio{{i}}" value="{{category._id}}"
                                        name="category" (change)="categoryChange($event)"
                                        [checked]="jobPost.category==category._id">
                                    <label for="category-radio{{i}}">{{category.translations.title}}</label>
                                </div>
                            </div>
                            <div *ngIf="f.submitted && jobPost.category == ''" class=" text-danger">
                                {{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Tasks Done-->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.SKILLS' | translate}}*</legend>
                        <div class="col-sm-9">
                            <div class="c-box">
                                <label class="checkbox-button" *ngFor="let designation of JobDesignationArr">
                                    <input type="checkbox" class="checkbox-button__input" value="{{designation._id}}"
                                        (change)="designationChange($event)"
                                        [checked]="jobPost.jobdesignations!=undefined && jobPost.jobdesignations.includes(designation._id)"
                                        name="designation[]">
                                    <span class="checkbox-button__control"></span>
                                    <span class="checkbox-button__label">{{ designation.translations.title }}</span>
                                </label>
                                <div *ngIf="f.submitted && jobPost.jobdesignations.length == 0" class=" text-danger">
                                    {{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>

                    <!-- Hashtags Done-->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.HASHTAG' | translate}}*</legend>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12"
                                    *ngFor="let tags of TagsArr;let i = index">
                                    <div class="c-box">
                                        <label class="checkbox-button">
                                            <input type="checkbox" class="checkbox-button__input" value="{{tags._id}}"
                                                (change)="tagsChange($event)"
                                                [checked]="jobPost.tags!=undefined && jobPost.tags.includes(tags._id)"
                                                name="tags[]">
                                            <span class="checkbox-button__control"></span>
                                            <span class="checkbox-button__label">{{ tags.translations.title }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="f.submitted && jobPost.tags.length == 0" class=" text-danger">
                                {{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
                            </div>
                        </div>
                    </div>
                    <hr />

                    <!-- Photo Gallary -->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.PHOTO_GALLERY' | translate}}</legend>
                        <div class="col-sm-9">
                            <div id="gallery" class="edit-gallery">
                                <div id="image-gallery">
                                    <div class="row pt-0">
                                        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 image "
                                            *ngFor="let photo of photoArr;let i = index">
                                            <div class="img-wrapper">
                                                <img src="{{ photo.url?photo.url:photosBucket+photo.name }}"
                                                    alt="Photo-gallery-{{i}}" class="img-responsive" height="100%"
                                                    width="auto">
                                                <div class="img-overlay">
                                                    <a href="{{ photo.url?photo.url:photosBucket+photo.name }}"></a>
                                                    <a href="javascript:void(0)" class="openGallery"><i
                                                            class="fa fa-eye" aria-hidden="true" title="View"></i></a>
                                                    <a href="javascript:void(0);" (click)=removePhoto(i)>
                                                        <i class="fa fa-trash-o red" style="color:red"
                                                            aria-hidden="true" title="Delete"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- photosBucket -->
                                        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 add-plus">
                                            <div class="img-wrapper add-image">
                                                <a href="javascript:void(0)" (click)="openFileSelector('gallery')">
                                                    <i class="fa fa-plus"></i>
                                                </a>
                                            </div>
                                            <input type="file" id="gallery" style="display:none;" multiple="multiple"
                                                #fileInput1 (click)="fileInput1.value = null"
                                                (change)="onGalleryAdded($event)" accept="image/png, image/jpeg">
                                        </div>
                                        <!-- <div *ngIf="f.submitted && photoArr.length==0" class=" text-danger">
                                            Please upload document.
                                        </div> -->
                                    </div>
                                    <p class="small-text pl-3 pt-3">{{'PAGES.EDIT_PROFILE.UPLOAD_IMG_DESC' | translate}}</p>
                                    <!-- End row -->
                                </div>
                                <div id="overlay" style="display: none;">
                                    <div id="prevButton"><i class="fa fa-angle-left"></i></div>
                                    <img alt="images" id="myGalleryImage">
                                    <div id="nextButton"><i class="fa fa-angle-right"></i></div>
                                    <div id="exitButton"><i class="fa fa-times"></i></div>
                                </div>
                                <!-- End image gallery -->
                            </div>
                        </div>
                    </div>

                    <h3 class="blue-title mt-64">{{'PAGES.VIEW_JOB_POST.DETAILS' | translate}}</h3>
                    <hr />

                    <!-- Description -->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.DESCRIPTION' | translate}}*</legend>
                        <div class="col-sm-9">
                            <div class="form-row">
                                <div class="col-sm-12">
                                    <p class="mb-0" id="description" style="white-space: pre-line;">
                                        {{jobPost.description==""||jobPost.description==null? ('PAGES.VIEW_JOB_POST.DETAIL_JOB_DESC' | translate) :jobPost.description}}
                                        <a href="javascript:void(0);" (click)="edit($event,'description')">
                                            <img class="gray-edit-p" src="./assets/images/ingeding/gray-pencil.svg"
                                                alt="Modifica" title="Modifica">
                                        </a>
                                    </p>
                                    <div style="display: none;" id="edit-description">
                                        <textarea class="form-control" rows="10"
                                            style="resize: none; height: auto !important; " minlength="1"
                                            maxlength="800" (blur)="hide($event,'description',null,null,null)"
                                            [(ngModel)]="jobPost.description" #description="ngModel" name="description"
                                            id="textbox-edit-description" required>
                                            {{'PAGES.VIEW_JOB_POST.DETAIL_JOB_DESC' | translate}}
                                        </textarea>
                                    </div>
                                    <div *ngIf="f.submitted && description.errors?.required" class=" text-danger">
                                        Campo richiesto
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <!-- Documents Done-->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.VIEW_PROFILE.DOCUMENTS' | translate}}</legend>
                        <div class="col-sm-9">
                            <p>{{'PAGES.VIEW_JOB_POST.UPLOAD_FILE_DESC' | translate}}</p>
                            <div class="form-group">
                                <div class="dropzone-wrapper mb-2">
                                    <div class="dropzone-desc">
                                        <img src="./assets/images/ingeding/upload-icon.svg" alt="upload" title="Upload">
                                        <h4>{{'PAGES.USER_SETTING.DRAG_AND_DROP' | translate}}</h4>
                                        <a href="javascript:void(0);">{{'PAGES.EDIT_PROFILE.SELECT_FILE' | translate}}</a>
                                        <p>{{'PAGES.EDIT_PROFILE.FILE' | translate}}</p>
                                    </div>
                                    <input type="file" name="img_logo" id="img_logo" class="dropzone" #fileInput2
                                        (dragenter)="fileInput2.value = null" (click)="fileInput2.value = null"
                                        multiple="multiple" (change)="onDocumentAdded($event)"
                                        accept="image/png, image/jpeg, .pdf">
                                </div>
                                <!-- <div *ngIf="f.submitted && documentArr.length==0" class=" text-danger">
                                    Please upload document.
                                </div> -->
                            </div>
                            <div class="row" *ngFor="let doc of documentArr;let i = index">
                                <div class="col-sm-12 col-md-8 file-upload-t">
                                    <ul>
                                        <li alt="Done" title="Done">
                                            <img src="./assets/images/ingeding/done.svg" alt="Done" title="Done">{{
                                            doc.name }}
                                            <a *ngIf="doc._id" href="{{documentBucket + doc.name}}" target="_blank"
                                                download>
                                                <img src="./assets/images/ingeding/download.svg" alt="Download"
                                                    title="Download">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-sm-12 col-md-4 file-size-t">
                                    <ul>
                                        <li>
                                            {{ Math.floor(doc.size / 1024) > 1000 ? Math.floor(doc.size /
                                            1048576)+' MB ' : Math.floor(doc.size / 1024)+' KB ' }}
                                            <a href="javascript:void(0)" (click)=removeDocument(i)>
                                                <img src="./assets/images/ingeding/trash.svg" alt="Delete"
                                                    title="Delete">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <!-- Phone Email Done-->
                    <div class="row">
                        <legend class="col-form-label col-sm-3 float-sm-left pt-0">{{'PAGES.EDIT_PROFILE.CONTACT' | translate}}*</legend>
                        <div class="col-sm-9">
                            <div class="form-row">
                                <!-- <div class="col-md-2">
                                    <select class="minimal form-control" #countrycode="ngModel"
                                        [(ngModel)]="jobPost.countrycode" name="countrycode" required>
                                        <option value="">Prefisso</option>
                                        <option *ngFor="let country of CountryArr" [value]="country._id">
                                            + {{ country.phone_code }}
                                        </option>
                                    </select>
                                    <div *ngIf="f.submitted && f.hasError('required', 'countrycode')"
                                        class=" text-danger">
                                        Si prega di selezionare
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="Telefono"
                                        [(ngModel)]="jobPost.contact_no" name="contact_no" required
                                        #contact_no="ngModel"
                                        pattern="^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$"
                                        />
                                    <div *ngIf="f.submitted && contact_no.errors?.required" class=" text-danger">
                                        Campo richiesto
                                    </div>
                                    <div *ngIf="f.submitted && contact_no.errors?.pattern" class=" text-danger">
                                        Campo invalido
                                    </div>
                                    <div *ngIf="f.submitted && contact_no.errors?.maxlength" class=" text-danger">
                                        Contact number cannot exceed more than 12 Digit.
                                    </div>
                                </div> -->
                                <div class="col-md-5">
                                    <input type="text" class="form-control" placeholder="E-mail"
                                        [(ngModel)]="jobPost.email" name="email" #email="ngModel" required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                                    <div *ngIf="f.submitted && email.errors?.required" class=" text-danger">
                                        {{'PAGES.UPGRADE_ACCOUNT.REQUIRED_FIELD' | translate}}
                                    </div>
                                    <div *ngIf="f.submitted && email.errors?.pattern" class=" text-danger">
                                        {{'PAGES.VIEW_PROFILE.PLEASE_SELECT' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div class="form-group row mb-64 mt-64">
                        <div class="col-sm-10 inline-btn">
                            <button type="submit" class="btn-aqua" (click)="addPost(f)" *ngIf="jobPost._id==undefined"
                                [disabled]="submitted || !checkProfileStatus">{{'PAGES.VIEW_JOB_POST.PUBLISH' | translate}}</button>
                            <button type="submit" class="btn-aqua mr-2" (click)="updatePost(f)"
                                *ngIf="jobPost._id!=undefined" [disabled]="submitted">{{'PAGES.VIEW_JOB_POST.PUBLISH' | translate}}</button>
                            <button type="button" class="btn-aqua" (click)="openPopup(jobPost._id, 0)"
                                *ngIf="jobPost._id!=undefined && jobPost.status==1">{{'PAGES.VIEW_JOB_POST.REMOVE' | translate}}</button>
                            <button type="button" class="btn-aqua" (click)="openPopup(jobPost._id, 1)"
                                *ngIf="jobPost._id!=undefined && jobPost.status==0">{{'PAGES.USER_SETTING.DELETE' | translate}}</button>
                            <span class="text-danger ml-2" *ngIf="!checkProfileStatus"><strong>{{'PAGES.VIEW_PROFILE.COMPLETE_YOUR_PROFILE' | translate}}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>

<ng-template #confirmModel let-modal>
    <div class="login-main backdrop">
        <div class="popup">
            <div class="modal-header">
                <span class="ribbon top-left ribbon-primary">
                    <!-- <small><b>Conferma</b></small> -->
                    <h4>{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</h4>
                </span>
                <!-- <h1>Conferma</h1> -->
            </div>
            <div class="modal-body">
                <p class="mt-2">{{'PAGES.UPGRADE_ACCOUNT.DELETE_PERMANTELY' | translate}}</p>

                <div id="option">
                    <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-danger" (click)="onNoClick()"
                            style="background-color:black;border:none;">{{'PAGES.UPGRADE_ACCOUNT.NO' | translate}}</button>&nbsp;
                        <button type="button" class="btn btn-success" style="background-color:#00ad9e;border:none;"
                            (click)="onYesClick()">{{'PAGES.UPGRADE_ACCOUNT.YES' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #inactivePostModel let-modal>
    <div class="login-main backdrop">
        <div class="popup">
            <div class="modal-body">
                <div class="col-sm-12 col-md-12 main-registration-area">
                    <form class="logform p-0">
                        <button type="button" class="close" (click)="cancel()">&times;</button>
                        <h2 class="mb-5 card-title">{{'PAGES.MY_POST.REMOVE_AD' | translate}}</h2>
                        <div class="mb-5">
                            <p class="small-text">{{'PAGES.MY_POST.REMOVE_AD_MSG' | translate}}</p>
                            <p class="small-text">{{'PAGES.MY_POST.REMOVE_AD_NOTE' | translate}}</p>
                        </div>
                        <button type="button" class="btn-aqua btn-block loginbtn"
                            style="background-color:#D70000;border:none;" (click)="inactive()"
                            [disabled]="submitted">{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deletePostModel let-modal>
    <div class="login-main backdrop ">
        <div class="popup">
            <div class="modal-body">
                <div class="col-sm-12 col-md-12 main-registration-area">
                    <form class="logform p-0">
                        <button type="button" class="close" (click)="cancel()">&times;</button>
                        <h2 class="mb-5 card-title">{{'PAGES.MY_POST.DELETE_POST' | translate}}</h2>
                        <div class="mb-5">
                            <p class="small-text">{{'PAGES.MY_POST.AD_DELETE_PERMANTELY' | translate}}</p>
                            <p class="small-text">{{'PAGES.MY_POST.TO_CONTINUE' | translate}}</p>
                        </div>
                        <button type="button" class="btn-aqua btn-block loginbtn"
                            style="background-color:#D70000;border:none;" (click)="delete()"
                            [disabled]="submitted">{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--BODY END-->