import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { JobPost } from "../_models/jobpost.model";
import { AuthenticationService } from "../_services/authentication.service";
import { JobPostService } from "../_services/jobpost.service";
import { environment } from "src/environments/environment";
import { ApplyForJobService } from "../_services/applyforjob.service";
import * as $ from "jquery";
import { UserProfileService } from "../_services/userprofile.service";

@Component({
  selector: "app-job-post-view",
  templateUrl: "./job-post-view.component.html",
  styleUrls: ["./job-post-view.component.scss"],
})
export class JobPostViewComponent implements OnInit {
  jobPost = new JobPost();
  photosBucket = environment.imgBucketUrl + "/gallery/";
  documentBucket = environment.imgBucketUrl + "/document/";
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  currentUser: any = "";
  viewJobData: any = [];
  flag: any = 0;
  relevantJobPOstArr: any = [];
  regType: any = "";
  isApply: boolean = false;
  applicationMsg: any = "";
  showApplyForm: boolean = false;
  Math: any;
  categoryName: any = "";
  checkProfileStatus: boolean;
  latin_month: any = [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private applyforjobService: ApplyForJobService,
    private jobPostSetvice: JobPostService,
    private userProfileService: UserProfileService,
    private meta: Meta
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.Math = Math;
    this.titleService.setTitle("Ingeding | View Job Post");
    route.params.subscribe((val) => {
      window.scrollTo(0, 0);
      this.jobPost._id = this.route.snapshot.params["id"];
      this.authenticationService.currentUser$.subscribe(async (data) => {
        this.currentUser = data ? data._id : null;
      });
      this.getPostData();
      this.getRelevantJobPost();
    });
  }

  ngOnInit() {
    this.checkUserProfile(this.currentUser);
  }

  checkIfNumber(x: any) {
    var y = Number(x);
    if (Number.isInteger(y)) {
      return y + ".00";
    } else {
      return y;
    }
  }

  getRelevantJobPost() {
    if (this.jobPost._id != "" && this.jobPost._id != undefined) {
      const data = {
        userid: this.currentUser,
        postid: this.jobPost._id,
      };
      this.jobPostSetvice.relevantJobPost(data).subscribe((res) => {
        this.relevantJobPOstArr = res.data;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  checkUserProfile(userId: any) {
    this.userProfileService.checkProfile(userId).subscribe((res) => {
      this.checkProfileStatus = res;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  /** Get Post data */
  getPostData() {
    if (this.jobPost._id != "" && this.jobPost._id != undefined) {
      const myIds = {
        currentuserid: this.currentUser,
        jobpostid: this.jobPost._id,
      };
      this.jobPostSetvice.getJobPost(myIds).subscribe((res) => {
        if (res.data._id) {
          this.categoryName = res.data.categories[0].translations[0].title;
          this.viewJobData = res.data;
          this.flag = 1;
          this.regType =
            res.data.user[0].usergroup == "60f679adc3afbf2ab4974e4e"
              ? "1"
              : "0";
          if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
          }
        } else {
          this.flag = 1;
          if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
          }
        }
      });
    }
  }

  filterMyDate(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  showPopup() {
    if (this.checkProfileStatus) {
      this.showApplyForm = true;
      setTimeout(function () {
        $("#applicationMsg").focus();
      }, 200);
    }
  }

  applyForJob() {
    this.isApply = true;
    const myJobApp = {
      userid: this.currentUser,
      jobpostid: this.viewJobData._id,
      description: this.applicationMsg,
    };
    this.applyforjobService.create(myJobApp).subscribe((res) => {
      if (res.message === "success" || res.status === 1) {
        this.showApplyForm = false;
        this.toastr.success(
          "La tua domanda è stata inviata correttamente.",
          "Successo"
        );
        this.getPostData();
      } else {
        this.isApply = false;
        this.toastr.error(res.message, "Errore");
      }
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
}
