export class User {
  _id: number;
  user_register_type: any = "front";
  nameprefix: any = "";
  firstname: any;
  lastname: any;
  category: any;
  iams: any;
  vatnumber: any;
  isCollaborator: boolean;
  city_id: any;
  idnumber: any;
  country_id: any;
  contact_no: Number;
  email: any;
  images: any;
  companyname: any;
  website: any;
  noOfCollaborator: any;
  paaddress: any = "";
  pastreetno: any = "";
  paregion: any = "";
  pacity: any = "";
  papostcode: any = "";
  codicefiscale: any = "";
  codicedestinatario: any = "";
  operationalheadquartersaddress: any;
  operationalheadquartersstreetno: any;
  operationalheadquartersregion: any;
  operationalheadquarterscity: any;
  operationalheadquarterspostcode: any;
  registeredofficeaddress: any;
  registeredofficestreetno: any;
  registeredofficeregion: any;
  registeredofficecity: any;
  registeredofficepostcode: any;
  pec: any;
  usergroup: any;
  isNewIams: boolean = false;
  newIams: any;
  status: number;
  deletedFiles: any = [];
  avatar: any;
  pic: any = null;
  createdAt: any = null;
}
