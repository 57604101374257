// USA

const projectname = "INGEDING";
export const locale = {
  lang: "en",
  data: {
    UPDATE: "Update",
    LIST: "List",
    HOME: "Home",
    ADD: "Add",
    NAME: "Name",
    ADSH: "Adsh",
    CIK: "CIK",
    SIC: "SIC",
    COUNTRY: "Country",
    STPRBA: "State",
    CITY: "City",
    ZIP: "ZIP",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "id",
    Usergroup: "User Group",
    QUESTION_TYPE: "question type",
    ANSWER_INPUT: "answer input",
    ANSWER_TYPE: "answer type",
    QUESTION_TEXT: "question text",
    DESCRIPTION: "description",
    FIRSTNAME: "First name",
    LASTNAME: "Last name",
    EMAIL: "Email",
    PHONE: "Phone",
    COUNTRY_CODE: "Country code",
    REGIONS: "Regions",
    STATES: "State",
    CITIES: "City",
    LOCALE: "Locale",
    COUNTRIES: "Countries",
    VALUES: "Values",
    SETTINGTYPES: "SETTING TYPES",
    ANSWER_OPTIONS: "ANSWER OPTIONS",
    TRANSLATE: "Translate",
    TITLE: "Title",
    TRANSLATOR: {
      SELECT: "Select Language",
    },
    MENU: {
      HOME: "Home",
      MAP: "Map",
      NEW: "new",
      ACTIONS: "Actions",
      CREATE_POST: "Create New Post",
      PAGES: "Pages",
      WARD: "Ward",
      FEATURES: "Features",
      APPS: "Apps",
      DASHBOARD: "Dashboard",
      ASSIGNMENT: "Assignment List",
      USERS: "Users",
      MASTERS: "Masters",
      MODULES: "Modules",
      COMPANY: "Company",
      REGION: "Region",
      COUNTRY: "Country",
      STATE: "State",
      CITY: "City",
      DISTRICT: "District",
      ZONE: "Zone",
      TAG: "Tag",
      LANGUAGE: "Language",
      USERMANAGEMENT: "User Management",
      USERGROUP: "User Group",
      PACKAGE: "Package",
      SEC: "SEC",
      GLOBALSUCCESS: "Global Successo",
      PDF: "PDF",
      FIELDGROUPMAPPING: "Field Group Mapping",
      STATEMENTTYPENAME: "Statement Type",
      FIELDMAPPING: "Field Mapping",
      QUESTIONNAIRETOOL: "Questionnaire Tool",
      GLOBALSETTING: "Global Setting",
      SETTING: "Setting",
      SETTINGTYPE: "Setting Type",
      // kalu add
      QUESTIONS: "questions",
      MENU: "menu",
    },
    AUTH: {
      GENERAL: {
        OR: "Or",
        SUBMIT_BUTTON: "Submit",
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: "Sign Up",
        FORGOT_BUTTON: "Forgot Password?",
        BACK_BUTTON: "Back",
        PRIVACY: "Privacy",
        LEGAL: "Legal",
        CONTACT: "Contact",
      },
      LOGIN: {
        TITLE: "Login",
        BUTTON: "SIGN IN",
        SUCCESS: "Successfully login.",
      },
      FORGOT: {
        TITLE: "Forgot Password",
        DESC: "Enter your email to reset your password",
        SUCCESS:
          "Link has been sent to your registered email using which you can reset your password.",
      },
      SETPASSWORD: {
        TITLE: "Set Password",
        BUTTON: "Reset",
        SUCCESS: "La password è stata cambiata con successo.",
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Fullname",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password",
        USERNAME: "Username",
        TITLE: "Enter Title",
        REMEMBER_ME: "Remember Me",
      },
      VALIDATION: {
        INVALID: "{{name}} is not valid",
        REQUIRED: "{{name}} is required",
        MIN_LENGTH: "{{name}} minimum length is {{min}}",
        AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
        NOT_FOUND: "The requested {{name}} is not found",
        INVALID_LOGIN: "The login detail is incorrect",
        REQUIRED_FIELD: "Required field",
        MIN_LENGTH_FIELD: "Minimum field length:",
        MAX_LENGTH_FIELD: "Maximum field length:",
        INVALID_FIELD: "Field is not valid",
        MUST_MATCH: "Password and confirm password must match",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: "Map View",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " | Language List",
        ADDTITLE: projectname + " | Language Add",
        EDITTITLE: projectname + " | Language Update",
        LANGUAGELIST: "Languages List",
        LANGUAGENAMEPLURAL: "Languages",
        LANGUAGENAMESINGULAR: "language",
        NEWLANGUAGE: "New Language",
        DELETEDSELECTED: "Delete selected Languages",
        UPDATESELECTED: "Update status for selected Languages",
        ADDNEW: "Add New Language",
        UPDATE: "Update Language",
        CREATEMESSAGE: "Create language successfully",
        UPDATEMESSAGE: "Update language successfully",
        EDIT: "Edit Language",
        ADD: "Add Language",
        LOCALE: "Locale",
        LOCALE_REQUIRED: "Locale Required",
        LOCALE_INVALID: "Locale contains invalid characters",
      },
      USERS: {
        LISTINGTITLE: projectname + " | User List",
        USERSLIST: "Users List",
        NEWUSER: "New User",
        USERNAMEPLURAL: "users",
        USERNAMESINGULAR: "user",
        DELETEDSELECTED: "Delete selected users",
        UPDATESELECTED: "Update status for selected users",
        ADDNEW: "Add New User",
        UPDATE: "Update User",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        USERSUCCESS: "User Added Successfully",
        FILTERUSERGROUP: "Filter User Group",
        ADDTITLE: projectname + " | Users Add",
        EDITTITLE: projectname + " | Users Update",
        EDIT: "Edit User",
        ADD: "Add User",
        GOVT: "Government",
        OTHERGOVT: "Other than Government",
        GOVTEMP: "Government employee",
        NGO: "Non-governmental Organization (NGO)",
        PVT: "Private organization",
        CONSTENT: "Consultant",
        SHG: "Self Help Group (SHG)",
        ACADEMIC: "Academia",
        OCCUPATION: "Occupation",
        ORGANIZTION: "Organization",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " | User Group List",
        USERGROUPLIST: "User Group List",
        USERGROUPNAMEPLURAL: "usergroups",
        USERGROUPNAMESINGULAR: "usergroup",
        NEWUSERGROUP: "New User Group",
        DELETEDSELECTED: "Delete selected user groups",
        UPDATESELECTED: "Update status for selected user groups",
        ADDNEW: "Add New User Group",
        UPDATE: "Update User Group",
        ADDTITLE: projectname + " | Add User Group",
        EDITTITLE: projectname + " | Update User Group",
      },
      QUESTION: {
        LISTINGTITLE: projectname + " | Question List",
        ADDTITLE: projectname + " | Question Add",
        EDITTITLE: projectname + " | Question Update",
        QUESTIONLIST: "Questions List",
        QUESTIONNAMEPLURAL: "Questions",
        QUESTIONNAMESINGULAR: "Question",
        NEWQUESTION: "New Question",
        DELETEDSELECTED: "Delete selected questions",
        UPDATESELECTED: "Update status for selected questions",
        ADDNEW: "Add New Question",
        UPDATE: "Update Question",
        CREATEMESSAGE: "Create question successfully",
        UPDATEMESSAGE: "Update question successfully",
        EDIT: "Edit Question",
        ADD: "Add Question",
        SINGLE_CHOICE: "Single Choice",
        MULTIPLE_CHOICE: "Multiple Choice",
        QUESTION_IMAGES: "Question Images",
        ANSWER_LIMIT: "Answer Limit",
        CHOICE: "Choice",
        NUMERIC: "Numeric",
        DATE: "Date",
        UNIQUEID: "Unique ID",
        TEXT: "text",
        IMAGE: "Image",
        BOTH: "Both",
        MULTIPLE_ANSWER: "Multiple answer",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: "Multiple lable Multiple answer",
        ANSWER_INPUT: "Answer Input",
        ATTACH_FILES: "Attach files",
        QUESTION_TEXT: "question text",
        ANSWER_TYPE: "Answer Type",
        ANSWER_IMAGE: "Answer Image",
        MIN_RANGE: "Min Range",
        MAX_RANGE: "Max Range",
        ANSWER: "Answer",
        QUESTION: "Question",
        SEQUENCE: "Sequence",
        ENTER_SEQUENCE: "Please enter sequence",
        INVALID_SEQUENCE: "Invalid sequence",
        ADD_OPTION: "Add",
        ADD_MAXIMUM_OF: "You can add maximum of",
        ENTER_ANSWER: "Please enter answer",
        INVALID_ANSWER: "Invalid answer",
        EXCLUSIVE_RESPONSE: "Mutually Exclusive Response",
        ENTER_MAXIMUM_RANGE: "Please enter maximum range",
        Maximum_number_minimum_number:
          "Maximum number is not less than to minimum number",
        ENTER_MINIMUM_RANGE: "Please enter minimum range",
        INVALID_RANGE: "Please insert a valid 10 digit phone number",
        PLEASEENTERQUESTION: "Please enter question",
        INVALID_QUESTION: "Invalid question",
        QUESTIONMUSTBEATLEAST:
          "Question must be at least 3 characters long and space is not allowed",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. you can upload maximum  question image size must be 228px * 200px",
        MAX_UPLOAD_SIZE_ADD:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. Image size must be 228px * 200px",
        REVISION_FOR_THIS_QUESTION:
          "Do you want to create revision for this question?",
        CREATEMAPPINGMESSAGE: "Create question mapping successfully",
        UPDATEMAPPINGMESSAGE: "Update question mapping successfully",
        DELETEMAPPINGMESSAGE: "Delete question mapping successfully",
        UPLOAD_ONLY: "You can upload only",
        CONDITIONISMAPPED: "You can not change order as condition is mapped!!",
      },
      MENU: {
        LISTINGTITLE: projectname + " | Menu List",
        ADDTITLE: projectname + " | Menu Add",
        EDITTITLE: projectname + " | Menu Update",
        MENULIST: "Menus List",
        MENUNAMEPLURAL: "Menu",
        MENUNAMESINGULAR: "Menu",
        NEWMENU: "New Menu",
        DELETEDSELECTED: "Delete selected menus",
        UPDATESELECTED: "Update status for selected menus",
        ADDNEW: "Add New Menu",
        UPDATE: "Update Menu",
        CREATEMESSAGE: "Create menu successfully",
        UPDATEMESSAGE: "Update menu successfully",
        EDIT: "Edit Menu",
        ADD: "Add Menu",
        VALUES: "Values",
        PARENT: "Parent",
        MODULE: "Module",
        PAGE: "Page",
        ICON: "Icon",
        ORDERING: "Ordering",
        VALIDATION: {
          SELECTTYPE: "Please Select Menu Type",
        },
      },
      SURVEY: {
        LISTINGTITLE: projectname + " | Survey Listing",
        SURVEYLIST: "List of surveys conducted",
        NEWSURVEY: "New Survey",
        SURVEYNAMEPLURAL: "survey",
        SURVEYNAMESINGULAR: "survey",
        DELETEDSELECTED: "Delete selected surveyor",
        UPDATESELECTED: "Update status for selected surveyor",
        ADDNEW: "Add New Survey",
        UPDATE: "Update Survey",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        SURVEYSUCCESS: "Survey submitted successfully",
        SURVEYQUESTIONSUBMIT: "Submit successfully",
        SURVEYQUESTIONSAVE: "Save successfully",
        FILTERSURVEYGROUP: "Filter Survey Group",
        ADDTITLE: projectname + " | Surveyor Add",
        EDITTITLE: projectname + " | Surveyor Update",
        EDIT: "Edit Survey",
        ADD: "Start New Survey",
        SRNO: "Sr No",
        SEARCH: "Search",
        SURVEYORNAME: "Surveyor Name",
        SURVEYQUESTIONTITLE: "Survey of public conveniences",
        SURVEYBY: "Form to be surveyed by SAATH team ",
        VALIDATIONTEXT: "Marked questions are compulsory.",
        NOTE: "Note",
        SURVEYTEXT:
          "Zone and ward are not complusory to fill. In case you know these information, please select an appropriate option.",
        PUBLICCONVENIENCENOTE:
          "If toilet location is different, please select an appropriate location from the dropdown",
      },
      PROFILE: {
        NAME: "My Profile",
        LISTINGTITLE: projectname + " | My Profile",
        PROFILESUCCESS: "Profile updated successfully!!",
        INVALID_IMAGE:
          "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
        PROFILE_IMAGE: "Profile Image",
        BASIC_INFO: "Basic info",
        PHONE: "Contact No.",
        PHONEREQ: "Contact No. is Required",
        PHONEERROR: "Please enter valid phone number.",
        ADDRESS: "Enter Address",
        ADDRESSTAB: "Address",
        CHANGEPASSWORD: "Change Password",
        RESIDENTIALADDREDSS: "Residential Address",
        PASSWORDNOTMATCH: "Oh snap! password not match.",
        PASSWORDREQUIRED: "Password is required",
        OLDPASSWORDREQUIRED: "Old password is required",
        ENTEROLDPASSWORD: "Old Password",
        NEWPASSWORDREQUIRED: "New password is required",
        ENTERNEWPASSWORD: "New Password",
        INVALIDNEWPASSWORD: "New password is not valid",
        INVALIDPASSWORD: "Password is not valid",
        NEWPASSWORDSAME: "New password should not be same as old password",
        CONFIRMPASSWORD: "Confirm Password",
        CONFIRMPASSWORDREQUIRED: "Confirm password is required",
        CONFIRMPASSWORDSAME:
          "New password and confirm password values do not match.",
        INVALIDOLDPASSWORD: "Old password is not valid",
        CREATEUSERGROUP: "Create user group successfully",
        UPDATEUSERGROUP: "Update user group successfully",
      },
      LOGIN: {
        WELCOME: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        LONGTEXT:
          "This tool will help you to understand the existing condition of public and community toilets in terms of cleanliness and you can assess cubicle-wise infrastructure and cleanliness gaps in the toilet facilities.",
        EMAIL: "Email Address/Phone Number",
        EMAIL_VALIDATION: "Please enter email or mobile number.",
        EMAIL_PATTERN:
          "Wrong format, It should be a valid email or 10 digit mobile number.",
        ACCOUNT: "Need to create an account?",
        REGISTER: "Register",
        OTP: "Send OTP",
        ENTER_OTP: "Enter OTP",
        ONE_TIME_PASS: "One Time Password",
        OTP_VALIDATION: "Please enter OTP...!",
        RESEND_OTP: "Resend OTP in",
        OTP_RESEND: "Resend OTP",
        NOT_GET_OTP: "Don`t get OTP?",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        TERMSOFUSE: "Terms of Use",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The Terms of Service administer your use of this application and its services. Please read these Terms and Conditions carefully before using the application. By using the application or the services, you agree to be bound by our Terms and Conditions including the policies referenced in these terms. These Terms and Conditions may get modified from time to time.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "While Urban Management Centre (UMC) makes every effort to ensure that the content of the application is accurate and up-to-date, but does not warrant to be referred to at or in any forums for its reliability, accuracy or completeness of the information. UMC has authority and rights to delete or update complete or part of the information included in this application without prior intimation of any kinds. The content and the information being collected through this application are solely for",
        TEXT4: "information and education purpose only",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "All materials, information contained in this application, including photographs, reports and text are property of UMC group and thus are protected under Indian laws of copyright. Using our services does not give you ownership of any intellectual property rights in our services or the content you access.",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "Unless otherwise specified or notified that particular materials/information published are for its restricted use, downloading, copying, reproducing, modifying and republishing the content from this application for distributing publically or using commercially will be in violation of copyright and proprietary rights of the owner – UMC.",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        PRIVACYPOLICY: "Privacy Policy",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The purpose of this application is to capture survey data of Public and Community Toilets under SBM - MISAAL project and to process such data for projecting budget requirement to make such toilets operational. While doing so we may collect your personal information like name, profession, email ID, contact number, city to identify or contact the user of the application.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "In this process, we assure you that the protection of your privacy is crucial to us. Your personal information shall be collected by us through a form only with your consent. While some of the information will be collected from you, we may also collect other data like IP address, domain name, browser, operating system etc. from the system logs of our server for data and system security.",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "UMC assures you that the information collected through this application will be strictly confidential and will not be disclosed to a third party in any manner and shall only be used in the way you have consented.",
      },
      COMMON: {
        DATE: "Date",
        GOTO: "Go to",
        UNIQUEID: "Unique ID",
        COMPLETED: "Completed",
        INPROGRESS: "In-Progress",
        CITYLIST: "Cities List",
        COUNT: "Survey Count",
        LOGOUT: "Logout",
        NEXT: "Next",
        PREVIOUS: "Previous",
        HOME: "HOME",
        ALL: "All",
        ACTIVE: "Active",
        INACTIVE: "In Active",
        PLEASESELECT: "Please Select",
        BASICINFORMATION: "Basic Information",
        COMPANYMANAGEMENT: "Company Management",
        COMPANYREGISTEARS: "Company Registrars",
        CREATEDAT: "Created Date",
        STATUS: "Status",
        ACTION: "Action",
        DELETE: "Delete",
        TITLE: "Title",
        VIEW: "View",
        UPDATE: "Edit",
        PARENT: "Parent",
        PIC: "Pic",
        SEARCHBTN: "Search",
        FIRSTNAME: "First Name ",
        FIRSTNAMEREQ: "Please specify first name",
        FIRSTNAMEERROR: "First name is invalid",
        NAME: "Name",
        LASTNAME: "Last Name",
        LASTNAMEREQ: "Please specify last name",
        LASTNAMEERROR: "Last name is invalid",
        EMAIL: "Emailss",
        EMAILREQ: "Please specify email",
        EMAILERROR: "Email is invalid",
        WEBSITE: "Website",
        WEBSITEREQ: "Please specify website",
        WEBSITEERROR: "Website is invalid",
        FAX: "Fax",
        FAXERROR: "Fax is invalid",
        ADDRESS: "Address",
        MOBILENUMBER: "Mobile Number",
        DATEOFBIRTH: "Date of Birth",
        DATEOFBIRTHERROR: "Please specify date of birth",
        SAVEBTN: "Save",
        SUBMITBTN: "Submit",
        SEARCH: "Search",
        BACKBTN: "Back",
        UPDATEBTN: "Update",
        RESETBTN: "Reset",
        CANCELBTN: "Cancel",
        REPORT: "Report",
        CITY: "City",
        COUNTRY: "Country",
        CODE: " Code",
        ZONE: "Zone",
        WARD: "Ward",
        PUBLICCONVENIENCE: "Public convenience",
        SELECTCOUNTRY: "Select Country",
        SELECTREGION: "Select Region",
        SELECTSTATE: "Select State",
        SELECTCITY: "Select City",
        SELECTZONE: "Select Zone",
        SELECTWARD: "Select Ward",
        SELECTUSERGROUP: "Select UserGroup",
        STATE: "State",
        PIN: "Pin",
        ZIPCODE: "Zip code",
        NORECORDS: "No record(s) found",
        DELETEALL: "Delete All",
        UPDATESTATUS: "Update status",
        DESCRIPTION: "Description",
        DESIGNATION: "Designation",
        ENTERDESCRIPTION: "Please enter description",
        STATUSUPDATED: "Status has been updated successfully",
        SUCCESS: "Successfully",
        SELECTED: "Selected",
        STATUSSELECTEDUPDATE: "Status has been updated for selected",
        PERMANTLYDELETE: "Are you sure to permanently delete this",
        DELETETEXT: "Are you sure you want to delete this record permanently?",
        ISDELETING: "is deleting",
        DELETESELECTED: "Delete Selected",
        UPDATESELECTED: "Update Status for Selected",
        AMOUNT: "Amount",
        TIME: "Time",
        VALUE: "Value",
        USERGROUP: "User Group",
        SELECT: "Select",
        VERSION: "Version",
        TYPE_THREE_LETTERS: "Type three letters and wait some time",
        PERMISSION: "Permissions",
        LABELSEARCH: "Type Label Here",
        SELECTANYONE: "Select any one",
        IMAGEERROR: "Please upload image ",
        TEXTERROR: "Please specify text",
        NUMBERERROR: "Please specify number",
        INVALIDFILE: "You have uploaded an invalid file type",
        // tslint:disable-next-line: max-line-length
        PASSWORDHINT:
          "contains 8-20 characters, at least one number, at least  one upper character, at least one lower character, at least one special character.",
        OOPS: "Oops!",
        ERROR: "Error!",
        HELLO: "Hello!",
        WELCOMEBACK: "Welcome Back",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          "It looks like you couldn`t complete the previous participation. Would you like to continue from where you left or would you like to start a new survey question?",
        CONTINUEWITH: "Continue With",
        OR: "or",
        PREVIOUSQUESTION: "Previous Question",
        FRESHQUESTION: "Fresh Question",
        CONFIRM: "Confirm",
        AREYOUSURE: "Are you sure will start first?",
        CONFIRMIMAGE: "Are you sure delete this image?",
        YES: "Yes",
        NO: "No",
        TEXTINVALIDE: "Text contains invalid characters",
        CONFIRMCONVENIENCE:
          "Are you sure will change public convenience name ?",
        PRIVACYPOLICY: "Privacy Policy",
        TERMSOFUSE: "Terms of Use",
        FORHELPCONTACT: "For help Contact",
        CLICKFORANEWSURVEY: "Click for a new survey",
        VALIDATION: {
          INVALID_IMAGE:
            "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
          NAME_INVALID: "Name contains invalid characters",
          NAME_REQUIRED: "Please specify name",
          USERGROUPREQUIRED: "Please specify user group",
          NAME_MIN_LENGTH: "Name must be at least 2 characters long.",
          NAME_MAX_LENGTH: "cannot exceed more than 50 characters.",
          ZIP_REQUIRED: "Please specify zipcode",
          ZIP_INVALID: "Zipcode is invalid",
          CITY_REQUIRED: "Please specify city",
          STATE_REQUIRED: "Please specify state",
          REGION_REQUIRED: "Please specify region",
          COUNTRY_REQUIRED: "Please specify country",
          VALUE_REQUIRED: "Please specify value",
          TITLE_INVALID: "Title contains invalid characters",
          TITLE_REQUIRED: "Please specify title",
          TITLE_MIN_LENGTH: "Title must be at least 2 characters long.",
          TITLE_MAX_LENGTH: "cannot exceed more than 50 characters.",
          PIN_REQUIRED: "Please specify Pin",
          PIN_REQUIRED_MIN_LENGTH: "Pin must be at least 5 characters long.",
          PIN_REQUIRED_MAX_LENGTH: "cannot exceed more than 6 characters.",
        },
        PATTERN: {
          // tslint:disable-next-line: quotemark
          // NAME : "[a-zA-Z '.&:_()-]*'"
          // tslint:disable-next-line: quotemark
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
          // tslint:disable-next-line: quotemark
          EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
          // tslint:disable-next-line: quotemark
          ADSH: "[0-9-]{20}",
          // tslint:disable-next-line: quotemark
          SIC: "[0-9]{2,50}",
          // tslint:disable-next-line: quotemark
          NUMBER_ONLY: "[0-9]+",
          // tslint:disable-next-line: quotemark
          FNAME_LNAME: "^[a-zA-Z]+\\s?[a-zA-Z]+$",
          // tslint:disable-next-line: quotemark
          CONTACT_NO: "[0-9]{10}",
          // tslint:disable-next-line: quotemark
          TAG: "[a-zA-Z']*",
          // tslint:disable-next-line: quotemark
          AMOUNT: "[0-9.]+",
          // tslint:disable-next-line: quotemark
          TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
        },
        posttext: 'For every job its own professional'
      },
      UPGRADE_ACCOUNT: {
        YOUR_SUBSCRIPTION: 'Your subscription',
        CURRENT_SUBSCRIPTION: 'Current subscription',
        START_DATE: "Start date:",
        END_DATE: "End date:",
        SUBSCRIPTION_EXPIRED: 'Your subscription has expired.',
        NEXT_RENEWAL: 'Next renewal',
        BUY: 'Buy',
        SUBSCRIPTION: 'Subscription',
        NO_OFFER_SUB_MSG: 'Sorry, we do not currently offer a subscription for your category. Please contact us. See the "Contacts" page.',
        DURATION: 'Duration:',
        PAYMENT_METHOD: 'Payment method',
        BANK_TRANSFER: 'Bank Transfer',
        CREDIT_CARD: 'Credit card',
        INVOICE_INFO_MSG: 'Inginging will send you an invoice based on the information you provide. To view or update this information, go to',
        SETTING_PAGE: 'settings page.',
        CHOOSE_CARD: 'Choose a card',
        ADD_CARD: 'Add a card',
        NAME_ON_CARD: 'Name on card',
        REQUIRED_FIELD: 'Required field',
        CARD_NUMBER: 'Card number',
        EXPIRATION_DATE: 'Expiration date',
        CVV: 'CVV/CVC',
        INVALID_FIELD: 'Invalid field',
        SUBSCRIBE: 'Subscribe',
        CANCEL: 'Delete',
        PAYMENT_CONFIRMATION: 'Payment confirmation',
        COMPLETE_PURCHASE_MSG: "Do you want to continue and complete the purchase?",
        CONFIRM: 'Confirm',
        DELETE_PERMANTELY: 'Do you want to delete it permanently?',
        YES: 'Yes',
        NO: 'No',
        CHOOSE_CARD_TO_DELETE: 'Choose a card to delete.',
        SUBSCRIPTION_PURCHASE_SUCCESSFULLY: 'Subscription purchased successfully'
      },
      MY_POST: {
        AD_MSG: 'Here you will find the list of your jobs. Active jobs are published. The jobs in the trash are not visible and can no longer be activated, but only permanently deleted. In any case, they can be very useful as a reference for your new job.',
        ACTIVE_AD: 'Active jobs',
        BASKET: 'Trash bin',
        PUBLICATION_DATE: 'Publication date',
        PUBLISHED_ON: 'Published on',
        NO_ACTIVE_AD_MSG: 'There are no active jobs.',
        NO_AD_TRASH_MSG: 'There are no jobs in the trash.',
        REMOVE_AD: 'Remove job',
        REMOVE_AD_MSG: 'Your job will be removed from the public and will therefore be closed for applications.',
        REMOVE_AD_NOTE: 'Note: You will still be able to view this job an inactive job, but you will not be able to republish it.',
        DELETE_POST: 'Delete post',
        AD_DELETE_PERMANTELY: 'Your job will be permanently deleted.',
        TO_CONTINUE: 'Continue?',
        POST_REMOVE_SUCCESSFULLY: 'The post was successfully removed',
        POST_DELTETE_SUCCESSFULLY: 'The post was successfully deleted.',
        CONTINUE_COMPLETE_YOUR_PROFILE: 'To continue please complete your profile'
      },
      SEARCH_JOB_POST: {
        JOB_SECTION_TITLE: 'For every job its own professional',
        JOB_SECTION_DESC: 'Here you will find the list of jobs (service offers, assignment offers, job offers...). Use the filters to optimize your search.',
        ALL_REGION: 'All regions',
        REMOTELY: 'Remotely',
        DEADLINE_UP_TO: 'Deadline up to',
        PUBLICATION_DATE: 'Publication date',
        DEADLINE: 'Deadline',
        NO_RESULT_FOUND: "Sorry, we couldn't find any results for your search"
      },
      SEARCH_PROFILE: {
        JOB_SECTION_TITLE: 'Discover the best of our users',
        JOB_SECTION_DESC: 'Here you will find various professionals and companies available with specific skills for different fields.',
        SHOW_ALL: 'Show all',
        PROFESSIONALS: 'Professionals',
        COMPANIES: 'Companies',
        AVAILABLE_FROM: 'Available from',
        AVAILABILITY: 'Availability'
      },
      VIEW_PROFILE: {
        USER_FROM: 'User from',
        REGISTER: 'Registered in the register of',
        DESCRIPTION: 'Description',
        OPEN_JOB: 'Open jobs',
        REGION_COVERED: 'Regions covered',
        SERVICE_AND_RATE: 'Services and rates',
        HOURLY_RATE: 'Hourly rate',
        HASHTAG: 'Hashtag',
        PHOTO_GALLERY: 'Photo gallery',
        CURRICULUM: 'Curriculum',
        PROFILE: 'Profile',
        WHO_WE_ARE: 'Who we are',
        NO_OF_COLLABORATORS: 'Number of collaborators',
        WORKING_EXPERIENCE: 'Working experience',
        TRAINING: 'Training',
        SKILLS: 'Skills',
        SOFTWARE: 'Software',
        LANGUAGES: 'Languages',
        DOCUMENTS: 'Documents',
        EDIT: 'Edit',
        ALREADY_SEND_REQUEST: "You have already sent your contact request to this user.",
        AT_YOUR_SERIVCE: "Questions, doubts, special requests? We're at your service!",
        AT_YOUR_DISPOSAL: 'Questions, doubts, special requests? I am at your disposal!',
        COMPLETE_YOUR_PROFILE: '**To contact a user please complete your profile.',
        PROFILE_NOT_COMPLETED: 'Your profile is not complete!',
        PLEASE: 'Please',
        CLICK_HERE: 'click here',
        ENTER_DETAIL: 'to enter your profile details.',
        CONTACT_ME: 'Contact me',
        CONTACT_US: 'Contact us',
        LEAVE_YOUR_MSG: 'Leave your message here.',
        EMAIL: 'Email',
        COUNTRY_PREFIX: 'Country Prefix',
        PLEASE_SELECT: 'Please select',
        TELEPHONE_NUMBER: 'Telephone number',
        SEND: 'Send',
        SEE_OTHER_PROFESSIONAL: 'See other professionals and companies in the sector',
        SHOW_ALL: 'Show all',
        MSG_SEND_CORRECTLY: 'Your message was sent successfully.'
      },
      EDIT_PROFILE: {
        DESCRIPTION_MSG: 'A description must be entered. Briefly introduce yourself and your areas of expertise (max. 140 characters).',
        IMG_DESC: 'Format: 400x400 px, JPG, PNG (500 KB max.)',
        UPLOAD: 'Upload',
        YOU_CHOOSE: 'Choose...',
        SERVICE: 'Service',
        BRIEF_DESCRIPTION: 'Brief description',
        ERROR_MSG: 'Field cannot exceed 7 characters',
        ADD_FIELD_PROJECT: 'Add field to project',
        ADD_HOURLY_RATE: 'Add hourly rate',
        WORKING_ENVIRONMENT: 'Working environment',
        UPLOAD_IMG_DESC: 'Upload up to 12 images. Supported files: JPG, PNG (max 5 MB)',
        WORK_EXPERIENCES: 'Work experiences',
        FROM_THE: 'From',
        YEAR: 'Year',
        TODAY: 'Today',
        POSITION: 'Position',
        AGENCY: 'Company',
        PLACE: 'Place',
        ADD_WORK_EXPERIENCE: 'Add work experience',
        FIRST_NAME: 'First Name',
        ADD_TRAINING: 'Add training',
        DETAIL_DESCRIPTION: 'Detailed description (800 characters maximum)',
        ADD_SKILLS: 'Add skills',
        ADD_SOFTWARE: 'Add software',
        OTHER_LANGUAGE: 'Other language',
        ADD_LANGUAGE: 'Add language',
        ADD_LANGUAGE_NOT_IN_LIST: 'Add a language not in our list',
        UPLOAD_FILE: 'Upload functional files for your profile. The more complete it is, the better the results will be obtained',
        SELECT_FILE: 'or click to select a file',
        FILE: 'PDF, JPG, PNG (maximum 5 MB)',
        CONTACT: 'Contact',
        PREFIX: 'Prefix',
        SAVE: 'Save',
        INVALID_PROFILE: 'Warning: Invalid or too large file type (maximum size: 500 KB)',
        INVALID_IMG: 'Warning: Invalid or too large file type (maximum size: 5 MB)',
        PROFILE_REMOVE_SUCCESSFULLY: 'Profile Pic removed successfully',
        REACHED_MAX_IMG: 'You have reached the maximum of 12 images',
        SUCCESSFULLY_SAVED: 'Successfully saved',
        PROFILE_UPDATE_SUCCESSFULLY: 'Profile Pic update successfully',
        LangArray: ["English", "Spanish", "French", "German", "Italian"]
      },
      USER_SETTING: {
        PROFILE_SETTING: 'Profile Settings',
        TITLE: 'Title',
        SURNAME: 'Surname',
        MIN_CHARACTER: 'Field must contain at least 2 characters',
        MAX_CHARACTER: 'Field cannot exceed 50 characters',
        I_AM_I: 'I am a',
        OTHER: 'Other',
        JOB_TITLE: 'Job title',
        MATCH_IVA: 'VAT number',
        OCCASIONAl_COLLABORATOR: 'Are you an occasional collaborator?',
        VAT_MSG: "Our portal is open for a limited time of 1 year even to professionals who have not yet obtained a VAT number. At the end of the year, to continue using the service, it will be necessary to enter the VAT number.",
        TAX_ID_CODE: 'Tax code',
        RECIPIENT_CODE: 'Recipient code',
        BILLING_ADDRESS: 'Billing address',
        ADDRESS: 'Address',
        STREET_NUMBER: 'Street number',
        CITY: 'City',
        REGION: 'Region',
        POSTAL_CODE: 'ZIP',
        INVALIDATION_FIELD: 'Invalidation field',
        WEBSITE: 'Website',
        EMPLOYEE_AND_COLLABORATORS: 'Employees and collaborators',
        OPERATIONAl_HEADQUARTERS: 'Operational headquarters',
        REGISTERED_OFFICE: 'Registered office',
        REG_OFC_AS_SAME: 'The registered office is the same',
        REGISTERED_IN_THE_REGISTER: 'Registered in the register of',
        IDENTIFICATION_NUMBER: 'Identification number',
        ACCOUNT_ADMINISTRATION: "Account Administration",
        TELEPHONE: 'Phone',
        PEC: 'PEC',
        CONTACT_MSG: 'These contacts will be used by Ingeding for the administration of your account. Once registration is complete you can enter the contact details to publish on your personal profile.',
        IDENTITY_CARD: 'Identity card',
        UPLOAD_MSG: 'Upload the scan of your identity card (front and back): this will give more professionalism to your profile.',
        DRAG_AND_DROP: 'Drag and drop',
        CHOOSE_YOUR_AVATAR: 'Choose your avatar',
        SHOW_LESS: 'Show less',
        DELETE_ACCOUNT: 'Delete account',
        CHANGE_PASSWORD: 'Change password',
        CURRENT_PASSWORD: 'Current password',
        NEW_PASSWORD: 'New password',
        PASSWORD_IS_REQUIRED: 'Password is required',
        PASSWORD_IS_INVALID: 'The password is invalid',
        REPEAT_YOUR_PASSWORD: 'Repeat password',
        PASSWORD_ERROR_MSG: 'The confirmation password does not match the password.',
        PASSWORD_VALIDATION_MSG: 'At least 8 characters (including capital letters, numbers and special characters)',
        NOTIFICATION_SETTING: 'Notification Settings',
        NOTIFY_MSG: "Send me an email notification when there is a new interesting announcement, similar to the hashtags on my profile",
        EDIT_YOUR_PUBLIC_PROFILE: 'Edit your public profile',
        EDIT_PROFILE_MSG: 'Enter your work areas, your main skills and contact details. The more complete your profile, the better your results will be. You can enter more detailed information in the "Edit profile" section.',
        ACCOUNT_DELETE_PERMANENTLY: 'Your account will be deleted permanently.',
        ARE_YOU_SURE: 'Are you sure?',
        PASSWORD: "Password",
        DELETE: 'Delete',
        YOUR_CHANGES_SAVED: 'Your changes have been saved.',
        ACCOUNT_REMOVE_SUCCESSFULLY: 'Your account has been successfully removed',
        CONFIRMED: 'Confirmed!',
        'Architetto/a': 'Architect',
        Geometra: 'Geometrician',
        'Ingegnere/a': 'Engineer',
        Perito: 'Surveyor'
      },
      VIEW_JOB_POST: {
        ANNOUNCEMENT_CLOSED: 'Job closed',
        ADVERTISMENT_OPEN: 'Job open',
        BREIF_OVERVIEW: 'Brief overview of the job (140 characters maximum)',
        NUMBER_OF_HOUR_EXPECTED: 'Number of hours expected',
        COMPENSATION: 'Compensation',
        HOURLY_WAGE: 'Hourly wage',
        WORKPLACE: 'Workplace',
        DETAILS: 'Details',
        BRIEF_DESC_ABOUT_YOURSELF: 'Brief description about yourself.',
        CONTACT: 'Contact',
        ALREADY_APPILED_FOR_ANNOUNCEMENT: 'You have already applied for this jobs.',
        CONTACT_WHO_POST_THE_AD: "Click here to contact whoever posted the job.",
        AD_NOT_FOUND: 'Job not found. Please try again or contact us.',
        MESSAGE: 'Message',
        WHY_CONTACT_WHO_POST_ADV: 'Explain in a few words why you want to contact the person who posted the job.',
        SEND_THE_MSG: 'Send the message',
        DESC_MAX_140_CHARACTER: 'Field cannot exceed 140 characters',
        FROM: 'From',
        BRIEF_DESC: 'Short description (max. 140 characters)',
        PROJECT_RATE: 'Project rate',
        RATE_MAX_7_CHARACTER: 'Field cannot exceed 7 characters',
        ADD_FEE_TO_PROJECT: 'Add fee to project',
        DETAIL_JOB_DESC: 'Detailed job description (max. 800 characters)',
        UPLOAD_FILE_DESC: 'Upload functional files for your job. The more complete your job, the better your results will be.',
        PUBLISH: 'Publish',
        REMOVE: 'Remove',
        SUCCESFULLY_REMOVED: 'Successfully removed.',
        SUCCESSFULLY_DELETED: 'Successfully deleted',
        AD_POSTED_BULLETIN: 'Your job has been posted on the bulletin board.',
        QUE_SEND_SUCCESSFULLY: 'Your question has been sent successfully.'
      },
      LOGIN_PAGE: {
        LOGIN: 'Login',
        EMAIL_REQUIRED: "Email is required",
        EMAIL_INVALID: "The email is invalid",
        PASSWORD_REQUIRED: 'Password is required',
        PASSWORD_INVALID: 'The password is invalid',
        REMEMBER_ME: 'Remember me',
        FORGET_PASSWORD: 'Forgot your password?',
        REGISTER_NOW: 'Register now',
        PASSWORD: "Password",
        LOGIN_SUCCESFULLY: 'Logged in successfully',
        SUCCESS: 'Success',
        SOMETHING_WENT_WRONG: 'Something went wrong, please try again later',
        MISTAKE: 'Error'
      },
      REGISTRATION_PAGE: {
        REGISTER: 'Register',
        WHO_ARE_YOU: 'Who are you?',
        PROFESSIONAL: 'Professional',
        NAME_AND_SURNAME: 'Name and Surname',
        REGISRATION_NUMBER: 'Registration number',
        IDENTIFICATION_DOCUMENTS: 'Identification documents',
        UPLOAD_MSG: 'Upload the scan of your professional association membership card or similar identification documentation. This will give professionalism to your profile.',
        TERMS_COND_AND_POLICY: 'Terms and conditions, privacy',
        I_CONFIRM_ACCEPT: 'I confirm that I have read and fully accept the',
        COND_OF_USE: 'general conditions of use.',
        PURPOSE_OF_ARTICLE: 'Pursuant to and for the purposes of articles 1341 and 1342 c.c. the following provisions of the are specifically examined and approved',
        OTHER_POLICY: ': 3.3 (suspension and cancellation of the account); 6.1 and 6.2 (functioning of the Site and the Portal); 6.4 and 6.5 (closure or suspension of the Site and the Portal); 10 (withdrawal and closure of the Site and/or Portal); 11 (limitations of liability); 14 (sale); 15 (modification of conditions); 16 (competent court).',
        USE_FOR_MARKETING_PURPOSE: 'I give my consent to processing for marketing purposes as described in the information on',
        USE_MY_DATA: 'I give my consent for the use of my personal data for the purposes of promoting the portal, as described in the information on',
        I_CONFIRM_READ: 'I confirm that I have read the information on',
        CONTINUE_POLICY: "To continue please accept the terms and conditions and privacy policy",
        REGISTRATION_COMPLETED_SUCCESSFULLY: 'Registration completed successfully',
        CORRECT_DATA_TRY_AGAIN: 'Please correct the data and try again.',
        REGISTRATION_COMPLETED_LOGIN: 'Registration successful! Please login to continue.',
        LOGIN: 'Go to login',
      },
      FORGET_PASSWORD_PAGE: {
        RESET_PASWORD: 'Reset password',
        RESET_MSG: 'Enter your email address and we will send you a link to reset your password.',
        ADDRESS_INVALID: 'The address entered is invalid.',
        CONTINUES: 'Continue',
        SEND_MAIL: 'We have sent you a verification email.',
        CONFIRM_ACC: 'Confirm your account by clicking on the link in the email and reset your password.',
        CHECK_SPAM_FOLDER: "Didn't receive our email? Also check your spam folder;",
        CONTACT_SUPPORT: 'otherwise contact customer support.',
        RETURN_TO_LOGIN: 'Return to login',
        CLOSE: 'Close',
        ENTRY_NOT_FOUND: 'Entry not found.'
      },
      SET_PASSWORD: {
        PASSWORD: 'Password',
        PASSWORD_VALIDATION_MSG: 'Minimum 8 characters (at least one number, one special character and one capital letter)',
        SAVE_CHANGES: 'Save changes',
        SUCCESS: "The password was successfully changed.",
        BACK_TO_HOME: 'Go back to home'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Selected records count: ",
        ALL: "All",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        FILTER: "Filter",
        BY_STATUS: "by Status",
        BY_TYPE: "by Type",
        BUSINESS: "Business",
        INDIVIDUAL: "Individual",
        SEARCH: "Search",
        IN_ALL_FIELDS: "in all fields",
      },
      ECOMMERCE: "eCommerce",
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        CUSTOMERS_LIST: "Customers list",
        NEW_CUSTOMER: "New Customer",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Customer Delete",
          DESCRIPTION: "Are you sure to permanently delete this customer?",
          WAIT_DESCRIPTION: "Customer is deleting...",
          MESSAGE: "Customer has been deleted",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Customers Delete",
          DESCRIPTION: "Are you sure to permanently delete selected customers?",
          WAIT_DESCRIPTION: "Customers are deleting...",
          MESSAGE: "Selected customers have been deleted",
        },
        UPDATE_STATUS: {
          TITLE: "Status has been updated for selected customers",
          MESSAGE: "Selected customers status have successfully been updated",
        },
        EDIT: {
          UPDATE_MESSAGE: "Customer has been updated",
          ADD_MESSAGE: "Customer has been created",
        },
      },
    },
    HEADER: {
      SEARCH: {
        PLACE_TEXT: 'Try "calculation"',
        JOB_SEARCH: 'Job search',
        COMPANY_SEARCH: 'Professional/company search',
        ALL_CATEGORY: 'All areas',
        HIDE_BAR: 'Hide bar',
        RESET_FILTER: 'Reset filters',
        ONLY_ALPHANUMBERIC_ACCEPT: 'Only alphanumeric characters accepted'
      },
      DROPDOWN: {
        MY_SUBSCIRPTION: 'My subscription',
        VIEW_PROFILE: 'View profile',
        EDIT_PROFILE: 'Edit profile',
        MY_ADS: 'My jobs',
        SETTINGS: 'Settings',
        LOGOUT: 'Logout'
      },
      CREATE_AD: 'Create job',
      CREATD_AD_COMPELETE_PROFILE: 'To create an job please complete your profile',
      LOG_IN: 'Log in',
    },
    HOMEPAGE: {
      SHOW_MORE: 'Show more',
      LOG_IN: 'Log in',
      RESIGSETRED_SEE_THE_AD: 'Only registered users can see jobs and profiles. Login or register now!',
      AVAILABLE_IMMEDIATELY: 'Available immediately'
    },
    FOOTER: {
      INFORMATION: 'Information',
      ABOUT_US: 'About us',
      FAQ: 'FAQ',
      CONTACTS: 'Contacts',
      LEGAL: 'Legal',
      COLOPHON: 'Colophon',
      TERMS: 'Terms & Conditions',
      PRIVACY: 'Privacy',
      COOKIES: 'Cookies'
    },
    NAME_OF_PAGES: {
      HOME: 'home',
      SIGN_IN: 'Register',
      SET_PASSWORD: 'reset password ',
      RESET_PASSWORD: 'Reset password'
    }
  },
};
