import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";

@Injectable({ providedIn: "root" })
export class TagsService extends CommonService {
  constructor(public http: HttpClient) {
    super("tags", http);
  }
}
