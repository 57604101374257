<!--HERO BANNER START-->
<section class="hero-banner">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="banner-caption">
                    <span *ngFor="let data of dataArray">
                        <h1 *ngIf="data.translations.slug == 'home-page---section-1---title'"
                            [innerHtml]='data.translations.shortdescription| safe'></h1>
                    </span>
                    <span *ngFor="let data of dataArray">
                        <p *ngIf="data.translations.slug == 'home-page---section-1---subtitle'"
                            [innerHtml]='data.translations.shortdescription| safe'></p>
                    </span>
                </div>
                <form (ngSubmit)="f.form.valid && hideSearchBar()" #f="ngForm">
                    <div class="input-group search-area">
                        <input type="text" class="form-control" id="searchtext" name="searchtext"
                            [(ngModel)]="searchtext" placeholder='Prova "calcolo"' (focus)="showSearchBar()">
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" *ngIf="searchtext!=''"
                                (click)="clearSearchbar()">
                                <img src="./assets/images/ingeding/cancel.svg" alt="Clear" title="Clear">
                            </button>
                            <button class="btn btn-secondary" type="submit">
                                <img src="./assets/images/ingeding/search.svg" alt="Search" title="search">
                            </button>
                        </div>
                    </div>
                    <div *ngIf="f.submitted && f.hasError('pattern', 'searchtext')" class="text-danger">
                        <span class="bg-light">Si accettano solo caratteri alfanumerici</span>
                    </div>
                    <div class="radiobuttons">
                        <div class="rdio rdio-primary radio-inline">
                            <input name="radio" value="1" id="radio1" type="radio" [(ngModel)]="searchRadio">
                            <label for="radio1">Ricerca lavoro</label>
                        </div>
                        <div class="rdio rdio-primary radio-inline">
                            <input name="radio" value="2" id="radio2" type="radio" [(ngModel)]="searchRadio">
                            <label for="radio2">Ricerca professionista/azienda</label>
                        </div>
                    </div>
                    <div class="banner-search-tag" *ngIf="searchBar">
                        <ul *ngIf="CategoryArr.length > 0">
                            <li class="label label-primary">
                                <a href="javascript:void(0);"
                                    [ngClass]="{'active':hideshowclass == true,'inactive':hideshowclass != true}"
                                    (click)="selectThisCate1()">Tutte le aree</a>
                            </li>
                            <li class="label label-primary" *ngFor="let category of CategoryArr; let i = index">
                                <a href="javascript:void(0);" [ngClass]="{'active':category._id == selectedCategory}"
                                    (click)="selectThisCate(category._id)">{{category.translations.title}}</a>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="searchBar" class="banner-search-tag">
                        <a href="javascript:void(0);" (click)="changeSearchbarState()" class="small-font"> <i
                                class="fa fa-arrow-up" aria-hidden="true"></i> Nascondi barra</a>
                        <a href="javascript:void(0);" (click)="resetFilter()" class="small-font ml-5"> <i
                                class="fa fa-refresh" aria-hidden="true"></i> Ripristina filtri</a>
                    </div>
                </form>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 main-left-text-box">
                <!-- <canvas id="canvas3d"></canvas> -->
                <span *ngFor="let data of dataArray">
                    <img *ngIf="data.translations.slug == 'home-page---section-1---image-1'"
                        [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                        alt="" title="Gears">
                </span>
            </div>
        </div>
    </div>
</section>
<!--HERO BANNER END-->
<!--BODY START-->
<section class="main-content-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="section-title">
                    <span *ngFor="let data of dataArray">
                        <h2 *ngIf="data.translations.slug == 'home-page---section-2---title'"
                            [innerHtml]='data.translations.shortdescription| safe'></h2>
                    </span>
                    <!-- <h2>I seguenti lavori sono alla ricerca del professionista perfetto</h2> -->
                </div>
            </div>
        </div>
        <div class="row about-desc">
            <div class="col-sm-12 col-md-8">
                <span *ngFor="let data of dataArray">
                    <p *ngIf="data.translations.slug == 'home-page---section-2---subtitle'"
                        [innerHtml]='data.translations.shortdescription| safe'>
                    </p>
                </span>
            </div>
            <div class="col-sm-12 col-md-4 right-align">
                <span *ngFor="let data of dataArray">
                    <a [routerLink]="['/search-job-posts']"
                        *ngIf="data.translations.slug == 'home-page---section-2---link'"> <span
                            [innerHtml]='data.translations.shortdescription| safe'></span>
                        <img src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a>
                </span>
            </div>
        </div>
    </div>
</section>
<section class="card-main mt-64">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4" *ngFor="let jobPost of jobPostArr;let i = index">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-left media-middle" *ngIf="!isLogin">
                                <img [src]="avatarBucket + jobPost.avatars[0].avatar" class="media-object" alt="Avatar"
                                    title="Avatar">
                            </div>
                            <div class="media-left media-middle"
                                *ngIf="jobPost.user[0] && jobPost.user[0].pic!=null && jobPost.user[0].pic!='' && isLogin">
                                <img [src]="profilePicBucket + jobPost.user[0].pic" class="media-object"
                                    alt="Profile picture" title="Profile picture">
                            </div>
                            <div class="media-left media-middle"
                                *ngIf="jobPost.user[0] && (jobPost.user[0].pic==null || jobPost.user[0].pic=='') && isLogin">
                                <img [src]="avatarBucket + jobPost.avatars[0].avatar" class="media-object" alt="Avatar"
                                    title="Avatar">
                            </div>
                            <div class="media-body">
                                <a *ngIf="jobPost.user && jobPost.user[0].usergroup == '60f679adc3afbf2ab4974e4e' && isLogin"
                                    href="javascript:void(0);" (click)="redirectToProfile(jobPost.user[0]._id)">
                                    <h5 class="name-tag">
                                        {{jobPost.user[0].companyname}}
                                    </h5>
                                </a>
                                <a *ngIf="jobPost.user && jobPost.user[0].usergroup == '60f6843ac3afbf2ab4974e4f' && isLogin"
                                    href="javascript:void(0);" (click)="redirectToProfile(jobPost.user[0]._id)">
                                    <h5 class="name-tag">
                                        {{jobPost.user[0].firstname + ' ' + jobPost.user[0].lastname }}
                                    </h5>
                                </a>
                                <span class="label label-primary">{{jobPost.tag[0].translations[0].title}}</span>
                                <span class="label label-primary" *ngIf="jobPost.tag.length > 1">+{{jobPost.tag.length -
                                    1}}</span>
                            </div>
                        </div>
                        <div class="card-height-fix">
                            <a href="javascript:void(0);" (click)="redirectToJobpost(jobPost._id)">
                                <h3 class="card-title">{{jobPost.jobtitle}}</h3>
                            </a>
                            <p class="card-text">{{jobPost.overview}}</p>
                        </div>
                        <div class="card-bt-info">
                            <p class="card-subtitle">Pubblicato il {{ dateFilter(jobPost.createdAt)}}</p>
                            <a href="javascript:void(0);" class="card-link" (click)="redirectToJobpost(jobPost._id)">
                                <img src="./assets/images/ingeding/link-icon.svg" alt="Arrow" title="Arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 bottom-btn text-center">
            <div class="btn-group"> <a [routerLink]="['/search-job-posts']" class="btn-outline">Mostra di più</a></div>
        </div>
    </div>
</section>
<section class="main-content-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="section-title">
                    <span *ngFor="let data of dataArray">
                        <h2 *ngIf="data.translations.slug == 'home-page---section-3---title'"
                            [innerHtml]='data.translations.shortdescription| safe'></h2>
                        <!-- <h2>Scopri il meglio dei nostri talenti</h2> -->
                    </span>
                </div>
            </div>
        </div>
        <div class="row about-desc">
            <div class="col-sm-12 col-md-8">
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                <span *ngFor="let data of dataArray">
                    <p *ngIf="data.translations.slug == 'home-page---section-3---subtitle'"
                        [innerHtml]='data.translations.shortdescription| safe'>
                    </p>
                </span>
            </div>
            <div class="col-sm-12 col-md-4 right-align">
                <span *ngFor="let data of dataArray">
                    <a [routerLink]="['/search-profiles']"
                        *ngIf="data.translations.slug == 'home-page---section-3---link'"> <span
                            [innerHtml]='data.translations.shortdescription| safe'></span>
                        <img src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a>
                </span>
            </div>
        </div>
    </div>
</section>
<section class="card-main mt-64">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4" *ngFor="let profile of profileArr;let i = index">
                <!-- <div class="top-bg-area">
          <div class="text-box">
            <p>Professionisti</p>
            <h3>Area industria</h3>
          </div>
        </div> -->
                <div class="card h-100">
                    <div class="card-body">
                        <div class="media">
                            <div class="media-left media-middle" *ngIf="!isLogin">
                                <img [src]="avatarBucket + profile.avatars[0].avatar" class="media-object" alt="Avatar"
                                    title="Avatar">
                            </div>
                            <div class="media-left media-middle"
                                *ngIf="profile.user[0] && profile.user[0].pic!=null && profile.user[0].pic!='' && isLogin">
                                <img [src]="profilePicBucket + profile.user[0].pic" class="media-object"
                                    alt="Profile picture" title="Profile picture">
                            </div>
                            <div class="media-left media-middle"
                                *ngIf="profile.user[0] && (profile.user[0].pic==null || profile.user[0].pic=='') && isLogin">
                                <img [src]="avatarBucket + profile.avatars[0].avatar" class="media-object" alt="Avatar"
                                    title="Avatar">
                            </div>
                            <div class="media-body">
                                <a *ngIf="profile.user && profile.user[0].usergroup == '60f679adc3afbf2ab4974e4e' && isLogin"
                                    href="javascript:void(0);" (click)="redirectToProfile(profile.user[0]._id)">
                                    <h5 class="name-tag">
                                        {{profile.user[0].companyname}}
                                    </h5>
                                </a>
                                <a href="javascript:void(0);" (click)="redirectToProfile(profile.user[0]._id)"
                                    *ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f' && isLogin">
                                    <h5 class="name-tag">
                                        {{profile.user[0].firstname + ' ' + profile.user[0].lastname }}
                                    </h5>
                                </a>
                                <span class="label label-primary">{{profile.tag[0].translations[0].title}}</span>
                                <span class="label label-primary" *ngIf="profile.tag.length > 1">+{{profile.tag.length -
                                    1}}</span>
                            </div>
                        </div>
                        <div class="card-height-fix">
                            <a href="javascript:void(0);" (click)="redirectToProfile(profile.user[0]._id)"
                                *ngIf="profile.user && profile.user[0].usergroup == '60f6843ac3afbf2ab4974e4f'">
                                <h3 class="card-title">
                                    {{profile.iam[0].title}}</h3>
                            </a>
                            <p class="card-text">{{profile.description}}</p>
                        </div>
                        <div class="card-bt-info">
                            <p class="card-subtitle">
                                <span
                                    [ngClass]="{'green-dot':profileAlertLights(profile.availableFrom), 'yellow-dot':!profileAlertLights(profile.availableFrom) }"></span>
                                {{profileDateFilter(profile.availableFrom)}}
                            </p>
                            <a href="javascript:void(0);" (click)="redirectToProfile(profile.user[0]._id)"
                                class="card-link">
                                <img src="./assets/images/ingeding/link-icon.svg" alt="Arrow" title="Arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 bottom-btn text-center">
            <div class="btn-group"> <a [routerLink]="['/search-profiles']" class="btn-outline">Mostra di più</a></div>
        </div>
    </div>
</section>
<section class="main-content-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="section-title">
                    <!-- <h2>I nostri benefits</h2> -->
                    <span *ngFor="let data of dataArray">
                        <h2 *ngIf="data.translations.slug == 'home-page---section-4---title'"
                            [innerHtml]='data.translations.shortdescription| safe'></h2>
                    </span>
                </div>
            </div>
        </div>
        <div class="row about-desc">
            <div class="col-sm-12 col-md-8">
                <span *ngFor="let data of dataArray">
                    <p *ngIf="data.translations.slug == 'home-page---section-4---subtitle'"
                        [innerHtml]='data.translations.shortdescription| safe'>
                    </p>
                </span>
            </div>
            <div class="col-sm-12 col-md-4 right-align">
                <span *ngFor="let data of dataArray">
                    <a [routerLink]="['/upgrade-account']"
                        *ngIf="data.translations.slug == 'home-page---section-4---link' && isLogin">
                        <span [innerHtml]='data.translations.shortdescription| safe'></span>
                        <img src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a>

                    <a [routerLink]="['/auth/register']"
                        *ngIf="data.translations.slug == 'home-page---section-4---link' && !isLogin">
                        <span [innerHtml]='data.translations.shortdescription| safe'></span>
                        <img src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a>
                </span>
            </div>
        </div>
    </div>
</section>
<section class="benifits-box bottom-btn">
    <div class="container">
        <div class="row pt-pb">
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-1'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Trova lavoro velocemente</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-1-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-1-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-2'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Lavora alle ore che vuoi</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-2-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-2-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-3'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Avanza nella tua carriera</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-3-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-3-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-pb">
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-4'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Lavora da remoto</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-4-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-4-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-5'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Compenso da definire assieme</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-5-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-5-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="media">
                    <span *ngFor="let data of dataArray">
                        <div class="media-left align-self-center mr-3"
                            *ngIf="data.translations.slug == 'home-page---section-4---image-6'">
                            <img [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                                alt="" title="">
                        </div>
                    </span>
                    <div class="media-body">
                        <!-- <h4>Scegli i progetti più interessanti</h4> -->
                        <span *ngFor="let data of dataArray">
                            <h3 *ngIf="data.translations.slug == 'home-page---section-4---image-6-text-1'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </h3>
                        </span>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipi scing elit. 
						Nunc lacus, massa leo, tincidunt eget.</p> -->
                        <span *ngFor="let data of dataArray">
                            <p *ngIf="data.translations.slug == 'home-page---section-4---image-6-text-2'"
                                [innerHtml]='data.translations.shortdescription| safe'>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bottom-banner bottom-btn" *ngIf="!isLogin">
    <div class="container banner-bg">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="banner-b-caption">
                    <!-- <h2> Ottieni accesso a tutti i contenuti in modo semplice e veloce.</h2> -->
                    <span *ngFor="let data of dataArray">
                        <h2 *ngIf="data.translations.slug == 'home-page---section-5---title'"
                            [innerHtml]='data.translations.shortdescription| safe'>
                        </h2>
                    </span>
                    <!-- <p>I primi 3 mesi sono gratuiti, nessuna carta di credito richiesta.</p> -->
                    <span *ngFor="let data of dataArray">
                        <p *ngIf="data.translations.slug == 'home-page---section-5---subtitle'"
                            [innerHtml]='data.translations.shortdescription| safe'></p>
                    </span>
                    <div class="btn-group">
                        <span *ngFor="let data of dataArray">
                            <a [routerLink]="['/auth/register']"
                                *ngIf="data.translations.slug == 'home-page---section-4---link'" class="btn-white"
                                [innerHtml]='data.translations.shortdescription| safe'></a>
                        </span>
                        <span *ngFor="let data of dataArray">
                            <a [routerLink]="['/faq']" *ngIf="data.translations.slug == 'faq'"
                                class="btn-outline white ml-3">FAQ</a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 main-left-text-box">
                <div class="phone-img">
                    <!-- <img src="./assets/images/ingeding/Free_iPhone_11_Pro_Mockup_3 1.png" alt="iphone" title="iphone"> -->
                    <span *ngFor="let data of dataArray">
                        <img *ngIf="data.translations.slug == 'home-page---section-5---image-1'"
                            [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                            alt="" title="person">
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="bottom-banner">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6 main-left-text-box">
                <div class="left-text-box">
                    <!-- <h2> Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>-->
                    <span *ngFor="let data of dataArray">
                        <h2 *ngIf="data.translations.slug == 'home-page---section-6---title'"
                            [innerHtml]='data.translations.shortdescription| safe'>
                        </h2>
                    </span>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lacus, massa leo, tincidunt eget. Ante eget risus in mauris, maecenas.</p> -->
                    <span *ngFor="let data of dataArray">
                        <p *ngIf="data.translations.slug == 'home-page---section-6---subtitle'"
                            [innerHtml]='data.translations.shortdescription| safe'>
                        </p>
                    </span>
                    <span *ngFor="let data of dataArray">
                        <a [routerLink]="['/about-us']"
                            *ngIf="data.translations.slug == 'home-page---section-6---link'">
                            <span [innerHtml]='data.translations.shortdescription| safe'></span>
                            <img src="./assets/images/ingeding/arrow.svg" alt="Arrow" title="Arrow"></a>
                    </span>
                </div>
            </div>
            <div class="col-sm-12 col-md-6">
                <div class="right-side t-footer">
                    <!-- <img src="./assets/images/ingeding/img.png" alt="iphone" title="iphone">  -->
                    <span *ngFor="let data of dataArray">
                        <img *ngIf="data.translations.slug == 'home-page---section-6---image-1'"
                            [src]="data.translations.image ? cmsImageUrl+data.translations.slug+'/'+data.translations.image : ''"
                            alt="" title="person">
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<!--BODY END-->