import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { Meta, Title } from "@angular/platform-browser";
import { GridOption } from "../_models/gridoptions.model";
import { AuthenticationService } from "../_services";
import { UserProfileService } from "../_services/userprofile.service";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import { CountryService } from "../_services/country.service";
import { ContactProfileService } from "../_services/contactprofile.service";
import { JobPostService } from "../_services/jobpost.service";

@Component({
  selector: "app-user-profile-view",
  templateUrl: "./user-profile-view.component.html",
  styleUrls: ["./user-profile-view.component.scss"],
})
export class UserProfileViewComponent implements OnInit {
  documentBucket = environment.imgBucketUrl + "/document/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  photosBucket = environment.imgBucketUrl + "/gallery/";
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profileData: any = [];
  Category: any = "";
  regType: any = "";
  CurrentUserId: any = "";
  personalmsg: any = "";
  phone_no: any = "";
  phone_code: any = "";
  email: any = "";
  showContactForm: boolean = false;
  isContacted: boolean = false;
  Math: any;
  userId: any;
  flag: any = 0;
  CountryArr: any = [];
  relevantProfilesArr: any = [];
  tags: any = [];
  photoArr: any = [];
  documentArr: any = [];
  region: any = [];
  regionCount: any = 0;
  RegionArr: any = [];
  ActiveJobPost: any = [];
  localUserData: any = [];
  checkProfileStatus: boolean;
  latin_month: any = [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ];
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "",
    sortOrder: "",
    skip: 0,
    limit: 0,
    allrecords: true,
  };

  constructor(
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private jobPostService: JobPostService,
    private countryService: CountryService,
    private contactprofileService: ContactProfileService,
    private cdr: ChangeDetectorRef,
    private meta: Meta
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.titleService.setTitle("Ingeding | View Profile");
    this.Math = Math;
    this.authenticationService.currentUser$.subscribe((data) => {
      if (data) {
        this.CurrentUserId = data._id ? data._id : "";
        this.authenticationService.getfullUserDetails(this.CurrentUserId).subscribe((data) => {
          this.phone_no = data.contact_no;
          this.phone_code = data.country_id;
          this.email = data.email;
        });
      }
    });
    route.params.subscribe((val) => {
      window.scrollTo(0, 0);
      this.userId = this.route.snapshot.params["id"]
        ? this.route.snapshot.params["id"]
        : "";
      if (this.userId != "" && this.userId != undefined) {
        this.getProfile(this.userId);
        this.activeJobPostofUser(this.userId);
      } else {
        this.getLoginUserData();
      }
    });
  }

  ngOnInit() {
    this.checkUserProfile(this.CurrentUserId);
  }

  checkIfNumber(x: any) {
    var y = Number(x);
    if (Number.isInteger(y)) {
      return y + ".00";
    } else {
      return y;
    }
  }

  checkUserProfile(userId: any) {
    this.userProfileService.checkProfile(userId).subscribe((res) => {
      this.checkProfileStatus = res;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  activeJobPostofUser(userId: any) {
    this.jobPostService.getUserViseJobPost(userId).subscribe((res) => {
      if (res.length > 0) {
        this.ActiveJobPost = res;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      }
    });
  }

  getRelevantProfiles(userprofile: any, currentUser: any) {
    if (userprofile != "" && userprofile != undefined) {
      const data = {
        userid: currentUser,
        profileid: userprofile,
      };
      this.userProfileService.relevantUserProfile(data).subscribe((res) => {
        this.relevantProfilesArr = res.data;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      });
    }
  }

  getLoginUserData() {
    if (this.CurrentUserId != "") {
      this.getProfile(this.CurrentUserId);
    }
  }

  getProfile(id: any) {
    const myIds = {
      currentuserid: this.CurrentUserId,
      profileid: id,
    };
    this.userProfileService.getOneUserProfile(myIds).subscribe((res) => {
      if (res.data._id && res.data.availableFrom != null) {
        this.gridOption.filter = {};
        this.profileData = res.data;
        this.getRelevantProfiles(res.data._id, this.CurrentUserId);
        this.regType =
          res.data.user[0].usergroup == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
        this.tags = res.data.tag;
        this.photoArr = res.data.gallery;
        this.documentArr = res.data.document;
        this.region = res.data.region;
        this.flag = 1;
        this.getCountry();
        this.moreThreeRegion();
      } else if (
        res.data._id &&
        res.data.availableFrom == null &&
        res.data.flag == 0
      ) {
        this.flag = 2;
        this.profileData = res.data;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      } else {
        this.flag = 1;
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
      }
    });
  }

  filterMyDate(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  moreThreeRegion() {
    if (this.regionCount < this.region.length) {
      for (var i = this.regionCount; i < this.regionCount + 3; i++) {
        if (i < this.region.length) {
          this.RegionArr.push(this.region[i]);
        } else {
          break;
        }
      }
      if (i <= this.region.length) {
        this.regionCount = i;
      }
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    }
  }

  profileAlertLights(date: any = null) {
    if (date != null) {
      var myDate = new Date(date);
      var today = new Date();
      if (myDate > today) {
        return false;
      } else {
        return true;
      }
    }
  }

  nameFormat(fname: string, lname: string) {
    var firstName = "";
    var lastName = "";
    if (fname != "" && fname != undefined && fname != null && fname.length > 10) {
      firstName += fname.substring(0, 20) + "...";
    } else {
      firstName = fname;
    }
    if (lname != "" && lname != undefined && lname != null && lname.length > 10) {
      lastName += lname.substring(0, 20) + "...";
    } else {
      lastName = lname;
    }
    return firstName + "<sup class='sup-tag'>#iaming</sup><br/>" + lastName;
  }

  companyNameFormate(comapny: string) {
    if (
      comapny != "" &&
      comapny != undefined &&
      comapny != null &&
      comapny.length > 20
    ) {
      return comapny.substring(0, 20) + "...";
    } else {
      return comapny + "<sup class='sup-tag'>#iaming</sup>";
    }
  }

  showPopup() {
    if (this.checkProfileStatus) {
      this.showContactForm = true;
      setTimeout(function () {
        $("#personalmsg").focus();
      }, 200);
    }
  }

  contactProfile() {
    this.isContacted = true;
    const myContactInfo = {
      userid: this.CurrentUserId,
      userprofileid: this.profileData._id,
      description: this.personalmsg,
      countrycode: this.phone_code,
      contactno: this.phone_no,
      email: this.email,
    };
    this.contactprofileService.create(myContactInfo).subscribe((res) => {
      if (res.message === "success" || res.status === 1) {
        this.personalmsg = "";
        this.phone_no = "";
        this.phone_code = "";
        this.email = "";
        this.showContactForm = false;
        this.toastr.success(
          "Il tuo messaggio è stato inviato correttamente.",
          "Successo"
        );
        if (this.userId != "" && this.userId != undefined) {
          this.getProfile(this.userId);
        } else {
          this.getLoginUserData();
        }
      } else {
        this.isContacted = false;
        this.toastr.error(res.message, "Errore");
      }
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  getCountry() {
    this.gridOption.filter = {};
    this.gridOption.allrecords = true;
    this.gridOption.filter.status = 1;
    this.countryService.searchAll(this.gridOption).subscribe((res) => {
      this.CountryArr = _.orderBy(res.data, ["translations.name"], ["asc"]);
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }
}
