export class JobPost {
  _id: string;
  userid: any;
  jobtitle: any = "";
  overview: any = "";
  category: any = "";
  jobdesignations: any = [];
  tags: any = [];
  gallery: any = [];
  description: any = "";
  document: any = [];
  contact_no: any = null;
  countrycode: any = null;
  email: any = "";
  deadline: any = "";
  noofhours: any = "";
  flatrate: any = [];
  hourlyrate: any = [];
  placeofwork: any = "";
  deletedGallery: any = [];
  deletedDocument: any = [];
  status: any;
  createdAt: any;
}
