<!--BODY START-->
<section class="main-content-area sticky-space">
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a [routerLink]="['/']">home</a></li>
				<li class="breadcrumb-item active" aria-current="page">my posts</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-sm-12">
				<div class="section-title">
					<h2>{{'HEADER.DROPDOWN.MY_ADS' | translate}}</h2>
				</div>
			</div>
		</div>
		<div class="row about-desc">
			<div class="col-sm-12 col-md-6">
				<p>{{'PAGES.MY_POST.AD_MSG' | translate}}</p>
			</div>
		</div>
	</div>
</section>
<section class="card-main mt-64">
	<div class="container pro-research">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-3 main-registration-area">
				<hr class="mt-0 mb-3">
				<div [ngClass]="{'blue-text':myTabs==0 ,'inactive':myTabs!=0}"><a href="javascript:void(0)"
						(click)="showThisPost(0)">{{'PAGES.MY_POST.ACTIVE_AD' | translate}}</a></div>
				<hr class="mt-3 mb-3">
				<div [ngClass]="{'blue-text':myTabs==1 ,'inactive':myTabs!=1}"><a href="javascript:void(0)"
						(click)="showThisPost(1)">{{'PAGES.MY_POST.BASKET' | translate}}</a></div>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-9">
				<div class="row" *ngIf="MyJobPosts.length > 0">
					<div class="col-sm-12 right-align filter mb-5">
						<a href="javascript:void(0);" class="active" (click)="changeOrder('desc')"
							*ngIf="gridOption.sortOrder == 'asc'">
							{{'PAGES.MY_POST.PUBLICATION_DATE' | translate}}
							<img src="./assets/images/ingeding/filter-acending.svg" alt="Arrow" title="Arrow">
						</a>
						<a href="javascript:void(0);" class="active" (click)="changeOrder('asc')"
							*ngIf="gridOption.sortOrder == 'desc'">
							{{'PAGES.MY_POST.PUBLICATION_DATE' | translate}}
							<img src="./assets/images/ingeding/filter-descending.svg" alt="Arrow" title="Arrow">
						</a>
					</div>
					<div class="col-sm-12 col-md-6" *ngFor="let post of MyJobPosts">
						<div class="card h-100">
							<div class="card-body">
								<div class="media">
									<div class="media-left media-middle"
										*ngIf="post.user[0] && post.user[0].pic!=null && post.user[0].pic!=''">
										<img [src]="profilePicBucket + post.user[0].pic" class="media-object"
											alt="Avatar" title="Avatar">
									</div>
									<div class="media-left media-middle"
										*ngIf="post.user[0] && (post.user[0].pic==null || post.user[0].pic=='')">
										<img [src]="avatarBucket + post.avatars[0].avatar" class="media-object"
											alt="Avatar" title="Avatar">
									</div>
									<div class="media-body">
										<h5 class="name-tag" *ngIf="userType==1">{{post.user[0].companyname}}</h5>
										<h5 class="name-tag" *ngIf="userType==0">{{post.user[0].firstname + ' ' +
											post.user[0].lastname }}
										</h5>
										<span class="label label-primary">{{post.tag[0].translations[0].title}}</span>
										<span class="label label-primary" *ngIf="post.tag.length > 1">+{{post.tag.length
											- 1}}</span>
									</div>
								</div>
								<div class="card-height-fix">
									<a href="javascript:void(0)" [routerLink]="['/view-job-post/'+post._id]">
										<h4 class="card-title">{{post.jobtitle}}</h4>
									</a>
									<p class="card-text">{{post.overview}}</p>
								</div>
								<div class="card-bt-info">
									<p class="card-subtitle mb-2">{{'PAGES.MY_POST.PUBLISHED_ON' | translate}} {{ dateFilter(post.createdAt)}}</p>
									<div>
										<a [routerLink]="['/edit-job-post/'+post._id]" class="card-link"
											*ngIf="myTabs==0">
											<img src="./assets/images/ingeding/blue-pencil.svg" alt="Modifica"
												title="Modifica">
										</a>
										<!-- <a [routerLink]="['/edit-job-post/'+post._id]" class="card-link"
											*ngIf="myTabs==1">
											<i class="fa fa-refresh" aria-hidden="true" title="Active"></i>
										</a> -->
										<a href="javascript:void(0);" class="card-link" (click)="openPopup(post._id, 0)"
											*ngIf="myTabs==0">
											<img src="./assets/images/ingeding/trash.svg" alt="Cestino" title="Cestino">
										</a>
										<a href="javascript:void(0);" class="card-link" (click)="openPopup(post._id, 1)"
											*ngIf="myTabs==1">
											<img src="./assets/images/ingeding/trash.svg" alt="Delete" title="Delete">
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="MyJobPosts.length == 0">
					<div class="col-sm-12 right-align filter mb-5">
						<a href="javascript:void(0);" class="active">
							{{'PAGES.MY_POST.PUBLICATION_DATE' | translate}}
							<img *ngIf="gridOption.sortOrder == 'asc'" (click)="changeOrder('asc')"
								src="./assets/images/ingeding/filter-acending.svg" alt="Arrow" title="Arrow">
							<img *ngIf="gridOption.sortOrder == 'desc'" (click)="changeOrder('desc')"
								src="./assets/images/ingeding/filter-descending.svg" alt="Arrow" title="Arrow">
						</a>
					</div>
					<div class="col-sm-12 mb-5">
						<div class="card">
							<div class="card-body text-center">
								<span *ngIf="myTabs==0">{{'PAGES.MY_POST.NO_ACTIVE_AD_MSG' | translate}}</span>
								<span *ngIf="myTabs==1">{{'PAGES.MY_POST.NO_AD_TRASH_MSG' | translate}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<div class="container mt-5">
	<div class="row">
		<div class="col-sm-12 inline-btn">
			<div class="form-group mb-64">
				<button (click)="createJobPost()" class="btn-aqua">{{'HEADER.CREATE_AD' | translate}}</button>
			</div>
		</div>
	</div>
</div>

<ng-template #inactivePostModel let-modal>
	<div class="login-main backdrop">
		<div class="popup">
			<div class="modal-body">
				<div class="col-sm-12 col-md-12 main-registration-area">
					<form class="logform p-0">
						<button type="button" class="close" (click)="cancel()">&times;</button>
						<h2 class="mb-5 card-title">{{'PAGES.MY_POST.REMOVE_AD' | translate}}</h2>
						<div class="mb-5">
							<p class="small-text">{{'PAGES.MY_POST.REMOVE_AD_MSG' | translate}}</p>
							<p class="small-text">{{'PAGES.MY_POST.REMOVE_AD_NOTE' | translate}}</p>
						</div>
						<button type="button" class="btn-aqua btn-block loginbtn"
							style="background-color:#D70000;border:none;" (click)="inactive()">{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #deletePostModel let-modal>
	<div class="login-main backdrop ">
		<div class="popup">
			<div class="modal-body">
				<div class="col-sm-12 col-md-12 main-registration-area">
					<form class="logform p-0">
						<button type="button" class="close" (click)="cancel()">&times;</button>
						<h2 class="mb-5 card-title">{{'PAGES.MY_POST.DELETE_POST' | translate}}</h2>
						<div class="mb-5">
							<p class="small-text">{{'PAGES.MY_POST.AD_DELETE_PERMANTELY' | translate}}</p>
							<p class="small-text">{{'PAGES.MY_POST.TO_CONTINUE' | translate}}</p>
						</div>
						<button type="button" class="btn-aqua btn-block loginbtn"
							style="background-color:#D70000;border:none;" (click)="delete()">{{'PAGES.UPGRADE_ACCOUNT.CONFIRM' | translate}}</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!--BODY END-->