// USA

const projectname = "INGEDING";

export const locale = {
  lang: "it",
  data: {
    UPDATE: "Update",
    LIST: "List",
    HOME: "Home",
    ADD: "Add",
    NAME: "Name",
    ADSH: "Adsh",
    CIK: "CIK",
    SIC: "SIC",
    COUNTRY: "Country",
    STPRBA: "State",
    CITY: "City",
    ZIP: "ZIP",
    BAS1: "BAS 1",
    BAS2: "BAS 2",
    COUNTRY_ID: "id",
    Usergroup: "User Group",
    QUESTION_TYPE: "question type",
    ANSWER_INPUT: "answer input",
    ANSWER_TYPE: "answer type",
    QUESTION_TEXT: "question text",
    DESCRIPTION: "description",
    FIRSTNAME: "First name",
    LASTNAME: "Last name",
    EMAIL: "Email",
    PHONE: "Phone",
    COUNTRY_CODE: "Country code",
    REGIONS: "Regions",
    STATES: "State",
    CITIES: "City",
    LOCALE: "Locale",
    COUNTRIES: "Countries",
    VALUES: "Values",
    SETTINGTYPES: "SETTING TYPES",
    ANSWER_OPTIONS: "ANSWER OPTIONS",
    TRANSLATE: "Translate",
    TITLE: "Title",
    TRANSLATOR: {
      SELECT: "Select Language",
    },
    MENU: {
      HOME: "Home",
      MAP: "Map",
      NEW: "new",
      ACTIONS: "Actions",
      CREATE_POST: "Create New Post",
      PAGES: "Pages",
      WARD: "Ward",
      FEATURES: "Features",
      APPS: "Apps",
      DASHBOARD: "Dashboard",
      ASSIGNMENT: "Assignment List",
      USERS: "Users",
      MASTERS: "Masters",
      MODULES: "Modules",
      COMPANY: "Company",
      REGION: "Region",
      COUNTRY: "Country",
      STATE: "State",
      CITY: "City",
      DISTRICT: "District",
      ZONE: "Zone",
      TAG: "Tag",
      LANGUAGE: "Language",
      USERMANAGEMENT: "User Management",
      USERGROUP: "User Group",
      PACKAGE: "Package",
      SEC: "SEC",
      GLOBALSUCCESS: "Global Successo",
      PDF: "PDF",
      FIELDGROUPMAPPING: "Field Group Mapping",
      STATEMENTTYPENAME: "Statement Type",
      FIELDMAPPING: "Field Mapping",
      QUESTIONNAIRETOOL: "Questionnaire Tool",
      GLOBALSETTING: "Global Setting",
      SETTING: "Setting",
      SETTINGTYPE: "Setting Type",
      // kalu add
      QUESTIONS: "questions",
      MENU: "menu",
    },
    AUTH: {
      GENERAL: {
        OR: "Or",
        SUBMIT_BUTTON: "Submit",
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: "Sign Up",
        FORGOT_BUTTON: "Forgot Password?",
        BACK_BUTTON: "Back",
        PRIVACY: "Privacy",
        LEGAL: "Legal",
        CONTACT: "Contact",
      },
      LOGIN: {
        TITLE: "Login",
        BUTTON: "SIGN IN",
        SUCCESS: "Successfully login.",
      },
      FORGOT: {
        TITLE: "Forgot Password",
        DESC: "Enter your email to reset your password",
        SUCCESS:
          "Link has been sent to your registered email using which you can reset your password.",
      },
      SETPASSWORD: {
        TITLE: "Set Password",
        BUTTON: "Reset",
        SUCCESS: "La password è stata cambiata con successo.",
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Fullname",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password",
        USERNAME: "Username",
        TITLE: "Enter Title",
        REMEMBER_ME: "Remember Me",
      },
      VALIDATION: {
        INVALID: "{{name}} is not valid",
        REQUIRED: "{{name}} is required",
        MIN_LENGTH: "{{name}} minimum length is {{min}}",
        AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
        NOT_FOUND: "The requested {{name}} is not found",
        INVALID_LOGIN: "The login detail is incorrect",
        REQUIRED_FIELD: "Required field",
        MIN_LENGTH_FIELD: "Minimum field length:",
        MAX_LENGTH_FIELD: "Maximum field length:",
        INVALID_FIELD: "Field is not valid",
        MUST_MATCH: "Password and confirm password must match",
      },
    },
    PAGES: {
      MAP: {
        MAPVIEW: "Map View",
      },
      LANGUAGE: {
        LISTINGTITLE: projectname + " | Language List",
        ADDTITLE: projectname + " | Language Add",
        EDITTITLE: projectname + " | Language Update",
        LANGUAGELIST: "Languages List",
        LANGUAGENAMEPLURAL: "Languages",
        LANGUAGENAMESINGULAR: "language",
        NEWLANGUAGE: "New Language",
        DELETEDSELECTED: "Delete selected Languages",
        UPDATESELECTED: "Update status for selected Languages",
        ADDNEW: "Add New Language",
        UPDATE: "Update Language",
        CREATEMESSAGE: "Create language successfully",
        UPDATEMESSAGE: "Update language successfully",
        EDIT: "Edit Language",
        ADD: "Add Language",
        LOCALE: "Locale",
        LOCALE_REQUIRED: "Locale Required",
        LOCALE_INVALID: "Locale contains invalid characters",
      },
      USERS: {
        LISTINGTITLE: projectname + " | User List",
        USERSLIST: "Users List",
        NEWUSER: "New User",
        USERNAMEPLURAL: "users",
        USERNAMESINGULAR: "user",
        DELETEDSELECTED: "Delete selected users",
        UPDATESELECTED: "Update status for selected users",
        ADDNEW: "Add New User",
        UPDATE: "Update User",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        USERSUCCESS: "User Added Successfully",
        FILTERUSERGROUP: "Filter User Group",
        ADDTITLE: projectname + " | Users Add",
        EDITTITLE: projectname + " | Users Update",
        EDIT: "Edit User",
        ADD: "Add User",
        GOVT: "Government",
        OTHERGOVT: "Other than Government",
        GOVTEMP: "Government employee",
        NGO: "Non-governmental Organization (NGO)",
        PVT: "Private organization",
        CONSTENT: "Consultant",
        SHG: "Self Help Group (SHG)",
        ACADEMIC: "Academia",
        OCCUPATION: "Occupation",
        ORGANIZTION: "Organization",
      },
      USERGROUP: {
        LISTINGTITLE: projectname + " | User Group List",
        USERGROUPLIST: "User Group List",
        USERGROUPNAMEPLURAL: "usergroups",
        USERGROUPNAMESINGULAR: "usergroup",
        NEWUSERGROUP: "New User Group",
        DELETEDSELECTED: "Delete selected user groups",
        UPDATESELECTED: "Update status for selected user groups",
        ADDNEW: "Add New User Group",
        UPDATE: "Update User Group",
        ADDTITLE: projectname + " | Add User Group",
        EDITTITLE: projectname + " | Update User Group",
      },
      QUESTION: {
        LISTINGTITLE: projectname + " | Question List",
        ADDTITLE: projectname + " | Question Add",
        EDITTITLE: projectname + " | Question Update",
        QUESTIONLIST: "Questions List",
        QUESTIONNAMEPLURAL: "Questions",
        QUESTIONNAMESINGULAR: "Question",
        NEWQUESTION: "New Question",
        DELETEDSELECTED: "Delete selected questions",
        UPDATESELECTED: "Update status for selected questions",
        ADDNEW: "Add New Question",
        UPDATE: "Update Question",
        CREATEMESSAGE: "Create question successfully",
        UPDATEMESSAGE: "Update question successfully",
        EDIT: "Edit Question",
        ADD: "Add Question",
        SINGLE_CHOICE: "Single Choice",
        MULTIPLE_CHOICE: "Multiple Choice",
        QUESTION_IMAGES: "Question Images",
        ANSWER_LIMIT: "Answer Limit",
        CHOICE: "Choice",
        NUMERIC: "Numeric",
        DATE: "Date",
        UNIQUEID: "Unique ID",
        TEXT: "text",
        IMAGE: "Image",
        BOTH: "Both",
        MULTIPLE_ANSWER: "Multiple answer",
        MULTIPLE_LABLE_MULTIPLE_ANSWER: "Multiple lable Multiple answer",
        ANSWER_INPUT: "Answer Input",
        ATTACH_FILES: "Attach files",
        QUESTION_TEXT: "question text",
        ANSWER_TYPE: "Answer Type",
        ANSWER_IMAGE: "Answer Image",
        MIN_RANGE: "Min Range",
        MAX_RANGE: "Max Range",
        ANSWER: "Answer",
        QUESTION: "Question",
        SEQUENCE: "Sequence",
        ENTER_SEQUENCE: "Please enter sequence",
        INVALID_SEQUENCE: "Invalid sequence",
        ADD_OPTION: "Add",
        ADD_MAXIMUM_OF: "You can add maximum of",
        ENTER_ANSWER: "Please enter answer",
        INVALID_ANSWER: "Invalid answer",
        EXCLUSIVE_RESPONSE: "Mutually Exclusive Response",
        ENTER_MAXIMUM_RANGE: "Please enter maximum range",
        Maximum_number_minimum_number:
          "Maximum number is not less than to minimum number",
        ENTER_MINIMUM_RANGE: "Please enter minimum range",
        INVALID_RANGE: "Please insert a valid 10 digit phone number",
        PLEASEENTERQUESTION: "Please enter question",
        INVALID_QUESTION: "Invalid question",
        QUESTIONMUSTBEATLEAST:
          "Question must be at least 3 characters long and space is not allowed",
        // tslint:disable-next-line: max-line-length
        MAX_UPLOAD_SIZE:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. you can upload maximum  question image size must be 228px * 200px",
        MAX_UPLOAD_SIZE_ADD:
          "Max Upload size is 5MB. Allowed file types are jpg , png, jpeg. Image size must be 228px * 200px",
        REVISION_FOR_THIS_QUESTION:
          "Do you want to create revision for this question?",
        CREATEMAPPINGMESSAGE: "Create question mapping successfully",
        UPDATEMAPPINGMESSAGE: "Update question mapping successfully",
        DELETEMAPPINGMESSAGE: "Delete question mapping successfully",
        UPLOAD_ONLY: "You can upload only",
        CONDITIONISMAPPED: "You can not change order as condition is mapped!!",
      },
      MENU: {
        LISTINGTITLE: projectname + " | Menu List",
        ADDTITLE: projectname + " | Menu Add",
        EDITTITLE: projectname + " | Menu Update",
        MENULIST: "Menus List",
        MENUNAMEPLURAL: "Menu",
        MENUNAMESINGULAR: "Menu",
        NEWMENU: "New Menu",
        DELETEDSELECTED: "Delete selected menus",
        UPDATESELECTED: "Update status for selected menus",
        ADDNEW: "Add New Menu",
        UPDATE: "Update Menu",
        CREATEMESSAGE: "Create menu successfully",
        UPDATEMESSAGE: "Update menu successfully",
        EDIT: "Edit Menu",
        ADD: "Add Menu",
        VALUES: "Values",
        PARENT: "Parent",
        MODULE: "Module",
        PAGE: "Page",
        ICON: "Icon",
        ORDERING: "Ordering",
        VALIDATION: {
          SELECTTYPE: "Please Select Menu Type",
        },
      },
      SURVEY: {
        LISTINGTITLE: projectname + " | Survey Listing",
        SURVEYLIST: "List of surveys conducted",
        NEWSURVEY: "New Survey",
        SURVEYNAMEPLURAL: "survey",
        SURVEYNAMESINGULAR: "survey",
        DELETEDSELECTED: "Delete selected surveyor",
        UPDATESELECTED: "Update status for selected surveyor",
        ADDNEW: "Add New Survey",
        UPDATE: "Update Survey",
        PASSWORD: "Password",
        CONFIRMPASSWORD: "Confirm Password",
        SURVEYSUCCESS: "Survey submitted successfully",
        SURVEYQUESTIONSUBMIT: "Submit successfully",
        SURVEYQUESTIONSAVE: "Save successfully",
        FILTERSURVEYGROUP: "Filter Survey Group",
        ADDTITLE: projectname + " | Surveyor Add",
        EDITTITLE: projectname + " | Surveyor Update",
        EDIT: "Edit Survey",
        ADD: "Start New Survey",
        SRNO: "Sr No",
        SEARCH: "Search",
        SURVEYORNAME: "Surveyor Name",
        SURVEYQUESTIONTITLE: "Survey of public conveniences",
        SURVEYBY: "Form to be surveyed by SAATH team ",
        VALIDATIONTEXT: "Marked questions are compulsory.",
        NOTE: "Note",
        SURVEYTEXT:
          "Zone and ward are not complusory to fill. In case you know these information, please select an appropriate option.",
        PUBLICCONVENIENCENOTE:
          "If toilet location is different, please select an appropriate location from the dropdown",
      },
      PROFILE: {
        NAME: "My Profile",
        LISTINGTITLE: projectname + " | My Profile",
        PROFILESUCCESS: "Profile updated successfully!!",
        INVALID_IMAGE:
          "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
        PROFILE_IMAGE: "Profile Image",
        BASIC_INFO: "Basic info",
        PHONE: "Contact No.",
        PHONEREQ: "Contact No. is Required",
        PHONEERROR: "Please enter valid phone number.",
        ADDRESS: "Enter Address",
        ADDRESSTAB: "Address",
        CHANGEPASSWORD: "Change Password",
        RESIDENTIALADDREDSS: "Residential Address",
        PASSWORDNOTMATCH: "Oh snap! password not match.",
        PASSWORDREQUIRED: "Password is required",
        OLDPASSWORDREQUIRED: "Old password is required",
        ENTEROLDPASSWORD: "Old Password",
        NEWPASSWORDREQUIRED: "New password is required",
        ENTERNEWPASSWORD: "New Password",
        INVALIDNEWPASSWORD: "New password is not valid",
        INVALIDPASSWORD: "Password is not valid",
        NEWPASSWORDSAME: "New password should not be same as old password",
        CONFIRMPASSWORD: "Confirm Password",
        CONFIRMPASSWORDREQUIRED: "Confirm password is required",
        CONFIRMPASSWORDSAME:
          "New password and confirm password values do not match.",
        INVALIDOLDPASSWORD: "Old password is not valid",
        CREATEUSERGROUP: "Create user group successfully",
        UPDATEUSERGROUP: "Update user group successfully",
      },
      LOGIN: {
        WELCOME: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        LONGTEXT:
          "This tool will help you to understand the existing condition of public and community toilets in terms of cleanliness and you can assess cubicle-wise infrastructure and cleanliness gaps in the toilet facilities.",
        EMAIL: "Email Address/Phone Number",
        EMAIL_VALIDATION: "Please enter email or mobile number.",
        EMAIL_PATTERN:
          "Wrong format, It should be a valid email or 10 digit mobile number.",
        ACCOUNT: "Need to create an account?",
        REGISTER: "Register",
        OTP: "Send OTP",
        ENTER_OTP: "Enter OTP",
        ONE_TIME_PASS: "One Time Password",
        OTP_VALIDATION: "Please enter OTP...!",
        RESEND_OTP: "Resend OTP in",
        OTP_RESEND: "Resend OTP",
        NOT_GET_OTP: "Don`t get OTP?",
      },
      TERMSOFUSE: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        TERMSOFUSE: "Terms of Use",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The Terms of Service administer your use of this application and its services. Please read these Terms and Conditions carefully before using the application. By using the application or the services, you agree to be bound by our Terms and Conditions including the policies referenced in these terms. These Terms and Conditions may get modified from time to time.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "While Urban Management Centre (UMC) makes every effort to ensure that the content of the application is accurate and up-to-date, but does not warrant to be referred to at or in any forums for its reliability, accuracy or completeness of the information. UMC has authority and rights to delete or update complete or part of the information included in this application without prior intimation of any kinds. The content and the information being collected through this application are solely for",
        TEXT4: "information and education purpose only",
        // tslint:disable-next-line: max-line-length
        TEXT5:
          "All materials, information contained in this application, including photographs, reports and text are property of UMC group and thus are protected under Indian laws of copyright. Using our services does not give you ownership of any intellectual property rights in our services or the content you access.",
        // tslint:disable-next-line: max-line-length
        TEXT6:
          "Unless otherwise specified or notified that particular materials/information published are for its restricted use, downloading, copying, reproducing, modifying and republishing the content from this application for distributing publically or using commercially will be in violation of copyright and proprietary rights of the owner – UMC.",
      },
      PRIVACYPOLICY: {
        WELCOMETOSANITATION: "Welcome to Sanitation Mapping",
        PRIVACYPOLICY: "Privacy Policy",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT1:
          "Sanitation Mapping is a comprehensive tool for Urban Local Bodies. It simplifies Public and Community Toilet repair, refurbishment, and maintenance to enable compliance with the ODF+ protocols of the Ministry of Housing and Urban Affairs (MoHUA) formed under the Swachh Bharat Mission-Urban. It contains surveys and mapping tools to assess maintenance, cleanliness, and seat wise infrastructure gaps in toilet facilities.",
        // tslint:disable-next-line: max-line-length
        WELCOMETEXT2:
          "This tool has been developed by Urban Management Centre (UMC), with support from USAID.",
        // tslint:disable-next-line: max-line-length
        TEXT2:
          "The purpose of this application is to capture survey data of Public and Community Toilets under SBM - MISAAL project and to process such data for projecting budget requirement to make such toilets operational. While doing so we may collect your personal information like name, profession, email ID, contact number, city to identify or contact the user of the application.",
        // tslint:disable-next-line: max-line-length
        TEXT3:
          "In this process, we assure you that the protection of your privacy is crucial to us. Your personal information shall be collected by us through a form only with your consent. While some of the information will be collected from you, we may also collect other data like IP address, domain name, browser, operating system etc. from the system logs of our server for data and system security.",
        // tslint:disable-next-line: max-line-length
        TEXT4:
          "UMC assures you that the information collected through this application will be strictly confidential and will not be disclosed to a third party in any manner and shall only be used in the way you have consented.",
      },
      COMMON: {
        DATE: "Date",
        GOTO: "Go to",
        UNIQUEID: "Unique ID",
        COMPLETED: "Completed",
        INPROGRESS: "In-Progress",
        CITYLIST: "Cities List",
        COUNT: "Survey Count",
        LOGOUT: "Logout",
        NEXT: "Next",
        PREVIOUS: "Previous",
        HOME: "HOME",
        ALL: "All",
        ACTIVE: "Active",
        INACTIVE: "In Active",
        PLEASESELECT: "Please Select",
        BASICINFORMATION: "Basic Information",
        COMPANYMANAGEMENT: "Company Management",
        COMPANYREGISTEARS: "Company Registrars",
        CREATEDAT: "Created Date",
        STATUS: "Status",
        ACTION: "Action",
        DELETE: "Delete",
        TITLE: "Title",
        VIEW: "View",
        UPDATE: "Edit",
        PARENT: "Parent",
        PIC: "Pic",
        SEARCHBTN: "Search",
        FIRSTNAME: "First Name ",
        FIRSTNAMEREQ: "Please specify first name",
        FIRSTNAMEERROR: "First name is invalid",
        NAME: "Name",
        LASTNAME: "Last Name",
        LASTNAMEREQ: "Please specify last name",
        LASTNAMEERROR: "Last name is invalid",
        EMAIL: "Email",
        EMAILREQ: "Please specify email",
        EMAILERROR: "Email is invalid",
        WEBSITE: "Website",
        WEBSITEREQ: "Please specify website",
        WEBSITEERROR: "Website is invalid",
        FAX: "Fax",
        FAXERROR: "Fax is invalid",
        ADDRESS: "Address",
        MOBILENUMBER: "Mobile Number",
        DATEOFBIRTH: "Date of Birth",
        DATEOFBIRTHERROR: "Please specify date of birth",
        SAVEBTN: "Save",
        SUBMITBTN: "Submit",
        SEARCH: "Search",
        BACKBTN: "Back",
        UPDATEBTN: "Update",
        RESETBTN: "Reset",
        CANCELBTN: "Cancel",
        REPORT: "Report",
        CITY: "City",
        COUNTRY: "Country",
        CODE: " Code",
        ZONE: "Zone",
        WARD: "Ward",
        PUBLICCONVENIENCE: "Public convenience",
        SELECTCOUNTRY: "Select Country",
        SELECTREGION: "Select Region",
        SELECTSTATE: "Select State",
        SELECTCITY: "Select City",
        SELECTZONE: "Select Zone",
        SELECTWARD: "Select Ward",
        SELECTUSERGROUP: "Select UserGroup",
        STATE: "State",
        PIN: "Pin",
        ZIPCODE: "Zip code",
        NORECORDS: "No record(s) found",
        DELETEALL: "Delete All",
        UPDATESTATUS: "Update status",
        DESCRIPTION: "Description",
        DESIGNATION: "Designation",
        ENTERDESCRIPTION: "Please enter description",
        STATUSUPDATED: "Status has been updated successfully",
        SUCCESS: "Successfully",
        SELECTED: "Selected",
        STATUSSELECTEDUPDATE: "Status has been updated for selected",
        PERMANTLYDELETE: "Are you sure to permanently delete this",
        DELETETEXT: "Are you sure you want to delete this record permanently?",
        ISDELETING: "is deleting",
        DELETESELECTED: "Delete Selected",
        UPDATESELECTED: "Update Status for Selected",
        AMOUNT: "Amount",
        TIME: "Time",
        VALUE: "Value",
        USERGROUP: "User Group",
        SELECT: "Select",
        VERSION: "Version",
        TYPE_THREE_LETTERS: "Type three letters and wait some time",
        PERMISSION: "Permissions",
        LABELSEARCH: "Type Label Here",
        SELECTANYONE: "Select any one",
        IMAGEERROR: "Please upload image ",
        TEXTERROR: "Please specify text",
        NUMBERERROR: "Please specify number",
        INVALIDFILE: "You have uploaded an invalid file type",
        // tslint:disable-next-line: max-line-length
        PASSWORDHINT:
          "contains 8-20 characters, at least one number, at least  one upper character, at least one lower character, at least one special character.",
        OOPS: "Oops!",
        ERROR: "Error!",
        HELLO: "Hello!",
        WELCOMEBACK: "Welcome Back",
        // tslint:disable-next-line: max-line-length
        QUESTIONTEXT:
          "It looks like you couldn`t complete the previous participation. Would you like to continue from where you left or would you like to start a new survey question?",
        CONTINUEWITH: "Continue With",
        OR: "or",
        PREVIOUSQUESTION: "Previous Question",
        FRESHQUESTION: "Fresh Question",
        CONFIRM: "Confirm",
        AREYOUSURE: "Are you sure will start first?",
        CONFIRMIMAGE: "Are you sure delete this image?",
        YES: "Yes",
        NO: "No",
        TEXTINVALIDE: "Text contains invalid characters",
        CONFIRMCONVENIENCE:
          "Are you sure will change public convenience name ?",
        PRIVACYPOLICY: "Privacy Policy",
        TERMSOFUSE: "Terms of Use",
        FORHELPCONTACT: "For help Contact",
        CLICKFORANEWSURVEY: "Click for a new survey",
        VALIDATION: {
          INVALID_IMAGE:
            "You have uploaded an invalid file type or maximum upload file size: 5 MB!",
          NAME_INVALID: "Name contains invalid characters",
          NAME_REQUIRED: "Please specify name",
          USERGROUPREQUIRED: "Please specify user group",
          NAME_MIN_LENGTH: "Name must be at least 2 characters long.",
          NAME_MAX_LENGTH: "cannot exceed more than 50 characters.",
          ZIP_REQUIRED: "Please specify zipcode",
          ZIP_INVALID: "Zipcode is invalid",
          CITY_REQUIRED: "Please specify city",
          STATE_REQUIRED: "Please specify state",
          REGION_REQUIRED: "Please specify region",
          COUNTRY_REQUIRED: "Please specify country",
          VALUE_REQUIRED: "Please specify value",
          TITLE_INVALID: "Title contains invalid characters",
          TITLE_REQUIRED: "Please specify title",
          TITLE_MIN_LENGTH: "Title must be at least 2 characters long.",
          TITLE_MAX_LENGTH: "cannot exceed more than 50 characters.",
          PIN_REQUIRED: "Please specify Pin",
          PIN_REQUIRED_MIN_LENGTH: "Pin must be at least 5 characters long.",
          PIN_REQUIRED_MAX_LENGTH: "cannot exceed more than 6 characters.",
        },
        PATTERN: {
          // tslint:disable-next-line: quotemark
          // NAME : "[a-zA-Z '.&:_()-]*'"
          // tslint:disable-next-line: quotemark
          // tslint:disable-next-line: max-line-length
          NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
          // tslint:disable-next-line: quotemark
          EMAIL: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
          // tslint:disable-next-line: quotemark
          ADSH: "[0-9-]{20}",
          // tslint:disable-next-line: quotemark
          SIC: "[0-9]{2,50}",
          // tslint:disable-next-line: quotemark
          NUMBER_ONLY: "[0-9]+",
          // tslint:disable-next-line: quotemark
          FNAME_LNAME: "^[a-zA-Z]+\\s?[a-zA-Z]+$",
          // tslint:disable-next-line: quotemark
          CONTACT_NO: "[0-9]{10}",
          // tslint:disable-next-line: quotemark
          TAG: "[a-zA-Z']*",
          // tslint:disable-next-line: quotemark
          AMOUNT: "[0-9.]+",
          // tslint:disable-next-line: quotemark
          TIME: "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
        },
        posttext: 'Ad ogni lavoro il proprio professionista'
      },
      UPGRADE_ACCOUNT: {
        YOUR_SUBSCRIPTION: 'Il tuo abbonamento',
        CURRENT_SUBSCRIPTION: 'Abbonamento attuale',
        START_DATE: "Data d'inizio:",
        END_DATE: "Data di fine:",
        SUBSCRIPTION_EXPIRED: 'Il tuo abbonamento è scaduto.',
        NEXT_RENEWAL: 'Prossimo rinnovo',
        BUY: 'Acquista',
        SUBSCRIPTION: 'Abbonamento',
        NO_OFFER_SUB_MSG: 'Siamo spiacenti, al momento non offriamo un abbonamento per la tua categoria. Per favore, contattaci. Vedi la pagina "Contatti".',
        DURATION: 'Durata:',
        PAYMENT_METHOD: 'Metodo di pagamento',
        BANK_TRANSFER: 'Bonifico bancario',
        CREDIT_CARD: 'Credit card',
        INVOICE_INFO_MSG: 'Ingeding ti invierà una fattura in base alle informazioni fornite. Per visualizzare o aggiornare queste informazioni, vai alla',
        SETTING_PAGE: 'pagina delle impostazioni.',
        CHOOSE_CARD: 'Scegliere una carta',
        ADD_CARD: 'Aggiungi una carta',
        NAME_ON_CARD: 'Nome sulla carta',
        REQUIRED_FIELD: 'Campo richiesto.',
        CARD_NUMBER: 'Numero della carta',
        EXPIRATION_DATE: 'Data di scadenza',
        CVV: 'CVV/CVC',
        INVALID_FIELD: 'Campo invalido.',
        SUBSCRIBE: 'Iscriviti',
        CANCEL: 'Cancella',
        PAYMENT_CONFIRMATION: 'Conferma del pagamento',
        COMPLETE_PURCHASE_MSG: "Vuoi continuare e completare l'acquisto?'",
        CONFIRM: 'Conferma',
        DELETE_PERMANTELY: 'Vuoi eliminarlo definitivamente?',
        YES: 'Sì',
        NO: 'No',
        CHOOSE_CARD_TO_DELETE: 'Scegli una carta da eliminare.',
        SUBSCRIPTION_PURCHASE_SUCCESSFULLY: 'Abbonamento acquistato con successo'

      },
      MY_POST: {
        AD_MSG: 'Qui troverai la lista dei tuoi annunci. Gli annunci attivi sono pubblicati. Gli annunci nel cestino non risultano visibili e non possono più essere attivati, ma solamente cancellati definitivamente. In ogni caso, essi possono risultare molto utili come riferimento per un tuo nuovo annuncio.',
        ACTIVE_AD: 'Annunci attivi',
        BASKET: 'Cestino',
        PUBLICATION_DATE: 'Data di pubblicazione',
        PUBLISHED_ON: 'Pubblicato il',
        NO_ACTIVE_AD_MSG: 'Non ci sono annunci attivi.',
        NO_AD_TRASH_MSG: 'Non ci sono annunci nel cestino.',
        REMOVE_AD: 'Rimuovi annuncio',
        REMOVE_AD_MSG: 'Il tuo annuncio verrà rimosso dalla bacheca pubblica e sarà dunque chiuso per le candidature.',
        REMOVE_AD_NOTE: 'Nota: potrai ancora visualizzarlo come annuncio inattivo, ma non potrai ripubblicarlo.',
        DELETE_POST: 'Elimina post',
        AD_DELETE_PERMANTELY: 'Il tuo annuncio verrà eliminato definitivamente.',
        TO_CONTINUE: 'Continuare?',
        POST_REMOVE_SUCCESSFULLY: 'Il post è stato rimosso con successo',
        POST_DELTETE_SUCCESSFULLY: 'Il post è stato eliminato correttamente.',
        CONTINUE_COMPLETE_YOUR_PROFILE: 'Per proseguire si prega di completare il proprio profilo'
      },
      SEARCH_JOB_POST: {
        JOB_SECTION_TITLE: 'Ad ogni lavoro il proprio professionista',
        JOB_SECTION_DESC: 'Qui troverai la lista degli annunci (offerte di servizi, offerte di incarichi, offerte di lavoro…).Usa i filtri per ottimizzare la ricerca.',
        ALL_REGION: 'Tutte le regioni',
        REMOTELY: 'Da remoto',
        DEADLINE_UP_TO: 'Deadline fino a',
        PUBLICATION_DATE: 'Data di pubblicazione',
        DEADLINE: 'Deadline',
        NO_RESULT_FOUND: 'Siamo spiacenti, non abbiamo trovato alcun risultato per la tua ricerca'
      },
      SEARCH_PROFILE: {
        JOB_SECTION_TITLE: 'Scopri il meglio dei nostri utenti',
        JOB_SECTION_DESC: 'Qui troverai vari professionisti ed aziende a disposizione con competenze specifiche per i diversi ambiti.',
        SHOW_ALL: 'Mostra tutto',
        PROFESSIONALS: 'Professionisti',
        COMPANIES: 'Aziende',
        AVAILABLE_FROM: 'Disponibile da',
        AVAILABILITY: 'Disponibiltà'

      },
      VIEW_PROFILE: {
        USER_FROM: 'Utente da',
        REGISTER: 'Iscritto all’albo di',
        DESCRIPTION: 'Descrizione',
        OPEN_JOB: 'Lavori aperti',
        REGION_COVERED: 'Regioni coperte',
        SERVICE_AND_RATE: 'Servizi e tariffe',
        HOURLY_RATE: 'Tariffa oraria',
        HASHTAG: 'Hashtag',
        PHOTO_GALLERY: 'Galleria fotografica',
        CURRICULUM: 'Curriculum',
        PROFILE: 'Profilo',
        WHO_WE_ARE: 'Chi siamo',
        NO_OF_COLLABORATORS: 'Numero collaboratori',
        WORKING_EXPERIENCE: 'Esperienza lavorativa',
        TRAINING: 'Formazione',
        SKILLS: 'Competenze',
        SOFTWARE: 'Software',
        LANGUAGES: 'Lingue',
        DOCUMENTS: 'Documenti',
        EDIT: 'Modifica',
        ALREADY_SEND_REQUEST: "Hai già inviato la tua richiesta di contatto a quest'utente.",
        AT_YOUR_SERIVCE: 'Domande, dubbi, richieste particolari? Siamo a tua disposizione!',
        AT_YOUR_DISPOSAL: 'Domande, dubbi, richieste particolari? Sono a tua disposizione!',
        COMPLETE_YOUR_PROFILE: '**Per contattare un utente si prega di completare il proprio profilo.',
        PROFILE_NOT_COMPLETED: 'Il tuo profilo non è completo!',
        PLEASE: 'Per favore',
        CLICK_HERE: 'clicca qui',
        ENTER_DETAIL: 'to per inserire i dettagli del tuo profilo.',
        CONTACT_ME: 'Contattami',
        CONTACT_US: 'Contattaci',
        LEAVE_YOUR_MSG: 'Lascia qui il tuo messaggio.',
        EMAIL: 'Email',
        COUNTRY_PREFIX: 'Country Prefisso',
        PLEASE_SELECT: 'Si prega di selezionare.',
        TELEPHONE_NUMBER: 'Numero di telefono',
        SEND: 'Invia',
        SEE_OTHER_PROFESSIONAL: 'Vedi altri professionisti e aziende del settore',
        SHOW_ALL: 'Mostra tutti',
        MSG_SEND_CORRECTLY: 'Il tuo messaggio è stato inviato correttamente.'
      },
      EDIT_PROFILE: {
        DESCRIPTION_MSG: 'È necessario inserire una descrizione. Presenta brevemente la tua persona ed i tuoi ambiti di competenza (max. 140 caratteri).',
        IMG_DESC: 'Formato: 400x400 px, JPG, PNG (500 KB max.)',
        UPLOAD: 'Carica',
        YOU_CHOOSE: 'Scegli...',
        SERVICE: 'Servizio',
        BRIEF_DESCRIPTION: 'Breve descrizione',
        ERROR_MSG: 'Campo non può superare 7 caratteri',
        ADD_FIELD_PROJECT: 'Aggiungi campo a progetto',
        ADD_HOURLY_RATE: 'Aggiungi tariffa oraria',
        WORKING_ENVIRONMENT: 'Ambito lavorativo',
        UPLOAD_IMG_DESC: 'Carica fino a 12 immagini. File supportati: JPG, PNG (max 5 MB)',
        WORK_EXPERIENCES: 'Esperienze lavorative',
        FROM_THE: 'Dal',
        YEAR: 'Anno',
        TODAY: 'Oggi',
        POSITION: 'Posizione',
        AGENCY: 'Azienda',
        PLACE: 'Luogo',
        ADD_WORK_EXPERIENCE: 'Aggiungi esperienza lavorativa',
        FIRST_NAME: 'Nome',
        ADD_TRAINING: 'Aggiungi formazione',
        DETAIL_DESCRIPTION: 'Descrizione dettagliata (800 caratteri al massimo)',
        ADD_SKILLS: 'Aggiungi competenze',
        ADD_SOFTWARE: 'Aggiungi software',
        OTHER_LANGUAGE: 'Altra lingua',
        ADD_LANGUAGE: 'Aggiungi lingua',
        ADD_LANGUAGE_NOT_IN_LIST: 'Aggiungi una lingua non presente nella nostra lista',
        UPLOAD_FILE: 'Carica files funzionali al tuo profilo. Quanto più completo sarà, migliori saranno i risultati ottenuti',
        SELECT_FILE: 'o clic per selezionare un file',
        FILE: 'PDF, JPG, PNG (massimo 5 MB)',
        CONTACT: 'Contatto',
        PREFIX: 'Prefisso',
        SAVE: 'Salva',
        INVALID_PROFILE: 'Attenzione: tipo di file invalido o troppo grande (dimensione massima: 500 KB)',
        INVALID_IMG: 'Attenzione: tipo di file invalido o troppo grande (dimensione massima: 5 MB)',
        REACHED_MAX_IMG: 'Hai raggiunto il massimo di 12 immagini',
        SUCCESSFULLY_SAVED: 'Salvato con successo',
        PROFILE_UPDATE_SUCCESSFULLY: 'Profile Pic update successfully',
        PROFILE_REMOVE_SUCCESSFULLY: 'Profile Pic removed successfully',
        LangArray: ["Inglese", "Spagnolo", "Francese", "Tedesco", "Italiano"]

      },
      USER_SETTING: {
        PROFILE_SETTING: 'Impostazioni del profilo',
        TITLE: 'Titolo',
        SURNAME: 'Cognome',
        MIN_CHARACTER: 'Campo deve contenere almeno 2 caratteri.',
        MAX_CHARACTER: 'Campo non può superare 50 caratteri.',
        I_AM_I: 'Sono un',
        OTHER: 'Altro',
        JOB_TITLE: 'Titolo lavoro',
        MATCH_IVA: 'Partita IVA',
        OCCASIONAl_COLLABORATOR: 'Sei un collaboratore occasionale?',
        VAT_MSG: "Il nostro portale è aperto per un tempo limitato di 1 anno anche ai professionisti che non si sono ancora dotati della partita IVA. Allo scadere dell'anno, per continuare ad usufruire del servizio, sarà necessario inserire la partita IVA.",
        TAX_ID_CODE: 'Codice fiscale',
        RECIPIENT_CODE: 'Codice destinatario',
        BILLING_ADDRESS: 'Indirizzo di fatturazione',
        ADDRESS: 'Indirizzo',
        STREET_NUMBER: 'N civico',
        CITY: 'Città',
        REGION: 'Regione',
        POSTAL_CODE: 'CAP',
        INVALIDATION_FIELD: 'Campo invalidoz',
        WEBSITE: 'Sito internet',
        EMPLOYEE_AND_COLLABORATORS: 'Dipendenti e collaboratori',
        OPERATIONAl_HEADQUARTERS: 'Sede operativa',
        REGISTERED_OFFICE: 'Sede legale',
        REG_OFC_AS_SAME: 'La sede legale è uguale',
        REGISTERED_IN_THE_REGISTER: 'Inscritta all’albo di',
        IDENTIFICATION_NUMBER: 'Numero d’identificazione',
        ACCOUNT_ADMINISTRATION: "Amministrazione dell' account",
        TELEPHONE: 'Telefono',
        PEC: 'PEC',
        CONTACT_MSG: 'Questi contatti verranno utilizzati da Ingeding per l’amministrazione del vostro account. A registrazione completata potrete inserire i recapiti da pubblicare sul vostro profilo personale.',
        IDENTITY_CARD: 'Carta d’identità',
        UPLOAD_MSG: 'Carica lo scan della tua carta d’identità (fronte e retro): questo conferirà maggiore professionalità al tuo profilo.',
        DRAG_AND_DROP: 'Drag and drop',
        CHOOSE_YOUR_AVATAR: 'Scegli il tuo avatar',
        SHOW_LESS: 'Mostra meno',
        DELETE_ACCOUNT: 'Elimina account',
        CHANGE_PASSWORD: 'Cambia password',
        CURRENT_PASSWORD: 'Password attuale',
        NEW_PASSWORD: 'Nuova password',
        PASSWORD_IS_REQUIRED: 'La password è richiesta',
        PASSWORD_IS_INVALID: 'La password non è valida',
        REPEAT_YOUR_PASSWORD: 'Ripetere la password',
        PASSWORD_ERROR_MSG: 'La password di conferma non corrisponde alla password.',
        PASSWORD_VALIDATION_MSG: 'Almeno 8 caratteri (incluse lettere maiuscole, numerie caratteri speciali)',
        NOTIFICATION_SETTING: 'Impostazioni di notifica',
        NOTIFY_MSG: "Inviami una notifica via email quando c'è un nuovo annuncio interessante, affine agli hashtag del mio profilo",
        EDIT_YOUR_PUBLIC_PROFILE: 'Modifica il tuo profilo pubblico',
        EDIT_PROFILE_MSG: 'Inserisci i tuoi ambiti lavorativi, le tue competenze principali ed i dati di contatto. Quanto più completo sarà il tuo profilo, migliori saranno i risultati ottenuti. Informazioni più dettagliate potrai inserirle nella sezione "Modifica il profilo".',
        ACCOUNT_DELETE_PERMANENTLY: 'Il tuo account sarà cancellato in modo permanente.',
        ARE_YOU_SURE: 'Sei sicuro?',
        PASSWORD: "Password",
        DELETE: 'Elimina',
        YOUR_CHANGES_SAVED: 'Le tue modifiche sono state salvate.',
        ACCOUNT_REMOVE_SUCCESSFULLY: 'Il tuo account è stato rimosso con successo',
        CONFIRMED: 'Confermato!',
        'Architetto/a': 'Architetto/a',
        Geometra: 'Geometra',
        'Ingegnere/a': 'Ingegnere/a',
        Perito: 'Perito'
      },
      VIEW_JOB_POST: {
        ANNOUNCEMENT_CLOSED: 'Annuncio chiuso',
        ADVERTISMENT_OPEN: 'Annuncio aperto',
        BREIF_OVERVIEW: 'Breve panoramica del lavoro (140 caratteri massimo)',
        NUMBER_OF_HOUR_EXPECTED: 'Numero ore previste',
        COMPENSATION: 'Compenso',
        HOURLY_WAGE: 'Salario orario',
        WORKPLACE: 'Luogo di lavoro',
        DETAILS: 'Dettagli',
        BRIEF_DESC_ABOUT_YOURSELF: 'Breve descrizione su te stesso.',
        CONTACT: 'Contatta',
        ALREADY_APPILED_FOR_ANNOUNCEMENT: 'Hai già presentato domanda per questo annuncio.',
        CONTACT_WHO_POST_THE_AD: "Clicca qui per contattare chi ha pubblicato I'annuncio.",
        AD_NOT_FOUND: 'Annuncio non trovato. Riprova o contattaci.',
        MESSAGE: 'Messaggio',
        WHY_CONTACT_WHO_POST_ADV: 'Illustra in poche parole perché desideri contattare chi ha pubblicato l’annuncio.',
        SEND_THE_MSG: 'Invia il messaggio',
        DESC_MAX_140_CHARACTER: 'Campo non può superare 140 caratteri',
        FROM: 'Da',
        BRIEF_DESC: 'Breve descrizione (max. 140 caratteri)',
        PROJECT_RATE: 'Tariffa a progetto',
        RATE_MAX_7_CHARACTER: ' Campo non può superare 7 caratteri',
        ADD_FEE_TO_PROJECT: 'Aggiungi tariffa a progetto',
        DETAIL_JOB_DESC: 'Descrizione dettagliata del lavoro (max. 800 caratteri)',
        UPLOAD_FILE_DESC: 'Carica files funzionali al tuo annuncio. Quanto più completo sarà il tuo annuncio, migliori saranno i risultati ottenuti.',
        PUBLISH: 'Pubblica',
        REMOVE: 'Rimuovi',
        SUCCESFULLY_REMOVED: 'Rimosso con successo.',
        SUCCESSFULLY_DELETED: 'Eliminato con successo',
        AD_POSTED_BULLETIN: 'Il tuo annuncio è stato pubblicato sulla bacheca. ',
        QUE_SEND_SUCCESSFULLY: 'La tua domanda è stata inviata correttamente.'

      },
      LOGIN_PAGE: {
        LOGIN: 'Login',
        EMAIL_REQUIRED: "L'e-mail è richiesta",
        EMAIL_INVALID: "L'e-mail non è valida",
        PASSWORD_REQUIRED: 'La password è richiesta',
        PASSWORD_INVALID: 'La password non è valida',
        REMEMBER_ME: 'Ricordami',
        FORGET_PASSWORD: 'Password dimenticata?',
        REGISTER_NOW: 'Registrati ora',
        PASSWORD: "Password",
        LOGIN_SUCCESFULLY: 'Login effettuato con successo',
        SUCCESS: 'Successo',
        SOMETHING_WENT_WRONG: 'Qualcosa è andato storto, per favore riprova più tardi',
        MISTAKE: 'Errore'
      },
      REGISTRATION_PAGE: {
        REGISTER: 'Registrati',
        WHO_ARE_YOU: 'Chi sei?',
        PROFESSIONAL: 'Professionista',
        NAME_AND_SURNAME: 'Nome e Cognome',
        REGISRATION_NUMBER: 'Numero di iscrizione',
        IDENTIFICATION_DOCUMENTS: 'Documenti di identificazione',
        UPLOAD_MSG: 'Carica lo scan del tuo tesserino di iscrizione all’ordine professionale o analoga documentazione di identificazione. Questo conferirà professionalità al tuo profilo.',
        TERMS_COND_AND_POLICY: 'Termini e condizioni, privacy',
        I_CONFIRM_ACCEPT: 'Confermo di aver preso visione e di accettare integralmente le',
        COND_OF_USE: 'condizioni generali di utilizzo.',
        PURPOSE_OF_ARTICLE: 'Ai sensi e per gli effetti degli articoli 1341 e 1342 c.c. vengono specificamente esaminate ed approvate le seguenti disposizioni delle',
        OTHER_POLICY: ': 3.3 (sospensione e cancellazione dell’account); 6.1 e 6.2 (funzionamento del Sito e del Portale); 6.4 e 6.5 (chiusura o sospensione del Sito e del Portale); 10 (recesso e chiusura del Sito e/o del Portale); 11 (limitazioni di responsabilità); 14 (cessione); 15 (modifica delle condizioni); 16 (foro competente).',
        USE_FOR_MARKETING_PURPOSE: 'Presto il mio consenso ai trattamenti con finalità di marketing come descritti nell’informativa sulla',
        USE_MY_DATA: 'Presto il mio consenso per l’utilizzo dei miei dati personali per finalità di promozione del portale, come descritte nell’informativa sulla',
        I_CONFIRM_READ: 'Confermo di aver preso visione dell’informativa sulla',
        CONTINUE_POLICY: "Per continuare si prega di accettare i termini e le condizioni e l'informativa sulla privacy",
        REGISTRATION_COMPLETED_SUCCESSFULLY: 'Registrazione effettuata con successo',
        CORRECT_DATA_TRY_AGAIN: 'Si prega di correggere i dati e riprovare.',
        REGISTRATION_COMPLETED_LOGIN: 'Registrazione riuscita! Effettuare il login per continuare.',
        LOGIN: 'Vai al login',
      },
      FORGET_PASSWORD_PAGE: {
        RESET_PASWORD: 'Resetta la password',
        RESET_MSG: 'Inserisci il tuo indirizzo e-mail e ti invieremo un link per reimpostare la password.',
        ADDRESS_INVALID: 'L’indirizzo inserito non è valido.',
        CONTINUES: 'Continua',
        SEND_MAIL: 'Ti abbiamo inviato una email di verifica.',
        CONFIRM_ACC: 'Conferma il tuo account cliccando sul link che trovi nella email e reimposta la password.',
        CHECK_SPAM_FOLDER: 'Non hai ricevuto la nostra email? Controlla anche la cartella spam;',
        CONTACT_SUPPORT: 'altrimenti contatta il servizio di assistenza clienti.',
        RETURN_TO_LOGIN: 'Torna al login',
        CLOSE: 'Chiudi',
        ENTRY_NOT_FOUND: 'Inserimento non trovato.'
      },
      SET_PASSWORD: {
        PASSWORD: 'Password',
        PASSWORD_VALIDATION_MSG: 'Minimo 8 caratteri (almeno un numero, un carattere speciale e una lettera maiuscola)',
        SAVE_CHANGES: 'Salva modifiche',
        SUCCESS: "La password è stata cambiata con successo.",
        BACK_TO_HOME: 'Torna alla home'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: "Selected records count: ",
        ALL: "All",
        SUSPENDED: "Suspended",
        ACTIVE: "Active",
        FILTER: "Filter",
        BY_STATUS: "by Status",
        BY_TYPE: "by Type",
        BUSINESS: "Business",
        INDIVIDUAL: "Individual",
        SEARCH: "Search",
        IN_ALL_FIELDS: "in all fields",
      },
      ECOMMERCE: "eCommerce",
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        CUSTOMERS_LIST: "Customers list",
        NEW_CUSTOMER: "New Customer",
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: "Customer Delete",
          DESCRIPTION: "Are you sure to permanently delete this customer?",
          WAIT_DESCRIPTION: "Customer is deleting...",
          MESSAGE: "Customer has been deleted",
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: "Customers Delete",
          DESCRIPTION: "Are you sure to permanently delete selected customers?",
          WAIT_DESCRIPTION: "Customers are deleting...",
          MESSAGE: "Selected customers have been deleted",
        },
        UPDATE_STATUS: {
          TITLE: "Status has been updated for selected customers",
          MESSAGE: "Selected customers status have successfully been updated",
        },
        EDIT: {
          UPDATE_MESSAGE: "Customer has been updated",
          ADD_MESSAGE: "Customer has been created",
        },
      },
    },
    HEADER: {
      SEARCH: {
        PLACE_TEXT: 'Prova "calcolo"',
        JOB_SEARCH: 'Ricerca lavoro',
        COMPANY_SEARCH: 'Ricerca professionista/azienda',
        ALL_CATEGORY: 'Tutte le aree',
        HIDE_BAR: 'Nascondi barra',
        RESET_FILTER: 'Ripristina filtri',
        ONLY_ALPHANUMBERIC_ACCEPT: 'Si accettano solo caratteri alfanumerici'
      },
      DROPDOWN: {
        MY_SUBSCIRPTION: 'Il mio abbonamento',
        VIEW_PROFILE: 'Visualizza il profilo',
        EDIT_PROFILE: 'Modifica il profilo',
        MY_ADS: 'I miei annunci',
        SETTINGS: 'Impostazioni',
        LOGOUT: 'Logout'
      },
      CREATE_AD: 'Crea un annuncio',
      CREATD_AD_COMPELETE_PROFILE: 'Per creare un annuncio si prega di completare il proprio profilo',
      LOG_IN: 'Accedi',

    },
    HOMEPAGE: {
      SHOW_MORE: 'Mostra di più',
      LOG_IN: 'Accedi',
      RESIGSETRED_SEE_THE_AD: 'Solo gli utenti registrati possono vedere annunci e profili. Effettua il login o registrati ora!',
      AVAILABLE_IMMEDIATELY: 'Disponibile da subito'

    },
    FOOTER: {
      INFORMATION: 'Informazioni',
      ABOUT_US: 'Su di noi',
      FAQ: 'FAQ',
      CONTACTS: 'Contatti',
      LEGAL: 'Legal',
      COLOPHON: 'Colophon',
      TERMS: 'Termini & condizioni',
      PRIVACY: 'privacy',
      COOKIES: 'Cookies'
    },
    NAME_OF_PAGES: {
      HOME: 'home',
      SIGN_IN: 'Registrati',
      SET_PASSWORD: 'reset password ',
      RESET_PASSWORD: 'Reset password'
    }
  },
};
