import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class JobPostService extends CommonService {
  constructor(public http: HttpClient) {
    super("jobpost", http);
  }
  randomView(data) {
    return this.http.post(this.baseUrl + "/randomView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getJobPost(data: any) {
    return this.http.post(this.baseUrl + "/onerecordView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  relevantJobPost(data: any) {
    return this.http.post(this.baseUrl + "/releventjobpost", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getOneJobPost(data: any) {
    return this.http.post(this.baseUrl + "/jobpostView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
  getUserViseJobPost(data: any) {
    return this.http.get(this.baseUrl + "/getjobpostbyuserId/" + data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
