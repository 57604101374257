import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class FrontHomeService extends CommonService {
  constructor(http: HttpClient) {
    super("cms", http);
  }
}
