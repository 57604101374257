import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class LanguageService extends CommonService {
  languageArr: any = [];
  token: any;
  tokenData: any;
  constructor(http: HttpClient) {
    super("language", http);
  }

  getLanguage() {
    this.tokenData = JSON.parse(localStorage.getItem("currentUser"));
    this.languageArr = this.tokenData.language;
    return this.languageArr;
  }
}
