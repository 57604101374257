import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from '../_layout/language-selector/language-selector.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [LanguageSelectorComponent]
})
export class SharedModule { }
