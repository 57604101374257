import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { NgbModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Meta, Title } from "@angular/platform-browser";
import { GridOption } from "../_models/gridoptions.model";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../_services";
import { JobPostService } from "../_services/jobpost.service";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { UserProfileService } from "../_services/userprofile.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-my-posts",
  templateUrl: "./my-posts.component.html",
  styleUrls: ["./my-posts.component.scss"],
})
export class MyPostsComponent implements OnInit {
  @ViewChild("deletePostModel", { static: false })
  deletePostModel: TemplateRef<any>; // Note: TemplateRef
  @ViewChild("inactivePostModel", { static: false })
  inactivePostModel: TemplateRef<any>; // Note: TemplateRef
  avatarBucket = environment.imgBucketUrl + "/avatar/";
  profilePicBucket = environment.imgBucketUrl + "/profile_images/";
  MyJobPosts: any = [];
  userType: any = "";
  myTabs = 0;
  id: any = "";
  inactivePostId: any = "";
  deletePostId: any = "";
  model: NgbDateStruct;
  gridOption: GridOption = {
    pageTitle: "select Page",
    page: 1,
    pagesize: 5,
    total: 0,
    order: "-_id",
    search: "",
    filter: { status: 1 },
    sortField: "createdAt",
    sortOrder: "desc",
    skip: 0,
    limit: 0,
    allrecords: true,
  };

  constructor(
    private titleService: Title,
    private myPostService: JobPostService,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private profileService: UserProfileService,
    private meta: Meta,
    private translateService: TranslateService
  ) {
    this.meta.addTag({ name: "robots", content: "noindex" });
    this.titleService.setTitle("Ingeding | My Posts");
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.authenticationService.currentUser$.subscribe((data) => {
      if (data) {
        this.id = data._id;
        this.userType =
          data.usergroup._id == "60f679adc3afbf2ab4974e4e" ? "1" : "0";
      }
    });
    this.getMyJobPost();
  }

  showThisPost(myTabs: any) {
    this.myTabs = myTabs;
    this.getMyJobPost();
  }

  getMyJobPost() {
    if (this.myTabs == 0) {
      this.gridOption.filter = {};
      this.gridOption.filter.userid = this.id;
      this.gridOption.filter.status = 1;
    } else {
      this.gridOption.filter = {};
      this.gridOption.filter.userid = this.id;
      this.gridOption.filter.status = 0;
    }
    this.myPostService.searchAll(this.gridOption).subscribe((res) => {
      this.MyJobPosts = res.data;
      if (!(this.cdr as any).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  openPopup(id: any, action: any) {
    if (action == 0) {
      this.inactivePostId = id;
      this.modalService.open(this.inactivePostModel);
    } else if (action == 1) {
      this.deletePostId = id;
      this.modalService.open(this.deletePostModel);
    }
  }

  cancel() {
    this.modalService.dismissAll();
  }

  inactive() {
    if (this.inactivePostId != "") {
      let idsForUpdate = [];
      idsForUpdate.push(this.inactivePostId);
      var jobPostUpdate = {
        ids: idsForUpdate,
        action: "update",
        value: 0,
      };
      this.myPostService.updateStatusMany(jobPostUpdate).subscribe((res) => {
        this.modalService.dismissAll(this.inactivePostModel);
        this.getMyJobPost();
        if (!(this.cdr as any).destroyed) {
          this.cdr.detectChanges();
        }
        this.toastr.success(
          //"Il post è stato rimosso con successo.",
          this.translateService.instant('PAGES.MY_POST.POST_REMOVE_SUCCESSFULLY'),
          this.translateService.instant('PAGES.LOGIN_PAGE.SUCCESS')
        );
      });
    }
  }

  delete() {
    if (this.deletePostId != "") {
      this.myPostService.delete(this.deletePostId).subscribe((res) => {
        if (res.message === "success" || res.status === 1) {
          this.modalService.dismissAll(this.deletePostModel);
          this.deletePostId = "";
          this.getMyJobPost();
          this.toastr.success(
            this.translateService.instant('PAGES.MY_POST.POST_DELTETE_SUCCESSFULLY'),
            this.translateService.instant('PAGES.FORGET_PASSWORD_PAGE.CLOSE')
          );
          if (!(this.cdr as any).destroyed) {
            this.cdr.detectChanges();
          }
        } else {
          this.toastr.error(
            this.translateService.instant('PAGES.LOGIN_PAGE.SOMETHING_WENT_WRONG'),
            this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE')
          );
        }
      });
    }
  }

  dateFilter(date: any = null) {
    if (date != null) {
      let myDate = new Date(date);
      const day = myDate.getDate();
      const month = myDate.getMonth() + 1;
      const year = myDate.getFullYear();
      return day + "." + month + "." + year;
    }
  }

  changeOrder(order) {
    this.gridOption.sortOrder = order;
    this.getMyJobPost();
  }

  createJobPost() {
    if (this.id != "") {
      this.profileService.checkProfile(this.id).subscribe((res) => {
        if (res) {
          this.router.navigate(["/add-job-post"]);
        } else {
          this.router.navigate(["/edit-profile"]);
          this.toastr.error(this.translateService.instant('PAGES.MY_POST.CONTINUE_COMPLETE_YOUR_PROFILE'), this.translateService.instant('PAGES.LOGIN_PAGE.MISTAKE'));
        }
      });
    }
  }
}
