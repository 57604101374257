export class Changepass {
  oldpassword: any;
  password: any;
  confirmpassword: any;
  clear(): void {
    this.oldpassword = "";
    this.password = "";
    this.confirmpassword = "";
  }
}
