<section class="main-content-area sticky-space">
    <div class="container">
        <div class="row text-break">
            <div class="col-sm-12 col-md-12 col-lg-12 main-registration-area" *ngIf="subscriptionDetails.length!=0">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['']">home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">upgrade account</li>
                    </ol>
                </nav>
                <div *ngIf="!isPurchase">
                    <div class="section-title">
                        <h2>Il tuo abbonamento</h2>
                    </div>
                    <div *ngIf="subscriptionDetails._id">
                        <h3 class="blue-title mt-64">Abbonamento attuale</h3>
                        <hr>
                        <fieldset class="form-group row upgrade-ac" *ngIf="subscriptionDetails">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">
                                {{subscriptionDetails.packagename != null?
                                subscriptionDetails.packagename : 'Trial pack'}}&nbsp;  <span style="color:#00ad9e; text-shadow: 2px 2px #00ad9e;">{{subscriptionDetails.packagename == null ? '(FREE YEAR)':
                                    ''}}</span> :
                            </legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{subscriptionDetails.packageamount != null ?
                                        checkIfNumber(subscriptionDetails.packageamount) : '0.00'}} €
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group row upgrade-ac">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">Data d'inizio:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{ filterMyDate(subscriptionDetails.startdate) }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group row upgrade-ac mb-64">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">Data di fine:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{ filterMyDate(subscriptionDetails.enddate) }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="mb-64" *ngIf="!subscriptionDetails._id">
                        <span class="text-danger">Il tuo abbonamento è scaduto.</span>
                    </div>

                    <div *ngIf="scheduled && scheduledPlan && scheduledPlan._id">
                        <h3 class="blue-title mt-64">Prossimo rinnovo</h3>
                        <hr>
                        <fieldset class="form-group row upgrade-ac" *ngIf="scheduledPlan">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">
                                {{scheduledPlan.packagename != null?
                                    scheduledPlan.packagename : 'Trial pack'}}&nbsp;  <span style="color:#00ad9e; text-shadow: 2px 2px #00ad9e;">{{scheduledPlan.packagename == null ? '(FREE YEAR)':
                                        ''}}</span> :</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{checkIfNumber(scheduledPlan.packageamount)}} €
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group row upgrade-ac" *ngIf="scheduledPlan.startdate != null">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">Data d'inizio:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{ filterMyDate(scheduledPlan.startdate) }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group row mb-64" *ngIf="scheduledPlan.startdate != null">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">Data di fine:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{ filterMyDate(scheduledPlan.enddate) }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="form-group row mb-64">
                        <div class="col-sm-10 inline-btn">
                            <button type="button" class="btn-aqua mr-2" (click)="openPayment()"
                                *ngIf="checkNearToExpired(subscriptionDetails.enddate) && !scheduled">
                                Acquista</button>
                            <button type="button" class="btn-aqua mr-2" (click)="openPayment()"
                                *ngIf="!subscriptionDetails._id && !scheduled">
                                Acquista</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="isPurchase">
                    <div *ngIf="packInfo.length == 0">
                        <div class="section-title">
                            <h2>Abbonamento</h2>
                        </div>
                        <hr>
                        <div class="mb-5 text-center">
                            <span class="text-danger"><strong>Siamo spiacenti, al momento non offriamo un abbonamento
                                    per la tua categoria. Per favore, contattaci. Vedi la pagina
                                    "Contatti".</strong></span>
                        </div>
                    </div>
                    <div *ngIf="packInfo.length != 0">
                        <div class="section-title">
                            <h2>Abbonamento</h2>
                        </div>
                        <hr>
                        <fieldset class="form-group row upgrade-ac">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">
                                {{packInfo.packagename}}:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        {{checkIfNumber(packInfo.amount)}} €
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group row upgrade-ac mb-64">
                            <legend class="col-form-label col-sm-3 float-sm-left pt-0">Durata:</legend>
                            <div class="col-sm-9">
                                <div class="form-row">
                                    <div class="col-md-6 text-right">
                                        1 {{packInfo.planinterval}}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <form (ngSubmit)="f.form.valid" #f="ngForm" autocomplete="off">
                            <h3 class="blue-title mt-64">Metodo di pagamento</h3>
                            <hr>
                            <fieldset class="form-group row">
                                <div class="col-12">
                                    <div class="topradiobuttons">
                                        <div class="toprdio toprdio-primary radio-inline">
                                            <input type="radio" name="paymenttype" value="0" id="radio0"
                                                [(ngModel)]="paymenttype">
                                            <label for="radio0">Bonifico bancario</label>
                                        </div>
                                        <div class="toprdio toprdio-primary radio-inline">
                                            <input type="radio" name="paymenttype" value="1" id="radio1"
                                                [(ngModel)]="paymenttype">
                                            <label for="radio1">Carta di credito</label>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group row">
                                <div class="col-md-8 col-sm-12" *ngIf="paymenttype == '0'">
                                    <span>Ingeding ti invierà una fattura in base alle informazioni fornite. Per
                                        visualizzare o aggiornare queste informazioni, vai alla<a
                                            [routerLink]="['/user-settings']"> pagina delle impostazioni.</a></span>
                                    <span *ngFor="let data of dataArray">
                                        <div *ngIf="data.translations.slug == 'upgrade-account---invoice-section'"
                                            [innerHtml]='data.translations.shortdescription| safe'>
                                        </div>
                                    </span>
                                </div>
                                <div class="col-md-8 col-sm-12" *ngIf="paymenttype == '1'">
                                    <div class="row mb-2 blue-text">
                                        <div class="col-sm-12">
                                            Scegliere una carta
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="topradiobuttons">
                                                <div class="toprdio toprdio-primary radio-inline"
                                                    *ngFor="let card of cardinfo;let i = index">
                                                    <input type="radio" name="selectedstripecardid" value="{{card.id}}"
                                                        id="radio_{{i}}" [(ngModel)]="selectedstripecardid">
                                                    <label for="radio_{{i}}"> {{card.name}} {{card.last4}}
                                                        {{card.exp_month
                                                        +
                                                        '/' + card.exp_year}} <a href="javascript:void(0);"
                                                            (click)="deleteThisCard(card.id, card.customer)"><i
                                                                class="fa fa-trash-o red ml-1" aria-hidden="true"
                                                                alt="Delete" title="Delete"></i></a>
                                                    </label>
                                                </div>
                                                <div class="toprdio toprdio-primary radio-inline">
                                                    <input type="radio" name="selectedstripecardid" value="new"
                                                        id="radio_new" [(ngModel)]="selectedstripecardid">
                                                    <label for="radio_new">
                                                        Aggiungi una carta
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="selectedstripecardid == 'new'">
                                        <div class="col-md-6 col-sm-12 mb-2">
                                            <label for="">Nome sulla carta</label>
                                            <input type="text" class="form-control" name="cardname"
                                                placeholder="Nome sulla carta" #cardname="ngModel"
                                                [(ngModel)]="cardinfo.cardname" minlength="1" maxlength="50" required>
                                            <div *ngIf="f.submitted &&  cardname.errors?.required" class="text-danger">
                                                Campo richiesto
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 mb-2">
                                            <label for="">Numero della carta</label>
                                            <input type="text" class="form-control" name="cardnumber"
                                                placeholder="Numero della carta" #cardnumber="ngModel"
                                                [(ngModel)]="cardinfo.cardnumber" pattern="[0-9]{16}$" minlength="16"
                                                maxlength="16" required>
                                            <div *ngIf="f.submitted &&  cardnumber.errors?.required"
                                                class="text-danger">
                                                Campo richiesto
                                            </div>
                                            <div *ngIf="f.submitted &&  cardnumber.errors?.pattern" class="text-danger">
                                                Campo invalido
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-12 mb-2">
                                            <label for="">Data di scadenza</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="cardmm" placeholder="MM"
                                                    #cardmm="ngModel" [(ngModel)]="cardinfo.cardmm" pattern="[0-9]{2}$"
                                                    minlength="2" maxlength="2" required>
                                                <input type="text" class="form-control" name="cardyy" placeholder="YY"
                                                    #cardyy="ngModel" [(ngModel)]="cardinfo.cardyy" pattern="[0-9]{2}$"
                                                    minlength="2" maxlength="2" required>
                                            </div>
                                            <div *ngIf="f.submitted && (cardmm.errors?.required || cardyy.errors?.required)"
                                                class="text-danger">
                                                Campo richiesto
                                            </div>
                                            <div *ngIf="f.submitted && (cardmm.errors?.pattern || cardyy.errors?.pattern)"
                                                class="text-danger">
                                                Campo invalido
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-12 mb-2">
                                            <label for="">CVV/CVC</label>
                                            <input type="password" class="form-control" name="cardcvc"
                                                placeholder="CVV/CVC" #cardcvc="ngModel" [(ngModel)]="cardinfo.cardcvc"
                                                minlength="3" maxlength="3" pattern="[0-9]{3}$" required>
                                            <div *ngIf="f.submitted &&  cardcvc.errors?.required" class="text-danger">
                                                Campo richiesto
                                            </div>
                                            <div *ngIf="f.submitted &&  cardcvc.errors?.pattern" class="text-danger">
                                                Campo invalido
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <hr>
                            <div class="form-group row mt-64 mb-64">
                                <div class="col-sm-10 inline-btn">
                                    <button type="submit" class="btn-aqua" (click)="paymentConfirmation(f)"
                                        *ngIf="checkNearToExpired(subscriptionDetails.enddate) && !scheduled && packInfo.length != 0">
                                        Iscriviti</button>
                                    <button type="submit" class="btn-aqua" (click)="paymentConfirmation(f)"
                                        *ngIf="!subscriptionDetails._id && packInfo.length != 0">
                                        Iscriviti</button>
                                    <button type="button" class="ml-2 btn-cancel" (click)="back()">
                                        Cancella</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #purchaseConfirmationPopup let-modal>
    <div class="login-main backdrop">
        <div class="popup">
            <div class="modal-body">
                <div class="col-sm-12 col-md-12 main-registration-area">
                    <form class="logform p-0">
                        <button type="button" class="close" (click)="cancel()">&times;</button>
                        <h2 class="mb-5 card-title">Conferma del pagamento</h2>
                        <div class="mb-5">
                            <p class="small-text">Vuoi continuare e completare l'acquisto?</p>
                        </div>
                        <button type="button" class="btn-aqua btn-block loginbtn" *ngIf="this.paymenttype == 1"
                            style="background-color:#00ad9e;border:none;" [disabled]="submitted"
                            (click)="newsubscription()">Conferma</button>

                        <button type="button" class="btn-aqua btn-block loginbtn" *ngIf="this.paymenttype == 0"
                            style="background-color:#00ad9e;border:none;" [disabled]="submitted"
                            (click)="invoice()">Conferma</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirmModel let-modal>
    <div class="login-main backdrop">
        <div class="popup">
            <div class="modal-header">
                <span class="ribbon top-left ribbon-primary">
                    <!-- <small><b>Conferma</b></small> -->
                    <h4>Conferma</h4>
                </span>
                <!-- <h1>Conferma</h1> -->
            </div>
            <div class="modal-body">
                <p class="mt-2">Vuoi eliminarlo definitivamente?</p>
                <div id="option">
                    <div class="col-md-12 text-center">
                        <button type="button" class="btn btn-danger" (click)="onNoClick()"
                            style="background-color:black;border:none;">No</button>&nbsp;
                        <button type="button" class="btn btn-success" style="background-color:#00ad9e;border:none;"
                            (click)="onYesClick()" [disabled]="deleted">Sì</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>