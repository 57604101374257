// Angular
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "./loader.service";
import { TranslationService } from "./translation.service";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  // intercept request and add token
  constructor(
    public loaderService: LoaderService,
    public authService: AuthenticationService,
    public toastr: ToastrService,
    public translationService: TranslationService
  ) { }
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.display(this.requests.length > 0);
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.body instanceof FormData) {
      if (
        localStorage.getItem("authToken") &&
        localStorage.getItem("authToken") !== null &&
        localStorage.getItem("authToken") !== undefined &&
        localStorage.getItem("language") &&
        localStorage.getItem("language") !== null &&
        localStorage.getItem("language") !== undefined
      ) {
        req = req.clone({
          setHeaders: {
            Authorization: localStorage.getItem("authToken"),
            language: localStorage.getItem("language"),
          },
        });
      } else if (
        localStorage.getItem("language") &&
        localStorage.getItem("language") !== null &&
        localStorage.getItem("language") !== undefined
      ) {
        req = req.clone({
          setHeaders: {
            language: localStorage.getItem("language"),
          },
        });
      } else {
        req = req.clone({});
      }
    } else {
      // tslint:disable-next-line: max-line-length
      if (
        localStorage.getItem("authToken") &&
        localStorage.getItem("authToken") !== null &&
        localStorage.getItem("authToken") !== undefined &&
        localStorage.getItem("language") &&
        localStorage.getItem("language") !== null &&
        localStorage.getItem("language") !== undefined
      ) {
        req = req.clone({
          setHeaders: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("authToken"),
            language: localStorage.getItem("language"),
          },
        });
      } else {
        req = req.clone({
          setHeaders: {
            "Content-Type": "application/json",
            language: JSON.stringify(this.translationService.getSelectedLanguage())
          },
        });
      }
    }
    this.requests.push(req);
    this.loaderService.display(true);
    // tslint:disable-next-line: deprecation
    return Observable.create((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          if (err && err.status === 401) {
            this.authService.logout();
          }
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
