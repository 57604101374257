import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "./data.service";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SubPackageService extends CommonService {
  constructor(public http: HttpClient) {
    super("subscriptionpackage", http);
  }
  getPackages(data: any) {
    return this.http.post(this.baseUrl + "/fetchpackageView", data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return null;
      })
    );
  }
}
